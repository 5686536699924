import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../Services/auth.service';
import { ToastService } from '../../Services/toast.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { DataTableDirective } from 'angular-datatables';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

declare var $: any; 

@Component({
  selector: 'app-speed-report-detail',
  templateUrl: './speed-report-detail.component.html',
  styleUrls: ['./speed-report-detail.component.sass']
})
export class SpeedReportDetailComponent implements OnInit {
  speedReportDetail :any={};
  errTime =  environment.timeout.errorNotificationTimeout;
  speed_above_type;
  speed_above_id;
  page = 3;
  collectionsize:any;
  page_size:any = 50; 
  current_page:any = 1;
  sort_value:any;
  speedFulldata:any;
  public data = [];
  dtOptions: any = {};
  imgurl = environment.imgUrl
  img_url
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('content') content: ElementRef; 

    // public SavePDF(): void {  
    //   let content=this.content.nativeElement;  
    //   const doc = new jsPDF();  
    //   let _elementHandlers =  
    //   {  
    //     '#editor':function(element,renderer){  
    //       return true;  
    //     }  
    //   };  
    //   doc.fromHTML(content.innerHTML,15,15,{  
    
    //     'width':190,  
    //     'elementHandlers':_elementHandlers  
    //   });  
    
    //   doc.save('Speed-Report-Details.pdf');  
    // }
    
    async createPdf() {
      var doc = new jsPDF();
      async function getBase64ImageFromUrl(imageUrl) {
        var res = await fetch(imageUrl);
        var blob = await res.blob();
      
        return new Promise((resolve, reject) => {
          var reader  = new FileReader();
          reader.addEventListener("load", function () {
              resolve(reader.result);
          }, false);
      
          reader.onerror = () => {
            return reject(this);
          };
          reader.readAsDataURL(blob);
        })
      }

    //  function addWaterMark(doc) {
    //     var totalPages = doc.internal.getNumberOfPages();
    //     var imgData = 'data:image/jpeg;base64' +  getBase64ImageFromUrl('assets/images/MOPH_LOGO.png')
    //     for (let i = 1; i <= totalPages; i++) {
    //       doc.setPage(i);
    //       doc.setTextColor(150);
    //       doc.text(50, doc.internal.pageSize.height - 30, 'Watermark');
    //       doc.addImage(imgData, 'JPEG', 10, 40, 180, 160);
         
    //     }
      
    //     return doc;
    //   }
      let data = []
      let startDate = new Date(this.speedFulldata[this.speedFulldata.length - 1].createdAt).toLocaleString();
      let endDate = new Date(this.speedFulldata[0].createdAt).toLocaleString();
      if(this.speedFulldata.length) data = this.speedFulldata.map(e=>{return [++data.length, Math.floor(e.speed), new Date(e.createdAt).toLocaleString()]})

     
      // if(this.speedFulldata.ambulancesDetails?.hospitalDetails.logo_image){
      //   img_url = this.imgurl + this.speedFulldata.ambulancesDetails?.hospitalDetails.logo_path + this.speedFulldata.ambulancesDetails?.hospitalDetails.logo_image;
      // }else{
      //   img_url = 'assets/images/MOPH_LOGO.png'
      // }
      console.log("img_url: " + this.img_url)
      var imgData = 'data:image/jpeg;base64' + await getBase64ImageFromUrl(this.img_url)
      var opcityimgData = 'data:image/jpeg;base64' + await getBase64ImageFromUrl('assets/images/opcity.png')
      // doc.addImage(imgData, 'JPEG', 15, 40, 180, 160);
     
      doc.setFontSize(14);
      doc.text('Aoc Speed Report.', 11, 20);
      doc.setFontSize(11);
      doc.setTextColor(100);
  
      (doc as any).autoTable({
        margin: {top: 20},
        head: [['-', 'Speed(km/h)', 'Date/Time']],
        body: data,
        theme: 'plain',
      })
      for (let pageNumber = 1; pageNumber <= doc.getNumberOfPages(); pageNumber++) {
        doc.setPage(pageNumber)
        doc.addImage(imgData, 5,3,10,10);
      } 
      for (let pageNumber = 1; pageNumber <= doc.getNumberOfPages(); pageNumber++) {
        doc.setPage(pageNumber)
        doc.addImage(opcityimgData, 50,100,100,100);
      } 
      // doc.output('dataurlnewwindow');
      doc.save('speedreport.pdf');
    }
    
  constructor(private apiService: AuthService,  private toastService:ToastService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
   
    this.speedReportDetail = JSON.parse(localStorage.getItem('speedReportDetail'));
    this.speed_above_type = this.speedReportDetail.speed_above_type
    this.speed_above_id = this.speedReportDetail.speed_above_id
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.page_size,
      lengthMenu : [100, 250, 500],
      serverSide: true,
      processing: true,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        this.spinner.show();
        this.current_page = (dataTablesParameters.start/dataTablesParameters.length)+1;
        this.page_size = dataTablesParameters.length;
        this.apiService.postAmbulanceSpeedReportDetail(
          this.speed_above_type, 
          this.speed_above_id,
          this.page_size,
          this.current_page,
        ).subscribe((res) => { 
          this.speedFulldata = res['content']['data'];
          console.log(":this.speedFulldata", this.speedFulldata)
          if(this.speedFulldata[0].ambulancesDetails?.hospitalDetails.logo_image){
            this.img_url = this.imgurl + this.speedFulldata[0].ambulancesDetails?.hospitalDetails.logo_path + this.speedFulldata[0].ambulancesDetails?.hospitalDetails.logo_image;
          }else{
            this.img_url = 'assets/images/MOPH_LOGO.png'
          }
          this.spinner.hide();
          callback({
            recordsTotal: Number(res['content']['count']),
            recordsFiltered: Number(res['content']['count']),
            data: []
          });
          $('.dataTables_empty').remove();
        },err=>{
          if(err.error && err.error.message){
            this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
            } else{
            this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
          }
        })
      },
    };
    // this.apiService.postAmbulanceSpeedReportDetail(
    //   this.speed_above_type, 
    //   this.speed_above_id,
    //   this.page_size = 10,
    //   this.current_page = 1,
    // ).subscribe((speedReportData: any) => {
    //   if(speedReportData.success){ 
    //     this.speedFulldata = speedReportData['content']['data']
    //     this.collectionsize = speedReportData['content'].count
    //     this.spinner.hide();
    //   }
      
    // },err=>{
    //   if(err.error && err.error.message){
    //     this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
    //     } else{
    //     this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
    //   }
    // })
  }

  // onPageChange(page){
  //   this.apiService.postAmbulanceSpeedReportDetail(
  //     this.speed_above_type, 
  //     this.speed_above_id,
  //     this.page_size = 10,
  //     this.current_page = page
  //   ).subscribe((speedReportData: any) => {
  //     if(speedReportData.success){ 
  //       this.speedFulldata = speedReportData['content']['data']
  //       this.collectionsize = speedReportData['content'].count
  //     }
  //   },err=>{
  //     if(err.error && err.error.message){
  //       this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
  //       } else{
  //       this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
  //     }
  //   })
  // }
 
}
