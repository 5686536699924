import { Component, Input, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../Services/auth.service';
import { ToastService } from '../../Services/toast.service'
import { DataTransService } from '../../Services/data-trans.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../Services/modal.service';

@Component({
  selector: 'app-alive-modal',
  templateUrl: './alive-modal.component.html',
  styleUrls: ['./alive-modal.component.sass']
})
export class AliveModalComponent implements OnInit {
  ambulanceReqForm: FormGroup;
  closeResult = '';
  errTime =  environment.timeout.errorNotificationTimeout;
  mapImg;
  mapImgPath;
  profilePath;
  @Input() aliveData;
  aoc_request_id;
  patientRrequest_id
  ambulanceId
  acceptRequestDetails;
  ambulanceList = [];
  selectHospital: any;
  selectedAmbulanceHospital;
  zone_id;
  constructor(private modalService: NgbModal, private apiService: AuthService,  private toastService:ToastService, public activeModal: NgbActiveModal, private dataTrans:DataTransService, private ngbModalService: NgbModal, private modalsService: ModalService) {}

  ngOnInit(): void {
    this.ambulanceReqForm = new FormGroup({
      hospital_id: new FormControl(null, [Validators.required]),
      ambulance_id: new FormControl(null, [Validators.required]),
    });
    this.aliveData

    this.mapImgPath = environment.apiUrl + this.aliveData?.patientRequestsDetail?.location_image_url;
    this.profilePath = environment.apiUrl + this.aliveData?.patientRequestsDetail?.patientsDetail?.profile_image_url;
  }

  close(){
    this.activeModal.close()
    $('.alivemodalopen').hide();
  }
  openMod(mymodal, detail) {
    this.ngbModalService.open(mymodal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {}, (reason) => {});
    this.patientRrequest_id = detail.patientRequestsDetail._id;
    // this.activeModal.close();
    this.apiService.getAmbPatientReq(detail.patientRequestsDetail).subscribe(data=>{
      if(data['success']){
        this.ambulanceList = data['content']['data'];
      }
    },err=>{
       if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }
  ambulanceChange(e){
    
    let hospital = this.ambulanceList.filter(ele=> { return (ele._id == e.target.value) });
    this.selectHospital = hospital[0]['hospitalsDetail']['full_name'];
    this.selectedAmbulanceHospital =  hospital;
   }

  acceptRequest(){
   
    this.ambulanceId = this.ambulanceReqForm.value['ambulance_id']
    this.apiService.postPatientReqAmbulance(this.patientRrequest_id, this.ambulanceId).subscribe(data=>{
      if(data['success']){
        
        this.acceptRequestDetails = data['content'];
        let acceptReportDetail = this.acceptRequestDetails;
        let patientRrequestid = this.patientRrequest_id
       
        // localStorage.setItem('patientRrequestid', JSON.stringify(patientRrequestid));
        
        this.modalsService.onFirstComponentButtonClick(acceptReportDetail);
        this.dataTrans.secondButtonclickdData(this.selectedAmbulanceHospital);
        this.dataTrans.thirdButtonclickdData(patientRrequestid);
        // this.dataTrans.secondButtonclickdData();
        
      }
    },err=>{
       if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  postPatientReqCancelRequest() {
    this.aoc_request_id = this.aliveData._id
    this.apiService.postPatientReqCancelRequest(this.aoc_request_id).subscribe((data: any) => {
      if(data.success){
        this.activeModal.close()
        $('.alivemodalopen').hide();
      }
      else {
        this.toastService.show('something went wrong please try again!', { classname: 'bg-danger text-white', delay: this.errTime });
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
