import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../../Services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../../Services/toast.service';
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-speed-report',
  templateUrl: './speed-report.component.html',
  styleUrls: ['./speed-report.component.sass']
})

export class SpeedReportComponent implements OnInit {
  zone_id:any;
  zoneList:any;
  hospitalList:any;
  ambulanceList:any;
  speedReportForm: FormGroup;
  submitted  = false;
  startDate;
  endDate; 
  ambulanceId; 
  hospitalId; 
  speedLimit;
  ambulanceSpeedReportList;
  user_id;
  userHospital;
  newDates;
  seletStart;
  selectDate:'01/01/2018 - 01/15/2018';
  
  page = 3;
  collectionsize:any;
  page_size:any = 10; 
  current_page:any = 1;
  sort_value:any;
  errTime =  environment.timeout.errorNotificationTimeout;
  constructor(private spinner: NgxSpinnerService, private apiService: AuthService, private cookieService : CookieService, private toastService:ToastService,  private _location: Location, private router: Router) { }
  
  selectSpeedreport = {
    _id: null,
    zone_id:'',
    hospital_id:'',
    ambulance_id:'',
    speed_limit:'speed_above_80',
  }
 
  ngOnInit(): void {
    this.user_id = this.cookieService.get('api_key');
    
    this.getAllAocZonesLists();
    this.speedReportForm = new FormGroup({
      _id: new FormControl(null),
      zone_id: new FormControl(null),
      hospital_id: new FormControl(null),
      ambulance_id: new FormControl(null),
      speed_limit: new FormControl(null),
    })

    this.getUserHospital(this.user_id)
    // this.postAmbulanceSpeedReport();
    this.datePickerFun();

   
  }

  datePickerFun(){
    var self = this;
    $('#daterange').daterangepicker({
      opens: 'left',
    }, function(start, end) {
        self.startDate = start;
        self.endDate = end;
    });
  }
  getAllAocZonesLists(){
    let userId = this.cookieService.get('api_key')
    this.spinner.show();
    this.apiService.getHospitalsByUserList(userId).subscribe((data) => {
      this.zoneList = data['content']['data'][0]['zoneDetails'];
      this.spinner.hide();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  getHospitalsList(zone_id) {
    this.spinner.show();
    this.apiService.getZoneHospitalsList(zone_id, this.user_id).subscribe((hospitalDetail: any) => {
      this.hospitalList = hospitalDetail['content']['data'];
      this.spinner.hide();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  getHospitalAmbulancesList(hospital_id) {
    this.spinner.show();
    this.apiService.getHospitalAmbulancesList(hospital_id).subscribe((ambulanceDetail: any) => {
      this.ambulanceList = ambulanceDetail['content']['data'];
      this.spinner.hide();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  getUserHospital(user_id) {
    this.spinner.show();
    this.apiService.getUserHospital(user_id).subscribe((ambulanceDetail: any) => {
      this.userHospital = ambulanceDetail['content']['data'][0]['hospital_id'];
      // this.selectSpeedreport.hospital_id = this.userHospital
      this.postAmbulanceSpeedReport();
      this.spinner.hide();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  zoneChange(e) {
    this.getHospitalsList(e.target.value);
  }
  
  hospitalChange(e){
    if(e.target.value){
      this.getHospitalAmbulancesList(e.target.value)
    }

    if(e.target.value == ''){
      this.selectSpeedreport.hospital_id = this.userHospital
    }
  }

  postAmbulanceSpeedReport(page_size = 10, current_page = 1){
    this.spinner.show();
    if(this.selectSpeedreport.hospital_id == ''){
      this.hospitalId =  this.userHospital
    }
    this.apiService.postAmbulanceSpeedReport(
      this.startDate, 
      this.endDate, 
      this.ambulanceId, 
      this.hospitalId, 
      this.speedLimit,
      
      this.page_size = page_size,
      this.current_page = current_page,
      ).subscribe((res: any)=>{
      if(res.success){ 
        this.ambulanceSpeedReportList = res['content']['data']
        this.collectionsize = res['content'].count
        this.spinner.hide();
      }
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 15000);
  }
  
  onSubmit(speedReportFormValue) {

    
    this.page_size = 10,
    this.current_page = 1,
    this.submitted = true;
    this.startDate = new Date(this.startDate).toISOString();
    this.endDate = new Date(this.endDate).toISOString();
    this.ambulanceId = speedReportFormValue.ambulance_id;
    if(speedReportFormValue.hospital_id){
      this.hospitalId = speedReportFormValue.hospital_id;
    }else{
      this.hospitalId = this.userHospital
    }
    this.speedLimit = speedReportFormValue.speed_limit;
    if( this.speedReportForm.value){
      $('#submitLoader').css('display', 'inline-block');
      this.postAmbulanceSpeedReport()
      $('#submitLoader').hide();
    }

    // this.spinner.show();
    // this.apiService.postAmbulanceSpeedReport(this.startDate, this.endDate, this.ambulanceId, this.hospitalId, this.speedLimit, this.page_size, this.current_page ).subscribe((res: any)=>{
    //   if(res.success){
    //     this.ambulanceSpeedReportList = res['content']['data']
    //     this.collectionsize = res['content'].count
    //     this.spinner.hide();
    //   }
    // },err=>{
    //   if(err.error && err.error.message){
    //     this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
    //     } else{
    //     this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
    //   }
    // })
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 5000);
  }

  onPageChange(page){
    this.current_page = page
    this.postAmbulanceSpeedReport(this.page_size, this.current_page)
  }

  speedReportDetail(detail){
    let speedReportDetail = {};
    speedReportDetail['speed_above_type'] = this.speedLimit;
    speedReportDetail['speed_above_id'] = detail._id;
    localStorage.setItem('speedReportDetail', JSON.stringify(speedReportDetail));
    window.open(this._location.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree(['/user/speed-report-detail']))), '_blank');
  }
}
