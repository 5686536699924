import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../../Services/auth.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { ToastService } from "../../Services/toast.service";
import {
  NgbModalConfig,
  NgbModal,
  NgbAccordion,
} from "@ng-bootstrap/ng-bootstrap";
import { DirectionViewComponent } from "../../common/direction-view/direction-view.component";
import { LocationService } from "src/app/Services/location.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-history-ongoing",
  templateUrl: "./history-ongoing.component.html",
  styleUrls: ["./history-ongoing.component.sass"],
})
export class HistoryOngoingComponent implements OnInit {
  errTime = environment.timeout.errorNotificationTimeout;
  page = 3;
  collectionsize: any;
  page_size: any;
  current_page: any;
  requestList: any;
  request_status: any;
  sort_value: any;
  hospital_id: any;
  zone_id: any;
  request_type: any;
  zoneList: any;
  hospitalList: any;
  filterReqForm: FormGroup;
  user_id: any;
  trackingDetail: any;
  isMapLoad: boolean = false;
  severityColorCode: { "background-color": string };
  eta_time: string;
  ambulance_id;
  constructor(
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private apiService: AuthService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private locationService: LocationService
  ) {}

  selectFilterreq = {
    zone_id: "",
    hospital_id: "",
    request_status: "",
    request_type: "",
  };
  ngOnInit(): void {
    this.user_id = this.cookieService.get("user_id");
    this.aocOngoingRequestHistory();
    // this.getAllAocZonesList();

    this.filterReqForm = new FormGroup({
      zone_id: new FormControl(),
      hospital_id: new FormControl(),
      request_status: new FormControl(),
      request_type: new FormControl(),
    });
    this.getZone();
  }

  submitFilter(filterReqFormValue) {
    this.zone_id = this.filterReqForm.value["zone_id"];
    this.hospital_id = this.filterReqForm.value["hospital_id"];
    this.request_status = this.filterReqForm.value["request_status"];
    this.request_type = this.filterReqForm.value["request_type"];

    if (this.filterReqForm.value) {
      $("#submitLoader").css("display", "inline-block");
      this.aocOngoingRequestHistory();
      $("#submitLoader").hide();
    }
  }

  getZone() {
    let userId = this.cookieService.get("api_key");
    this.apiService.getHospitalsByUserList(userId).subscribe(
      (data) => {
        this.zoneList = data["content"]["data"][0]["zoneDetails"];
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  getHospitalsList(zoneId) {
    // this.spinner.show();
    this.apiService.getHospitalsList(zoneId).subscribe(
      (hospitalDetail: any) => {
        this.hospitalList = hospitalDetail["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }
  zoneChange(e) {
    if (e.target.value) {
      this.getHospitalsList(e.target.value);
    }
  }

  aocOngoingRequestHistory(page_size = 10, current_page = 1) {
    this.spinner.show();
    // this.page_size = page_size;
    // this.current_page = current_page ;
    console.log(
      "117 aocOngoingRequestHistory",
      this.cookieService.get("user_id")
    );
    this.apiService
      .aocOngoingRequestHistory(
        (this.user_id = this.user_id),
        (this.sort_value = '{"_id":1}'),
        (this.page_size = page_size),
        (this.current_page = current_page),
        (this.request_status = this.request_status ? this.request_status : ""),
        (this.hospital_id = this.hospital_id ? this.hospital_id : ""),
        (this.zone_id = this.zone_id ? this.zone_id : ""),
        (this.request_type = this.request_type ? this.request_type : "")
      )
      .subscribe(
        (data) => {
          this.requestList = data["content"]["data"];
          this.collectionsize = data["content"].count;
          this.spinner.hide();
        },
        (err) => {
          if (err.error && err.error.message) {
            this.toastService.show(
              err.error.message + `<br>` + "status code:" + err.status,
              { classname: "bg-danger text-white", delay: this.errTime }
            );
          } else {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }
        }
      );
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }
  onPageChange(page) {
    this.current_page = page;
    this.aocOngoingRequestHistory(this.page_size, this.current_page);
  }

  openDirectionModal(request) {
    const modaref = this.modalService.open(DirectionViewComponent, {
      windowClass: "sidebar_list snapModal",
    });
    modaref.componentInstance.onGoingData = request;
  }
}
