import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../../Services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../../Services/toast.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.sass']
})
export class NotificationsListComponent implements OnInit {
  page = 3;
  collectionsize:any;
  page_size:any = 10; 
  current_page:any = 1;
  sort_value:any;

  user_id;
  zoneList;
  hospitalList;
  startDate = '';
  endDate = '';
  crashtList;
  hospitalId = '';
  zoneId = '';
  userId;
  userHospital;
  crashReportForm: FormGroup;
  submitted  = false;
  imgPath;
  dateBoolean:boolean = true;
  errTime =  environment.timeout.errorNotificationTimeout;
  constructor(private spinner: NgxSpinnerService, private apiService: AuthService, private cookieService : CookieService, private toastService:ToastService) { }

  selectCrashreport = {
    _id: null,
    zone_id:'',
    hospital_id:'',
    range_date:''
  }

  ngOnInit(): void {
    this.getAllAocZonesLists();

    this.user_id = this.cookieService.get('user_id');
    this.postAmbulanceCrashReport();

    this.crashReportForm = new FormGroup({
      _id: new FormControl(null),
      zone_id: new FormControl(null),
      hospital_id: new FormControl(null),
      range_date: new FormControl(null)
    })
    this.datePickerFun();

  }

  datePickerFun(){
    var self = this;
    $('#daterange').daterangepicker({
      opens: 'left',
    }, function(start, end) {
        self.startDate = start;
        self.endDate = end;
        self.dateBoolean = false;
    });
  }

  getAllAocZonesLists(){    
    let userId = this.cookieService.get('api_key')
    this.spinner.show();
    this.apiService.getHospitalsByUserList(userId).subscribe((data) => {

      this.zoneList = data['content']['data'][0]['zoneDetails'];
      this.spinner.hide();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  getHospitalsList(zone_id) {
    this.spinner.show();
    this.apiService.getZoneHospitalsList(zone_id, this.user_id).subscribe((hospitalDetail: any) => {
      this.hospitalList = hospitalDetail['content']['data'];
      this.spinner.hide();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }


  zoneChange(e) {
    if(e.target.value){
      this.getHospitalsList(e.target.value);
    }
  }
  
  onSubmit(crashReportFormValue) {
    this.page_size = 10,
    this.current_page = 1,
    this.submitted = true;
    this.startDate = new Date(this.startDate).toISOString();
    this.endDate = new Date(this.endDate).toISOString();
    this.zoneId = crashReportFormValue.zone_id;
    this.hospitalId = crashReportFormValue.hospital_id;
    if( this.crashReportForm.value){
      this.postAmbulanceCrashReport()
    }
  }
  postAmbulanceCrashReport(page_size = 10, current_page = 1){
      this.spinner.show();
      this.apiService.postAmbulanceCrashReport(
        this.startDate, 
        this.endDate, 
        this.zoneId,
        this.hospitalId, 
        this.userId = this.user_id,
        this.page_size = page_size,
        this.current_page = current_page,

      ).subscribe((data) => {
        this.crashtList = data['content']['data'];
        this.imgPath = data['content']?.tracking_server_url;
        this.collectionsize = data['content'].count
        this.spinner.hide();
      },err=>{
        if(err.error && err.error.message){
          this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
          } else{
          this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
        }
      })
       setTimeout(() => {
        this.spinner.hide();
      }, 5000);
  }
  onPageChange(page){
    this.current_page = page
    this.postAmbulanceCrashReport(this.page_size, this.current_page)
  }
}
