import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmbulanceListComponent } from './ambulance-list/ambulance-list.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgxSpinnerModule } from "ngx-spinner";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AddAmbulanceComponent } from './add-ambulance/add-ambulance.component';
import { SnapshotViewComponent } from './snapshot-view/snapshot-view.component';
import { PatientReqListComponent } from './patient-req-list/patient-req-list.component';
import { DataTablesModule } from "angular-datatables";
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../environments/environment';
import { DirectionViewComponent } from './direction-view/direction-view.component';
import { MonitorViewComponent } from './monitor-view/monitor-view.component';
import { EcgWaveformComponent } from './ecg-waveform/ecg-waveform.component';
import { AliveModalComponent } from './alive-modal/alive-modal.component';

@NgModule({
  declarations: [
    AmbulanceListComponent, 
    AddAmbulanceComponent, 
    SnapshotViewComponent, 
    PatientReqListComponent, 
    DirectionViewComponent, MonitorViewComponent, EcgWaveformComponent, AliveModalComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    NgxSpinnerModule,
    NgbModule,
    DataTablesModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApiKey,
      language: 'en',
      libraries: ['geometry', 'places'],
    })
  ]
})
export class CommonsModule { }
