<div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="card main-card mb-3">
          <div class="card-header-tab card-header">
            <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
              <i class="header-icon fa fa-list-alt"> </i>
              {{'titles.aoc_request_report' | translate}}
            </div>
          </div>
          <div class="no-gutters row">
            <div class="card-body">
              <form [formGroup]="filterReqForm" #form="ngForm">
                <div class="header-filter">
                  <div class="row align-items-center">
                    <div class="col-md-3 col-sm-6">
                      <div class="form-group position-relative mb-0">
                        <select name="zone_id" id="zoneId" class="form-control" [(ngModel)]="selectFilterreq.zone_id" formControlName="zone_id" (change)="zoneChange($event)">
                          <option value="" selected>{{'labels.selectzone' | translate}}</option>
                          <option value="{{zone._id}}"  *ngFor="let zone of zoneList">{{zone.name1}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="form-group position-relative mb-0">
                        <select name="hospital_id" id="destination" class="form-control" [(ngModel)]="selectFilterreq.hospital_id" (change)="hospitalChange($event)" formControlName="hospital_id">
                          <option value="" selected>{{'labels.selectHospital' | translate}}</option>
                          <option value="{{hospital._id}}"  *ngFor="let hospital of hospitalList">{{hospital.name1}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="form-group position-relative mb-0">
                          <select name="ambulance_id" id="destination"  class="form-control" [(ngModel)]="selectFilterreq.ambulance_id" formControlName="ambulance_id">
                            <option value="" selected>{{'labels.selectAmbulance' | translate}}</option>
                            <option value="{{ambulance._id}}"  *ngFor="let ambulance of ambulanceList">{{ambulance.name1}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4">
                        <button type="submit" (click)="submitFilter()" class="btn btn-primary mr-1">{{'buttons.submit' | translate}}
                          <span class="spinner-grow spinner-grow-sm" id="submitLoader" role="status" aria-hidden="true"></span>
                        </button>
                        <button class="btn btn-info" [disabled]="requestList?.length == 0" (click)="createPdf()">
                          {{'buttons.pdf' | translate}}
                         </button>
                      </div>
                </div>
                </div>
              </form>
              <div class="page-body historyTable">
                <div class="paginations">
                  <ngb-pagination [collectionSize]="collectionsize" (pageChange)="onPageChange($event)" [pageSize]="page_size" [(page)]="page" [rotate] = "true" [maxSize]="5" [boundaryLinks]="true" aria-label="Default pagination"></ngb-pagination>
                </div>
                <div class="table-responsive">
                  <table class="table table-striped row-border w-100" id="my-table">
                    <thead>
                      <tr>
                        <th>{{'labels.id' | translate}}</th>
                        <th>{{'labels.ambulance' | translate}}</th>
                        <th>{{'labels.patient' | translate}}</th>
                        <th>{{'labels.request_code' | translate}}</th>
                        <!-- <th>{{'labels.OPERATION_ASSIGNED' | translate}}</th> -->
                        <th>{{'labels.START_OPERATION' | translate}}</th>
                        <th>{{'labels.DEPART_FROM_BASE_HOSPITAL' | translate}}</th>
                        <th>{{'labels.ARRIVAL_AT_SCENE' | translate}}</th>
                        <th>{{'labels.GO_TO_BASE_HOSPITAL' | translate}}</th>
                        <th>{{'labels.GO_TO_ANOTHER_HOSPITAL' | translate}}</th>
                        <th>{{'labels.DEPART_FROM_SCENE' | translate}}</th>
                        <th>{{'labels.ARRIVAL_AT_BASE_HOSPITAL' | translate}}</th>
                        <th>{{'labels.ARRIVAL_AT_ANOTHER_HOSPITAL' | translate}}</th>
                        <th>{{'labels.GO_BACK_TO_BASE_HOSPITAL' | translate}}</th>
                        <th>{{'labels.COME_BACK_TO_BASE_HOSPITAL' | translate}}</th>
                        <th>{{'labels.COMPLETED' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="no-data" *ngIf="requestList?.length == 0">
                        <td colspan="13">{{'error.nodata' | translate}}</td>
                      </tr>
                      <tr *ngFor="let detail of requestList; let i = index">
                        <td>{{((current_page-1)*page_size + 1) + i}}</td>
                        <td *ngIf="detail.request_type == 'EMS'">{{'labels.ems'| translate }}{{'-'+ detail.ambulancesDetail.ambulance_box_code + detail.ambulancesDetail.name1}}</td>
                        <td *ngIf="detail.request_type == 'REF'">{{'labels.ref'| translate }}{{'-'+ detail.ambulancesDetail.ambulance_box_code + detail.ambulancesDetail.name1}}</td>
                        <td *ngIf="detail.request_type == 'IN'">{{'labels.in'| translate }}{{'-'+ detail.ambulancesDetail.ambulance_box_code + detail.ambulancesDetail.name1}}</td>
                        <td *ngIf="detail.request_type == 'OUT'">{{'labels.out'| translate }}{{'-'+ detail.ambulancesDetail.ambulance_box_code + detail.ambulancesDetail.name1}}</td>
                        <td *ngIf="detail.request_type == 'BACK'">{{'labels.back'| translate }}{{'-'+ detail.ambulancesDetail.ambulance_box_code + detail.ambulancesDetail.name1}}</td>
                        <td *ngIf="detail.request_type == 'OTHER'">{{'labels.other'| translate }}{{'-'+ detail.ambulancesDetail.ambulance_box_code + detail.ambulancesDetail.name1}}</td>

                        <td>{{detail.ambulancesDetail?.aocRequestsDetail?.patientRequestsDetail?.patientsDetail?.first_name}} {{detail.ambulancesDetail?.aocRequestsDetail?.patientRequestsDetail?.patientsDetail?.last_name}}</td>
                        <td>{{detail.request_code}}</td>
                        <!-- <td *ngIf= "detail.request_status_change[0].request_status == 'START_OPERATION'"> -->
                            <ng-template [ngIf]="detail.request_status_change[0]?.request_status == 'START_OPERATION'" [ngIfElse]="START_OPERATION" > 
                                <td>                        
                                {{detail.request_status_change[0]?.request_status}} <br><br>
                                {{detail.request_status_change[0]?.createdAt | date : 'medium'}}<br>
                                 {{detail.request_status_change[0]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #START_OPERATION> <td></td></ng-template>

                            <ng-template [ngIf]="detail.request_status_change[1]?.request_status == 'DEPART_FROM_BASE_HOSPITAL'" [ngIfElse]="DEPART_FROM_BASE_HOSPITAL" > 
                                <td>                        
                                {{detail.request_status_change[1]?.request_status}} <br><br>
                                {{detail.request_status_change[1]?.createdAt | date : 'medium'}}<br>
                                 {{detail.request_status_change[1]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #DEPART_FROM_BASE_HOSPITAL> <td></td></ng-template>

                            <ng-template [ngIf]="detail.request_status_change[2]?.request_status == 'ARRIVAL_AT_SCENE'" [ngIfElse]="ARRIVAL_AT_SCENE" > 
                                <td>                        
                                {{detail.request_status_change[2]?.request_status}} <br><br>
                                {{detail.request_status_change[2]?.createdAt | date : 'medium'}}<br>
                                 {{detail.request_status_change[2]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #ARRIVAL_AT_SCENE> <td></td></ng-template>

                            <ng-template [ngIf]="detail.request_status_change[3]?.request_status == 'GO_TO_BASE_HOSPITAL'" [ngIfElse]="GO_TO_BASE_HOSPITAL" > 
                                <td>                        
                                {{detail.request_status_change[3]?.request_status}} <br><br>
                                {{detail.request_status_change[3]?.createdAt | date : 'medium'}}<br>
                                {{detail.request_status_change[3]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #GO_TO_BASE_HOSPITAL> <td></td></ng-template>

                            <ng-template [ngIf]="detail.request_status_change[3]?.request_status == 'GO_TO_ANOTHER_HOSPITAL'" [ngIfElse]="GO_TO_ANOTHER_HOSPITAL" > 
                                <td>                        
                                {{detail.request_status_change[3]?.request_status}} <br><br>
                                {{detail.request_status_change[3]?.createdAt | date : 'medium'}}<br>
                                {{detail.request_status_change[3]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #GO_TO_ANOTHER_HOSPITAL> <td></td></ng-template>

                            <ng-template [ngIf]="detail.request_status_change[4]?.request_status == 'DEPART_FROM_SCENE'" [ngIfElse]="DEPART_FROM_SCENE" > 
                                <td>                        
                                {{detail.request_status_change[4]?.request_status}} <br><br>
                                {{detail.request_status_change[4]?.createdAt | date : 'medium'}}<br>
                                {{detail.request_status_change[4]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #DEPART_FROM_SCENE> <td></td></ng-template>

                            <ng-template [ngIf]="detail.request_status_change[5]?.request_status == 'ARRIVAL_AT_BASE_HOSPITAL'" [ngIfElse]="ARRIVAL_AT_BASE_HOSPITAL" > 
                                <td>                        
                                {{detail.request_status_change[5]?.request_status}} <br><br>
                                {{detail.request_status_change[5]?.createdAt | date : 'medium'}}<br>
                                {{detail.request_status_change[5]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #ARRIVAL_AT_BASE_HOSPITAL> <td></td></ng-template>

                            <ng-template [ngIf]="detail.request_status_change[5]?.request_status == 'ARRIVAL_AT_ANOTHER_HOSPITAL'" [ngIfElse]="ARRIVAL_AT_ANOTHER_HOSPITAL" > 
                                <td>      
                                {{detail.request_status_change[5]?.request_status}} <br><br>
                                {{detail.request_status_change[5]?.createdAt | date : 'medium'}}<br>
                                {{detail.request_status_change[5]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #ARRIVAL_AT_ANOTHER_HOSPITAL>
                                <ng-template [ngIf]="detail.request_status_change[2]?.request_status == 'ARRIVAL_AT_ANOTHER_HOSPITAL'" [ngIfElse]="ARRIVAL_AT_ANOTHER_HOSPITAL2" > 
                                    <td>     
                                    {{detail.request_status_change[2]?.request_status}} <br><br>
                                    {{detail.request_status_change[2]?.createdAt | date : 'medium'}}<br>
                                    {{detail.request_status_change[2]?.time_duration}} 
                                </td>
                                </ng-template>
                                <ng-template #ARRIVAL_AT_ANOTHER_HOSPITAL2> <td></td></ng-template>
                            </ng-template>


                            <ng-template [ngIf]="detail.request_status_change[6]?.request_status == 'GO_BACK_TO_BASE_HOSPITAL'" [ngIfElse]="GO_BACK_TO_BASE_HOSPITAL" > 
                                <td>                        
                                {{detail.request_status_change[6]?.request_status}} <br><br>
                                {{detail.request_status_change[6]?.createdAt | date : 'medium'}}<br>
                                {{detail.request_status_change[6]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #GO_BACK_TO_BASE_HOSPITAL> 
                                <ng-template [ngIf]="detail.request_status_change[3]?.request_status == 'GO_BACK_TO_BASE_HOSPITAL'" [ngIfElse]="GO_BACK_TO_BASE_HOSPITAL2" > 
                                    <td>     
                                      {{detail.request_status_change[3]?.request_status}} <br> <br>
                                      {{detail.request_status_change[3]?.createdAt | date : 'medium'}} <br>
                                {{detail.request_status_change[3]?.time_duration}} 
                                </td>
                                </ng-template>
                                <ng-template #GO_BACK_TO_BASE_HOSPITAL2> <td></td></ng-template>
                            </ng-template>


                            <ng-template [ngIf]="detail.request_status_change[7]?.request_status == 'COME_BACK_TO_BASE_HOSPITAL'" [ngIfElse]="COME_BACK_TO_BASE_HOSPITAL" > 
                                <td>                        
                                {{detail.request_status_change[7]?.request_status}} <br> <br>
                                {{detail.request_status_change[7]?.createdAt | date : 'medium'}}<br>
                                {{detail.request_status_change[7]?.time_duration}} 
                            </td>
                            </ng-template>
                            <ng-template #COME_BACK_TO_BASE_HOSPITAL> 
                                <ng-template [ngIf]="detail.request_status_change[4]?.request_status == 'COME_BACK_TO_BASE_HOSPITAL'" [ngIfElse]="COME_BACK_TO_BASE_HOSPITAL2" > 
                                    <td>     
                                    {{detail.request_status_change[4]?.request_status}} <br> <br>
                                    {{detail.request_status_change[4]?.createdAt | date : 'medium'}}<br>
                                {{detail.request_status_change[4]?.time_duration}} 
                                </td>
                                </ng-template>
                                <ng-template #COME_BACK_TO_BASE_HOSPITAL2> <td></td></ng-template>
                            </ng-template>

                            <ng-template [ngIf]="detail.request_status_change[8]?.request_status == 'COMPLETED'" [ngIfElse]="COMPLETED" >
                                <td>                        
                                {{detail.request_status_change[8]?.request_status}} <br> <br>
                                {{detail.request_status_change[8]?.createdAt | date : 'medium'}} <br>
                                {{detail.request_status_change[8]?.time_duration}}
                            </td>
                            </ng-template>

                            <ng-template #COMPLETED>
                                <ng-template  #COMPLETED [ngIf]="detail.request_status_change[6]?.request_status == 'COMPLETED'" [ngIfElse]="COMPLETED2" >
                                    <td>                        
                                      {{detail.request_status_change[6]?.request_status}} <br><br>
                                      {{detail.request_status_change[6]?.createdAt | date : 'medium'}}<br>
                                      {{detail.request_status_change[6]?.time_duration}}
                                </td>
                                </ng-template>
                                <ng-template #COMPLETED2> 
                                    <ng-template [ngIf]="detail.request_status_change[5]?.request_status == 'COMPLETED'" [ngIfElse]="COMPLETED2" > 
                                        <td>     
                                          {{detail.request_status_change[5]?.request_status}} <br> <br>
                                          {{detail.request_status_change[5]?.createdAt | date : 'medium'}}<br>
                                        {{detail.request_status_change[5]?.time_duration}}
                                    </td>
                                    </ng-template>
                                    <ng-template #COMPLETED2> <td></td></ng-template>
                                </ng-template>
                            </ng-template>
                            
                        <!-- </td> -->
                        
                      </tr>
                    </tbody>
                  </table>
                </div> 
              </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
          </div>
          </div>
        </div>
      </div>
  </div>