<div class="row">
  <div class="col-12">
    <div class="main-content mapFullview">
      <div class="map_fullview">
        <div #mapContainer id="map"></div>
      </div>

      <div class="operationList">
        <table class="table fixed_headers">
          <tbody>
              <tr class="dashboradsort w-100" style="max-width: 100%;">
                <td class="boxcodefilter">
                  <button class="btn m-0" (click) = "sortAocRequestsList('ambulance_box_code')">
                    <i class="fa fa-sort" aria-hidden="true"></i>
                  </button>
                </td>
                <td class="speedfilter">
                  <div class="row">
                    <div class="col-md-6">
                      <button class="btn" (click) = "sortAocRequestsList('estimated_time')">
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button class="btn" (click) = "sortAocRequestsList('speed')">
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </td>
                <td class="statusfilter">
                  <div class="form-group">
                    <ng-select [clearable]="false" [ngModel]='statusModal'  (change) = "requeststatusChange($event)">
                      <ng-option value=''>All</ng-option>
                      <ng-option value='OPERATION_ASSIGNED'>{{'labels.OPERATION_ASSIGNED' | translate}}</ng-option>
                      <ng-option value="START_OPERATION">{{'buttons.startopt' | translate}}</ng-option>
                      <ng-option value="DEPART_FROM_BASE_HOSPITAL">{{'buttons.depart_from_base_hospital' | translate}}</ng-option>
                      <ng-option value="ARRIVAL_AT_SCENE">{{'buttons.arrival_at_scene' | translate}}</ng-option>
                      <ng-option value="GO_TO_BASE_HOSPITAL">{{'buttons.go_to_base_hospital' | translate}}</ng-option>
                      <ng-option value="GO_TO_ANOTHER_HOSPITAL">{{'buttons.go_to_another_hospital' | translate}}</ng-option>
                      <ng-option value="DEPART_FROM_SCENE">{{'buttons.depart_from_scent' | translate}}</ng-option>
                      <ng-option value="ARRIVAL_AT_BASE_HOSPITAL">{{'buttons.arrival_at_base_hospital' | translate}}</ng-option>
                      <ng-option value="ARRIVAL_AT_ANOTHER_HOSPITAL">{{'buttons.arrival_at_another_hospital' | translate}}</ng-option>
                      <ng-option value="GO_BACK_TO_BASE_HOSPITAL">{{'buttons.go_back_to_base_hospital' | translate}}</ng-option>
                      <ng-option value="COME_BACK_TO_BASE_HOSPITAL">{{'buttons.come_back_to_base_hospital' | translate}}</ng-option>
                   </ng-select>
                  </div>
                </td>
                <td class="searchfilter">
                  <div class="form-group position-relative">
                    <input name="search" placeholder="{{'labels.search' | translate}}" id="search" type="text" class="form-control" #searchvalue (input)="filterItem(searchvalue.value)">
                  </div>
                </td>
              </tr>
            <div *ngIf="aocRequestsList.length > 0">
              <tr *ngFor="let detail of aocRequestsList; let i = index">
                <td class="boxcodefilter" scope="row" (click)="ambulanceFocus(detail._id)">
                  <span class="d-flex" [style.color]="detail.ambulanceTrackingsDetail.speed_color_code">
                    <span>{{detail.ambulance_box_code}}:</span>
                    <span class="testOverflow">{{detail.name1}}</span>
                    <span *ngIf="detail.aocRequestsDetail?.request_type =='EMS'" >{{'labels.ems'| translate}}</span>
                    <span *ngIf="detail.aocRequestsDetail?.request_type =='REF'" >{{'labels.ref'| translate}}</span>
                    <span *ngIf="detail.aocRequestsDetail?.request_type =='IN'" >{{'labels.in'| translate}}</span>
                    <span *ngIf="detail.aocRequestsDetail?.request_type =='OUT'" >{{'labels.out'| translate}}</span>
                    <span *ngIf="detail.aocRequestsDetail?.request_type =='BACK'" >{{'labels.back'| translate}}</span>
                    <span *ngIf="detail.aocRequestsDetail?.request_type =='OTHER'" >{{'labels.other'| translate}}</span>
                    <span *ngIf="!detail.aocRequestsDetail.request_type">{{detail.aocRequestsDetail.request_type}}</span>
                  </span>
                </td>
                <td class="speedfilter">
                  <div class="optvaluebox">
                    <div class="optvaluebox">
                      <span class="speedRound" [style.border-color]="detail.patientRequestsDetail.severity_color_code">
                        <div>
                          <span *ngIf="detail.ambulanceTrackingsDetail.estimated_time == undefined; else etaCheck2">
                            <div>00:00</div>
                          </span>
                          <ng-template #etaCheck2>
                            <div>{{detail.ambulanceTrackingsDetail.estimated_time * 1000 * 60 | date:'HH:mm':'UTC'}}</div>
                          </ng-template>
                          <div class="lastvalue">{{'labels.hhmm' | translate}}</div>
                        </div>
                      </span>
                      <span class="speedRound" [style.border-color]="detail.ambulanceTrackingsDetail.speed_color_code">
                        <div>
                          <span *ngIf="detail.ambulanceTrackingsDetail.speed == undefined; else speedCheck2">
                            <div>0</div>
                          </span>
                          <ng-template #speedCheck2>
                            <div>{{detail.ambulanceTrackingsDetail.speed}}</div>
                          </ng-template>
                          <div class="lastvalue">{{'labels.kmhr' | translate}}</div>
                        </div>
                      </span>
                    </div>
                  </div>
                </td>
                <td class="statusfilter">
                  <div class="assignbox" *ngIf="detail.aocRequestsDetail" >
                    <div class="assignimg">
                      <img *ngIf="detail.aocRequestsDetail.request_status === ''"
                        src="assets/images/status_icon/Frame.svg" alt="operation_assigned" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'START_OPERATION'"
                        src="assets/images/status_icon/Start operation.svg" alt="START_OPERATION" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'OPERATION_ASSIGNED'"
                        src="assets/images/status_icon/Frame.svg" alt="operation_assigned" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'DEPART_FROM_SCENE'"
                        src="assets/images/status_icon/Depart From Scene.svg" alt="DEPART_FROM_SCENE" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'DEPART_FROM_BASE_HOSPITAL'"
                        src="assets/images/status_icon/Depart From Hospital Base.svg" alt="DEPART_FROM_BASE_HOSPITAL"
                        class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'ARRIVAL_AT_SCENE'"
                        src="assets/images/status_icon/Arrival at Scene.svg" alt="ARRIVAL_AT_SCENE" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'GO_TO_BASE_HOSPITAL'"
                        src="assets/images/status_icon/Go to base hospital.svg" alt="GO_TO_BASE_HOSPITAL"
                        class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'ARRIVAL_AT_BASE_HOSPITAL'"
                        src="assets/images/status_icon/Arrival Hospital Base.svg" alt="ARRIVAL_AT_BASE_HOSPITAL"
                        class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'GO_TO_ANOTHER_HOSPITAL'"
                        src="assets/images/status_icon/Go to another hospital.svg" alt="GO_TO_ANOTHER_HOSPITAL"
                        class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'ARRIVAL_AT_ANOTHER_HOSPITAL'"
                        src="assets/images/status_icon/Arrival Destination Hospital.svg"
                        alt="ARRIVAL_AT_ANOTHER_HOSPITAL" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'GO_BACK_TO_BASE_HOSPITAL'"
                        src="assets/images/status_icon/Start Comming Back to Hospital Base.svg"
                        alt="GO_BACK_TO_BASE_HOSPITAL" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'COME_BACK_TO_BASE_HOSPITAL'"
                        src="assets/images/status_icon/Start Comming Back to Hospital Base.svg"
                        alt="COME_BACK_TO_BASE_HOSPITAL" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'CANCELED'"
                        src="assets/images/status_icon/Icon_plus.svg" alt="CANCELED" class="img-fluid">

                      <img *ngIf="detail.aocRequestsDetail.request_status === 'COMPLETED'"
                        src="assets/images/status_icon/Complate.svg" alt="operation_assigned" class="img-fluid">
                    </div>
                    <span class="assignValue">
                      <!-- {{detail.aocRequestsDetail.request_status}} -->
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'OPERATION_ASSIGNED'">{{'labels.OPERATION_ASSIGNED' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'START_OPERATION'">{{'buttons.startopt' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'DEPART_FROM_BASE_HOSPITAL'">{{'buttons.depart_from_base_hospital' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'ARRIVAL_AT_SCENE'">{{'buttons.arrival_at_scene' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'GO_TO_BASE_HOSPITAL'">{{'buttons.go_to_base_hospital' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'GO_TO_ANOTHER_HOSPITAL'">{{'buttons.go_to_another_hospital' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'DEPART_FROM_SCENE'">{{'buttons.depart_from_scent' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'ARRIVAL_AT_BASE_HOSPITAL'">{{'buttons.arrival_at_base_hospital' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'ARRIVAL_AT_ANOTHER_HOSPITAL'">{{'buttons.arrival_at_another_hospital' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'GO_BACK_TO_BASE_HOSPITAL'">{{'buttons.go_back_to_base_hospital' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'COME_BACK_TO_BASE_HOSPITAL'">{{'buttons.come_back_to_base_hospital' | translate}}</span>
                      <span *ngIf="detail.aocRequestsDetail.request_status == 'COMPLETED'">{{'buttons.completed' | translate}}</span>
                    </span>
                  </div>
                </td>
                <td class="actions searchfilter">
                  <span>
                    <img src="assets/images/video-camera.png" alt="ico_camera" class="img-fluid"
                      (click)="openSnapshotModal(detail)">
                  </span>
                  <span>
                    <img src="assets/images/direction.png" alt="ico_direction" class="img-fluid"
                      (click)="openDirectionModal(detail)">
                  </span>
                  <span>
                    <img src="assets/images/monitor.png" alt="monitor" class="img-fluid"
                      (click)="openMonitorModal(detail)">
                  </span>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>