import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModalRef, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { AuthService } from '../../Services/auth.service';
import { ModalService } from '../../Services/modal.service';
import { DataTransService } from '../../Services/data-trans.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../../Services/toast.service'
import { environment } from 'src/environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-patient-req-list',
  templateUrl: './patient-req-list.component.html',
  styleUrls: ['./patient-req-list.component.sass']
})
export class PatientReqListComponent implements OnInit {
  ambulanceReqForm: FormGroup;
  user_id: any;
  
  hospitalList = [];
  ambulanceList = [];
  selectHospital: any;
  selectAmbulance: any;
  acceptRequestDetails;
  errTime =  environment.timeout.errorNotificationTimeout;
  public data = [];
  dtOptions: any = {};
  patientRrequest_id;
  ambulanceId;
  selectedAmbulanceHospital;
  zone_id;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  
  @Output() onFilter  = new EventEmitter();

  pageSize = 10;
  currentPage= 1;
  searchValue= '';
  sortValue= {};
  column= ['_id', 'name1', 'name1'];
  request_status: string;
  spinner: any;
  aocRequestStatus: any;
  previousStatusUser: any;
  aoc_request_id
  hospital_id
  constructor(public activeModal: NgbActiveModal, private cookieService : CookieService, private apiService: AuthService,private modalService: ModalService, private toastService:ToastService, private ngbModalService: NgbModal, private dataTrans:DataTransService,  ) { }
  openMod(mymodal, detail) {
    this.ngbModalService.open(mymodal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {}, (reason) => {});
    this.patientRrequest_id = detail.patientRequestsDetail._id;
    // this.activeModal.close();
    this.apiService.getAmbPatientReq(detail.patientRequestsDetail).subscribe(data=>{
      if(data['success']){
        this.ambulanceList = data['content']['data'];
      }
      // this.modalService.onFirstComponentButtonClick(hospitalDetail, ambulanceDetail); 
    },err=>{
       if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }
  
  ngOnInit(): void {
    this.ambulanceReqForm = new FormGroup({
      hospital_id: new FormControl(null, [Validators.required]),
      ambulance_id: new FormControl(null, [Validators.required]),
    });
    this.user_id = this.cookieService.get('user_id');

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.pageSize,
      lengthMenu : [10, 25, 50],
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.sortValue= {};
        this.currentPage = (dataTablesParameters.start/dataTablesParameters.length)+1;
        this.pageSize = dataTablesParameters.length;
        this.searchValue = String(dataTablesParameters.search.value);
        this.sortValue[this.column[Number(dataTablesParameters.order[0].column)]] = ((String(dataTablesParameters.order[0].dir).toUpperCase() == 'DESC')?1:-1);
        this.apiService.patientRequestList(this.pageSize, this.currentPage, this.searchValue, this.sortValue,this.user_id).subscribe((res) => { 
          this.data = res['content']['data'];
          callback({
            recordsTotal: Number(res['content']['count']),
            recordsFiltered: Number(res['content']['count']),
            data: []
          });
          $('.dataTables_empty').remove();
        },err=>{
          if(err.error && err.error.message){
            this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
            } else{
            this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
          }
        })
      },
    };
  }

  close(){
    this.activeModal.close()
  }

  clickSnapfullView(){
    $('.snapModal').toggleClass('fullViewModal');
   }

   ambulanceChange(e){
    
     let hospital = this.ambulanceList.filter(ele=> { return (ele._id == e.target.value) });
     this.selectHospital = hospital[0]['hospitalsDetail']['full_name'];
     this.selectedAmbulanceHospital =  hospital;
    }

  //  openMod(){
  //  
  //     this.myCompOneObj.openAmbulanceListModal();
  //  }

  acceptRequest(){
   
    this.ambulanceId = this.ambulanceReqForm.value['ambulance_id']
    this.apiService.postPatientReqAmbulance(this.patientRrequest_id, this.ambulanceId).subscribe(data=>{
      if(data['success']){
       
        this.acceptRequestDetails = data['content'];
        let acceptReportDetail = this.acceptRequestDetails;
        let patientRrequestid = this.patientRrequest_id
       
        // localStorage.setItem('patientRrequestid', JSON.stringify(patientRrequestid));
        
        this.modalService.onFirstComponentButtonClick(acceptReportDetail);
        this.dataTrans.secondButtonclickdData(this.selectedAmbulanceHospital);
        this.dataTrans.thirdButtonclickdData(patientRrequestid);
        // this.dataTrans.secondButtonclickdData();
        
      }
    },err=>{
       if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  postPatientReqCancelRequest(reqValue) {
  
    this.aoc_request_id = reqValue.patientRequestsDetail?.aocRequestsDetail._id
    this.apiService.postPatientReqCancelRequest(this.aoc_request_id).subscribe((data: any) => {
      if(data.success){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.ajax.reload( null, false );
        });
      }
      else {
        this.toastService.show('something went wrong please try again!', { classname: 'bg-danger text-white', delay: this.errTime });
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }
}
