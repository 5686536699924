import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import {
  NgbModalConfig,
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "../../Services/toast.service";
import { AuthService } from "../../Services/auth.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { Subscription, Subject } from "rxjs-compat";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LocationService } from "src/app/Services/location.service";
import { ClipboardService } from "ngx-clipboard";
import { ActivatedRoute } from "@angular/router";
import { TestBed } from "@angular/core/testing";
declare var $: any;

@Component({
  selector: "app-snapshot-view",
  templateUrl: "./snapshot-view.component.html",
  styleUrls: ["./snapshot-view.component.sass"],
})
export class SnapshotViewComponent implements OnInit, OnDestroy, AfterViewInit {
  public languages: any = environment.languages.map((e) => {
    return { label: e };
  });
  show: boolean = false;
  eta_time = "";
  errTime = environment.timeout.errorNotificationTimeout;
  // @ViewChild(AgmMap) agmMap;
  @ViewChild("mapContainer", { static: false }) gmap: ElementRef;
  imgPath =  this.apiService.imgUrl;
  url: string = environment.ecg_url;
  imgUrls = environment.imgUrl;
  safeUrl: any;
  streamingUrl: any;
  streamingFrame: Boolean = false;
  streaminCode;
  zoom: 12;
  latitude: 22.29768;
  longitude: 70.78746;
  ambulance_id;
  snapShotDetail;
  snapTrackEcgDetail;
  modalopendata;
  severityColorCode;
  @Input() snapShotData;
  zoneList: any;
  hospitalList: any;
  anotherHospitalForm: FormGroup;
  aoc_request_id;
  request_status;
  hospital_id = "";
  user_id;
  aocRequestStatus: any;
  equipmentUrl: string = environment.apiUrl;
  medicalEquipmentUrl: any;
  showAnotherHospital = false;
  selectAnotherHospital = {
    _id: null,
    zone_id: "",
    hospital_id: "",
  };
  statusChange: boolean = false;
  timeout: any = true;
  subscription: Subscription;

  //map
  isOnlineTimeout: any = {};
  infoWindow: any = {};
  markerMoveTimeout: any = {};
  timeoutTime = environment.timeout.snapTrackEcgTimeout;
  coordinates = new google.maps.LatLng(
    environment.googleMapCenter.lat,
    environment.googleMapCenter.lon
  );
  map: google.maps.Map;
  note: any;
  aoc_requests_id: any;
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    // disableDefaultUI: true,
    mapTypeControl: false,
    zoom: 6,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    zoomControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM },
  };

  //video streaming
  @ViewChild("target", { static: true }) target: ElementRef;
  markerIcon: any = {
    url: "/assets/images/map_pin/ambulance/online.svg",
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(-30, -30),
  };
  markers: any;
  public isdriverCollapse = true;
  public isdoctorCollapse = true;
  public isnurseCollapse = true;
  public iserstaffCollapse = true;
  public isemsstaffCollapse = true;
  public ismonitorCollapse = true;
  location: any;
  SocketData: any;
  previousStatusUser: any = [];
  isMapLoad: any = false;
  smallView: any = false;
  markerFocus: any = false;
  requestType;
  copyBaseUrl;
  profilePicturePath: any;
  showSpinner: boolean = false;
  stream: boolean = true;
  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private apiService: AuthService,
    private locationService: LocationService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private toastService: ToastService,
    private clipboardApi: ClipboardService,
    private router: ActivatedRoute,
    private modal: NgbModal
  ) {}
  ngAfterViewInit() {
    this.mapInitializer();
  }
  ngOnInit(): void {
    this.ambulance_id = this.snapShotData._id;
    this.getAmbulanceSnapShotsDetail(this.ambulance_id);
    this.anotherHospitalForm = new FormGroup({
      _id: new FormControl(),
      zone_id: new FormControl(),
      hospital_id: new FormControl(),
    });

    this.getAllAocZonesList();
    this.aoc_request_id = this.snapShotData.aocRequestsDetail?._id;

    navigator.geolocation.getCurrentPosition((pos) => {
      this.mapOptions.center = new google.maps.LatLng(
        +pos.coords.latitude,
        +pos.coords.longitude
      );
    });

    this.location = this.locationService.location.subscribe(
      (data) => {
        if (data[0] == "{") {
          data = JSON.parse(data);
          this.SocketData = data;
          this.getLiveLocation(data["_id"], {
            lat: data["tracking_latitude"],
            lon: data["tracking_longitude"],
            heading: data["tracking_heading"],
            speed: data["tracking_speed"],
            is_assign: data["is_assign"],
            request_type: data["request_type"],
            estimated_time: data["estimated_time"],
          });
        }
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }
  getUrl(url: string) {
    this.streamingUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.streamingFrame = true;
  }
  close() {
    this.activeModal.close();
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutTime);
    this.timeout = false;
  }

  getAmbulanceSnapShotsDetail(ambulance_id) {
    if (!this.showSpinner) {
      this.spinner.show();
    }
    this.apiService.getAmbulanceSnapShotsDetail(ambulance_id).subscribe(
      (data) => {
        if (!data["success"] && data["status"] == 404) {
          this.toastService.show(data["message"], {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        } else if (!data["success"] && data["status"] == 403) {
          this.toastService.show(data["message"], {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        } else {
          this.aocRequestStatus = {
            next_status:
              data["content"]["ambulance_data"][0].aocRequestsDetail
                .next_status,
            previous_status:
              data["content"]["ambulance_data"][0].aocRequestsDetail
                .previous_status,
          };
          this.snapShotDetail = data["content"]["ambulance_data"][0];
          this.profilePicturePath = data["content"]?.ambulance_picture_url;
          this.previousStatusUser =
            this.snapShotDetail["aocRequestsDetail"]["requestStatusChange"];

          this.spinner.hide();

          if (
            !this.streamingFrame &&
            this.snapShotDetail?.aocRequestsDetail?.patientGlassMacsDetail._id
          ) {
            this.getUrl(
              String(
                this.snapShotDetail?.aocRequestsDetail?.patientGlassMacsDetail
                  .rtmp_url
              )
            );
          }
          this.severityColorCode = {
            "background-color":
              this.snapShotDetail.patientRequestsDetail?.severity_color_code,
          };

          if (this.timeout && data["success"]) {
            this.showSpinner = true;
            this.timeout = setTimeout(() => {
              this.getAmbulanceSnapShotsDetail(ambulance_id);
            }, this.timeoutTime);
          }
        }
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  clickSnapfullView() {
    $(".snapModal").toggleClass("fullViewModal");
    this.show = !this.show;
    this.smallView = !this.smallView;
    $(".detailMap").toggleClass("hidemap");

    if (this.show) {
      this.getAmbulanceSnapTrackEcgDetail(this.ambulance_id);
    }
  }

  getAmbulanceSnapTrackEcgDetail(ambulance_id) {
    // this.previousStatusUser = {}
    this.spinner.show();
    this.apiService.getAmbulanceSnapTrackEcgDetail(ambulance_id).subscribe(
      (data) => {
        this.snapTrackEcgDetail = data["content"]["ambulance_data"][0];
        this.medicalEquipmentUrl = data["content"]["medicalEquipmentsurl"];
        // this.previousStatusUser = data['content']['ambulance_data'][0]['requestStatusChange'];
        this.spinner.hide();
        this.etaTime();
        this.setHospitalLocation();
        if (!this.isMapLoad) {
          this.markers = {};
          this.snapTrackEcgDetail["_id"] = this.snapShotDetail._id;

          var content =
            "Box : " +
            String(this.snapShotDetail?.ambulance_box_code) +
            "</br>" +
            "Name :" +
            String(this.snapShotDetail?.name1) +
            "</br>" +
            "Req Type : " +
            String(
              this.snapShotDetail?.aocRequestsDetail.request_type == undefined
                ? "N/A"
                : this.snapShotDetail?.aocRequestsDetail.request_type
            ) +
            "</br>";

          if (
            this.snapShotDetail &&
            this.snapShotDetail.ambulanceTrackingDetail
          ) {
            content +=
              "Speed :" +
              Math.ceil(
                Number(this.snapShotDetail?.ambulanceTrackingDetail.speed)
              ) +
              "</br>" +
              "ETA :" +
              (String(
                this.snapShotDetail.ambulanceTrackingDetail.estimated_time
              ) == null
                ? "N/A"
                : String(
                    this.snapShotDetail?.ambulanceTrackingDetail.estimated_time
                  ));

            this.markers[String(this.snapShotDetail["_id"])] =
              new google.maps.Marker({
                position: new google.maps.LatLng(
                  this.snapShotDetail?.ambulanceTrackingDetail.location.lat,
                  this.snapShotDetail?.ambulanceTrackingDetail.location.lon
                ),
                map: this.map,
                title: content,
                icon: this.markerIcon,
              });

            this.infowindow();

            this.markers[String(this.snapShotDetail["_id"])].setMap(this.map);

            this.isOnlineTimeout[String(this.snapShotDetail["_id"])] =
              setTimeout(() => {
                if (this.snapShotDetail["aocRequestsDetail"]) {
                  this.markers[String(this.snapShotDetail["_id"])].setIcon({
                    scaledSize: new google.maps.Size(40, 40),
                    origin: new google.maps.Point(0, 0),
                    url:
                      this.locationService.setMapMarker(
                        0,
                        1,
                        String(
                          this.snapShotDetail["aocRequestsDetail"][
                            "request_type"
                          ]
                        ),
                        0
                      ) +
                      "?id=" +
                      String(this.snapShotDetail["_id"]),
                  });
                } else {
                  this.markers[String(this.snapShotDetail["_id"])].setIcon({
                    scaledSize: new google.maps.Size(40, 40),
                    origin: new google.maps.Point(0, 0),
                    url:
                      this.locationService.setMapMarker(0, 0, "", 0) +
                      "?id=" +
                      String(this.snapShotDetail["_id"]) +
                      "?id=" +
                      String(this.snapShotDetail["_id"]),
                  });
                }
              }, 30000);
          }
          this.locationService.roomJoin(
            String(this.snapShotDetail[String(this.ambulance_id)])
          );
          google.maps.event.trigger(
            this.markers[String(this.ambulance_id)],
            "click"
          );
          this.markerFocus = String(this.ambulance_id);
          this.map.setZoom(16);
          // this.infoWindow[String(this.ambulance_id)].open(this.markers[String(this.ambulance_id)].getMap(), this.markers[String(this.ambulance_id)]);
        }
        this.isMapLoad = true;
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  infowindow() {
    var content =
      "Box : " +
      String(this.snapShotDetail?.ambulance_box_code) +
      "</br>" +
      "Name :" +
      String(this.snapShotDetail?.name1) +
      "</br>" +
      "Req Type : " +
      String(
        this.snapShotDetail?.aocRequestsDetail.request_type == undefined
          ? "N/A"
          : this.snapShotDetail?.aocRequestsDetail.request_type
      ) +
      "</br>";
    content +=
      "Speed :" +
      Math.ceil(Number(this.snapShotDetail?.ambulanceTrackingDetail.speed)) +
      "</br>" +
      "ETA :" +
      (String(this.snapShotDetail.ambulanceTrackingDetail.estimated_time) ==
      null
        ? "N/A"
        : String(this.snapShotDetail?.ambulanceTrackingDetail.estimated_time));

    setTimeout(() => {
      if (this.infoWindow[String(this.snapShotDetail["_id"])])
        this.infoWindow[String(this.snapShotDetail["_id"])].close();
      this.infoWindow[String(this.snapShotDetail["_id"])] =
        new google.maps.InfoWindow({
          content: this.markers[String(this.snapShotDetail["_id"])].getTitle(),
        });
      console.log("window open");
      this.infoWindow[String(this.snapShotDetail["_id"])].open(
        this.markers[String(this.snapShotDetail["_id"])].getMap(),
        this.markers[String(this.snapShotDetail["_id"])]
      );
    }, 500);
  }

  etaTime() {
    let eta = this.snapTrackEcgDetail?.ambulanceTrackingDetail?.estimated_time;
    if (eta > 60 && eta % 60 !== 0) {
      this.eta_time =
        Math.floor(eta / 60) + " " + "hr" + " " + (eta % 60) + " " + "min";
    } else if (eta % 60 == 0) {
      this.eta_time = Math.floor(eta / 60) + " " + "hr";
    } else {
      this.eta_time = eta + " " + "min";
    }
  }

  zoneToggle(): void {
    this.showAnotherHospital = !this.showAnotherHospital;
    this.selectAnotherHospital.hospital_id = "";
    this.zoneChange(this.aoc_request_id);
  }

  getAllAocZonesList() {
    // this.spinner.show();
    this.apiService.getAllAocZonesList().subscribe(
      (data) => {
        this.zoneList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  zoneChange(aoc_request_id) {
    this.getZoneHospitalsLists(aoc_request_id);
  }

  getZoneHospitalsLists(zoneId) {
    this.spinner.show();
    this.apiService.getZoneHospitalsLists(zoneId).subscribe(
      (hospitalDetail: any) => {
        this.hospitalList = hospitalDetail["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  postAocRequestStatusChange(reqValue, statusValue = "") {
    this.requestType = reqValue.target.id;
    this.user_id = this.cookieService.get("api_key");
    this.request_status =
      statusValue == "" ? this.aocRequestStatus.next_status : statusValue;
    this.spinner.show();
    this.apiService
      .postAocRequestStatusChange(
        this.aoc_request_id,
        this.request_status,
        this.hospital_id,
        this.user_id,
        this.requestType
      )
      .subscribe(
        (data: any) => {
          if (
            this.request_status == "COMPLETED" ||
            this.request_status == "CANCELED"
          ) {
            this.activeModal.close();
            $("#confirmClose").click();
          }

          if (data["success"] == true) {
            this.aocRequestStatus = data["content"]["data"];
            this.previousStatusUser =
              data["content"]["data"]["request_status_change"];
            this.spinner.hide();
          } else {
            setTimeout(() => {
              this.spinner.hide();
            }, 5000);
          }
        },
        (err) => {
          if (err.error && err.error.message) {
            this.toastService.show(
              err.error.message + `<br>` + "status code:" + err.status,
              { classname: "bg-danger text-white", delay: this.errTime }
            );
          } else {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }
        }
      );
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  goToAnotherHospital(reqValue) {
    this.requestType = reqValue.target.id;
    this.user_id = this.cookieService.get("api_key");
    this.request_status = "GO_TO_ANOTHER_HOSPITAL";
    this.hospital_id = this.anotherHospitalForm.value["hospital_id"];
    this.apiService
      .postAocRequestStatusChange(
        this.aoc_request_id,
        this.request_status,
        this.hospital_id,
        this.user_id,
        this.requestType
      )
      .subscribe(
        (data: any) => {
          this.aocRequestStatus = data["content"]["data"];
          this.spinner.hide();
        },
        (err) => {
          if (err.error && err.error.message) {
            this.toastService.show(
              err.error.message + `<br>` + "status code:" + err.status,
              { classname: "bg-danger text-white", delay: this.errTime }
            );
          } else {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }
        }
      );
  }
  getLiveLocation(id, location) {
    if (this.markers && this.markers[String(id)]) {
      this.infowindow();
      var marker = this.markers[String(id)];
      var old = {
        lat: Number(marker.getPosition().lat()),
        lon: Number(marker.getPosition().lng()),
      };
      var current = { lat: Number(location.lat), lon: Number(location.lon) };

      var num = 100;
      var delta = {};
      delta["lat"] = (current.lat - old.lat) / num;
      delta["lon"] = (current.lon - old.lon) / num;

      clearTimeout(this.isOnlineTimeout[id]);
      this.isOnlineTimeout[id] = setTimeout(() => {
        this.markers[String(id)].setIcon({
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          url:
            this.locationService.setMapMarker(
              0,
              Number(location["is_assign"]),
              String(location["request_type"]),
              Number(location.speed)
            ) +
            "?id=" +
            String(id),
        });
      }, 30000);

      this.markers[String(id)].setIcon({
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        url:
          this.locationService.setMapMarker(
            1,
            Number(location["is_assign"]),
            String(location["request_type"]),
            Number(location.speed)
          ) +
          "?id=" +
          String(id),
      });
      this.map.setCenter(this.markers[String(id)].getPosition());
      // google.maps.event.trigger(this.markers[String(id)], 'click');

      if (Number(location.heading)) {
        $(
          'img[src="' +
            String(
              this.locationService.setMapMarker(
                1,
                Number(location["is_assign"]),
                String(location["request_type"]),
                Number(location.speed)
              ) +
                "?id=" +
                String(id)
            ) +
            '"]'
        ).css({
          transform: "rotate(" + Number(location.heading) + "deg)",
        });
      }
      this.locationService.markerMove(
        this.locationService.markerMove,
        id,
        0,
        num,
        delta,
        marker,
        old,
        current,
        location,
        this.markerMoveTimeout
      );
    }
  }

  setHospitalLocation() {
    if (this.snapShotDetail?.aocRequestsDetail.source) {
      new google.maps.Marker({
        position: new google.maps.LatLng(
          this.snapShotDetail?.aocRequestsDetail.source?.location.lat,
          this.snapShotDetail?.aocRequestsDetail.source.location.lon
        ),
        map: this.map,
        icon: {
          url: "/assets/images/map_pin/green_pin.png",
        },
      });
      new google.maps.Marker({
        position: new google.maps.LatLng(
          this.snapShotDetail?.aocRequestsDetail.destination?.location.lat,
          this.snapShotDetail?.aocRequestsDetail.destination.location.lon
        ),
        map: this.map,
        icon: {
          url: "/assets/images/map_pin/red_pin.png",
        },
      });
    }
  }
  mapInitializer(): void {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.map.addListener("zoom_changed", () => {
      Object.keys(this.infoWindow).map((e) => {
        this.infoWindow[e].close();
      });
      this.markerFocus = false;
    });
  }
  copyText() {
    this.copyBaseUrl = location.origin;
    // this.clipboardApi.copyFromContent(this.copyBaseUrl+'/'+'#'+'/'+'requestinfo'+'/'+this.ambulance_id)
    this.clipboardApi.copyFromContent(
      this.copyBaseUrl +
        "/" +
        "#" +
        "/" +
        "requestinfo" +
        "/" +
        this.snapShotDetail?.aocRequestsDetail._id
    );
    this.toastService.show("Copied to Link", {
      classname: "bg-info text-white",
      delay: this.errTime,
    });
  }

  postAocRequestStatusRevert(reqValue, statusValue = "") {
    this.user_id = this.cookieService.get("api_key");
    this.request_status =
      statusValue == "" ? this.aocRequestStatus.next_status : statusValue;
    this.spinner.show();
    this.apiService
      .postAocRequestStatusRevert(
        this.aoc_request_id,
        this.request_status,
        this.hospital_id,
        this.user_id
      )
      .subscribe(
        (data: any) => {
          if (data["success"] == true) {
            this.aocRequestStatus = data["content"]["data"];
            this.previousStatusUser =
              data["content"]["data"]["request_status_change"];
            this.spinner.hide();
          } else {
            setTimeout(() => {
              this.spinner.hide();
            }, 5000);
          }
        },
        (err) => {
          if (err.error && err.error.message) {
            this.toastService.show(
              err.error.message + `<br>` + "status code:" + err.status,
              { classname: "bg-danger text-white", delay: this.errTime }
            );
          } else {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }
        }
      );
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  reloadIframe() {
    $(".reloadbutton i").addClass("reload");
    let iframe = document.getElementById("streamIframe") as HTMLIFrameElement;
    iframe.src += "";
    setTimeout(() => {
      $(".reloadbutton i").removeClass("reload");
    }, 5000);
  }

  openAddnote(snapShotDetail, addnote) {
    this.note = this.snapShotDetail?.aocRequestsDetail?.request_note;
    this.aoc_requests_id = snapShotDetail?.aocRequestsDetail?._id;
    this.modal.open(addnote, { windowClass: "addnoteModal", centered: true });
  }

  submitNote(snapShotDetail) {
    this.apiService
      .postAocRequestDetailUpdate(this.aoc_requests_id, this.note)
      .subscribe((data: any) => {
        if (data["success"] == true) {
          $("#noteClose").click();
        }
      });
  }

  openconfirmModal(confirmModal, detail) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      centered: true,
      windowClass: "confirm_modal",
    };
    this.modal.open(confirmModal, ngbModalOptions);
  }

  ngOnDestroy() {
    this.timeout = false;
    clearTimeout(this.timeoutTime);
  }
}
