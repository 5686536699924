import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../../Services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../../Services/toast.service'
import { environment } from 'src/environments/environment';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { data } from 'jquery';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Sarabun: {
		normal: "Sarabun-Light.ttf",
		bold: "Sarabun-Regular.ttf",
		italics: "Sarabun-LightItalic.ttf",
		bolditalics: "Sarabun-Italic.ttf",
	},
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
	// Kanit: {
	// 	normal: "Kanit-Light.ttf",
	// 	bold: "Kanit-Regular.ttf",
	// 	italics: "Kanit-LightItalic.ttf",
	// 	bolditalics: "Kanit-Italic.ttf",
	// },
	// Prompt: {
	// 	normal: "Prompt-Light.ttf",
	// 	bold: "Prompt-Regular.ttf",
	// 	italics: "Prompt-LightItalic.ttf",
	// 	bolditalics: "Prompt-Italic.ttf",
	// },
}

// import html2canvas  from 'html2canvas'
// declare var jsPDF: any;
// import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-operation-report',
  templateUrl: './operation-report.component.html',
  styleUrls: ['./operation-report.component.sass']
})
export class OperationReportComponent implements OnInit {
    page = 3;
    collectionsize:any;
    page_size:any = 10; 
    current_page:any = 1;
    requestList:any;
    sort_value:any;
    hospital_id:any;
    zone_id:any;
    zoneList:any;
    hospitalList:any;
    filterReqForm: FormGroup;
    user_id: any;
    errTime =  environment.timeout.errorNotificationTimeout;
    imgurl = environment.imgUrl
  ambulanceList: any[];
  ambulance_id: any;
    constructor(private spinner: NgxSpinnerService, private cookieService : CookieService, private apiService: AuthService, private toastService:ToastService) { }
  
    selectFilterreq = {
      zone_id: '',
      hospital_id:'',
      ambulance_id : ''
    }
    ngOnInit(): void {
      this.user_id = this.cookieService.get('user_id');
  
      this.aocRequestStatusChangeHistory();
      this.getZone()
      
      this.filterReqForm = new FormGroup({
        zone_id:new FormControl(),
        hospital_id:new FormControl(),
        ambulance_id:new FormControl(),
      })
    }
  
    submitFilter(){
      
      this.zone_id = this.filterReqForm.value['zone_id']
      this.hospital_id = this.filterReqForm.value['hospital_id']
      this.ambulance_id = this.filterReqForm.value['ambulance_id']
      if(!this.hospital_id || this.hospital_id == ''){
        this.toastService.show("Please Select Hospital.", { classname: 'bg-danger text-white', delay: this.errTime });
        return
      }
      if( this.filterReqForm.value){
        $('#submitLoader').css('display', 'inline-block');
        this.aocRequestStatusChangeHistory()
        $('#submitLoader').hide();
      }
    }
  
    hospitalChange(e){
      this.ambulanceList = [];
      this.apiService.getAmbulanceFromHospital(e.target.value).subscribe(data=>{        
        this.ambulanceList = data['content']['data'];
        this.spinner.hide();
      })
    }

    getZone(){
      this.spinner.show();
      let userId = this.cookieService.get('api_key')
      this.apiService.getHospitalsByUserList(userId).subscribe(data=>{
        this.zoneList = data['content']['data'][0]['zoneDetails'];
        this.spinner.hide();
      },err=>{
        if(err.error && err.error.message){
          this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
          } else{
          this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
        }
      })
      setTimeout(() => {
        this.spinner.hide();
      }, 5000);
    }
  
    getHospitalsList(zoneId) {
      this.spinner.show();
      this.apiService.getHospitalsList(zoneId).subscribe((hospitalDetail: any) => {
        this.hospitalList = hospitalDetail['content']['data'];
        this.spinner.hide();
      },err=>{
        if(err.error && err.error.message){
          this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
          } else{
          this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
        }
      })
      setTimeout(() => {
        this.spinner.hide();
      }, 5000);
    }
    zoneChange(e) {
      if(e.target.value){
        this.getHospitalsList(e.target.value);
      }
    }
    
    aocRequestStatusChangeHistory(page_size = 10, current_page = 1){
      this.spinner.show();
  
      this.apiService.aocRequestStatusChangeHistory(
        this.user_id = this.user_id,
        this.page_size = page_size,
        this.current_page = current_page,
        this.hospital_id = this.hospital_id ? this.hospital_id :'', 
        this.ambulance_id =  this.ambulance_id ?  this.ambulance_id :'', 
        ).subscribe((data) => {
          if(data['success']){
            this.requestList = data['content']['data'];
            this.collectionsize = data['content'].count
            this.spinner.hide();

            this.requestList.filter(request => {
              request['request_status_change'].filter((element, i) => {
                if(i !== 0){
                    var milliSec;
                  milliSec = (new Date(request['request_status_change'][i].createdAt).getTime() - new Date(request['request_status_change'][i-1].createdAt).getTime())
                    var sec = Math.ceil((milliSec/1000) % 60) 
                    var min = Math.floor(((milliSec/1000) / 60) % 60)
                    var hour = Math.floor(((milliSec/1000) / 60) / 60)
                    request['request_status_change'][i]['time_duration'] = this.pad(hour) +':'+  this.pad(min) +':'+this.pad(sec) 
                  }
                });
              });
             
            }else{
              setTimeout(() => {
                this.spinner.hide();
              }, 5000);
            }
        
      },err=>{
        if(err.error && err.error.message){
          this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
          } else{
          this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
        }
      })
    }
    onPageChange(page){
      this.current_page = page
      this.aocRequestStatusChangeHistory(this.page_size, this.current_page )
    }

    pad(n, z=2) {
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
    
    async createPdf(){
      async function getBase64ImageFromUrl(imageUrl) {
        var res = await fetch(imageUrl);
        var blob = await res.blob();
      
        return new Promise((resolve, reject) => {
          var reader  = new FileReader();
          reader.addEventListener("load", function () {
              resolve(reader.result);
          }, false);
      
          reader.onerror = () => {
            return reject(this);
          };
          reader.readAsDataURL(blob);
        })
      }
      let img_url
      if(this.ambulanceList){
        this.ambulanceList.map(e => {
          if (String(this.selectFilterreq.hospital_id) == String(e.hospitalDetails._id) && e.hospitalDetails.logo_image) {
            img_url = this.imgurl + e.hospitalDetails.logo_path + e.hospitalDetails.logo_image;
          } else {
            img_url = 'assets/images/MOPH_LOGO.png'
          }
        })
      }else {
        img_url = 'assets/images/MOPH_LOGO.png'
      }
      

      let docDefinition = {
        pageSize: 'A0',
        pageOrientation: 'landscape',
        header: {
          margin: 10,
          
          columns: [
              {
                  image: await getBase64ImageFromUrl(img_url).then(),
                  width: 50,
                  margin: [10, 10, 0, 0],
              }
          ]
      },
        background: [
          {
            image: await getBase64ImageFromUrl('assets/images/MOPH_LOGO.png').then(),
            opacity: 0.05,
            width: 1000,
            absolutePosition: { x: 100, y: 590 },
            alignment: 'center'
          }
        ],
        content: [
          {
            text: 'Operation Report.',
            bold: true,
            fontSize: 14,
            alignment: 'center',
            margin: [0, 0, 0, 20]
          },
          {
            layout: 'lightHorizontalLines',
            table: {
              headerRows: 1,
              widths: [ '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*' ],
              body: []
            }
          },
        ]
      };

      let head = [
        [ 
          { text: '-', bold: true },
          { text: 'Ambulance', bold: true },
          { text: 'Patient', bold: true },
          { text: 'Request Code', bold: true },
          { text: 'START_OPERATION', bold: true },
          { text: 'DEPART_FROM_BASE_HOSPITAL', bold: true },
          { text: 'ARRIVAL_AT_SCENE', bold: true },
          { text: 'GO_TO_BASE_HOSPITAL', bold: true },
          { text: 'GO_TO_ANOTHER_HOSPITAL', bold: true },
          { text: 'DEPART_FROM_SCENE', bold: true },
          { text: 'ARRIVAL_AT_BASE_HOSPITAL', bold: true },
          { text: 'ARRIVAL_AT_ANOTHER_HOSPITAL', bold: true },
          { text: 'GO_BACK_TO_BASE_HOSPITAL', bold: true },
          { text: 'COME_BACK_TO_BASE_HOSPITAL', bold: true },
          { text: 'COMPLETED', bold: true },
        ]
      ];

      let index = 0;

      this.requestList.forEach((data, i) => {
        
        let detail = [
          { text: String(++index), bold: false },

          { text: String(data.request_type + '-' + data.ambulancesDetail.ambulance_box_code + ' ' + data.ambulancesDetail.name1), bold: false },

          { text: String(data.ambulancesDetail.aocRequestsDetail.patientRequestsDetail.patientsDetail.first_name + ' ' + data.ambulancesDetail.aocRequestsDetail.patientRequestsDetail.patientsDetail.last_name), bold: false },

          { text: String(data.request_code), bold: false },
        ]

        if(data?.request_status_change[0]?.request_status == 'START_OPERATION'){
          let text = data.request_status_change[0].request_status + '\n' + 
                      new Date(data.request_status_change[0].createdAt).toLocaleString();

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        if(data?.request_status_change[1]?.request_status == 'DEPART_FROM_BASE_HOSPITAL'){
          let text = data.request_status_change[1].request_status + '\n' + 
                      new Date(data.request_status_change[1].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[1].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        if(data?.request_status_change[2]?.request_status == 'ARRIVAL_AT_SCENE'){
          let text = data.request_status_change[2].request_status + '\n' + 
                      new Date(data.request_status_change[2].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[2].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        if(data?.request_status_change[3]?.request_status == 'GO_TO_BASE_HOSPITAL'){
          let text = data.request_status_change[3].request_status + '\n' + 
                      new Date(data.request_status_change[3].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[3].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        if(data?.request_status_change[3]?.request_status == 'GO_TO_ANOTHER_HOSPITAL'){
          let text = data.request_status_change[3].request_status + '\n' + 
                      new Date(data.request_status_change[3].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[3].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        if(data?.request_status_change[4]?.request_status == 'DEPART_FROM_SCENE'){
          let text = data.request_status_change[4].request_status + '\n' + 
                      new Date(data.request_status_change[4].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[4].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        if(data?.request_status_change[5]?.request_status == 'ARRIVAL_AT_BASE_HOSPITAL'){
          let text = data.request_status_change[5].request_status + '\n' + 
                      new Date(data.request_status_change[5].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[5].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }


        if(data?.request_status_change[5]?.request_status == 'ARRIVAL_AT_ANOTHER_HOSPITAL'){
          let text = data.request_status_change[5].request_status + '\n' + 
                      new Date(data.request_status_change[5].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[5].time_duration

          detail.push({ text, bold: false });
        } else if(data?.request_status_change[2]?.request_status == 'ARRIVAL_AT_ANOTHER_HOSPITAL'){
          let text = data.request_status_change[2].request_status + '\n' + 
                      new Date(data.request_status_change[2].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[2].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        if(data?.request_status_change[6]?.request_status == 'GO_BACK_TO_BASE_HOSPITAL'){
          let text = data.request_status_change[6]?.request_status + '\n' + 
                      new Date(data.request_status_change[6]?.createdAt).toLocaleString() + '\n' +
                      data.request_status_change[6]?.time_duration

          detail.push({ text, bold: false });
        } else if(data?.request_status_change[3]?.request_status == 'GO_BACK_TO_BASE_HOSPITAL'){
          let text = data.request_status_change[3].request_status + '\n' + 
                      new Date(data.request_status_change[3].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[3].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        if(data?.request_status_change[7]?.request_status == 'COME_BACK_TO_BASE_HOSPITAL'){
          let text = data.request_status_change[7]?.request_status + '\n' + 
                      new Date(data.request_status_change[7]?.createdAt).toLocaleString() + '\n' +
                      data.request_status_change[7]?.time_duration

          detail.push({ text, bold: false });
        } else if(data?.request_status_change[4]?.request_status == 'GO_BACK_TO_BASE_HOSPITAL'){
          let text = data.request_status_change[4].request_status + '\n' + 
                      new Date(data.request_status_change[4].createdAt).toLocaleString() + '\n' +
                      data.request_status_change[4].time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }
        
        if(data?.request_status_change[8]?.request_status == 'COMPLETED'){
          let text = data.request_status_change[8]?.request_status + '\n' + 
                      new Date(data.request_status_change[8]?.createdAt).toLocaleString() + '\n' +
                      data.request_status_change[8]?.time_duration

          detail.push({ text, bold: false });
        } else if(data?.request_status_change[6]?.request_status == 'COMPLETED'){
          let text = data.request_status_change[6]?.request_status + '\n' + 
                      new Date(data.request_status_change[6]?.createdAt).toLocaleString() + '\n' +
                      data.request_status_change[6]?.time_duration

          detail.push({ text, bold: false });
        } else if(data?.request_status_change[5]?.request_status == 'COMPLETED'){
          let text = data.request_status_change[5]?.request_status + '\n' + 
                      new Date(data.request_status_change[5]?.createdAt).toLocaleString() + '\n' +
                      data.request_status_change[5]?.time_duration

          detail.push({ text, bold: false });
        } else {
          detail.push({ text: '', bold: false });
        }

        head.push(detail);
        
        if(this.requestList.length == (i+1)){
          docDefinition['content'][1]['table']['body'] = head;
          pdfMake.createPdf(docDefinition).open();
        }
      });

      // const input = document.getElementById('my-table');   
      //   html2canvas(input)
      //   .then((canvas) => {
      //     const pdf = new jsPDF('l', 'mm', [297, 210]);
      //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
      //     pdf.addImage(imgData, 'JPEG', 5, 5, 280, 160); // add image to pdf page
      //     pdf.save("AocRequestReport.pdf");
      //   })
    }
  }