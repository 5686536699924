import { Component, OnInit, ViewChild, Input, OnDestroy, ElementRef } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { AgmMap, MapsAPILoader, MouseEvent } from "@agm/core";
import { ToastService } from '../../Services/toast.service'
import { AuthService } from '../../Services/auth.service'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LocationService } from 'src/app/Services/location.service';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-monitor-view',
  templateUrl: './monitor-view.component.html',
  styleUrls: ['./monitor-view.component.sass']
})

export class MonitorViewComponent implements OnInit, OnDestroy {
  errTime =  environment.timeout.errorNotificationTimeout;
  public languages: any = environment.languages.map(e => { return { 'label': e } });
  show: boolean = false;
  eta_time = '';
  streamingUrl: any;
  streamingFrame: Boolean = false;
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  requestType;
  imgPath = this.apiService.imgUrl;
  zoom: 12;
  latitude: 22.297680;
  longitude: 70.787460;
  equipmentUrl: string = environment.apiUrl;
  url: string = environment.ecg_url;
  isMapLoad: boolean = false;

  ambulance_id;
  monitorDetail;
  modalopendata;
  snapTrackEcgDetail;
  severityColorCode;
  zoneList: any;
  hospitalList: any;
  anotherHospitalForm: FormGroup;
  aoc_request_id;
  request_status;
  hospital_id = '';
  user_id;
  aocRequestStatus;
  @Input() monitorData;
  showAnotherHospital = false;
  selectAnotherHospital = {
    _id: null,
    zone_id: '',
    hospital_id: ''
  }
  statusChange: boolean = false;
  medicalEquipmentUrl: any;
  timeout: any = true;
  previousStatusUser: any = [];
  markerIcon: any = {
    url: '/assets/images/map_pin/ambulance/online.svg',
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(-30, -30)
  }
  //map
  isOnlineTimeout: any = {};
  infoWindow: any = {};
  coordinates = new google.maps.LatLng(environment.googleMapCenter.lat, environment.googleMapCenter.lon);
  map: google.maps.Map;
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    // disableDefaultUI: true,
    mapTypeControl: false,
    zoom: 6,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    zoomControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM },

  };
  moniterDetail: any={web_view: 0, is_online: 0};
  safe_web_view_url: any='';
  markers: any;
  location: any;
  SocketData: any;
  markerMoveTimeout: any = {};
  timeoutTime = environment.timeout.snapTrackEcgTimeout
  markerFocus: any = false;
  profilePicturePath: any;
  imgUrls =  environment.imgUrl;
  note:any;
  aoc_requests_id:any;
  constructor(public activeModal: NgbActiveModal, private locationService: LocationService, private apiService: AuthService, private spinner: NgxSpinnerService, private cookieService: CookieService, private toastService: ToastService, private router: Router, private _location: Location,public sanitizer: DomSanitizer, private modalService: NgbModal) { }
  ngAfterViewInit() {
    this.mapInitializer();
  }
  ngOnInit(): void {
    if(this.monitorData.aocRequestsDetail.patientMonitorsDetail){
      if(this.monitorData.aocRequestsDetail.patientMonitorsDetail.web_view){
        const web_view_url = 'http://'+this.monitorData.aocRequestsDetail.patientMonitorsDetail.web_view_url;
        this.safe_web_view_url = this.sanitizer.bypassSecurityTrustResourceUrl(web_view_url);
        
        if(this.monitorData.aocRequestsDetail.patientMonitorsDetail.type == "JENNY"){
          const web_view_url = this.monitorData.aocRequestsDetail.patientMonitorsDetail.web_view_url;
          this.safe_web_view_url = this.sanitizer.bypassSecurityTrustResourceUrl(web_view_url);
        }
      }
      this.moniterDetail = {
        serial_number: this.monitorData.aocRequestsDetail.patientMonitorsDetail.serial_number,
        type: this.monitorData.aocRequestsDetail.patientMonitorsDetail.type,
        web_view : this.monitorData.aocRequestsDetail.patientMonitorsDetail.web_view,
        // web_view_url : this.monitorData.aocRequestsDetail.patientMonitorsDetail.web_view_url,
        is_online : this.monitorData.aocRequestsDetail.patientMonitorsDetail.is_online,
      }    
    }
        
    this.ambulance_id = this.monitorData._id
    this.getAmbulanceEcgDetail(this.ambulance_id);
    // this.getAmbulanceSnapTrackEcgDetail(this.ambulance_id);

    this.anotherHospitalForm = new FormGroup({
      _id: new FormControl(),
      zone_id: new FormControl(),
      hospital_id: new FormControl(),
    });

    this.getAllAocZonesList();
    this.aocRequestStatus = { 'next_status': this.monitorData.aocRequestsDetail?.next_status };
    this.aoc_request_id = this.monitorData.aocRequestsDetail?._id

    navigator.geolocation.getCurrentPosition(pos => {
      this.mapOptions.center = new google.maps.LatLng(+pos.coords.latitude, +pos.coords.longitude);
    });
    this.location = this.locationService.location.subscribe(data => {
      if (data[0] == '{') {
        data = JSON.parse(data);
        this.SocketData = data
        this.getLiveLocation(data['_id'], { lat: data['tracking_latitude'], lon: data['tracking_longitude'], heading: data['tracking_heading'], speed: data['tracking_speed'], is_assign: data['is_assign'], request_type: data['request_type'], estimated_time: data['estimated_time'] });
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    });

  }
  getUrl(url: string) {
    this.streamingUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.streamingFrame = true;
  }
  
  close() {
    this.activeModal.close()
    clearTimeout(this.timeout)
    clearTimeout(this.timeoutTime);
    this.timeout = false;
  }

  getAmbulanceEcgDetail(ambulance_id) {
    this.apiService.getAmbulanceEcgDetail(ambulance_id).subscribe((data) => {
      this.monitorDetail = data['content']['ambulance_data'][0];
      this.profilePicturePath = data['content']?.ambulance_picture_url
      this.previousStatusUser = this.monitorDetail['aocRequestsDetail']['requestStatusChange'];
      this.aocRequestStatus = { 'next_status': data['content']['ambulance_data'][0].aocRequestsDetail?.next_status, 'previous_status': data['content']['ambulance_data'][0].aocRequestsDetail.previous_status }
     
      if (data['content']['ambulance_data'][0]['aocRequestsDetail']['patientMonitorsDetail']?.serial_number) {
      }

      this.severityColorCode = {
        'background-color':  this.monitorDetail.patientRequestsDetail?.severity_color_code,
      };

      if (this.timeout && data['success']) {
        this.timeout = setTimeout(() => {
          this.getAmbulanceEcgDetail(ambulance_id);
        }, this.timeoutTime)
      }
      this.etaTime();
      this.setHospitalLocation(this.monitorDetail)
      if (!this.isMapLoad) {
        this.markers = {}
        this.monitorDetail['_id'] = this.monitorDetail._id;

        var content = 'Box : ' + String(this.monitorDetail?.ambulance_box_code) + '</br>' +
          'Name :' + String(this.monitorDetail?.name1) + '</br>' + 'Req Type : ' + String((this.monitorDetail?.aocRequestsDetail.request_type == undefined) ? "N/A" : this.monitorDetail?.aocRequestsDetail.request_type) + '</br>';

        if (this.monitorDetail && this.monitorDetail.ambulanceTrackingDetail) {
          content += 'Speed :' + Math.ceil(Number(this.monitorDetail?.ambulanceTrackingDetail.speed)) + '</br>' + 'ETA :' + ((String(this.monitorDetail.ambulanceTrackingDetail.estimated_time) == null) ? 'N/A' : String(this.monitorDetail?.ambulanceTrackingDetail.estimated_time));

          this.markers[String(this.monitorDetail['_id'])] = new google.maps.Marker({
            position: new google.maps.LatLng(this.monitorDetail?.ambulanceTrackingDetail.location.lat, this.monitorDetail?.ambulanceTrackingDetail.location.lon),
            map: this.map,
            title: content,
            icon: this.markerIcon
          });

          // this.markers[String(this.monitorDetail['_id'])].addListener("click", () => {
          //   if (this.infoWindow[String(this.monitorDetail['_id'])]) this.infoWindow[String(this.monitorDetail['_id'])].close();
          //   this.infoWindow[String(this.monitorDetail['_id'])] = new google.maps.InfoWindow({
          //     content: this.markers[String(this.monitorDetail['_id'])].getTitle()
          //   });
          //   this.infoWindow[String(this.monitorDetail['_id'])].open(this.markers[String(this.monitorDetail['_id'])].getMap(), this.markers[String(this.monitorDetail['_id'])]);
          // });
          this.infowindow()
          this.markers[String(this.monitorDetail['_id'])].setMap(this.map);

          this.isOnlineTimeout[String(this.monitorDetail['_id'])] = setTimeout(() => {
            if (this.monitorDetail['aocRequestsDetail']) {
              this.markers[String(this.monitorDetail['_id'])].setIcon({
                scaledSize: new google.maps.Size(40, 40),
                origin: new google.maps.Point(0, 0),
                url: this.locationService.setMapMarker(0, 1, String(this.monitorDetail['aocRequestsDetail']['request_type']), 0) + '?id=' + String(this.monitorDetail['_id'])
              });
            } else {
              this.markers[String(this.monitorDetail['_id'])].setIcon({
                scaledSize: new google.maps.Size(40, 40),
                origin: new google.maps.Point(0, 0),
                url: this.locationService.setMapMarker(0, 0, '', 0) + '?id=' + String(this.monitorDetail['_id']) + '?id=' + String(this.monitorDetail['_id'])
              });
            }
          }, 30000);

        }
        this.locationService.roomJoin(String(this.monitorDetail[String(this.ambulance_id)]));
        google.maps.event.trigger(this.markers[String(this.ambulance_id)], 'click');
        this.markerFocus = String(this.ambulance_id);
        this.map.setZoom(16);
        // this.infoWindow[String(this.ambulance_id)].open(this.markers[String(this.ambulance_id)].getMap(), this.markers[String(this.ambulance_id)]);
      }
      this.isMapLoad = true

    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
this.spinner.hide()
  }

  getAmbulanceSnapTrackEcgDetail(ambulance_id) {
    // this.previousStatusUser = {}
    this.spinner.show();
    this.apiService.getAmbulanceSnapTrackEcgDetail(ambulance_id).subscribe((data) => {
      if(!data['success'] && data['status']== 404){
        this.toastService.show(data['message'], { classname: 'bg-danger text-white', delay: this.errTime });
      }else if(!data['success'] && data['status']== 403){
        this.toastService.show(data['message'], { classname: 'bg-danger text-white', delay: this.errTime });
      }else{
      this.snapTrackEcgDetail = data['content']['ambulance_data'][0];
      this.medicalEquipmentUrl = data['content']['medicalEquipmentsurl'];

      if(!this.streamingFrame && this.snapTrackEcgDetail?.aocRequestsDetail?.patientGlassMacsDetail._id){ 
        this.getUrl(String(this.snapTrackEcgDetail?.aocRequestsDetail?.patientGlassMacsDetail.rtmp_url));
      }
      this.spinner.hide();
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  infowindow(){
    var content = 'Box : ' + String( this.monitorDetail?.ambulance_box_code) + '</br>' +
    'Name :' + String( this.monitorDetail?.name1) + '</br>' + 'Req Type : ' + String(( this.monitorDetail?.aocRequestsDetail.request_type == undefined) ? "N/A" :  this.monitorDetail?.aocRequestsDetail.request_type) + '</br>';
     content += 'Speed :' + Math.ceil(Number( this.monitorDetail?.ambulanceTrackingDetail.speed)) + '</br>' + 'ETA :' + ((String( this.monitorDetail.ambulanceTrackingDetail.estimated_time) == null) ? 'N/A' : String( this.monitorDetail?.ambulanceTrackingDetail.estimated_time));

    setTimeout(() => {
      if (this.infoWindow[String( this.monitorDetail['_id'])]) this.infoWindow[String( this.monitorDetail['_id'])].close();
      this.infoWindow[String( this.monitorDetail['_id'])] = new google.maps.InfoWindow({
        content: this.markers[String( this.monitorDetail['_id'])].getTitle()
      });
      this.infoWindow[String( this.monitorDetail['_id'])].open(this.markers[String( this.monitorDetail['_id'])].getMap(), this.markers[String( this.monitorDetail['_id'])]);
    }, 500);
  }

  clickSnapfullView() {
    $('.snapModal').toggleClass('fullViewModal');
    this.show = !this.show;
    $('.detailMap').toggleClass('hidemap');
    $('#optMonitorview').toggleClass('optMonitorview');

    if (this.show) {
      this.getAmbulanceSnapTrackEcgDetail(this.ambulance_id);
    }
  }

  etaTime() {
    let eta = this.monitorDetail?.ambulanceTrackingDetail?.estimated_time;
    if ((eta > 60) && eta % 60 !== 0) {
      this.eta_time = Math.floor(eta / 60) + ' ' + 'hr' + ' ' + (eta % 60) + ' ' + 'min'

    }
    else if (eta % 60 == 0) {
      this.eta_time = Math.floor(eta / 60) + ' ' + 'hr'
    }
    else {
      this.eta_time = eta + ' ' + 'min'
    }
  }


  zoneToggle(): void {
    this.showAnotherHospital = !this.showAnotherHospital
    this.selectAnotherHospital.hospital_id = ''
    this.zoneChange(this.aoc_request_id);
  }

  getAllAocZonesList() {
    this.spinner.show();
    this.apiService.getAllAocZonesList().subscribe((data) => {
      this.zoneList = data['content']['data'];
    })
  }

  zoneChange(aoc_request_id) {
    this.getZoneHospitalsLists(aoc_request_id);
  }

  getZoneHospitalsLists(zoneId) {
    this.spinner.show();
    this.apiService.getZoneHospitalsLists(zoneId).subscribe((hospitalDetail: any) => {
      this.hospitalList = hospitalDetail['content']['data'];
      this.spinner.hide();
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  postAocRequestStatusChange(reqValue, statusValue = '') {
    this.user_id = this.cookieService.get('api_key');
    this.requestType = reqValue.target.id
      this.request_status = (statusValue == '')?this.aocRequestStatus.next_status:statusValue;

    this.spinner.show();
    this.apiService.postAocRequestStatusChange(this.aoc_request_id, this.request_status, this.hospital_id, this.user_id,  this.requestType).subscribe((data: any) => {
      if(this.request_status == "COMPLETED" || this.request_status == "CANCELED"){
        this.activeModal.close();
        $('#confirmClose').click();
      }
      if (data['success'] == true){
        this.aocRequestStatus = data['content']['data'];
        this.previousStatusUser = data['content']['data']['request_status_change'];
        this.spinner.hide();
      }else{
        setTimeout(() => {
          this.spinner.hide();
        }, 5000);
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
     setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  goToAnotherHospital(reqValue) {
    this.user_id = this.cookieService.get('api_key')
    this.request_status = 'GO_TO_ANOTHER_HOSPITAL';
    this.requestType = reqValue.target.id
    this.hospital_id = this.anotherHospitalForm.value['hospital_id'];
    this.apiService.postAocRequestStatusChange(this.aoc_request_id, this.request_status, this.hospital_id, this.user_id,  this.requestType).subscribe((data: any) => {
      this.aocRequestStatus = data['content']['data'];
      this.spinner.hide();
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  getLiveLocation(id, location) {
    if (this.markers && this.markers[String(id)]) {
      this.infowindow()
      var marker = this.markers[String(id)]
      var old = { lat: Number(marker.getPosition().lat()), lon: Number(marker.getPosition().lng()) };
      var current = { lat: Number(location.lat), lon: Number(location.lon) };

      var num = 100;
      var delta = {};
      delta['lat'] = (current.lat - old.lat) / num;
      delta['lon'] = (current.lon - old.lon) / num;

      clearTimeout(this.isOnlineTimeout[id]);
      this.isOnlineTimeout[id] = setTimeout(() => {
        this.markers[String(id)].setIcon({
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          url: this.locationService.setMapMarker(0, Number(location['is_assign']), String(location['request_type']), Number(location.speed)) + '?id=' + String(id)
        });
      }, 30000);

      this.markers[String(id)].setIcon({
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        url: this.locationService.setMapMarker(1, Number(location['is_assign']), String(location['request_type']), Number(location.speed)) + '?id=' + String(id)
      });
      this.map.setCenter(this.markers[String(id)].getPosition());
      // google.maps.event.trigger(this.markers[String(id)], 'click');

      if (Number(location.heading)) {
        $('img[src="' + String(this.locationService.setMapMarker(1, Number(location['is_assign']), String(location['request_type']), Number(location.speed)) + '?id=' + String(id)) + '"]').css({
          'transform': 'rotate(' + Number(location.heading) + 'deg)'
        });
      }

      this.locationService.markerMove(this.locationService.markerMove, id, 0, num, delta, marker, old, current, location, this.markerMoveTimeout);
    }
  }



  setHospitalLocation(monitorDetail) {

    if (monitorDetail?.aocRequestsDetail.source) {
      new google.maps.Marker({
        position: new google.maps.LatLng(monitorDetail?.aocRequestsDetail.source?.location.lat, monitorDetail?.aocRequestsDetail.source.location.lon),
        map: this.map,
        icon: {
          url: '/assets/images/map_pin/green_pin.png',
        }
      });
      new google.maps.Marker({
        position: new google.maps.LatLng(monitorDetail?.aocRequestsDetail.destination?.location.lat, monitorDetail?.aocRequestsDetail.destination.location.lon),
        map: this.map,
        icon: {
          url: '/assets/images/map_pin/red_pin.png',
        }
      });
    }
  }

  mapInitializer(): void {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.map.addListener("zoom_changed", () => {
      Object.keys(this.infoWindow).map(e => {
        this.infoWindow[e].close();
      });
      this.markerFocus = false;
    })
  }

  ecgMoniterData(){
    localStorage.setItem('moniterDetail', JSON.stringify(this.moniterDetail));
    window.open(this._location.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree(['/user/ecgwaveform']))), '_blank');
  }

  reloadIframe(){
    $('.reloadbutton i').addClass('reload')
    let iframe = document.getElementById('streamIframe') as HTMLIFrameElement
    iframe.src += '';
    setTimeout(() => {
      $('.reloadbutton i').removeClass('reload')
    }, 5000);
  }

  reloadIframeMonitor(){
    $('.reloadbutton i').addClass('reload')
    let iframe = document.getElementById('streamIframeMonitor') as HTMLIFrameElement
    iframe.src += '';
    setTimeout(() => {
      $('.reloadbutton i').removeClass('reload')
    }, 5000);
  }

  openWebview(webview){
    this.spinner.show()
    this.modalService.open(webview, { centered: true, windowClass: 'webviewmodal', size: 'xl'  })
    setTimeout(() => {
      this.spinner.hide()
    }, 5000)
  }

  iframeLoadedCallBack(){
    console.log('load complete...');
  }

  openAddnote(snapShotDetail, addnote){
    this.note = this.monitorDetail?.aocRequestsDetail?.request_note
    this.aoc_requests_id = snapShotDetail?.aocRequestsDetail?._id
    this.modalService.open(addnote, { windowClass: 'addnoteModal', centered: true })
  }

  submitNote(monitorDetail){
    this.apiService.postAocRequestDetailUpdate(this.aoc_requests_id, this.note).subscribe((data: any) => {
      if (data['success'] == true) {
        $('#noteClose').click();
      }
    })
  }

  openconfirmModal(confirmModal, detail) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true,
      windowClass: 'confirm_modal'
    };
      this.modalService.open(confirmModal, ngbModalOptions)
    }

  ngOnDestroy() {
      clearTimeout(this.timeout);
      clearTimeout(this.timeoutTime);
  }
}