<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div class="header__pane mr-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
    <div class="logosrc">
      <img src="assets/images/aoclabel.png" alt="logo" class="img-fluid" >
    </div>
    <div class="logoutIcon">
      <a class="vsm-link"  (click) = "logOut()">
        <i class="vsm-icon fa-2x	 pe-7s-power"></i>
      </a>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <div class="vsm-header">{{'menu.mainmenu' | translate}}
              <div class="d-inline-block landrop" ngbDropdown>
                <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn">
                  <i class="fa fa-language" aria-hidden="true"> </i>
                </button>
                <div ngbDropdownMenu>
                  <button class="dropdown-item"  *ngFor="let item of languages" (click)="onLanguageChange(item)">{{item.label.label}}</button>
                </div>
              </div>
            </div>
            <div class="vsm-item">
              <a routerLink="/user/dashboard" routerLinkActive="active-item" class="vsm-link">
                <i class="fa fa-tachometer"></i>
                <span class="vsm-title">{{'menu.dashboard' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/user/history-ongoing" routerLinkActive="active-item" class="vsm-link">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
                <span class="vsm-`">{{'menu.historyongoing' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a class="vsm-link" (click)="openAmbulanceListModal(ambulanceListModal)">
                <i class="fa fa-ambulance"></i>
                <span class="vsm-`">{{'menu.ambulnace' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a class="vsm-link" (click)="openPatientReqModal()">
                <i class="fa fa-user-plus"></i>
                <span class="vsm-`">{{'menu.patientreq' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/user/stafflist" routerLinkActive="active-item" class="vsm-link">
                <i class="fa fa-users"></i>
                <span class="vsm-`">{{'menu.stafflist' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/user/history" routerLinkActive="active-item" class="vsm-link">
                <i class="fa fa-list-alt"></i>
                <span class="vsm-`">{{'menu.history' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/user/operation-report" routerLinkActive="active-item" class="vsm-link">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
                <span class="vsm-`">{{'menu.operationreport' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/user/speed-report" target="_blank" routerLinkActive="active-item" class="vsm-link">
                <i class="fa fa-tachometer"></i>
                <span class="vsm-`">{{'menu.speedReport' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/user/ecg-report" routerLinkActive="active-item" class="vsm-link">
                <i class="fa fa-television" aria-hidden="true"></i>
                <span class="vsm-`">{{'menu.ecgreport' | translate}}</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/user/crash-notification" target="_blank" routerLinkActive="active-item" class="vsm-link">
                <i class="fa fa-bell"></i>
                <span class="vsm-`">{{'menu.crashreport' | translate}}</span>
              </a>
            </div>
          </div>
          <!-- <div class="vsm-item sidebar_footer">
            <a class="vsm-link" (click) = "logOut()">
              <i class="vsm-icon pe-7s-power"></i>
              <span class="vsm-title">{{'menu.logout' | translate}}</span>
            </a>
          </div> -->
        </div>
        
      </perfect-scrollbar>
    </div>
    
  </div>
  
</div>

<ng-template #ambulanceListModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'titles.ambulancelist' | translate}}</h4>
      <button type="button" id="ambulancelistID" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="content_filter">
        <div class="filteroptselect">
          <div class="typesLists form-group">
            <label class="typeRounded" for="emsFilter">
              <input id="emsFilter" name="request_type_value" value="EMS" type="radio" aria-required="true" (change)="changeReqTypeValue($event)">
              <span>{{'labels.ems' | translate}}</span>
            </label>
            <!-- <label class="typeRounded" for="refFilter">
              <input id="refFilter" name="request_type_value" value="REF" type="radio" aria-required="true" (change)="changeReqTypeValue($event)">
              <span>REF</span>
            </label> -->
            <label class="typeRounded" for="stbFilter">
              <input id="stbFilter" name="request_type_value" value="STB" type="radio" aria-required="true" (change)="changeReqTypeValue($event)">
              <span>{{'labels.stb' | translate}}</span>
            </label>
            <label class="typeRounded" for="allFilter">
              <input id="allFilter" name="request_type_value" value="" checked="checked" type="radio" aria-required="true" (change)="changeReqTypeValue($event)">
              <span>{{'labels.all' | translate}}</span>
            </label>
          </div>
        </div>
        <div class="filtersearch">
          <div class="form-group position-relative">
            <label for="search">{{'labels.search' | translate}}</label>
            <input name="search" id="search" type="text" class="form-control" #searchvalue (input)="filterItem(searchvalue.value)">
          </div>
        </div>
      </div>
      <div class="paginations">
        <ngb-pagination [collectionSize]="collectionsize" (pageChange)="onPageChange($event)" [pageSize]="pageSize" [(page)]="page" [rotate] = "true" [maxSize]="5" [boundaryLinks]="true" aria-label="Default pagination"></ngb-pagination>
      </div>
      <div class="listingdata">
        <input type="hidden" (click)="toggle(acc)" id="toggleClicks">
        <ngb-accordion  #acc="ngbAccordion">
          <ngb-panel id="static-{{hospitalDetail._id}}" *ngFor="let hospitalDetail of ambulancelist; let i = index">
            <ng-template ngbPanelTitle let-opened="opened">
              <span class="card-header--title">
                <span>{{hospitalDetail.name1}}</span> <span>[{{hospitalDetail.ambulances.length}}]</span>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="abmbulancelist_content">
                <ul>
                  <li *ngFor="let ambulanceDetail of hospitalDetail.ambulances">
                    <div class="left_data">
                      <div class="amb_icon">
                        <img onerror="this.src='assets/images/icon/amb_image.png'" [src]="imgUrls + hospitalDetail.profile_picture_path + ambulanceDetail.profile_picture" alt="ambulance" class="img-fluid">
                      </div>
                      <div class="amb_full_data">
                        <div class="amb_value">{{ambulanceDetail.ambulance_box_code}}</div>
                        <div class="amb_value">{{ambulanceDetail.name1}} {{ambulanceDetail.name2}}</div>
                        <!-- <span class="eta_km">ETA : Infinity Min 0.00 km/h</span> -->
                      </div>
                    </div>
                    <div class="right_data">
                      <div class="action_button">
                        <button *ngIf="ambulanceDetail.is_assign == 1">
                          <span *ngIf="ambulanceDetail.aocRequestsDetail?.request_type == 'EMS'">{{'labels.ems' | translate}}</span>
                          <span *ngIf="ambulanceDetail.aocRequestsDetail?.request_type == 'IN'">{{'labels.in' | translate}}</span>
                          <span *ngIf="ambulanceDetail.aocRequestsDetail?.request_type == 'OUT'">{{'labels.out' | translate}}</span>
                          <span *ngIf="ambulanceDetail.aocRequestsDetail?.request_type == 'BACK'">{{'labels.back' | translate}}</span>
                          <span *ngIf="ambulanceDetail.aocRequestsDetail?.request_type == 'OTHER'">{{'labels.other' | translate}}</span>
                        </button>
                        <button *ngIf="ambulanceDetail.is_assign == 1" (click) = "openDirectionModal(ambulanceDetail)">
                          <img src="assets/images/direction.png" alt="btn_direction" class="img-fluid" >
                        </button>
                        <button *ngIf="ambulanceDetail.is_assign == 1" (click) = "openSnapshotModal(ambulanceDetail)">
                          <img src="assets/images/video-camera.png" alt="btn_camera" class="img-fluid" >
                        </button>
                        <!-- <button [disabled]="ambulanceDetail.is_assign == 1 " [ngClass]="{'disabled': ambulanceDetail.is_assign == 1 }">
                          <img src="assets/images/gear.png" alt="btn_config" class="img-fluid" >
                        </button> -->
                        <button [disabled]="ambulanceDetail.is_assign == 1 " [ngClass]="{'disabled': ambulanceDetail.is_assign == 1 }" (click)="openaddAmbulanceModal(hospitalDetail, ambulanceDetail)">
                          <img src="assets/images/ambulance.png" alt="btn_input" class="img-fluid" >
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>  
    </div>
      
</ng-template>


<ng-container #addAmbulanceModal>
  <div class="modal fade sidebar_list" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [ngClass]="ambulanceListStatus ? 'ambulanceListModalShow' : 'ambulanceListModalhide'">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" id="addAmbulanceClose" aria-label="Close" (click)="closeambulanceListModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form_content">
          <div class="form_header">
            <div class="form_img">
              <img src="assets/images/icon/ambulance_icon.png" alt="ambulance_icon" class="img-fluid" >
            </div>
            <div class="form_default">
              <h4>{{selectHospital}}</h4>
              <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label for="ambulancename">{{'labels.ambulancename' | translate}}</label>
                        <input name="ambulancename"  id="ambulancename" type="text"  class="form-control" placeholder="{{selectAmbulance}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label for="platno">{{'labels.platno' | translate}}</label>
                        <input name="platno"  id="platno" type="text"  class="form-control" placeholder="{{platNo}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label for="platno">{{'labels.operating_time' | translate}}</label>
                        <input  name="datetimepicker" class="form-control" id='datetimepicker' placeholder="{{today}}" disabled/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form_button">
                        <button class="btn btn-primary">{{'buttons.record' | translate}}</button>
                        <button class="btn btn-secondary">{{'buttons.cancel_operation' | translate}}</button>
                      </div>
                    </div>
                  </div>
              </form>
            </div>
          </div>
          <div class="divider"></div>
          <p><ngb-progressbar type="info" [value]="currentValue" [striped]="true" [animated]="true" [max]="100"></ngb-progressbar></p>
          <div class="form_body">
            <div class="form_stepper">
              <form [formGroup]="ambulanceReqForm" #form="ngForm">
                <div class="form-group error-l-50">
                  <input type="hidden" class="form-control" [(ngModel)]="selectAmbulancereq._id" formControlName="_id" name="_id">

                  <input type="hidden" class="form-control" [(ngModel)]="selectAmbulanceId" formControlName="ambulance_id" name="ambulance_id" value="ambulance_id">
                </div>

                <ul ngbNav #nav="ngbNav" id="myTabs" [(activeId)]="active" (navChange)="changeTab($event)"  class="nav-tabs" #staticTabs>
                  <li [ngbNavItem]="1">
                    <a ngbNavLink  name="one">
                      <span class="roundedselect">1</span>
                      <span class="stepmenu">{{'titles.type_operation' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="typesLists form-group">
                        <label class="typeRounded" for="ems">
                          <input id="ems" name="request_type" value="EMS" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                          <span>{{'labels.ems' | translate}}</span>
                        </label>
                        <!-- <label class="typeRounded" for="ref">
                          <input id="ref" name="request_type" value="REF" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                          <span>{{'labels.ref' | translate}}</span>
                        </label> -->
                        <span *ngIf="patintBoolen" >
                          <label class="typeRounded" for="in" >
                            <input id="in" name="request_type" value="IN" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                            <span>{{'labels.in' | translate}}</span>
                          </label>
                          <label class="typeRounded" for="out">
                            <input id="out" name="request_type" value="OUT" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                            <span>{{'labels.out' | translate}}</span>
                          </label>
                          <label class="typeRounded" for="back">
                            <input id="back" name="request_type" value="BACK" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                            <span>{{'labels.back' | translate}}</span>
                          </label>
                          <label class="typeRounded" for="other">
                            <input id="other" name="request_type" value="OTHER" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                            <span>{{'labels.other' | translate}}</span>
                          </label>
                        </span>
                      </div>

                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" disabled>
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="2">
                    <a ngbNavLink  name="tow" >
                      <span class="roundedselect">2</span>
                      <span class="stepmenu">{{'titles.driver' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectDriver(driver, i)" *ngFor="let driver of driverList; let i = index">{{ driver.first_name }} {{ driver.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteSelectDriver(driver, i)" *ngFor="let driver of driverListShow; let i = index">
                                  <span>{{ driver.first_name }} {{ driver.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="3" >
                    <a ngbNavLink  name="three">
                      <span class="roundedselect">3</span>
                      <span class="stepmenu">{{'titles.doctor' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectDoctor(doctor, i)" *ngFor="let doctor of doctorList;  let i = index ">{{ doctor.first_name }} {{ doctor.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li  (click)="deleteSelectDoctor(doctor, i)" *ngFor="let doctor of doctorListShow; let i = index">
                                  <span>{{ doctor.first_name }} {{ doctor.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="4">
                    <a ngbNavLink  name="four">
                      <span class="roundedselect">4</span>
                      <span class="stepmenu">{{'titles.nurse' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectNurse(nurse, i)" *ngFor="let nurse of nurseList; let i = index">{{ nurse.first_name }} {{ nurse.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteselectNurse(nurse, i)" *ngFor="let nurse of nurseListShow; let i = index">
                                  <span>{{ nurse.first_name }} {{ nurse.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>

                  <li  [ngbNavItem]="5">
                    <a ngbNavLink  name="four">
                      <span class="roundedselect">5</span>
                      <span class="stepmenu">{{'titles.paramedic' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectParamedic(paramedic, i)" *ngFor="let paramedic of paramedicList; let i = index">{{ paramedic.first_name }} {{ paramedic.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteselectParamedic(paramedic, i)" *ngFor="let paramedic of paramedicListShow; let i = index">
                                  <span>{{ paramedic.first_name }} {{ paramedic.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>

                  <li  [ngbNavItem]="6">
                    <a ngbNavLink  name="five">
                      <span class="roundedselect">6</span>
                      <span class="stepmenu">{{'titles.erstaff' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectEr(er, i)" *ngFor="let er of erstaffList; let i = index">{{ er.first_name }} {{ er.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteSelectEr(er, i)" *ngFor="let er of erstaffListShow; let i = index">
                                  <span>{{ er.first_name }} {{ er.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="7">
                    <a ngbNavLink  name="six">
                      <span class="roundedselect">7</span>
                      <span class="stepmenu">{{'titles.emsstaff' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectEms(ems, i)" *ngFor="let ems of emsstaffList; let i = index">{{ ems.first_name }} {{ ems.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteSelectEms(ems, i)" *ngFor="let ems of emsstaffListShow; let i = index">
                                  <span>{{ ems.first_name }} {{ ems.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="8">
                    <a ngbNavLink  name="seven">
                      <span class="roundedselect">8</span>
                      <span class="stepmenu">{{'titles.details' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                    <div class="detailsForm">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label>{{ 'labels.hnnumber' | translate}}</label>
                            <input type="text"  [(ngModel)]="selectAmbulancereq.unique_hn_code" class="form-control" name="unique_hn_code" formControlName="unique_hn_code" [ngClass]="{'is-invalid': !ambulanceReqForm.get('unique_hn_code').valid && (ambulanceReqForm.get('unique_hn_code').dirty || ambulanceReqForm.get('unique_hn_code').touched || form.submitted)}">
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.unique_hn_code.valid && submitted">{{'error.hnnumber' | translate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form_button">
                            <button class="btn btn-primary">
                              <i class="vsm-icon pe-7s-up-arrow"></i>
                              {{'buttons.fetch' | translate}}
                            </button>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="patientname">{{'labels.patient_name' | translate}}</label>
                            <input name="first_name"  id="patientname" type="text"  class="form-control" [(ngModel)]="selectAmbulancereq.first_name" formControlName="first_name" [ngClass]="{'is-invalid': !ambulanceReqForm.get('first_name').valid && (ambulanceReqForm.get('first_name').dirty || ambulanceReqForm.get('first_name').touched || form.submitted)}" required aria-required="true">
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.first_name.valid && submitted">{{'error.first_name' | translate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="patientsur">{{'labels.patient_surname' | translate}}</label>
                            <input name="last_name"  id="patientsur" type="text"  class="form-control" [(ngModel)]="selectAmbulancereq.last_name" formControlName="last_name" [ngClass]="{'is-invalid': !ambulanceReqForm.get('last_name').valid && (ambulanceReqForm.get('last_name').dirty || ambulanceReqForm.get('last_name').touched || form.submitted)}">
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.last_name.valid && submitted">{{'error.last_name' | translate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative ageInput">
                            <label for="age">{{'labels.age' | translate}}</label>
                            <input name="age"  id="age" type="number"  class="form-control" [(ngModel)]="selectAmbulancereq.age" formControlName="age" [ngClass]="{'is-invalid': !ambulanceReqForm.get('age').valid && (ambulanceReqForm.get('age').dirty || ambulanceReqForm.get('age').touched || form.submitted)}">
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.age.valid && submitted">{{'error.age' | translate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="sickness">{{'labels.sickness_type' | translate}}</label>
                            <input name="sickness_type"  id="sickness" type="text"  class="form-control" [(ngModel)]="selectAmbulancereq.sickness_type" formControlName="sickness_type" [ngClass]="{'is-invalid': !ambulanceReqForm.get('sickness_type').valid && (ambulanceReqForm.get('sickness_type').dirty || ambulanceReqForm.get('sickness_type').touched || form.submitted)}">
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.sickness_type.valid && submitted">{{'error.sickness_type' | translate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="sickness">{{'labels.severity_level' | translate}}</label>
                            <div class="row">
                              <div class="col-2" *ngFor="let severitys of severityLists | keyvalue;  let i = index">
                                <div class="custom-control custom-radio">
                                  <input type="radio" [value]=i+1 formControlName="severity_level" id="sl{{severitys.key}}" checked  [(ngModel)]="selectAmbulancereq.severity_level" name="severity_level" class="custom-control-input" [ngClass]="{'is-invalid': !ambulanceReqForm.get('severity_level').valid && (ambulanceReqForm.get('severity_level').dirty || ambulanceReqForm.get('severity_level').touched || form.submitted)}" required aria-required="true">
                                  <label class="custom-control-label" for="sl{{severitys.key}}"><div style="height: 20px; width: 20px; border-radius: 10px; border: 1px solid black;" [style.background-color]="severitys.value"></div></label>
                                </div>
                              </div>
                            </div>
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.severity_level.valid && submitted">{{'error.severity_level' | translate}}</span>
                          </div>
                        </div>

                        <div class="col-md-6"  *ngIf="selectAmbulancereq.request_type !== 'EMS'">
                          <div class="form-group position-relative">
                            <label for="hospital">{{'labels.zone_enable' | translate}}</label>
                            <div class="row">
                              <div class="col-12">
                                <ui-switch aria-required="true" #iszone (click)="zoneToggle(iszone)"></ui-switch>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="hospital">{{'labels.hospital' | translate}}</label>
                            <input   type="text"  class="form-control"  [(ngModel)]="selectHospital" name="s_hosp" formControlName="s_hosp"  disabled>
                            <input hidden name="source_hospital_id"  id="hospital" type="text"  class="form-control" [(ngModel)]="selectSourceHospital" formControlName="source_hospital_id" required="required" aria-required="true" disabled>
                          </div>
                        </div>
                        <div class="col-md-6"  *ngIf="selectAmbulancereq.request_type !== 'EMS'">
                          <div class="form-group position-relative">
                            <label for="zoneId">{{'labels.zone_id' | translate}}</label>
                            <select name="zone_id" id="zoneId" disabled class="form-control" [(ngModel)]="selectAmbulancereq.zone_id" formControlName="zone_id" (change)="zoneChange($event)">
                              <option value="{{zone._id}}"  *ngFor="let zone of zoneList">{{zone.name1}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="destination">{{'labels.destination' | translate}}</label>
                            <select name="destination_hospital_id" id="destination" class="form-control" [(ngModel)]="selectAmbulancereq.destination_hospital_id" formControlName="destination_hospital_id" *ngIf="!(selectAmbulancereq.request_type === 'EMS' || selectAmbulancereq.request_type === 'OTHER')">
                              <option value=""> </option>
                              <option value="{{hospital._id}}"  *ngFor="let hospital of hospitalList">{{hospital.name1}}</option>
                            </select>

                            <button class="btn btn-light showmapbtn" (click) = "openShowMapModal(showMapmodal)" *ngIf="selectAmbulancereq.request_type === 'EMS' || selectAmbulancereq.request_type === 'OTHER'">Show Maps</button>
                            
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <label for="glass">{{'labels.glass' | translate}}</label>
                            <ng-select [items]="glassList" class="form-control p-0 b-0" formControlName="glass_id" [ngClass]="{'is-invalid': !ambulanceReqForm.get('glass_id').valid && (ambulanceReqForm.get('glass_id').dirty || ambulanceReqForm.get('glass_id').touched || form.submitted)}"
                            name="glass_id" id="glass_id" bindLabel="name" 
                            bindValue="_id" 
                            name="glass_id" formControlName="glass_id"
                            [(ngModel)]="selectAmbulancereq.glass_id">
                           </ng-select> 
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.glass_id.valid && submitted">{{'error.glass_req' | translate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <label for="monitor">{{'labels.monitor' | translate}}</label>
                            <ng-select [items]="monitorsList" class="form-control p-0 b-0" formControlName="moniter_id" [ngClass]="{'is-invalid': !ambulanceReqForm.get('moniter_id').valid && (ambulanceReqForm.get('moniter_id').dirty || ambulanceReqForm.get('moniter_id').touched || form.submitted)}"
                            name="moniter_id" id="moniter_id" bindLabel="name" 
                            bindValue="_id" 
                            name="moniter_id" formControlName="moniter_id"
                            [(ngModel)]="selectAmbulancereq.moniter_id">
                           </ng-select> 
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.moniter_id.valid && submitted">{{'error.monitor_req' | translate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="gender">{{'labels.gender' | translate}}</label>
                            <select name="gender" id="gender" class="form-control" [(ngModel)]="selectAmbulancereq.gender" formControlName="gender" [ngClass]="{'is-invalid': !ambulanceReqForm.get('gender').valid && (ambulanceReqForm.get('gender').dirty || ambulanceReqForm.get('gender').touched || form.submitted)}">
                              <option value="0">{{'labels.male' | translate}}</option>
                              <option value="1">{{'labels.female' | translate}}</option>
                              <option value="2">{{'labels.other' | translate}}</option>
                            </select>
                            <span style="color:red" *ngIf="!ambulanceReqForm.controls.gender.valid && submitted">{{'error.gender' | translate}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="diagnos_note">{{'labels.diagnos_note' | translate}}</label>
                            <textarea name="diagnosis_note" id="diagnos_note" class="form-control" [(ngModel)]="selectAmbulancereq.diagnosis_note" formControlName="diagnosis_note"></textarea>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="selectAmbulancereq.request_type !== 'EMS'">
                          <div class="form-group position-relative">
                            <label for="fast_track_id">{{'labels.fast_track' | translate}}</label>
                            <div class="fastTrackbox">
                              <div class="form-group">
                                <label class="fastList" for="{{fasttrack.name}}" *ngFor="let fasttrack of fasttrackList">
                                  <input id="{{fasttrack.name}}" name="fast_track_id" value="{{fasttrack._id}}" type="radio" [(ngModel)]="selectAmbulancereq.fast_track_id" formControlName="fast_track_id" >
                                  <span>{{fasttrack.name}}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="hospital">{{'labels.trauma' | translate}}</label>
                            <div class="row">
                              <div class="col-12">
                                <ui-switch [(ngModel)]="selectAmbulancereq.is_trauma" formControlName="is_trauma" [checked]="true"></ui-switch>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="tabaction">
                      <button class="btn btn-warning previous-tab" (click)="previous()">
                        <i class="vsm-icon pe-7s-angle-left-circle"></i>
                        {{'buttons.previous' | translate}}
                      </button>
                      <button class="btn btn-warning" (click)="onSubmit(form.value)" [disabled]="submitted">
                        {{'buttons.submit' | translate}}
                        <span class="spinner-grow spinner-grow-sm" id="submitLoader" role="status" aria-hidden="true"></span>
                        <!-- <i class="vsm-icon pe-7s-angle-right-circle"></i> -->
                      </button>
                    </div>
                    </ng-template>
                  </li>
                </ul>
              </form>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="showMapmodal">
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [ngClass]="status ? 'mapViewmodalShow' : 'mapViewmodalhide'">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">{{'titles.googlemap' | translate}}</h4>
          <button type="button" id="mapID" class="close" aria-label="Close" (click)="closeMap()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="googleSearch">
            <div  class="form-group position-relative">
              <input name="search" id="search" type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search [(ngModel)] = "dragValue">
              <button type="button" id="mapID" class="btn btn-primary mt-1 mb-2 float-right" aria-label="Close" (click)="closeMap()">
                {{'buttons.submit' | translate}}
              </button>
            </div>
          </div>
          <div class="showmap">
            <agm-map [latitude]="latitude" [longitude]="longitude"  [zoom]="zoom">
              <agm-marker [latitude]="latitude" [longitude]="longitude"  [markerDraggable]="true"
              (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map>
          </div>
        </div>
      </div>
    </div> 
</div>
</ng-container>
