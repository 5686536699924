import {BrowserModule} from '@angular/platform-browser';
import {Injectable, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import {CommonModule} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {AppComponent} from './app.component';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ChartsModule} from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';
import {HeaderComponent} from './Layout/Components/header/header.component';
import {SearchBoxComponent} from './Layout/Components/header/elements/search-box/search-box.component';
import {UserBoxComponent} from './Layout/Components/header/elements/user-box/user-box.component';
import {SidebarComponent} from './Layout/Components/sidebar/sidebar.component';
import {LogoComponent} from './Layout/Components/sidebar/elements/logo/logo.component';
import {FooterComponent} from './Layout/Components/footer/footer.component';
import { CookieService } from 'ngx-cookie-service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DashboardComponent } from './User_AOC/Dashboards/dashboard/dashboard.component';
import { LoginBoxedComponent } from './User_AOC/UserPages/login-boxed/login-boxed.component';
import { CustomInterceptor } from './app.intercepter';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgxSpinnerModule } from "ngx-spinner";
import { DataTablesModule } from "angular-datatables";
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { CommonsModule } from './common/common.module'
import { ToastContainerComponent } from './common/toast-container/toast-container.component';
import { StaffListComponent } from './User_AOC/staff-list/staff-list.component';
import { HistoryComponent } from './User_AOC/history/history.component';
import { SpeedReportComponent } from './User_AOC/speed-report/speed-report.component';
import { SpeedReportDetailComponent } from './User_AOC/speed-report-detail/speed-report-detail.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { HistoryOngoingComponent } from './User_AOC/history-ongoing/history-ongoing.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CrashModalComponent } from './common/crash-modal/crash-modal.component';
import { NotificationsListComponent } from './User_AOC/notifications-list/notifications-list.component'
import { environment } from 'src/environments/environment';
import { RequestinfoComponent } from './User_AOC/UserPages/requestinfo/requestinfo.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ErrorPageComponent } from './Layout/error-page/error-page.component';
import { UserprofileComponent } from './User_AOC/UserPages/userprofile/userprofile.component';
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "./Services/messaging.service";
import { AsyncPipe } from "../../node_modules/@angular/common";
import { OperationReportComponent } from './User_AOC/operation-report/operation-report.component';
import { EcgReportComponent } from './User_AOC/ecg-report/ecg-report.component';
import { EcgReportDetailComponent } from './User_AOC/ecg-report-detail/ecg-report-detail.component';

// import { SpeedReport2Component } from './User_AOC/speed-report2/speed-report2.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@Injectable({providedIn: 'root'})
export class ecgConfig extends Socket {
  constructor() {
    super({ url: environment.ecg_url, options: {} });
  }
}

@Injectable({providedIn: 'root'})
export class locationConfig extends Socket {
  constructor() {
    super({ url: environment.ws_url, options: {} });
  }
}

@Injectable({providedIn: 'root'})
export class aliveServer extends Socket {
  constructor() {
    super({ url: environment.alive_server_url, options: {} });
  }
}

// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/lang/', '.json');
// }
export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,
    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,
    SidebarComponent,
    LogoComponent,
    FooterComponent,
    DashboardComponent,
    LoginBoxedComponent,
    ToastContainerComponent,
    StaffListComponent,
    HistoryComponent,
    SpeedReportComponent,
    HistoryOngoingComponent,
    // SpeedReport2Component,
    CrashModalComponent,
    NotificationsListComponent,
    SpeedReportDetailComponent,
    RequestinfoComponent,
    ErrorPageComponent,
    UserprofileComponent,
    OperationReportComponent,
    EcgReportComponent,
    EcgReportDetailComponent
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApiKey,
      language: 'en',
      libraries: ['geometry', 'places'],
    }),
    AgmJsMarkerClustererModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    // SocketIoModule.forRoot(trackingconfig, ecgconfig),
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    UiSwitchModule,
    NgxSpinnerModule,
    DataTablesModule,
    CommonsModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    ClipboardModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
      MessagingService,
      AsyncPipe,
      NgbActiveModal,
      locationConfig,
      ecgConfig,
      {
        provide:HTTP_INTERCEPTORS,
        useClass :CustomInterceptor,
        multi :true
      },
    CookieService,
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>, private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
