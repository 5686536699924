import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class DataTransService {

  subsV: Subscription;    
  patId:  Subscription;

  constructor() { }    

  secondData = new EventEmitter();
  thirdData = new EventEmitter();
  
  secondButtonclickdData(secondDetails){
    let detail2 = {secondDetails};
    this.secondData.emit(detail2);    
  }
  thirdButtonclickdData(thirdDetails){
    let details = {thirdDetails};
    this.thirdData.emit(details);    
  }
}

