import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LangService } from './Services/lang.service';
import {NgbConfig} from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../app/Services/location.service';
import { ToastService } from '../app/Services/toast.service'
import { Subscription } from 'rxjs-compat';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrashModalComponent } from '../app/common/crash-modal/crash-modal.component';
// import { AliveModalComponent } from '../app/common/alive-modal/alive-modal.component';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { MessagingService } from "./Services/messaging.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'AOC-v2';
  private location: Subscription;
  errTime =  environment.timeout.errorNotificationTimeout;
  message;
  constructor(private modalService: NgbModal, private router: Router, private cookieService: CookieService, private lang : LangService, ngbConfig: NgbConfig, private locationService: LocationService, private toastService: ToastService, private messagingService: MessagingService) {
    ngbConfig.animation = false;
  }


  ngOnInit() {   
    // this.getAliveNotify();
    // this.toastService.show("push check", { classname: 'bg-success text-white', delay: 5000});
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage


    this.location = this.locationService.location.subscribe(data => {
      if(data[0] == '{'){
        data = JSON.parse(data);
        if(data.type == 'CRASH_NOTIFY'){
          this.getCrashNotify(data.ambulance_crashes_id)
        } 
      } else {
      }
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    });
    
    this.lang.init() 
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  getCrashNotify(ambulanceCrashesId){
    $('.modalopen').show()
    const modaref = this.modalService.open(CrashModalComponent, {backdropClass: 'light-pink',  centered: true, windowClass:'crashModal'});
    modaref.componentInstance.crashDetail = ambulanceCrashesId;
  }

  // getAliveNotify(){
  //   $('.alivemodalopen').show()
  //   const modaref = this.modalService.open(AliveModalComponent, {backdropClass: 'light-pink',  centered: true, windowClass:'aliveModal'});
  // }

  pushNotify(){
    
  }
}
