import { Injectable } from '@angular/core';
import { aliveServer } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class AliveServerService {
  aliveServer = this.aliveSocket.fromEvent<any>('data');

  constructor(public aliveSocket: aliveServer) { }
  roomJoin(roomName: string) {
    this.aliveSocket.emit('room_join', String(roomName));
  }
  roomLeave(roomName: string) {
    this.aliveSocket.emit('room_leave', String(roomName));
  }

}
