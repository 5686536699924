<div class="footer">
  <div class="row" style="background-color: black;">
    <div class="col-6">
      <div class="tely_address">
        <img src="/assets/images/tely_address.png" alt="logo" class="img-fluid">
      </div>
    </div>
    <div class="col-6">
      <div class="timers">
        {{date_now | date :'medium'}}
      </div>
    </div>
  </div>
</div>