import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../../Services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../../Services/toast.service'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.sass']
})
export class HistoryComponent implements OnInit {
  page = 3;
  collectionsize:any;
  page_size:any = 10; 
  current_page:any = 1;
  requestList:any;
  request_status:any;
  sort_value:any;
  hospital_id:any;
  zone_id:any;
  request_type:any;
  zoneList:any;
  hospitalList:any;
  filterReqForm: FormGroup;
  user_id: any;
  errTime =  environment.timeout.errorNotificationTimeout;
  constructor(private spinner: NgxSpinnerService, private cookieService : CookieService, private apiService: AuthService, private toastService:ToastService) { }

  selectFilterreq = {
    zone_id: '',
    hospital_id:'',
    request_status:'',
    request_type:'',
  }
  ngOnInit(): void {
    this.user_id = this.cookieService.get('user_id');

    this.getZone()
    
    this.filterReqForm = new FormGroup({
      zone_id:new FormControl(),
      hospital_id:new FormControl(),
      request_status:new FormControl(),
      request_type:new FormControl(),
    })
  }

  submitFilter(){

    this.zone_id = this.filterReqForm.value['zone_id']
    this.hospital_id = this.filterReqForm.value['hospital_id']
    this.request_status = this.filterReqForm.value['request_status']
    this.request_type = this.filterReqForm.value['request_type']

    if( this.filterReqForm.value){
      $('#submitLoader').css('display', 'inline-block');
      this.aocRequestHistory()
      $('#submitLoader').hide();
    }
  }

  getZone(){
    this.spinner.show();
    let userId = this.cookieService.get('api_key')
    this.apiService.getHospitalsByUserList(userId).subscribe(data=>{
      this.zoneList = data['content']['data'][0]['zoneDetails'];
      this.aocRequestHistory();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  getHospitalsList(zoneId) {
    this.spinner.show();
    this.apiService.getHospitalsList(zoneId).subscribe((hospitalDetail: any) => {
      this.hospitalList = hospitalDetail['content']['data'];
      this.spinner.hide();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }
  zoneChange(e) {
    if(e.target.value){
      this.getHospitalsList(e.target.value);
    }
  }
  
  aocRequestHistory(page_size = 10, current_page = 1){
    // this.page_size = page_size;
    // this.current_page = current_page ;
    this.spinner.show()
    this.apiService.aocRequestHistory(
      this.user_id = this.user_id,
      this.sort_value = '{"_id":1}',
      this.page_size = page_size,
      this.current_page = current_page,
      this.request_status = this.request_status ?this.request_status :'', 
      this.hospital_id = this.hospital_id ? this.hospital_id :'', 
      this.zone_id =  this.zone_id ?  this.zone_id :'', 
      this.request_type = this.request_type ?this.request_type :''
      ).subscribe((data) => {
     if(data['success']){
      this.requestList = data['content']['data'];
      this.collectionsize = data['content'].count
      this.spinner.hide();
     }else{
      setTimeout(() => {
        this.spinner.hide();
      },5000)
     }
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  
  }
  onPageChange(page){
    this.current_page = page
    this.aocRequestHistory(this.page_size, this.current_page )
  }

}
