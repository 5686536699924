<div class="content-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="card main-card mb-3">
        <div class="card-header-tab card-header">
          <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon fa fa-list-alt"> </i>
            {{'titles.history_request_list' | translate}}
          </div>
        </div>
        <div class="no-gutters row">
          <div class="card-body">
            <form [formGroup]="filterReqForm" #form="ngForm">
              <div class="header-filter">
                <div class="row align-items-center">
                  <div class="col-md-3 col-sm-6">
                    <div class="form-group position-relative mb-0">
                      <select name="zone_id" id="zoneId" class="form-control" [(ngModel)]="selectFilterreq.zone_id" formControlName="zone_id" (change)="zoneChange($event)">
                        <option value="" selected>{{'labels.selectzone' | translate}}</option>
                        <option value="{{zone._id}}"  *ngFor="let zone of zoneList">{{zone.name1}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="form-group position-relative mb-0">
                      <select name="hospital_id" id="destination" class="form-control" [(ngModel)]="selectFilterreq.hospital_id" formControlName="hospital_id">
                        <option value="" selected>{{'labels.selectHospital' | translate}}</option>
                        <option value="{{hospital._id}}"  *ngFor="let hospital of hospitalList">{{hospital.name1}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="form-group position-relative mb-0">
                      <select name="zone_id" id="requestType" class="form-control" [(ngModel)]="selectFilterreq.request_type" formControlName="request_type">
                        <option value="" selected>{{'labels.select_request_type' | translate}}</option>
                        <option value="EMS">{{'labels.resuscitation' | translate}}</option>
                        <option value="REF">{{'labels.forward' | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="form-group position-relative mb-0">
                      <select name="zone_id" id="requestStatus" class="form-control" [(ngModel)]="selectFilterreq.request_status" formControlName="request_status">
                        <!-- <option value="" disabled selected>Select Request Status</option> -->
                        <option value="">{{'labels.all' | translate}}</option>
                        <option value="COMPLETED">{{'labels.completed' | translate}}</option>
                        <option value="CANCELED">{{'labels.canceled' | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-4">
                    <button type="submit" (click)="submitFilter()" class="btn btn-primary btn-block">{{'buttons.submit' | translate}}
                      <span class="spinner-grow spinner-grow-sm" id="submitLoader" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
              </div>
              </div>
            </form>
            <div class="page-body historyTable">
              <div class="paginations">
                <ngb-pagination [collectionSize]="collectionsize" (pageChange)="onPageChange($event)" [pageSize]="page_size" [(page)]="page" [rotate] = "true" [maxSize]="5" [boundaryLinks]="true" aria-label="Default pagination"></ngb-pagination>
              </div>
              <div class="table-responsive">
                <table class="table table-striped row-border w-100">
                  <thead>
                    <tr>
                      <th>{{'labels.id' | translate}}</th>
                      <th>{{'labels.patient' | translate}}</th>
                      <th>{{'labels.ambulance' | translate}}</th>
                      <th>{{'labels.request_code' | translate}}</th>
                      <th>{{'labels.dest_hosp' | translate}}</th>
                      <th>{{'labels.time' | translate}}</th>
                      <th>{{'labels.driver' | translate}}</th>
                      <th>{{'labels.doctor' | translate}}</th>
                      <th>{{'labels.nurse' | translate}}</th>
                      <th>{{'labels.er' | translate}}</th>
                      <th>{{'labels.resuscitation' | translate}}</th>
                      <th>{{'labels.monitor' | translate}}</th>
                      <th>{{'labels.status' | translate}}</th>
                      <!-- <th>{{'labels.detail' | translate}}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="no-data" *ngIf="requestList?.length == 0">
                      <td colspan="13">{{'error.nodata' | translate}}</td>
                    </tr>
                    <tr *ngFor="let detail of requestList; let i = index">
                      <td>{{((current_page-1)*page_size + 1) + i}}</td>
                      <td>{{detail.patientsDetail.first_name}} {{detail.patientsDetail.last_name}}</td>
                      <td>{{(detail.ambulancesDetail && detail.ambulancesDetail.ambulance_box_code) ?  detail.ambulancesDetail.ambulance_box_code  : 'N/A'}} {{detail?.ambulancesDetail?.number}}</td>
                      <td>{{(detail.request_code) ?detail.request_code : "N/A" }}</td>
                      <td>{{detail.destinationHospitalsDetail  ? detail.destinationHospitalsDetail?.full_name : detail.destinationDetail.address}}</td>
                      <!-- <td>{{ detail.destinationHospitalsDetail.full_name || '' }}</td> -->
                      <td>{{ detail.updatedAt | date:'medium' }}</td>
                      <td>
                        <ul>
                          <li *ngFor="let driver of detail.driversDetail">
                            {{driver.first_name}} {{driver.last_name}}
                          </li>
                        </ul>
                        <ul *ngIf ="detail.driversDetail.length == 0">N/A</ul>
                      </td>
                      <td>
                        <ul>
                          <li *ngFor="let doctor of detail.doctorsDetail">
                            {{doctor.first_name}} {{doctor.last_name}}
                          </li>
                        </ul>
                        <ul *ngIf ="detail.doctorsDetail.length == 0">N/A</ul>
                      </td>
                      <td>
                        <ul>
                          <li *ngFor="let nurse of detail.nursesDetail">
                            {{nurse.first_name}} {{nurse.last_name}}
                          </li>
                        </ul>
                        <ul *ngIf ="detail.nursesDetail.length == 0">N/A</ul>
                      </td>
                      <td>
                        <ul>
                          <li *ngFor="let er of detail.aocErStaffsDetail">
                            {{er.first_name}} {{er.last_name}}
                          </li>
                        </ul>
                        <ul *ngIf ="detail.aocErStaffsDetail.length == 0">N/A</ul>
                      </td>
                      <td>
                        <ul>
                          <li *ngFor="let ems of detail.aocEmsStaffsDetail">
                            {{ems.first_name}} {{ems.last_name}}
                          </li>
                        </ul>
                        <ul *ngIf ="detail.aocEmsStaffsDetail.length == 0">N/A</ul>
                      </td>
                      <td>{{(detail.patientMonitorsDetail && detail.patientMonitorsDetail.name) ?  detail.patientMonitorsDetail?.name : 'N/A'}}</td>
                      <td>
                        <span *ngIf="detail.request_status =='CANCELED'" class="badge badge-danger p-2">{{detail.request_status}}</span>
                        <span *ngIf="detail.request_status =='COMPLETED'" class="badge badge-success p-2">{{detail.request_status}}</span>
                       </td>
                      <!-- <td class="action">
                       <button class="btn btn-primary">{{'buttons.detail' | translate}}</button>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div> 
            </div>
          </div>
          <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        </div>
      </div>
    </div>
</div>
