import { Injectable } from '@angular/core';
import { locationConfig } from '../app.module';

@Injectable({
  providedIn: 'root'
})

export class LocationService {
  location = this.locationSocket.fromEvent<any>('data');
  roomJoined:any = [];
  constructor(public locationSocket: locationConfig) {}

  roomJoin(roomName: string) {
    this.locationSocket.emit('room_join', String(roomName));
  }

  roomLeave(roomName: string) {
    this.locationSocket.emit('room_leave', String(roomName));
  }

  setMapMarker(is_tracking_on: Number, is_assign: Number, request_type: String, speed: Number) {
    if(is_tracking_on){
      if(is_assign){
        switch (request_type) {
          case 'EMS':
            if(speed <= 80){
              return '/assets/images/map_pin/ambulance/EMS-GREEN.svg';
            } else if (speed > 80 && speed <= 120) {
              return '/assets/images/map_pin/ambulance/EMS-YELLOW.svg';
            } else if (speed > 120) {
              return '/assets/images/map_pin/ambulance/EMS-RED.svg';
            } else {
              return '/assets/images/map_pin/ambulance/EMS-OFFILNE.svg';
            }
  
          case 'OTHER':
            if(speed <= 80){
              return '/assets/images/map_pin/ambulance/OTHER-GREEN.svg';
            } else if (speed > 80 && speed <= 120) {
              return '/assets/images/map_pin/ambulance/OTHER-YELLOW.svg';
            } else if (speed > 120) {
              return '/assets/images/map_pin/ambulance/OTHER-RED.svg';
            } else {
              return '/assets/images/map_pin/ambulance/OTHER-OFFILNE.svg';
            }
        
          default:
            if(speed <= 80){
              return '/assets/images/map_pin/ambulance/REF-GREEN.svg';
            } else if (speed > 80 && speed <= 120) {
              return '/assets/images/map_pin/ambulance/REF-YELLOW.svg';
            } else if (speed > 120) {
              return '/assets/images/map_pin/ambulance/REF-RED.svg';
            } else {
              return '/assets/images/map_pin/ambulance/REF-OFFILNE.svg';
            }
        }
      } else {
        return '/assets/images/map_pin/ambulance/online.svg';
      }
    } else {
      if(is_assign){
        switch (request_type) {
          case 'EMS':
            return '/assets/images/map_pin/ambulance/EMS-ASSIGN-NOT-ONLINE.svg';
  
          case 'OTHER':
            return '/assets/images/map_pin/ambulance/OTHER-ASSIGN-NOT-ONLINE.svg';
        
          default:
            return '/assets/images/map_pin/ambulance/REF-ASSIGN-NOT-ONLINE.svg';
        }
      } else {
        return '/assets/images/map_pin/ambulance/offline.svg';
      }
    }
  }

  markerMove(fun, id, speed, num, delta, marker, old, current, location, markerMoveTimeout){
    var postition = new google.maps.LatLng((old.lat + delta['lat']), (old.lon + delta['lon']));
    marker.setPosition(postition);
    var eta_time = '';
    if(Number(location['estimated_time'])){

      if ((Number(location['estimated_time']) > 60) && Number(location['estimated_time']) % 60 !== 0) {
        if(Math.ceil(Number(location['estimated_time']) / 60) < 24){
          eta_time = Math.ceil(Number(location['estimated_time']) / 60) + ' ' + 'hr' + ' ' + Math.ceil((Number(location['estimated_time']) % 60)) + ' ' + 'min'
        } else {
          eta_time = 'N/A';
        }
      }
      else if (Number(location['estimated_time']) % 60 == 0) {
        if((Number(location['estimated_time']) % 60) < 24){
          eta_time = Math.ceil(Number(location['estimated_time']) / 24) + ' ' + 'hr'
        } else {
          eta_time = 'N/A';
        }
      }
      else {
        eta_time = Math.ceil(Number(location['estimated_time'])) + ' ' + 'min'
      }
    } else {
      eta_time = 'N/A';
    }

    var content = marker.getTitle()
    content = content.split('Speed');
    content = String(content[0]) + 'Speed :' + Math.ceil(Number(location['speed'])) + '</br>' + 'ETA :' + String(eta_time);
    marker.setTitle(content);

    old = { lat: (old.lat + delta.lat), lon: (old.lon + delta.lon) };
    if((speed !== num) && (delta.lat !== 0 || delta.lon !== 0)){
        speed++;
        if(markerMoveTimeout && markerMoveTimeout[String(id)]) clearTimeout(markerMoveTimeout[String(id)]);
        markerMoveTimeout[String(id)] = setTimeout(fun, 1, fun, id, speed, num, delta, marker, old, current, location, markerMoveTimeout);
    }
  }
}
