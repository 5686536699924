<div class="snapViewModal">
  <div class="modal-header actionHeader">
    <button
      type="button"
      id="ambulancelistID"
      class="close"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="row">
      <div class="col-md-8">
        <h4 class="modal-title" id="modal-basic-title">
          <img
            src="assets/images/icon/btn_input.png"
            alt="btn_config"
            class="img-fluid mr-1"
          />
          <span>{{ "titles.ambulance_snap_view" | translate }}</span>
        </h4>
      </div>
      <div class="col-md-4">
        <div class="modal_action_button">
          <button type="button" class="btn d-none">
            <img
              src="assets/images/gear.png"
              alt="btn_config"
              class="img-fluid"
            />
          </button>
          <button type="button" class="btn" (click)="clickSnapfullView()">
            <img
              src="assets/images/full-screen-button.png"
              alt="ico_full"
              id="fullBtn"
              class="img-fluid"
            />
            <img
              src="assets/images/small-screen.png"
              alt="ico_small"
              id="smallBtn"
              class="img-fluid"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="snapviewContent">
      <div class="row">
        <div [ngClass]="show ? 'col-md-12 col-lg-6 order_2' : 'col-md-12'">
          <div class="detailMap hidemap">
            <div class="map_fullview">
              <div #mapContainer id="map"></div>
            </div>
          </div>
        </div>
        <div [ngClass]="show ? 'col-md-12 col-lg-6 order_1' : 'col-md-12'">
          <div class="row">
            <div class="col-md-7">
              <div class="ambulanceHeading">
                <div class="ambulance_img">
                  <!-- <img src="assets/images/icon/ambl.jpeg" alt="ambl" class="img-fluid"> -->
                  <img
                    onerror="this.src='assets/images/icon/ambl.jpeg'"
                    [src]="
                      imgUrls +
                      profilePicturePath +
                      snapShotDetail?.profile_picture
                    "
                    alt="ambulance"
                    class="img-fluid"
                  />
                </div>
                <div class="ambulancename">
                  <label
                    >{{ snapShotDetail?.name1 }}
                    {{ snapShotDetail?.name2 }}</label
                  >
                  <span>{{ snapShotDetail?.number }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="status_log">
                <label>{{ "labels.prev_status" | translate }} : </label>
                <span>
                  <br /><label>{{ "labels.user_name" | translate }} : </label>
                  <span *ngIf="!previousStatusUser?.aocUsersDetail?.first_name">
                    N/A</span
                  >
                  <span
                    >{{ previousStatusUser?.aocUsersDetail?.first_name }}
                    {{ previousStatusUser?.aocUsersDetail?.last_name }}</span
                  >
                  <br /><label>{{ "labels.user_type" | translate }} : </label>
                  <span
                    *ngIf="!previousStatusUser?.aocUsersDetail?.designation"
                  >
                    N/A</span
                  >
                  <span>{{
                    previousStatusUser?.aocUsersDetail?.designation
                  }}</span>
                  <br /><label>{{ "labels.laststatus" | translate }} : </label>
                  <span *ngIf="!previousStatusUser?.request_status"> N/A</span>
                  <span>
                    <!-- {{previousStatusUser?.request_status}} -->
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'OPERATION_ASSIGNED'
                      "
                      >{{ "labels.OPERATION_ASSIGNED" | translate }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status == 'START_OPERATION'
                      "
                      >{{ "buttons.startopt" | translate }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'DEPART_FROM_BASE_HOSPITAL'
                      "
                      >{{
                        "buttons.depart_from_base_hospital" | translate
                      }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status == 'ARRIVAL_AT_SCENE'
                      "
                      >{{ "buttons.arrival_at_scene" | translate }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'GO_TO_BASE_HOSPITAL'
                      "
                      >{{ "buttons.go_to_base_hospital" | translate }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'GO_TO_ANOTHER_HOSPITAL'
                      "
                      >{{ "buttons.go_to_another_hospital" | translate }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'DEPART_FROM_SCENE'
                      "
                      >{{ "buttons.depart_from_scent" | translate }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'ARRIVAL_AT_BASE_HOSPITAL'
                      "
                      >{{
                        "buttons.arrival_at_base_hospital" | translate
                      }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'ARRIVAL_AT_ANOTHER_HOSPITAL'
                      "
                      >{{
                        "buttons.arrival_at_another_hospital" | translate
                      }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'GO_BACK_TO_BASE_HOSPITAL'
                      "
                      >{{
                        "buttons.go_back_to_base_hospital" | translate
                      }}</span
                    >
                    <span
                      *ngIf="
                        previousStatusUser?.request_status ==
                        'COME_BACK_TO_BASE_HOSPITAL'
                      "
                      >{{
                        "buttons.come_back_to_base_hospital" | translate
                      }}</span
                    >
                    <span
                      *ngIf="previousStatusUser?.request_status == 'COMPLETED'"
                      >{{ "buttons.completed" | translate }}</span
                    >
                  </span>
                  <br /><label>{{ "labels.updatedtime" | translate }} : </label>
                  <span *ngIf="!previousStatusUser?.createdAt"> N/A</span>
                  <span>{{
                    previousStatusUser?.createdAt | date : "d MMM, y, h:mm:ss a"
                  }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="snapAllbutton">
                <span class="row">
                  <span
                    *ngIf="aocRequestStatus?.previous_status"
                    class="col-md-5"
                  >
                    <!-- <label  class="statusaction prevs" for="PREVIOUS">
                            <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
                          </label>  -->
                    <button
                      type="button"
                      class="btn light-orange statuslabel"
                      *ngIf="aocRequestStatus?.previous_status"
                      [value]="aocRequestStatus?.previous_status"
                      id="PREVIOUS"
                      (click)="
                        postAocRequestStatusChange(
                          $event,
                          aocRequestStatus?.previous_status
                        )
                      "
                    >
                      <label class="statusaction prevs" for="PREVIOUS">
                        <i
                          class="fa fa-chevron-circle-left"
                          aria-hidden="true"
                        ></i>
                      </label>
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'OPERATION_ASSIGNED'
                        "
                        >{{ "labels.OPERATION_ASSIGNED" | translate }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status == 'START_OPERATION'
                        "
                        >{{ "buttons.startopt" | translate }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'DEPART_FROM_BASE_HOSPITAL'
                        "
                        >{{
                          "buttons.depart_from_base_hospital" | translate
                        }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'ARRIVAL_AT_SCENE'
                        "
                        >{{ "buttons.arrival_at_scene" | translate }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'GO_TO_BASE_HOSPITAL'
                        "
                        >{{ "buttons.go_to_base_hospital" | translate }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'GO_TO_ANOTHER_HOSPITAL'
                        "
                        >{{
                          "buttons.go_to_another_hospital" | translate
                        }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'DEPART_FROM_SCENE'
                        "
                        >{{ "buttons.depart_from_scent" | translate }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'ARRIVAL_AT_BASE_HOSPITAL'
                        "
                        >{{
                          "buttons.arrival_at_base_hospital" | translate
                        }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'ARRIVAL_AT_ANOTHER_HOSPITAL'
                        "
                        >{{
                          "buttons.arrival_at_another_hospital" | translate
                        }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'GO_BACK_TO_BASE_HOSPITAL'
                        "
                        >{{
                          "buttons.go_back_to_base_hospital" | translate
                        }}</span
                      >
                      <span
                        *ngIf="
                          aocRequestStatus?.previous_status ==
                          'COME_BACK_TO_BASE_HOSPITAL'
                        "
                        >{{
                          "buttons.come_back_to_base_hospital" | translate
                        }}</span
                      >
                      <span
                        *ngIf="aocRequestStatus?.previous_status == 'COMPLETED'"
                        >{{ "buttons.completed" | translate }}</span
                      >
                    </button>
                  </span>
                  <span class="col-md-5 ml-auto">
                    <!-- <label class="statusaction nexts" for="NEXT">
                          <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                        </label> -->
                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="aocRequestStatus?.next_status == 'START_OPERATION'"
                      value="START_OPERATION"
                      id="NEXT"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.startopt" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        aocRequestStatus?.next_status ==
                        'DEPART_FROM_BASE_HOSPITAL'
                      "
                      id="NEXT"
                      value="DEPART_FROM_BASE_HOSPITAL"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.depart_from_base_hospital" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        aocRequestStatus?.next_status == 'ARRIVAL_AT_SCENE'
                      "
                      id="NEXT"
                      value="ARRIVAL_AT_SCENE"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.arrival_at_scene" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        !showAnotherHospital &&
                        aocRequestStatus?.next_status ==
                          'GO_TO_BASE_HOSPITAL, GO_TO_ANOTHER_HOSPITAL'
                      "
                      value="GO_TO_BASE_HOSPITAL"
                      id="NEXT"
                      (click)="
                        postAocRequestStatusChange(
                          $event,
                          'GO_TO_BASE_HOSPITAL'
                        )
                      "
                    >
                      {{ "buttons.go_to_base_hospital" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        showAnotherHospital &&
                        aocRequestStatus?.next_status ==
                          'GO_TO_BASE_HOSPITAL, GO_TO_ANOTHER_HOSPITAL'
                      "
                      value="GO_TO_ANOTHER_HOSPITAL"
                      id="NEXT"
                      (click)="
                        goToAnotherHospital($event, 'GO_TO_ANOTHER_HOSPITAL')
                      "
                    >
                      {{ "buttons.go_to_another_hospital" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        aocRequestStatus?.next_status == 'DEPART_FROM_SCENE'
                      "
                      value="DEPART_FROM_SCENE"
                      id="NEXT"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.depart_from_scent" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        aocRequestStatus?.next_status ==
                        'ARRIVAL_AT_BASE_HOSPITAL'
                      "
                      id="NEXT"
                      value="ARRIVAL_AT_BASE_HOSPITAL"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.arrival_at_base_hospital" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        aocRequestStatus?.next_status ==
                        'ARRIVAL_AT_ANOTHER_HOSPITAL'
                      "
                      id="NEXT"
                      value="ARRIVAL_AT_ANOTHER_HOSPITAL"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.arrival_at_another_hospital" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        aocRequestStatus?.next_status ==
                        'GO_BACK_TO_BASE_HOSPITAL'
                      "
                      id="NEXT"
                      value="GO_BACK_TO_BASE_HOSPITAL"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.go_back_to_base_hospital" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="
                        aocRequestStatus?.next_status ==
                        'COME_BACK_TO_BASE_HOSPITAL'
                      "
                      id="NEXT"
                      value="COME_BACK_TO_BASE_HOSPITAL"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.come_back_to_base_hospital" | translate }}
                      <label class="statusaction nexts">
                        <i
                          class="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </label>
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="aocRequestStatus?.next_status == 'COMPLETED'"
                      value="COMPLETED"
                      id="NEXT"
                      (click)="postAocRequestStatusChange($event)"
                    >
                      {{ "buttons.completed" | translate }}
                    </button>

                    <div
                      class="anotherHospital"
                      *ngIf="
                        aocRequestStatus?.next_status ==
                        'GO_TO_BASE_HOSPITAL, GO_TO_ANOTHER_HOSPITAL'
                      "
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group position-relative mt-2">
                            <label for="hospital">{{
                              "labels.anotherHospitalSelect" | translate
                            }}</label>
                            <div class="row">
                              <div class="col-12">
                                <ui-switch
                                  aria-required="true"
                                  (click)="zoneToggle()"
                                ></ui-switch>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form
                        [formGroup]="anotherHospitalForm"
                        #form="ngForm"
                        *ngIf="showAnotherHospital"
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group position-relative">
                              <label for="destination">{{
                                "labels.destination" | translate
                              }}</label>
                              <select
                                name="hospital_id"
                                id="destination"
                                [(ngModel)]="selectAnotherHospital.hospital_id"
                                formControlName="hospital_id"
                                class="form-control"
                              >
                                <option
                                  value="{{ hospital._id }}"
                                  *ngFor="let hospital of hospitalList"
                                >
                                  {{ hospital.name1 }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <button
                      class="btn btn-primary"
                      (click)="openAddnote(snapShotDetail, addnote)"
                      *ngIf="!show"
                    >
                      {{ "buttons.addnote" | translate }}
                    </button>
                    <button
                      class="btn btn-danger cancelButton"
                      *ngIf="
                        aocRequestStatus?.next_status == 'OPERATION_ASSIGNED' ||
                        aocRequestStatus?.next_status == 'START_OPERATION' ||
                        aocRequestStatus?.next_status ==
                          'DEPART_FROM_BASE_HOSPITAL' ||
                        aocRequestStatus?.next_status == 'ARRIVAL_AT_SCENE' ||
                        aocRequestStatus?.next_status == 'DEPART_FROM_SCENE' ||
                        aocRequestStatus?.next_status ==
                          'GO_TO_BASE_HOSPITAL, GO_TO_ANOTHER_HOSPITAL'
                      "
                      id="NEXT"
                      value="CANCELED"
                      (click)="openconfirmModal(confirmModal)"
                    >
                      {{ "buttons.cancel_operation" | translate }}
                    </button>

                    <!-- <button class="btn btn-primary" *ngIf = "!show">{{'buttons.editdetail' | translate}}</button> -->
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="hospitalTitle">
                <h5>{{ "titles.patient_info" | translate }}</h5>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="ambulanceViewdetail">
                <div class="row">
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.name" | translate
                      }}</label>
                      <span
                        *ngIf="snapShotDetail?.patientsDetail?.first_name"
                        class="values"
                        >{{ snapShotDetail?.patientsDetail?.first_name }}
                        {{ snapShotDetail?.patientsDetail?.last_name }}</span
                      >
                      <span
                        *ngIf="
                          !snapShotDetail?.patientsDetail ||
                          (snapShotDetail?.patientsDetail?.first_name.length ==
                            0 &&
                            snapShotDetail?.patientsDetail?.last_name.length ==
                              0)
                        "
                        >N/A</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.age" | translate
                      }}</label>
                      <span
                        *ngIf="snapShotDetail?.patientsDetail?.age"
                        class="values"
                        >{{ snapShotDetail?.patientsDetail?.age }}</span
                      >
                      <span
                        *ngIf="
                          snapShotDetail?.patientsDetail?.age.length == 0 ||
                          !snapShotDetail?.patientsDetail
                        "
                        >N/A</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.gender" | translate
                      }}</label>
                      <span
                        class="values"
                        *ngIf="snapShotDetail?.patientsDetail?.gender == 0"
                        >{{ "labels.male" | translate }}</span
                      >
                      <span
                        class="values"
                        *ngIf="snapShotDetail?.patientsDetail?.gender == 1"
                        >{{ "labels.female" | translate }}</span
                      >
                      <span
                        class="values"
                        *ngIf="snapShotDetail?.patientsDetail?.gender == 2"
                        >{{ "labels.other" | translate }}</span
                      >
                      <span
                        *ngIf="
                          snapShotDetail?.patientsDetail?.gender?.length == 0
                        "
                        >N/A</span
                      >
                    </div>
                  </div>

                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.illness" | translate
                      }}</label>
                      <span
                        *ngIf="
                          snapShotDetail?.patientRequestsDetail?.sickness_type
                        "
                        class="values"
                        >{{
                          snapShotDetail?.patientRequestsDetail?.sickness_type
                        }}</span
                      >
                      <span
                        *ngIf="
                          snapShotDetail?.patientRequestsDetail?.sickness_type
                            ?.length == 0 ||
                          !snapShotDetail?.patientRequestsDetail?.sickness_type
                        "
                        >N/A</span
                      >
                    </div>
                  </div>

                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.request_code" | translate
                      }}</label>
                      <span
                        *ngIf="snapShotDetail?.aocRequestsDetail?.request_code"
                        class="values"
                        >{{
                          snapShotDetail?.aocRequestsDetail?.request_code
                        }}</span
                      >
                      <span
                        *ngIf="
                          snapShotDetail?.aocRequestsDetail?.request_code
                            ?.length == 0 ||
                          !snapShotDetail?.aocRequestsDetail?.request_code
                        "
                        >N/A</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.severity_level" | translate
                      }}</label>
                      <span class="values">
                        <span
                          class="activeIcon"
                          [ngStyle]="severityColorCode"
                        ></span>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.note" | translate
                      }}</label>
                      <span
                        *ngIf="snapShotDetail?.aocRequestsDetail?.request_note"
                        class="values"
                        >{{
                          snapShotDetail?.aocRequestsDetail?.request_note
                        }}</span
                      >
                      <span
                        *ngIf="
                          snapShotDetail?.aocRequestsDetail?.request_note
                            ?.length == 0 ||
                          !snapShotDetail?.aocRequestsDetail?.request_note
                        "
                        >N/A</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.fast_track" | translate
                      }}</label>
                      <span
                        *ngIf="
                          snapShotDetail?.aocRequestsDetail?.fastTracksDetail
                            ?.name
                        "
                        class="values"
                        >{{
                          snapShotDetail?.aocRequestsDetail?.fastTracksDetail
                            ?.name
                        }}</span
                      >
                      <span
                        *ngIf="
                          snapShotDetail?.aocRequestsDetail?.fastTracksDetail
                            ?.name?.length == 0 ||
                          !snapShotDetail?.aocRequestsDetail
                        "
                        >N/A</span
                      >
                    </div>
                  </div>

                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.speed" | translate
                      }}</label>
                      <span
                        class="values"
                        *ngIf="
                          snapShotDetail?.ambulanceTrackingDetail?.speed ==
                            null ||
                          snapShotDetail?.ambulanceTrackingDetail?.speed == 0
                        "
                        >0 {{ "labels.kmhr" | translate }}</span
                      >
                      <span
                        class="values"
                        *ngIf="
                          snapShotDetail?.ambulanceTrackingDetail?.speed > 0
                        "
                        >{{ snapShotDetail?.ambulanceTrackingDetail?.speed }}
                        {{ "labels.kmhr" | translate }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.ambulance_box_code" | translate
                      }}</label>
                      <span class="values">{{
                        snapShotDetail?.ambulance_box_code
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.eta" | translate
                      }}</label>
                      <span class="values text-orange"
                        >{{
                          snapShotDetail?.ambulanceTrackingDetail
                            ?.estimated_time *
                            1000 *
                            60 | date : "HH:mm" : "UTC"
                        }}
                        / {{ "labels.hhmm" | translate }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="valuebox">
                      <label class="valuetitle">{{
                        "labels.time" | translate
                      }}</label>
                      <span
                        *ngIf="snapShotDetail?.patientRequestsDetail?.createdAt"
                        class="values"
                        >{{
                          snapShotDetail?.patientRequestsDetail?.createdAt
                            | date : "medium"
                        }}</span
                      >
                      <span
                        *ngIf="
                          snapShotDetail?.patientRequestsDetail?.createdAt
                            ?.length == 0 ||
                          !snapShotDetail?.patientRequestsDetail?.createdAt
                        "
                        >N/A</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="!show">
              <div class="link_button">
                <button
                  class="btn btn-info"
                  [disabled]="snapShotDetail?.is_assign == 0"
                  (click)="copyText()"
                >
                  {{ "buttons.sociallink" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div [ngClass]="show ? 'col-md-12 col-lg-6' : 'col-md-12 '">
          <div class="order4 destinationContent" *ngIf="show">
            <div class="destinationshow">
              <div class="destinationWay">
                <img
                  src="assets/images/icon/green_pin.png"
                  class="startPoint img-fluid"
                />
                <img
                  src="assets/images/icon/red_pin.png"
                  class="endPoint img-fluid"
                />
              </div>
              <div class="destiAddress">
                <div class="row">
                  <div class="col-6">
                    <span class="startAdd">
                      {{ snapTrackEcgDetail?.SourceHospitalDetail?.address
                      }}<br />
                      <b>{{
                        snapTrackEcgDetail?.SourceHospitalDetail?.full_name
                      }}</b>
                    </span>
                  </div>
                  <div class="col-6">
                    <span class="endAdd">
                      {{
                        snapTrackEcgDetail?.DestinationHospitalDetail?.address
                          ? snapTrackEcgDetail?.DestinationHospitalDetail
                              ?.address
                          : snapTrackEcgDetail?.aocRequestsDetail.destination
                              .address
                      }}<br />
                      <b>
                        {{
                          snapTrackEcgDetail?.DestinationHospitalDetail
                            ?.full_name
                        }}</b
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="show ? 'col-md-12 col-lg-6' : 'col-md-12'">
          <div *ngIf="show">
            <div class="listFacility">
              <div class="row justify-content-center">
                <div
                  class="colmd2"
                  *ngFor="
                    let detail of snapTrackEcgDetail?.medicalEquipmentsDetail
                  "
                >
                  <div>
                    <img
                      *ngIf="detail.icon_image"
                      src="{{ equipmentUrl }}{{ medicalEquipmentUrl }}{{
                        detail.icon_image
                      }}"
                      alt="not found image"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div [ngClass]="show ? 'col-md-6' : 'col-md-12'"></div>
        <div [ngClass]="show ? 'col-md-12 col-lg-6 order_1' : 'col-md-12'">
          <div class="row">
            <div class="listsnapPhoto col-md-12">
              <div class="row">
                <div class="col-md-6 col-6 pr-0">
                  <img
                    onerror="this.src='/assets/images/offline_stb.jpg'"
                    [src]="
                      imgPath +
                      snapShotDetail?.ambulanceImagesDetail?.images?.image0
                    "
                    alt="No Image Found"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-6 col-6 pl-0">
                  <img
                    onerror="this.src='/assets/images/offline_stb.jpg'"
                    [src]="
                      imgPath +
                      snapShotDetail?.ambulanceImagesDetail?.images?.image1
                    "
                    alt="No Image Found"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-6 col-6 pr-0">
                  <img
                    onerror="this.style.display='none'"
                    [src]="
                      imgPath +
                      snapShotDetail?.ambulanceImagesDetail?.images?.image2
                    "
                    alt="No Image Found"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-6 col-6 pl-0">
                  <img
                    onerror="this.style.display='none'"
                    [src]="
                      imgPath +
                      snapShotDetail?.ambulanceImagesDetail?.images?.image3
                    "
                    alt="No Image Found"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="snapVideo" *ngIf="streamingFrame">
              <button
                class="btn btn-primary reloadbutton"
                (click)="reloadIframe()"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <!-- <div class="resp-container">
                <iframe  [src]="streamingUrl" id="streamIframe" class="resp-iframe"></iframe> 
              </div> -->
              <div class="videoWrapper">
                <iframe
                  width="560"
                  height="349"
                  [src]="streamingUrl"
                  id="streamIframe"
                  class="respiframe"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addnote let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "titles.addnote" | translate }}
    </h4>
    <button
      type="button"
      class="close"
      id="noteClose"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group position-relative">
        <label for="ambulance_id">{{ "labels.note" | translate }}</label>
        <input
          type="text"
          class="form-control"
          name="note"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="note"
        />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submitNote()">
      {{ "buttons.submit" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #confirmModal let-modal>
  <!-- <div class="modal-header"> -->
  <button
    type="button"
    class="close d-none"
    id="confirmClose"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <!-- </div> -->
  <div class="modal-body">
    <h5 class="modal-title" id="modal-basic-title">
      {{ "labels.confirm_text" | translate }}
    </h5>
    <div class="reassignbutton">
      <button
        type="button"
        class="btn btn-info"
        (click)="postAocRequestStatusChange($event, 'CANCELED')"
      >
        {{ "buttons.yes" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="modal.close('Save click')"
      >
        {{ "buttons.no" | translate }}
      </button>
    </div>
  </div>
</ng-template>
