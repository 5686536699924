import { Component, OnInit } from "@angular/core";
import * as CanvasJS from "./canvasjs.min";
import { Subscription } from "rxjs";

import { EcgService } from "src/app/Services/ecg.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-ecg-waveform",
  templateUrl: "./ecg-waveform.component.html",
  styleUrls: ["./ecg-waveform.component.sass"],
})
export class EcgWaveformComponent implements OnInit {
  private ecgData: Subscription;
  moniterDetail: any;

  chartbackgroundColor = "#000000";
  stripLineWidth = 10;

  axisYgridThickness = 0.2;

  // chart1Interval :any;
  chart2Interval: any;
  // chart3Interval :any;
  chart4Interval: any;

  // dps1 = [];
  dps2 = [];
  // dps3 = [];
  dps4 = [];

  lineThickness = 1.5;
  xAxisInterval = 4;
  yAxisInterval = 14;

  // chart1: any;
  // chart1StripLinesValue = 0;
  // chart1Max = 255;
  // chart1Min = -225;
  // chart1xVal = 0;
  // chart1updateInterval = 1;
  // chart1dataLength = 800;

  chart2: any;
  chart2StripLinesValue = 0;
  chart2Max = 255;
  chart2Min = -140;
  chart2xVal = 0;
  chart2updateInterval = 1;
  chart2dataLength = 800;

  // chart3: any;
  // chart3StripLinesValue = 0;
  // chart3Max = 255;
  // chart3Min = -140;
  // chart3xVal = 0;
  // chart3updateInterval = 1;
  // chart3dataLength = 800;

  chart4: any;
  chart4StripLinesValue = 0;
  chart4Max = 255;
  chart4Min = -80;
  chart4xVal = 0;
  chart4updateInterval = 30;
  chart4dataLength = 400;

  // text_ECG_I = 'EcgI';
  text_ECG_II = "EcgII";
  // text_ECG_III = 'EcgIII';
  text_ECG_SPO2 = "Spo2";
  text_ECG_RESP = "RESP";

  // ECG_I = [-6,-7,-7,-9,-9,-9,-9,-10,-11,-11,-10,-10,-12,-12,-11,-11,-13,-12,-12,-12,-13,-11,-10,-7,-7,-6,-2,-1,1,-2,-2,-4,-6,-7,-10,-11,-12,-12,-12,-12,-11,-11,-13,-13,-12,-11,-9,-10,-12,-14,-14,-12,-10,-8,-7,-7,-7,-6,-3,2,12,28,68,90,84,57,-30,-66,-75,-52,-42,-34,-28,-19,1,4,4,3,3,2,1,1,1,4,3,3,2,3,5,5,5,3,4,6,7,8,9,11,11,12,13,14,19,21,22,22,24,26,29,31,31,32,32,32,34,34,30,26,22,15,12,9,0,-4,-8,-9,-9,-12,-14,-16,-16,-16,-15,-17,-16,-15,-11,-10,-12,-12,-12,-11,-10,-9,-10,-10,-10,-9,-8,-8,-8,-9,-8,-6,-6,-5,-4,-3,-2,-2,-3,-3,-3,-2,-3,-4,-4,-4,-4,-5,-6,-7,-7,-7,-7,-6,-4,-4,-5,-5,-5,-4,-4,-2,-3,-4,-5,-5,-4,-2,-2,0,2,4,5,4,4,2,-2,-3,-5,-5,-8,-9,-9,-8,-10,-10,-9,-7];
  // ECG_II = [-15,-15,-13,-14,-14,-14,-15,-15,-15,-17,-16,-16,-16,-14,-15,-15,-13,-11,-11,-13,-17,-16,-12,-7,-7,-7,-6,-1,0,-4,-6,-8,-10,-13,-11,-8,-7,-7,-7,-8,-7,-7,-7,-7,-8,-9,-10,-9,-6,-2,0,0,3,4,5,1,-2,-1,-3,-5,-9,-9,-9,-8,-9,-9,-10,-9,-10,-9,-8,-8,-8,-9,-10,-10,-10,-7,-6,-5,-6,-7,-11,-8,6,31,67,148,167,108,61,37,7,-4,-22,-28,-27,-15,-11,-10,-10,-10,-8,-9,-9,-9,-8,-7,-6,-4,-3,-3,-2,1,0,-1,-1,3,4,4,5,6,6,8,11,13,17,20,23,26,28,30,30,32,39,42,44,44,44,44,44,41,37,33,23,18,14,6,2,-3,-5,-9,-10,-11,-14,-14,-14,-13,-12,-12,-11,-10,-12,-13,-11,-10,-9,-9,-11,-12,-12,-10,-10,-10,-10,-11,-12,-12,-8,-9,-9,-7,-6,-9,-11,-14,-14,-8,-7,-9,-12,-12,-10,-7,-7,-8,-8,-8,-5,-4,-6,-6,-6,-9,-11,-7,-6,-5,-6,-3];
  // ECG_III = [5,4,3,2,-2,-3,-3,-2,-3,-5,-7,-7,-5,-4,-2,-3,-3,-2,-2,-3,-4,-4,-4,-4,-5,-5,-4,-6,-6,-8,-8,-8,-7,-8,-8,-5,-6,-6,-6,-6,-5,-4,-3,-2,-3,-3,-6,-5,-5,-3,-4,-3,-1,1,2,0,0,0,1,1,1,0,0,-1,-2,-2,-2,-1,-1,-1,-1,-1,-1,-1,-2,-2,-1,-1,-2,-4,-4,-2,-2,-2,-3,-3,-3,0,-2,-2,-2,-2,-2,0,0,0,1,-1,-1,-3,-2,-1,1,1,-1,-2,-6,-11,-27,-37,-40,-14,22,119,136,125,99,75,51,11,-3,-10,-15,-17,-17,-16,-16,-17,-17,-15,-14,-13,-13,-13,-11,-11,-10,-11,-11,-10,-10,-10,-11,-9,-9,-9,-9,-11,-11,-11,-11,-11,-11,-9,-9,-9,-9,-9,-7,-5,-4,-2,2,6,7,9,13,13,14,12,11,10,6,6,5];
  // ECG_SPO2 = [156,149,142,135,128,124,120,118,118,118,118,118,118,118,31,26,21,17,17,22,34,53,77,104,128,149,164,174,180,182,183,182,179,175,170,163,154,146,137,130,124,119,117,116,117,118,119,120,120,119,118,116,113,109,105,100,95,91,86,82,77,73,68,64,60,57,53,50,46,42,37,33,30,26,22,16];

  // ECG_I = [];
  ECG_II = [];
  // ECG_III = [];
  ECG_SPO2 = [];

  constructor(private ecgService: EcgService) {}

  ngOnInit(): void {
    this.moniterDetail = JSON.parse(localStorage.getItem("moniterDetail"));
    this.ecgData = this.ecgService.ecg.subscribe((data) => {
      // console.log("data", data);
      this.dataPlotting(data);
    });

    // this.chart1 = new CanvasJS.Chart("chartContainer1", {
    //   backgroundColor: this.chartbackgroundColor,
    //   title :{
    //       text: "ECG I",
    //       fontColor: "#FFFFFF"
    //   },
    //   axisX:{
    //       gridColor: "#000000",
    //       stripLines: [{
    //         value: this.chart1StripLinesValue,
    //         thickness: this.stripLineWidth,
    //         color:"#000000",
    //         showOnTop: true
    //       }],
    //       valueFormatString: "####"
    //   },
    //   axisY:{
    //     gridColor: "#000000",
    //     // maximum: this.chart1Max,
    //     // minimum: this.chart1Min,
    //   },
    //   data: [{
    //       type: "line",
    //       color: "#00FF00",
    //       markerSize: 0,
    //       dataPoints: this.dps1,
    //       lineThickness: this.lineThickness
    //   }]
    // });

    this.chart2 = new CanvasJS.Chart("chartContainer2", {
      backgroundColor: this.chartbackgroundColor,
      title: {
        text: "ECG II",
        fontColor: "#FFFFFF",
      },
      axisX: {
        stripLines: [
          {
            value: this.chart2StripLinesValue,
            thickness: this.stripLineWidth,
            color: "#000000",
            showOnTop: true,
          },
        ],
        valueFormatString: "####",
      },
      axisY: {
        gridColor: "#000000",
        // maximum: this.chart2Max,
        // minimum: this.chart2Min,
      },
      data: [
        {
          type: "line",
          color: "#00FF00",
          markerSize: 0,
          dataPoints: this.dps2,
          lineThickness: this.lineThickness,
        },
      ],
    });

    // this.chart3 = new CanvasJS.Chart("chartContainer3", {
    //   backgroundColor: this.chartbackgroundColor,
    //   title :{
    //     text: "ECG III",
    //     fontColor: "#FFFFFF"
    //   },
    //   axisX:{
    //       stripLines: [{
    //         value: this.chart3StripLinesValue,
    //         thickness: this.stripLineWidth,
    //         color:"#000000",
    //         showOnTop: true
    //       }],
    //       valueFormatString: "####"
    //   },
    //   axisY:{
    //     gridColor: "#000000",
    //     // maximum: this.chart3Max,
    //     // minimum: this.chart3Min,
    //   },
    //   data: [{
    //       type: "line",
    //       color: "#00FF00",
    //       markerSize: 0,
    //       dataPoints: this.dps3,
    //       lineThickness: this.lineThickness
    //   }]
    // });

    this.chart4 = new CanvasJS.Chart("chartContainer4", {
      backgroundColor: this.chartbackgroundColor,
      title: {
        text: "SPO2",
        fontColor: "#FFFFFF",
      },
      axisX: {
        stripLines: [
          {
            value: this.chart4StripLinesValue,
            thickness: this.stripLineWidth,
            color: "#000000",
            showOnTop: true,
          },
        ],
        valueFormatString: "####",
      },
      axisY: {
        gridColor: "#000000",
        maximum: this.chart4Max,
        minimum: this.chart4Min,
      },
      data: [
        {
          type: "line",
          color: "#0000FF",
          markerSize: 0,
          dataPoints: this.dps4,
          lineThickness: this.lineThickness,
        },
      ],
    });

    // this.chart1.render();
    this.chart2.render();
    // this.chart3.render();
    this.chart4.render();
    this.setChartMaxMin(String(this.moniterDetail.type));

    // for (let i = 0; i <= this.chart1dataLength; i++) {
    //   this.chart1updateStripLine();
    // }

    for (let i = 0; i <= this.chart2dataLength; i++) {
      this.chart2updateStripLine();
    }

    // for (let i = 0; i <= this.chart3dataLength; i++) {
    //   this.chart3updateStripLine();
    // }

    for (let i = 0; i <= this.chart4dataLength; i++) {
      this.chart4updateStripLine();
    }

    setTimeout(() => {
      this.ecgService.roomJoin(String(this.moniterDetail.serial_number));
      document.getElementById("moniter_serial_number").innerHTML = String(
        this.moniterDetail.serial_number
      );
    }, 5000);
  }

  // chart1dataEle = 0;
  // chart1updateStripLine(){
  //     if(this.chart1StripLinesValue > this.chart1dataLength){
  //       this.chart1StripLinesValue = 0;
  //       this.chart1xVal = 0;
  //     } else {
  //       // if((this.chart1dataEle+1) == this.ECG_I.length) this.chart1dataEle = 0;
  //       // else this.chart1dataEle++;

  //       this.chart1.options.data[0].dataPoints[this.chart1xVal] = {
  //         x: this.chart1xVal,
  //         y: (this.ECG_I.length)?(this.ECG_I[0]):0
  //         // y: (this.ECG_I.length)?(this.ECG_I[this.chart1dataEle]):this.chart1dataEle
  //       };

  //       if(this.ECG_I.length) this.ECG_I.shift();
  //       this.chart1xVal++
  //     }

  //     this.chart1.axisX[0].stripLines[0].set("value", (this.chart1StripLinesValue++));
  // }

  chart2dataEle = 0;
  chart2updateStripLine() {
    if (this.chart2xVal > this.chart2dataLength) {
      this.chart2StripLinesValue = 0;
      this.chart2xVal = 0;
    } else {
      // if((this.chart2dataEle+1) == this.ECG_II.length) this.chart2dataEle = 0;
      // else this.chart2dataEle++;

      this.chart2.options.data[0].dataPoints[this.chart2xVal] = {
        x: this.chart2xVal,
        y: this.ECG_II[0] ? this.ECG_II[0] : 0,
        // y: (this.ECG_II.length)?(this.ECG_II[this.chart2dataEle]):this.chart2dataEle
      };
      if (this.ECG_II.length) this.ECG_II.shift();
      this.chart2xVal++;
    }

    this.chart2.axisX[0].stripLines[0].set(
      "value",
      this.chart2StripLinesValue++
    );
  }

  // chart3dataEle = 0;
  // chart3updateStripLine(){
  //   if(this.chart3StripLinesValue > this.chart3dataLength){
  //     this.chart3StripLinesValue = 0;
  //     this.chart3xVal = 0;
  //   } else {
  //     // if((this.chart3dataEle+1) == this.ECG_III.length) this.chart3dataEle = 0;
  //     // else this.chart3dataEle++;

  //     this.chart3.options.data[0].dataPoints[this.chart3xVal] = {
  //       x: this.chart3xVal,
  //       y: (this.ECG_III[0])?(this.ECG_III[0]):0
  //       // y: (this.ECG_III.length)?(this.ECG_III[this.chart3dataEle]):this.chart3dataEle
  //     };
  //     if(this.ECG_III.length) this.ECG_III.shift();
  //     this.chart3xVal++
  //   }

  //   this.chart3.axisX[0].stripLines[0].set("value", (this.chart3StripLinesValue++));
  // }

  chart4dataEle = 0;
  chart4updateStripLine() {
    if (this.chart4xVal > this.chart4dataLength) {
      this.chart4StripLinesValue = 0;
      this.chart4xVal = 0;
    } else {
      // if((this.chart4dataEle+1) == this.ECG_SPO2.length) this.chart4dataEle = 0;
      // else this.chart4dataEle++;

      this.chart4.options.data[0].dataPoints[this.chart4xVal] = {
        x: this.chart4xVal,
        y: this.ECG_SPO2[0] ? this.ECG_SPO2[0] : 0,
        // y: (this.ECG_SPO2.length)?(this.ECG_SPO2[this.chart4dataEle]):this.chart4dataEle
      };

      if (this.ECG_SPO2.length) this.ECG_SPO2.shift();
      this.chart4xVal++;
    }

    this.chart4.axisX[0].stripLines[0].set(
      "value",
      this.chart4StripLinesValue++
    );
  }

  dataPlotting(rowdata) {
    let dataStr = rowdata.toString();

    dataStr = dataStr.trim("\n");
    dataStr = dataStr.replaceAll("}", "}abc");
    dataStr = dataStr.replaceAll("}abc}abc", "}}abc");
    dataStr = dataStr.replaceAll("abc]}abc", "]}");
    dataStr = dataStr.replaceAll("}abc]", "}]");
    dataStr = dataStr.replaceAll("abc,", ",");
    var dataArray = dataStr.split("abc");

    dataArray = dataArray.filter(function (d) {
      return d != "";
    });
    if (dataArray.length) {
      dataArray.forEach((d) => {
        if (d !== "") {
          d = d.trim("\n");
          try {
            let data = JSON.parse(d);
            switch (String(data.Key)) {
              case "vital":
                try {
                  var ValueArray = data.Value;
                  var isHRzero = false;
                  ValueArray.forEach((V) => {
                    var showValue =
                      parseFloat(V.Value) !== 0
                        ? Math.ceil(Number(V.Value))
                        : "--";
                    var showUnit = V.Unit;
                    // console.log("showValue", showValue);
                    // console.log("V.TrendName", V.TrendName);

                    switch (V.TrendName) {
                      case "HR":
                        if (showValue == "--") {
                          isHRzero = true;
                        }
                        document.getElementById("hr").innerHTML =
                          String(showValue);
                        break;

                      case "PR":
                        if (isHRzero) {
                          document.getElementById("hr").innerHTML =
                            String(showValue);
                        }
                        break;

                      case "SPO2 Pulse":
                        if (isHRzero) {
                          document.getElementById("hr").innerHTML =
                            String(showValue);
                        }
                        break;

                      case "ETCO2":
                        document.getElementById("etco2").innerHTML =
                          String(showValue);
                        break;

                      case "SPO2":
                        document.getElementById("spo2").innerHTML =
                          String(showValue);
                        break;

                      case "SpO2":
                        document.getElementById("spo2").innerHTML =
                          String(showValue);
                        break;

                      case "NIBP Sys":
                        document.getElementById("nibpsys").innerHTML =
                          String(showValue);
                        break;

                      case "SYS":
                        document.getElementById("nibpsys").innerHTML =
                          String(showValue);
                        break;

                      case "NIBP Dia":
                        document.getElementById("nibpdia").innerHTML =
                          String(showValue);
                        break;

                      case "DIA":
                        document.getElementById("nibpdia").innerHTML =
                          String(showValue);
                        break;

                      case "NIBP Map":
                        document.getElementById("nibpmap").innerHTML =
                          String(showValue);
                        break;

                      case "MAP":
                        document.getElementById("nibpmap").innerHTML =
                          String(showValue);
                        break;

                      default:
                        break;
                    }
                  });
                } catch (error) {
                  // console.log(error)
                }
                break;

              case "alarm":
                // console.log('alarm');
                break;

              // case this.text_ECG_I:
              //   try {
              //     let Value = (data.Value);
              //     let ValueArray = Value.split(',');
              //     if (ValueArray.length) {
              //       ValueArray.forEach(V => {
              //         if((String(this.moniterDetail.type) == 'BLT') && (Number(V) > 30)) this.ECG_I.push(Number(V));
              //         else if((String(this.moniterDetail.type) == 'DGT7')) this.ECG_I.push(Number(V));
              //       })
              //     }
              //   } catch (err) {
              //     console.log(this.text_ECG_I + ' Data Parse ERROR: ', err)
              //   }
              //   break;

              case this.text_ECG_II:
                try {
                  let Value = data.Value;
                  let ValueArray = Value.split(",");
                  if (ValueArray.length) {
                    ValueArray.forEach((V) => {
                      if (
                        String(this.moniterDetail.type) == "BLT" &&
                        Number(V) > 30
                      )
                        this.ECG_II.push(Number(V));
                      else if (String(this.moniterDetail.type) == "DGT7")
                        this.ECG_II.push(Number(V));
                    });
                  }
                } catch (err) {
                  // console.log(this.text_ECG_II + ' Data Parse ERROR: ', err)
                }
                break;

              // case this.text_ECG_III:
              //   try {
              //     let Value = (data.Value);
              //     let ValueArray = Value.split(',');
              //     if (ValueArray.length) {
              //       ValueArray.forEach(V => {
              //         if((String(this.moniterDetail.type) == 'BLT') && (Number(V) > 30)) this.ECG_III.push(Number(V));
              //         else if((String(this.moniterDetail.type) == 'DGT7')) this.ECG_III.push(Number(V));
              //       })
              //     }
              //   } catch (err) {
              //     console.log(this.text_ECG_III + ' Data Parse ERROR: ', err)
              //   }
              //   break;

              case this.text_ECG_SPO2:
                try {
                  var Value = data.Value;
                  var ValueArray = Value.split(",");
                  if (ValueArray.length) {
                    ValueArray.forEach((V) => {
                      this.ECG_SPO2.push(Number(V));
                    });
                  }
                } catch (err) {
                  // console.log(this.text_ECG_SPO2 + ' Data Parse ERROR: ', err);
                }
                break;

              case this.text_ECG_RESP:
                // console.log(this.text_ECG_RESP + ' Data Parse ERROR: ');
                break;

              default:
                break;
            }
          } catch (err) {
            // console.log('Data Parse ERROR: ', err)
          }
        }
      });
    }
  }

  setChartMaxMin(type: string) {
    if (type == "BLT") {
      this.chart2dataLength = 1200;
      this.chart4dataLength = 900;

      // this.chart1.axisY[0].set("maximum", 180);
      // this.chart1.axisY[0].set("minimum", 50);

      // this.chart2.axisY[0].set("maximum", 180);
      // this.chart2.axisY[0].set("minimum", 50);

      // this.chart3.axisY[0].set("maximum", 180);
      // this.chart3.axisY[0].set("minimum", 50);

      // this.chart4.axisY[0].set("maximum", 120);
      // this.chart4.axisY[0].set("minimum", -10);

      // setTimeout(() => {
      // this.chart1Interval =  setInterval(() => {
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       document.getElementById("ecgi_data_length").innerHTML = String(this.ECG_I.length);
      //   }, this.chart1updateInterval);
      // }, this.chart1dataLength);

      setTimeout(() => {
        this.chart2Interval = setInterval(() => {
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          this.chart2updateStripLine();
          // document.getElementById("ecgii_data_length").innerHTML = String(this.ECG_II.length);
        }, this.chart2updateInterval);
      }, this.chart2dataLength);

      // setTimeout(() => {
      //   this.chart3Interval=  setInterval(() => {
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     document.getElementById("ecgiii_data_length").innerHTML = String(this.ECG_III.length);
      //   }, this.chart3updateInterval);
      // }, this.chart3dataLength)

      setTimeout(() => {
        this.chart4Interval = setInterval(() => {
          this.chart4updateStripLine();
          this.chart4updateStripLine();
          this.chart4updateStripLine();
          this.chart4updateStripLine();
          this.chart4updateStripLine();
          this.chart4updateStripLine();
          this.chart4updateStripLine();
          this.chart4updateStripLine();
          // this.chart4updateStripLine();
          // document.getElementById("spo2_data_length").innerHTML = String(this.ECG_SPO2.length);
        }, this.chart4updateInterval);
      }, this.chart4dataLength);
    } else {
      // this.chart1dataLength = 600;
      this.chart2dataLength = 600;
      // this.chart3dataLength = 600;
      this.chart4dataLength = 400;

      // setTimeout(() => {
      //   setInterval(() => {
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       this.chart1updateStripLine();
      //       document.getElementById("ecgi_data_length").innerHTML = String(this.ECG_I.length);
      //   }, this.chart1updateInterval);
      // }, this.chart1dataLength);

      setTimeout(() => {
        setInterval(() => {
          this.chart2updateStripLine();
          // this.chart2updateStripLine();
          // this.chart2updateStripLine();
          // this.chart2updateStripLine();
          // this.chart2updateStripLine();
          // document.getElementById("ecgii_data_length").innerHTML = String(this.ECG_II.length);
        }, this.chart2updateInterval);
      }, this.chart2dataLength);

      // setTimeout(() => {
      //   setInterval(() => {
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     this.chart3updateStripLine();
      //     document.getElementById("ecgiii_data_length").innerHTML = String(this.ECG_III.length);
      //   }, this.chart3updateInterval);
      // }, this.chart3dataLength)

      setTimeout(() => {
        setInterval(() => {
          this.chart4updateStripLine();
          // this.chart4updateStripLine();
          // document.getElementById("spo2_data_length").innerHTML = String(this.ECG_SPO2.length);
        }, this.chart4updateInterval);
      }, this.chart4dataLength);
    }
  }

  ngOnDestroy() {
    // this.ECG_I = [];
    this.ECG_II = [];
    // this.ECG_III = [];
    this.ECG_SPO2 = [];
    this.ecgData.unsubscribe();
    // clearInterval( this.chart1Interval)
    clearInterval(this.chart2Interval);
    // clearInterval(this.chart3Interval)
    clearInterval(this.chart4Interval);
  }
}
