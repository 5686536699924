<div class="content-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="card main-card mb-3">
                <div class="card-header-tab card-header">
                  <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i class="header-icon fa fa-television"> </i>
                    {{'titles.ecg_report' | translate}}
                  </div>
                </div>
                <div class="no-gutters row">
                  <div class="card-body">
                    <div class="page-body">
                      <div class="table-responsive">
                        <table class="table table-striped row-border w-100" datatable [dtOptions]="dtOptions">
                          <thead>
                            <tr>
                              <th>{{'labels.name' | translate}}</th>
                              <th>{{'labels.serial_num' | translate}}</th>
                              <th>{{'labels.action' | translate}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="no-data" *ngIf="data?.length == 0">
                              <td colspan="5">{{'error.nodata' | translate}}</td>
                            </tr>
                            <tr *ngFor="let detail of data; let i = index">
                              <td>{{(detail.name)? detail.name : "N/A"}}</td>
                              <td>{{(detail.serial_number)? detail.serial_number : "N/A"}}</td>
                              <td>
                                <button class="btn btn-primary" style="width: 118px;" (click) = "ecgDetail(detail)">{{'buttons.detail' | translate}}</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> 
                    </div>
                  </div>
                  <div class="divider m-0 d-md-none d-sm-block"></div>
                </div>
                </div>
        </div>
    </div>
</div>