import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  date_now: number;

  constructor() { }

  ngOnInit() {
    setInterval(() => {
      this.date_now = Date.now()
    }, 1000);
  }

}
