import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { AgmMap, MapsAPILoader, MouseEvent } from "@agm/core";
import { LocationService } from 'src/app/Services/location.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../Services/auth.service'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastService } from '../../Services/toast.service'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-direction-view',
  templateUrl: './direction-view.component.html',
  styleUrls: ['./direction-view.component.sass']
})
export class DirectionViewComponent implements OnInit, OnDestroy, AfterViewInit {
  errTime =  environment.timeout.errorNotificationTimeout;
  public languages: any = environment.languages.map(e => { return { 'label': e } });
  show: boolean = false;
  ambulance_id;
  streamingUrl: any;
  streamingFrame: Boolean = false;
  // @ViewChild(AgmMap) agmMap;
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  imgPath = this.apiService.imgUrl;
  url: string = environment.ecg_url;
  requestType;
  @Input() ambulanceData;
  @Input() onGoingData;
  eta_time = '';
  mapLoad = true;
  trackingDetail: any;
  severityColorCode;
  aocRequestStatus;
  user_id;
  request_status;
  aoc_request_id;
  hospital_id = '';
  previousStatusUser: any = [];
  anotherHospitalForm: FormGroup;
  public iconUrl = {
    start: '/assets/images/map_pin/start.png',
    end: '/assets/images/map_pin/end.png',
    track: '/assets/images/map_pin/track.png',
  }
  hospitalList;
  zoneList;
  showAnotherHospital = false;
  selectAnotherHospital = {
    _id: null,
    zone_id: '',
    hospital_id: ''
  }
  statusChange: boolean = false;
  equipmentUrl: string = environment.apiUrl;
  medicalEquipmentUrl: any;
  snapTrackEcgDetail;
  ambulanceOngoingId;
  timeout: any = true;
  urlSafe: SafeResourceUrl;
  timeoutTime = environment.timeout.snapTrackEcgTimeout
  //map
  isOnlineTimeout: any = {};
  infoWindow: any = {};
  coordinates = new google.maps.LatLng(environment.googleMapCenter.lat, environment.googleMapCenter.lon);
  map: google.maps.Map;
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    // disableDefaultUI: true,
    mapTypeControl: false,
    zoom: 6,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    zoomControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM },

  };
  markerIcon: any = {
    url: '/assets/images/map_pin/ambulance/online.svg',
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(-30, -30)
  }
  markers: any;
  location: any;
  SocketData: any;
  markerFocus: any = false;
  markerMoveTimeout: any= {};
  profilePicturePath: any;
  imgUrls =  environment.imgUrl;
  note:any;
  aoc_requests_id:any;
  constructor(public activeModal: NgbActiveModal, private locationService: LocationService, private cookieService: CookieService, private apiService: AuthService, private spinner: NgxSpinnerService, private toastService: ToastService, public sanitizer: DomSanitizer, public modalService : NgbModal) { }
  ngAfterViewInit() {
    this.mapInitializer();
  }

  ngOnInit(): void {
    this.anotherHospitalForm = new FormGroup({
      _id: new FormControl(),
      zone_id: new FormControl(),
      hospital_id: new FormControl(),
    })

    if (this.onGoingData) {
      this.ambulance_id = this.onGoingData.ambulance_id
    }

    if (this.ambulanceData) {
      this.ambulance_id = this.ambulanceData._id
    }

    navigator.geolocation.getCurrentPosition(pos => {
      this.mapOptions.center = new google.maps.LatLng(+pos.coords.latitude, +pos.coords.longitude);
    });

    this.location = this.locationService.location.subscribe(data => {
      if (data[0] == '{') {
        data = JSON.parse(data);
        this.SocketData = data
        
        this.getLiveLocation(data['_id'], { lat: data['tracking_latitude'], lon: data['tracking_longitude'], heading: data['tracking_heading'], speed: data['tracking_speed'], is_assign: data['is_assign'], request_type: data['request_type'], estimated_time: data['estimated_time'] });
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    });

    this.getAmbulanceTrackingDetail();
    this.getAmbulanceSnapTrackEcgDetail(this.ambulance_id);
    if (this.ambulanceData && this.ambulanceData.aocRequestsDetail) {
      this.aoc_request_id = this.ambulanceData.aocRequestsDetail._id
    }
    if (this.onGoingData) {
      this.aoc_request_id = this.onGoingData._id
    }
  }

  getAmbulanceTrackingDetail(){
    this.apiService.getAmbulanceTrackingDetail(this.ambulance_id).subscribe(data => {
      this.trackingDetail = data['content'].ambulance_data[0];
      this.profilePicturePath = data['content']?.ambulance_picture_url
      this.previousStatusUser = this.trackingDetail['aocRequestsDetail']['requestStatusChange'];
      this.aocRequestStatus = { 'next_status': data['content']['ambulance_data'][0].aocRequestsDetail.next_status, 'previous_status': data['content']['ambulance_data'][0].aocRequestsDetail.previous_status }
      this.urlSafe = this.trackingDetail.aocRequestsDetail?.patientMonitorsDetail?.serial_number
      this.severityColorCode = {
        'background-color':  this.trackingDetail.patientRequestsDetail?.severity_color_code,
      };

      // for next API call //
      if(this.timeout && data['success']){
        this.timeout = setTimeout(() => {
          this.getAmbulanceTrackingDetail();
        }, this.timeoutTime)
      }
      ///////////////////////

      // set marker for tracking //
      if(this.mapLoad){
        this.setHospitalLocation(this.trackingDetail);
        this.markers = {}

        var content = 'Box : ' + String(this.trackingDetail?.ambulance_box_code) + '</br>' +
          'Name :' + String(this.trackingDetail?.name1) + '</br>' + 'Req Type : ' + String((this.trackingDetail?.aocRequestsDetail.request_type == undefined) ? "N/A" :this.trackingDetail?.aocRequestsDetail.request_type) + '</br>';

        if (this.trackingDetail && this.trackingDetail.ambulanceTrackingsDetail) {
          content += 'Speed :' + Math.ceil(Number(this.trackingDetail?.ambulanceTrackingsDetail.speed)) + '</br>' + 'ETA :' + ((String(this.trackingDetail.ambulanceTrackingsDetail.estimated_time) == null) ? 'N/A' : String(this.trackingDetail?.ambulanceTrackingsDetail.estimated_time));

          this.markers[String(this.trackingDetail['_id'])] = new google.maps.Marker({
            position: new google.maps.LatLng(this.trackingDetail?.ambulanceTrackingsDetail.location.lat, this.trackingDetail?.ambulanceTrackingsDetail.location.lon),
            map: this.map,
            title: content,
            icon: this.markerIcon
          });

          // this.markers[String(this.trackingDetail['_id'])].addListener("click", () => {
          //   Object.keys(this.infoWindow).map(e=> {
          //     this.infoWindow[e].close();
          //   });

          //   this.infoWindow[String(this.trackingDetail['_id'])] = new google.maps.InfoWindow({
          //     content: this.markers[String(this.trackingDetail['_id'])].getTitle()
          //   });

          //   this.infoWindow[String(this.trackingDetail['_id'])].open(this.markers[String(this.trackingDetail['_id'])].getMap(), this.markers[String(this.trackingDetail['_id'])]);
          // });
          this.infowindow()
          this.markers[String(this.trackingDetail['_id'])].setMap(this.map);

          this.map.setCenter(this.markers[String(this.trackingDetail['_id'])].getPosition());

          this.isOnlineTimeout[String(this.trackingDetail['_id'])] = setTimeout(() => {
            if(this.trackingDetail['aocRequestsDetail']){
              this.markers[String(this.trackingDetail['_id'])].setIcon({
                scaledSize: new google.maps.Size(40, 40),
                origin: new google.maps.Point(0, 0),
                url: this.locationService.setMapMarker(0, 1, String(this.trackingDetail['aocRequestsDetail']['request_type']), 0)+'?id='+String(this.trackingDetail['_id']) 
              });
            }else{
              this.markers[String(this.trackingDetail['_id'])].setIcon({
                scaledSize: new google.maps.Size(40, 40),
                origin: new google.maps.Point(0, 0),
                url: this.locationService.setMapMarker(0, 0, '', 0)+'?id='+String(this.trackingDetail['_id'])+'?id='+String(this.trackingDetail['_id'])
              });
            }
          }, 30000);

          google.maps.event.trigger(this.markers[String(this.ambulance_id)], 'click');
          this.markerFocus = String(this.ambulance_id);
          this.map.setZoom(16);
          // this.infoWindow[String(this.ambulance_id)].open(this.markers[String(this.ambulance_id)].getMap(), this.markers[String(this.ambulance_id)]);
          this.locationService.roomJoin(String(this.trackingDetail[String(this.ambulance_id)]));
        }

        this.mapLoad = false;
      }
      /////////////////////////////
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code :" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  infowindow(){
    var content = 'Box : ' + String( this.trackingDetail?.ambulance_box_code) + '</br>' +
    'Name :' + String( this.trackingDetail?.name1) + '</br>' + 'Req Type : ' + String(( this.trackingDetail?.aocRequestsDetail.request_type == undefined) ? "N/A" :  this.trackingDetail?.aocRequestsDetail.request_type) + '</br>';
     content += 'Speed :' + Math.ceil(Number( this.trackingDetail?.ambulanceTrackingsDetail.speed)) + '</br>' + 'ETA :' + ((String( this.trackingDetail.ambulanceTrackingsDetail.estimated_time) == null) ? 'N/A' : String( this.trackingDetail?.ambulanceTrackingsDetail.estimated_time));
    
    setTimeout(() => {
      if (this.infoWindow[String( this.trackingDetail['_id'])]) this.infoWindow[String( this.trackingDetail['_id'])].close();
      this.infoWindow[String( this.trackingDetail['_id'])] = new google.maps.InfoWindow({
        content: this.markers[String( this.trackingDetail['_id'])].getTitle()
      });
      this.infoWindow[String( this.trackingDetail['_id'])].open(this.markers[String( this.trackingDetail['_id'])].getMap(), this.markers[String( this.trackingDetail['_id'])]);
    }, 500);
  }

  getUrl(url: string) {
    this.streamingUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.streamingFrame = true;
  }

  close() {
    this.activeModal.close();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    clearTimeout(this.timeoutTime);
    this.timeout = false;
  }

  clickSnapfullView() {
    $('.snapModal').toggleClass('fullViewModal');
    this.show = !this.show;
  }

  etaTime() {
    let eta = this.trackingDetail?.ambulanceTrackingsDetail?.estimated_time;
    if ((eta > 60) && eta % 60 !== 0) {
      this.eta_time = Math.floor(eta / 60) + ' ' + 'hr' + ' ' + (eta % 60) + ' ' + 'min'

    }
    else if (eta % 60 == 0) {
      this.eta_time = Math.floor(eta / 60) + ' ' + 'hr'
    }
    else {
      this.eta_time = eta + ' ' + 'min'
    }
  }

  zoneToggle(): void {
    this.showAnotherHospital = !this.showAnotherHospital
    this.selectAnotherHospital.hospital_id = ''
    this.zoneChange(this.aoc_request_id);
  }

  getAllAocZonesList() {
    this.spinner.show();
    this.apiService.getAllAocZonesList().subscribe((data) => {
      this.zoneList = data['content']['data'];
      this.spinner.hide();
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  zoneChange(aoc_request_id) {
    this.getZoneHospitalsLists(aoc_request_id);
  }

  getAmbulanceSnapTrackEcgDetail(ambulance_id) {
    // this.previousStatusUser = {}
    this.spinner.show();

    this.apiService.getAmbulanceSnapTrackEcgDetail(ambulance_id).subscribe((data) => {
      if(!data['success'] && data['status']== 404){
        this.toastService.show(data['message'], { classname: 'bg-danger text-white', delay: this.errTime });
      }else if(!data['success'] && data['status']== 403){
        this.toastService.show(data['message'], { classname: 'bg-danger text-white', delay: this.errTime });
      }else{
      this.snapTrackEcgDetail = data['content']['ambulance_data'][0];
      this.medicalEquipmentUrl = data['content']['medicalEquipmentsurl']
      if(!this.streamingFrame && this.snapTrackEcgDetail?.aocRequestsDetail?.patientGlassMacsDetail._id) {
        this.getUrl(String(this.snapTrackEcgDetail?.aocRequestsDetail?.patientGlassMacsDetail.rtmp_url));
      }
      // this.previousStatusUser = data['content']['ambulance_data'][0]['requestStatusChange'];
      this.spinner.hide();
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code :" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  getZoneHospitalsLists(zoneId) {
    this.spinner.show();
    this.apiService.getZoneHospitalsLists(zoneId).subscribe((hospitalDetail: any) => {
      this.hospitalList = hospitalDetail['content']['data'];
      this.spinner.hide();
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code :" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    });

    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  postAocRequestStatusChange(reqValue, statusValue = '') {
    this.requestType = reqValue.target.id
    this.user_id = this.cookieService.get('api_key');
      this.request_status = (statusValue == '')?this.aocRequestStatus.next_status:statusValue;

    
    this.spinner.show();
    this.apiService.postAocRequestStatusChange(this.aoc_request_id, this.request_status, this.hospital_id, this.user_id, this.requestType).subscribe((data: any) => {
      if(this.request_status == "COMPLETED" || this.request_status == "CANCELED")     
      {
        this.activeModal.close();
        $('#confirmClose').click(); 
      }
      if (data['success'] == true){
        this.aocRequestStatus = data['content']['data'];
        this.previousStatusUser = data['content']['data']['request_status_change'];
        this.spinner.hide();
      }else{
        setTimeout(() => {
          this.spinner.hide();
        }, 5000);
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  goToAnotherHospital(reqValue) {
    this.requestType = reqValue.target.id
    this.user_id = this.cookieService.get('api_key')
    this.request_status = 'GO_TO_ANOTHER_HOSPITAL';
    this.hospital_id = this.anotherHospitalForm.value['hospital_id'];
    this.apiService.postAocRequestStatusChange(this.aoc_request_id, this.request_status, this.hospital_id, this.user_id, this.requestType).subscribe((data: any) => {
      this.aocRequestStatus = data['content']['data'];
      this.spinner.hide();
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  mapInitializer(): void {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.map.addListener("zoom_changed", () => { 
      Object.keys(this.infoWindow).map(e=> {
        this.infoWindow[e].close();
      });
      this.markerFocus = false;
    })
  }

  setHospitalLocation(trackingDetail) {
    if(trackingDetail?.aocRequestsDetail.source){
      new google.maps.Marker({
        position: new google.maps.LatLng(trackingDetail?.aocRequestsDetail.source.location.lat, trackingDetail?.aocRequestsDetail.source.location.lon),
        map: this.map,
        icon: {
          url: '/assets/images/map_pin/green_pin.png',
        }
      });

      new google.maps.Marker({
        position: new google.maps.LatLng(trackingDetail?.aocRequestsDetail.destination.location.lat, trackingDetail?.aocRequestsDetail.destination.location.lon),
        map: this.map,
        icon: {
          url: '/assets/images/map_pin/red_pin.png',
        }
      });
    }
  }

  getLiveLocation(id, location){ 
    if(this.markers && this.markers[String(id)]){
      this.infowindow()
      var old = { lat: Number(this.markers[String(id)].getPosition().lat()), lon: Number(this.markers[String(id)].getPosition().lng()) };
      var current = { lat: Number(location.lat), lon: Number(location.lon) };
  
      var num = 80; 
      var delta = {};
      delta['lat'] = (current.lat - old.lat)/num;
      delta['lon'] = (current.lon - old.lon)/num;
  
      clearTimeout(this.isOnlineTimeout[id]);
      this.isOnlineTimeout[id] = setTimeout(() => {
        this.markers[String(id)].setIcon({
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          url: this.locationService.setMapMarker(0, Number(location['is_assign']), String(location['request_type']), 0)+'?id='+String(id)
        });
      }, 30000);
  
      this.markers[String(id)].setIcon({
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        url: this.locationService.setMapMarker(1, Number(location['is_assign']), String(location['request_type']), Number(location.speed))+'?id='+String(id)
      });
  
      this.map.setCenter(this.markers[String(id)].getPosition());
      // google.maps.event.trigger(this.markers[String(id)], 'click');

      if(Number(location.heading)){              
        $('img[src="' + String(this.locationService.setMapMarker(1, Number(location['is_assign']), String(location['request_type']), Number(location.speed))+'?id='+String(id)) + '"]').css({
          'transform': 'rotate(' + Number(location.heading) + 'deg)'
        });
      }
  
      this.locationService.markerMove(this.locationService.markerMove, id, 0, num, delta, this.markers[String(id)], old, current, location,this.markerMoveTimeout);
    }
  }

  reloadIframe(){
    $('.reloadbutton i').addClass('reload')
    let iframe = document.getElementById('streamIframe') as HTMLIFrameElement
    iframe.src += '';
    setTimeout(() => {
      $('.reloadbutton i').removeClass('reload')
    }, 5000);
  }

  openAddnote(snapShotDetail, addnote){
    this.note = this.trackingDetail?.aocRequestsDetail?.request_note
    this.aoc_requests_id = snapShotDetail?.aocRequestsDetail?._id
    this.modalService.open(addnote, { windowClass: 'addnoteModal', centered: true })
  }

  submitNote(monitorDetail){
    this.apiService.postAocRequestDetailUpdate(this.aoc_requests_id, this.note).subscribe((data: any) => {
      if (data['success'] == true) {
        $('#noteClose').click();
      }
    })
  }

  openconfirmModal(confirmModal, detail) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true,
      windowClass: 'confirm_modal'
    };
      this.modalService.open(confirmModal, ngbModalOptions)
    }

  ngOnDestroy() {
    clearTimeout(this.timeoutTime);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
