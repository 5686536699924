<div class="content-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="card main-card mb-3">
        <div class="card-header-tab card-header">
          <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon fa fa-tachometer"> </i>
            {{'titles.over_speed_repo' | translate}}
          </div>
        </div>
        <div class="no-gutters row">
          <div class="card-body">
            <form [formGroup]="speedReportForm" #form="ngForm" (ngSubmit)="onSubmit(form.value)">
              <input type="hidden" class="form-control" [(ngModel)]="selectSpeedreport._id" formControlName="_id" name="_id">

              <div class="header-filter">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <div class="form-group input-group position-relative">
                      <input  class="form-control" type="text" name="daterange" id="daterange" value=" "/>
                      <!-- <span style="color:red" *ngIf="!speedReportForm.controls.daterange.valid && submitted">{{'error.date' | translate}}</span> -->
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <select name="zone_id" id="zoneId" [(ngModel)]="selectSpeedreport.zone_id" formControlName="zone_id" class="form-control" (change)="zoneChange($event)">
                        <option value="" selected>{{'labels.selectzone' | translate}}</option>
                        <option value="{{zone._id}}" *ngFor="let zone of zoneList">{{zone.name1}}</option>
                      </select>
                      <!-- <span style="color:red" *ngIf="!speedReportForm.controls.zone_id.valid && submitted">{{'error.zone' | translate}}</span> -->
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <select name="hospital_id" id="destination" (change)="hospitalChange($event)" class="form-control" [(ngModel)]="selectSpeedreport.hospital_id" formControlName="hospital_id">
                        <option value="" selected>{{'labels.selectHospital' | translate}}</option>
                        <option value="{{hospital._id}}"  *ngFor="let hospital of hospitalList">{{hospital.name1}}</option>
                      </select>
                      <!-- <span style="color:red" *ngIf="!speedReportForm.controls.hospital_id.valid && submitted">{{'error.hospital' | translate}}</span> -->
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <select name="ambulance_id" id="ambulancelisr" class="form-control" [(ngModel)]="selectSpeedreport.ambulance_id" formControlName="ambulance_id">
                        <option value="" selected>{{'labels.selectAmbulance' | translate}}</option>
                        <option value="{{ambulance._id}}"  *ngFor="let ambulance of ambulanceList">{{ambulance.number}} : {{ambulance.name1}} {{ambulance.name2}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <select name="speed_limit" id="speedlimit" class="form-control" [(ngModel)]="selectSpeedreport.speed_limit" formControlName="speed_limit">
                        <option value="speed_above_80" selected="selected">80</option>
                        <option value="speed_above_120">120</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <button type="submit" class="btn btn-primary mb-3">{{'buttons.submit' | translate}}</button>
                    <!-- <button type="button" class="btn btn-primary ml-2 mb-3">{{'buttons.excel' | translate}}</button> -->
                  </div>
              </div>
              </div>
            </form>
            <div class="page-body historyTable">
              <div class="paginations">
                <ngb-pagination [collectionSize]="collectionsize" (pageChange)="onPageChange($event)" [pageSize]="page_size" [(page)]="page" [rotate] = "true" [maxSize]="5" [boundaryLinks]="true" aria-label="Default pagination"></ngb-pagination>
              </div>
              <div class="table-responsive">
                <table class="table table-striped row-border w-100">
                  <thead>
                    <tr>
                      <th>{{'labels.ambulance' | translate}}</th>
                      <th>{{'labels.startspeed' | translate}}</th>
                      <th>{{'labels.starttime' | translate}}</th>
                      <th>{{'labels.endspeed' | translate}}</th>
                      <th>{{'labels.endtime' | translate}}</th>
                      <th>{{'labels.action' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="no-data" *ngIf="ambulanceSpeedReportList?.length == 0">
                      <td colspan="5">{{'error.nodata' | translate}}</td>
                    </tr>
                    <tr *ngFor="let detail of ambulanceSpeedReportList; let i = index">
                      <td>{{detail.ambulance_box_code}}: {{detail.name1}} {{detail.name2}}</td>
                      <td>{{detail.start_speed | number:'1.0-0' }}</td>
                      <td>{{detail.start_speed_time | date:'medium' }}</td>
                      <td>{{detail.end_speed | number:'1.0-0' }}</td>
                      <td>{{detail.end_speed_time | date:'medium' }}</td>
                      <td>
                        <button class="btn btn-primary" style="width: 118px;" (click)="speedReportDetail(detail)">{{'buttons.detail' | translate}}</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
            </div>
          </div>
          <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        </div>
      </div>
    </div>
</div>
