
<div #ambulanceListModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'titles.ambulancelist' | translate}}</h4>
    <button type="button" id="ambulancelistID" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="content_filter">
      <div class="filteroptselect">
        <ul>
          <li>
            <img src="assets/images/icon/ems_a.png" alt="ems_a" class="img-fluid" >
          </li>
          <li>
            <img src="assets/images/icon/ref_a.png" alt="ref_a" class="img-fluid" >
          </li>
          <li>
            <img src="assets/images/icon/stb_a.png" alt="stb_a" class="img-fluid" >
          </li>
          <li>
            <img src="assets/images/icon/all_a.png" alt="all_a" class="img-fluid" >
          </li>
        </ul>
      </div>
      <div class="filtersearch">
        <div class="form-group position-relative">
          <label for="search">{{'labels.search' | translate}}</label>
          <input name="search" id="search" type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="paginations">
      <ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
    </div>

    <div class="listingdata">
      <ngb-accordion>
        <ngb-panel id="static-1" *ngFor="let hospitalDetail of ambulancelist">
          <ng-template ngbPanelTitle>
            <span class="card-header--title">
              <span>{{hospitalDetail.name1}}</span> <span>[{{totalAmbulance}}]</span>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="abmbulancelist_content">
              <ul>
                <li *ngFor="let ambulanceDetail of hospitalDetail.ambulances">
                  <div class="left_data">
                    <div class="amb_icon">
                      <img src="assets/images/icon/amb_image.png" alt="ambulance" class="img-fluid" >
                    </div>
                    <div class="amb_full_data">
                      <div class="amb_value">{{ambulanceDetail.name1}} {{ambulanceDetail.name2}}</div>
                      <div class="amb_value">ambcdata</div>
                      <span class="eta_km">ETA : Infinity Min 0.00 km/h</span>
                    </div>
                  </div>
                  <div class="right_data">
                    <div class="action_button">
                      <a>
                        <img src="assets/images/icon/ems_a.png" alt="ems_a" class="img-fluid" (click) = "openModal(addAmbulanceModal, hospitalDetail, ambulanceDetail)">
                      </a>
                      <a>
                        <img src="assets/images/icon/btn_direction.png" alt="btn_direction" class="img-fluid" >
                      </a>
                      <a>
                        <img src="assets/images/icon/btn_camera.png" alt="btn_camera" class="img-fluid" >
                      </a>
                      <a>
                        <img src="assets/images/icon/btn_config.png" alt="btn_config" class="img-fluid" >
                      </a>
                      <a (click)="openaddAmbulanceModal(addAmbulanceModal, hospitalDetail, ambulanceDetail)">
                        <img src="assets/images/icon/btn_input.png" alt="btn_input" class="img-fluid" >
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </ng-template>
        </ngb-panel>
        <!-- <ngb-panel id="static-2">
          <ng-template ngbPanelTitle>
            <span class="card-header--title">
              <span>Hospital name [0]</span>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="abmbulancelist_content">
              <ul>
                <li>
                  <div class="left_data">
                    <div class="amb_icon">
                      <img src="assets/images/icon/amb_image.png" alt="ambulance" class="img-fluid" >
                    </div>
                    <div class="amb_full_data">
                      <div class="amb_value">ambulance name</div>
                      <div class="amb_value">ambcdata</div>
                      <span class="eta_km">ETA : Infinity Min 0.00 km/h</span>
                    </div>
                  </div>
                  <div class="right_data">
                    <div class="action_button">
                      <a>
                        <img src="assets/images/icon/ems_a.png" alt="ems_a" class="img-fluid" >
                      </a>
                      <a>
                        <img src="assets/images/icon/btn_direction.png" alt="btn_direction" class="img-fluid" >
                      </a>
                      <a>
                        <img src="assets/images/icon/btn_camera.png" alt="btn_camera" class="img-fluid" >
                      </a>
                      <a>
                        <img src="assets/images/icon/btn_config.png" alt="btn_config" class="img-fluid" >
                      </a>
                      <a (click)="openaddAmbulanceModal(addAmbulanceModal)">
                        <img src="assets/images/icon/btn_input.png" alt="btn_input" class="img-fluid" >
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </ng-template>
        </ngb-panel> -->
      </ngb-accordion>
    </div>  
  </div>
<div>