import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ThemeOptions} from '../../theme-options';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgbModalConfig, NgbModal,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../Services/auth.service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
// import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-add-ambulance',
  templateUrl: './add-ambulance.component.html',
  styleUrls: ['./add-ambulance.component.sass']
})
export class AddAmbulanceComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,private spinner: NgxSpinnerService, private apiService: AuthService, private modalService: NgbModal, public globals: ThemeOptions, private activatedRoute: ActivatedRoute, private translate: TranslateService, private cookieService: CookieService) { }

  activeId;
  page = 3;
  public isCollapsed = true;
  ambulancelist:any;
  totalAmbulance: any = [];
  ambulanceReqForm: FormGroup;
  active = 1;
  currentValue = 15;
  
  driverList = [];
  driverListShow = [];
  selectDriverIds = [];
  
  doctorList = []
  doctorListShow = [];
  selectDoctorIds = [];
  
  nurseList = []
  nurseListShow = [];
  selectNurseIds = [];
  
  erstaffList = []
  erstaffListShow = [];
  selectErIds = [];
  
  
  emsstaffList = []
  emsstaffListShow = [];
  selectEmsIds = [];
  
  selectHospital;
  selectAmbulance;
  selectHospitalId;
  
  zoneList:any;
  hospitalList:any;

  selectAmbulancereq = {
    _id: null,
    first_name:'',
    last_name:'',
    gender: '',
    severity_level:'',
    fast_track:'',
    source_hospital:'',
    // destination_hospital:'',
    doctor_ids:'',
    driver_ids:'',
    nurse_ids:'',
    er_ids:'',
    ems_ids:'',
    moniter_id:'',
    age:'',
    request_type:'',
    sickness_type:'',
    is_trauma:'',
    glass_id:'',
    diagnosis_note:'',
    unique_hn_code:'',
    hospital_id:'',
    zone_id:''
    // remaining
    // zone_id:'',
    // ambulance_id:'',
    // user_request_id:'',
    // request_status:'',
    // request_note: '',
    // creater_id:'',
  };

  ngOnInit(): void {
    this.ambulanceReqForm = new FormGroup({
      _id: new FormControl(),
      first_name:new FormControl(null, [Validators.required]),
      last_name:new FormControl(null, [Validators.required]),
      gender: new FormControl(null, [Validators.required]),
      severity_level:new FormControl(null, [Validators.required]),
      fast_track:new FormControl(null, [Validators.required]),
      source_hospital:new FormControl(null),
      // destination_hospital:new FormControl(null, [Validators.required]),
      doctor_ids:new FormControl(null, [Validators.required]),
      driver_ids:new FormControl(null, [Validators.required]),
      nurse_ids:new FormControl(null, [Validators.required]),
      er_ids:new FormControl(null, [Validators.required]),
      ems_ids:new FormControl(null, [Validators.required]),
      moniter_id:new FormControl(null, [Validators.required]),
      age:new FormControl(null, [Validators.required]),
      sickness_type:new FormControl(null, [Validators.required]),
      is_trauma:new FormControl(null, [Validators.required]),
      glass_id:new FormControl(null, [Validators.required]),
      diagnosis_note:new FormControl(null, [Validators.required]),
      request_type:new FormControl(null, [Validators.required]),
      unique_hn_code:new FormControl(null, [Validators.required]),

      hospital_id:new FormControl(null, [Validators.required]),
      zone_id:new FormControl({value: null, disabled:true}, [Validators.required]), 

      // request_status:new FormControl(null),
      // request_note: new FormControl(null),
      // creater_id:new FormControl(null),
      // zone_id:new FormControl(null),
      // ambulance_id:new FormControl(null),
      // user_request_id:new FormControl(null),
    })
  }

  jqeryfun(){
    $(document).ready(function() {
     $('.filteroptselect').on('click', 'li', function(){
       $('.filteroptselect li').removeClass('activeFilter');
       $(this).addClass('activeFilter');
   });
   });
 }
 next() {
  ++this.active;
  this.currentValue = this.active * 15;
  
}

previous() {
    --this.active;
    this.currentValue = this.currentValue - 15;
}

changeTab(event){
  switch (event.nextId) {
    case 1:
      break;
    case 2:
      this.getAllDriverslist();
      break;

    case 3:
      this.getAllDoctorslist();
      break;

    case 4:
      this.getAllNurseslist();
      break;

    case 5:
      this.getAllErslist();
      break;

    case 6:
      this.getAllEmsslist();
      break;

    case 7:
      this.getAllAocZonesList();
    default:
      this.currentValue = event.nextId * 15;
  }
  this.currentValue = event.nextId * 15;
}

getAllDriverslist(){
  this.spinner.show();
  this.apiService.postAllDriversList(this.selectHospitalId).subscribe((data) => {
    this.driverList = data['content']['data'];
    this.spinner.hide();
  })
}

getAllDoctorslist(){
  this.spinner.show();
  this.apiService.postAllDoctorsList(this.selectHospitalId).subscribe((data) => {
    this.doctorList = data['content']['data'];
    this.spinner.hide();
  })
}

getAllNurseslist(){
  this.spinner.show();
  this.apiService.postAllNursesList(this.selectHospitalId).subscribe((data) => {
    this.nurseList = data['content']['data'];
    this.spinner.hide();
  })
}

getAllErslist(){
  this.spinner.show();
  this.apiService.postAllAocErStaffsList(this.selectHospitalId).subscribe((data) => {
    this.erstaffList = data['content']['data'];
    this.spinner.hide();
  })
}

getAllEmsslist(){
  this.spinner.show();
  this.apiService.postAllAocEmsStaffsList(this.selectHospitalId).subscribe((data) => {
    this.emsstaffList = data['content']['data'];
    this.spinner.hide();
  })
}

getAllAocZonesList(){
  this.spinner.show();
  this.apiService.getAllAocZonesList().subscribe((data) => {
    this.zoneList = data['content']['data'];
    this.spinner.hide();
  })
}

getHospitalsList(zoneId) {
  this.spinner.show();
  this.apiService.getHospitalsList(zoneId).subscribe((hospitalDetail: any) => {
    this.hospitalList = hospitalDetail['content']['data'];    
    this.spinner.hide();
  })
}

zoneChange(e) {
  this.getHospitalsList(e.target.value);
}

selectDriver(driver)  {
  if(this.driverListShow.findIndex((e) => e._id == driver._id ) === -1) {
    this.driverListShow.push(driver);
    this.selectDriverIds.push(driver._id)
    this.driverList.splice(driver);
  } else {

  }
}

deleteSelectDriver(driver){
  this.driverList.push(driver);
  this.driverListShow.splice(driver);
}

selectDoctor(doctor)  {
  if(this.doctorListShow.indexOf(doctor) === -1) {
    this.doctorListShow.push(doctor);
    this.selectDoctorIds.push(doctor._id);
    this.doctorList.splice(doctor);
  } else {
  }
}
deleteSelectDoctor(doctor){
  this.doctorList.push(doctor);
  this.doctorListShow.splice(doctor);
}

selectNurse(nurse)  {
  if(this.nurseListShow.indexOf(nurse) === -1) {
    this.nurseListShow.push(nurse);
    this.selectNurseIds.push(nurse._id);
    this.nurseList.splice(nurse);
  } else {
  }
}

deleteselectNurse(nurse){
  this.nurseList.push(nurse);
  this.nurseListShow.splice(nurse);
}

selectEr(er)  {
  if(this.erstaffListShow.indexOf(er) === -1) {
    this.erstaffListShow.push(er);
    this.selectErIds.push(er._id);
    this.erstaffList.splice(er);
  } else {
   
  }
}
deleteSelectEr(er){
  this.erstaffList.push(er);
  this.erstaffListShow.splice(er);
}

selectEms(ems)  {
  if(this.emsstaffListShow.indexOf(ems) === -1) {
    this.emsstaffListShow.push(ems);
    this.selectEmsIds.push(ems._id);
    this.emsstaffList.splice(ems);
  } else {
   
  }
}
deleteSelectEms(ems){
  this.emsstaffList.push(ems);
  this.emsstaffListShow.splice(ems);
}

zoneToggle(e) : void { 
  
  let zoneCtrl = this.ambulanceReqForm.get('zone_id')
  zoneCtrl.disabled ? zoneCtrl.enable() : zoneCtrl.disable() 
  this.selectAmbulancereq.zone_id = ''
  
}

onSubmit(ambulanceReqValue) {

  this.ambulanceReqForm.patchValue({
    doctor_ids: this.selectDoctorIds.join(','),
    driver_ids: this.selectDriverIds.join(','),
    nurse_ids: this.selectNurseIds.join(','),
    er_ids: this.selectErIds.join(','),
    ems_ids: this.selectEmsIds.join(','),
    source_hospital: this.selectHospitalId
 });
 let Form = JSON.stringify(this.ambulanceReqForm.value);
  if(Form){
    this.spinner.show();
    this.apiService.postAmbulanceRequest(Form).subscribe((res: any)=>{
      if(res.success){
        this.spinner.hide();
      }
    })
    this.spinner.hide();
  }
}

close(){
  this.activeModal.close()
}
}
