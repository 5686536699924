import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import { DashboardComponent } from './User_AOC/Dashboards/dashboard/dashboard.component';
import { LoginBoxedComponent } from './User_AOC/UserPages/login-boxed/login-boxed.component';
import { StaffListComponent } from './User_AOC/staff-list/staff-list.component';
import { HistoryComponent } from './User_AOC/history/history.component';
import { SpeedReportComponent } from './User_AOC/speed-report/speed-report.component';
import { SpeedReportDetailComponent } from './User_AOC/speed-report-detail/speed-report-detail.component';
import { HistoryOngoingComponent } from './User_AOC/history-ongoing/history-ongoing.component';
// import { SpeedReport2Component } from './User_AOC/speed-report2/speed-report2.component';
import { NotificationsListComponent } from './User_AOC/notifications-list/notifications-list.component'
import { EcgWaveformComponent } from './common/ecg-waveform/ecg-waveform.component'
import { RequestinfoComponent } from './User_AOC/UserPages/requestinfo/requestinfo.component';
import { ErrorPageComponent } from './Layout/error-page/error-page.component'
import { UserprofileComponent } from './User_AOC/UserPages/userprofile/userprofile.component';
import { OperationReportComponent } from './User_AOC/operation-report/operation-report.component';
import { EcgReportComponent } from './User_AOC/ecg-report/ecg-report.component'
import { EcgReportDetailComponent } from './User_AOC/ecg-report-detail/ecg-report-detail.component'

const routes: Routes = [
  {
    path: '',
    component: PagesLayoutComponent,
    children: [
      {path: '', component: LoginBoxedComponent},
      {path: 'requestinfo/:id', component: RequestinfoComponent},
      {path: 'error', component: ErrorPageComponent},
    ]
  },
  {
    path: 'user',
    component: BaseLayoutComponent,
    children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'stafflist', component: StaffListComponent},
      {path: 'history', component: HistoryComponent},
      {path: 'ecgwaveform', component: EcgWaveformComponent},
      {path: 'speed-report', component: SpeedReportComponent},
      {path: 'speed-report-detail', component: SpeedReportDetailComponent},
      {path: 'history-ongoing', component: HistoryOngoingComponent},
      {path: 'operation-report', component:OperationReportComponent },
      {path: 'crash-notification', component: NotificationsListComponent},
      {path: 'user-profile', component: UserprofileComponent},
      {path: 'ecg-report', component: EcgReportComponent},
      {path: 'ecg-report-detail', component: EcgReportDetailComponent}
    ]

  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    useHash: true,
    enableTracing: true
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
