<div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="card main-card mb-3">
          <div class="card-header-tab card-header">
            <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
              <i class="header-icon fa fa-tachometer"> </i>
              {{'titles.over_speed_repo_details' | translate}}
            </div>
          </div>
          <div class="no-gutters row">
            <div class="card-body">
              <div class="page-body historyTable">
                <button class="btn btn-primary float-right" [disabled]="speedFulldata?.length == 0" (click)="createPdf()">
                 {{'buttons.pdf' | translate}}
                </button>
                <!-- <div class="paginations">
                  <ngb-pagination [collectionSize]="collectionsize" (pageChange)="onPageChange($event)" [pageSize]="page_size" [(page)]="page" [rotate] = "true" [maxSize]="5" [boundaryLinks]="true" aria-label="Default pagination"></ngb-pagination>
                </div> -->
                <div id="content" #content>
                  <table class="table table-striped w-100" datatable [dtOptions]="dtOptions">
                    <thead>
                      <tr>
                        <th>{{'labels.speed' | translate}}</th>
                        <th>{{'labels.time' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr class="no-data" *ngIf="speedFulldata?.length == 0">
                          <td colspan="5">{{'error.nodata' | translate}}</td>
                        </tr>
                        <tr  *ngFor="let detail of speedFulldata; let i = index">
                          <td>{{detail.speed | number:'1.0-0' }}</td>
                          <td>{{detail.createdAt | date:'medium' }}</td>
                        </tr>
                    </tbody>
                  </table>
                </div> 
              </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
          </div>
          </div>
        </div>
      </div>
  </div>
  