import {
  Component,
  Output,
  HostListener,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
  NgZone,
  AfterViewInit,
  Renderer2,
  EventEmitter,
} from "@angular/core";
import { ThemeOptions } from "../../../theme-options";
import { fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import {
  NgbModalConfig,
  NgbModal,
  NgbAccordion,
} from "@ng-bootstrap/ng-bootstrap";
import { NgbPanelChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { AuthService } from "../../../Services/auth.service";
import { environment } from "../../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "../../../Services/toast.service";
import { PatientReqListComponent } from "../../../common/patient-req-list/patient-req-list.component";
import { AgmMap, MapsAPILoader, MouseEvent } from "@agm/core";
import { ModalService } from "../../../Services/modal.service";
import { DataTransService } from "../../../Services/data-trans.service";
import { SnapshotViewComponent } from "../../../common/snapshot-view/snapshot-view.component";
import { DirectionViewComponent } from "../../../common/direction-view/direction-view.component";
import { MonitorViewComponent } from "../../../common/monitor-view/monitor-view.component";
import { Subscription } from "rxjs-compat";
import { LocationService } from "../../../Services/location.service";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit, AfterViewInit {
  public extraParameter: any;
  public languages: any = environment.languages.map((e) => {
    return { label: e };
  });
  errTime = environment.timeout.errorNotificationTimeout;
  suceess = "Successfully Data Added";
  invalid = "Invalid Details";
  private location: Subscription;
  @ViewChild(AgmMap) agmMap;
  // @ViewChild('showMapmodal') public showMapmodal: TemplateRef<any>;
  @ViewChild("search", { static: false }) searchElementRef: ElementRef;

  imgUrls = environment.imgUrl;
  submitted = false;
  showMapmodal = false;
  zoom: number = 12;
  isDragged: boolean = false;
  private geoCoder;
  latitude: number = 15.87;
  longitude: number = 100.9925;
  mapOpen: boolean = false;
  dragValue: "";
  ambulanceListStatus: boolean = false;
  addAmbulanceModal;
  hospitalDetail;
  ambulanceDetail;
  selectSourceHospital;
  userHospitalIds: any[];
  selectHospitalLocation: any;

  patientReqDetail: any = {};
  patientRequestsAmbulancesData: any;
  patientSelectHospitalId;
  imgPath: any;

  ambulanceListSearch: any = null;
  ambulanceListSearchTimeout: any = environment.searchTimeout;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private apiService: AuthService,
    private modalService: NgbModal,
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private cookieService: CookieService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private modalS: ModalService,
    private router: Router,
    private locationService: LocationService,
    private tranS: DataTransService
  ) {
    translate.setDefaultLang("en");
    translate.use(this.cookieService.get("lang"));
  }

  @select("config") public config$: Observable<any>;
  today = new Date(Date.now()).toLocaleString();
  private newInnerWidth: number;
  private innerWidth: number;
  activepanel = true;
  activeId;
  page = 3;
  collectionSize = 40;

  public isCollapsed = true;
  ambulancelist: any;
  totalAmbulance: any = [];
  ambulanceReqForm: FormGroup;
  active = 1;
  currentValue = 15;

  driverList = [];
  driverListShow = [];
  selectDriverIds = [];

  doctorList = [];
  doctorListShow = [];
  selectDoctorIds = [];

  nurseList = [];
  nurseListShow = [];
  selectNurseIds = [];

  paramedicList = [];
  paramedicListShow = [];
  selectParamedicIds = [];

  erstaffList = [];
  erstaffListShow = [];
  selectErIds = [];

  emsstaffList = [];
  emsstaffListShow = [];
  selectEmsIds = [];

  selectHospital;
  selectAmbulance;
  selectHospitalId;

  selectAmbulanceId;

  zoneList: any;
  hospitalList: any;

  monitorsList: any;
  glassList: any;
  fasttrackList: any;
  customer;
  pageSize: any;
  currentPage: any;
  searchValue: any;
  request_type_value: any;

  collectionsize: any;
  name: any;

  patientRequestsData;
  patintBoolen: boolean = true;
  source;
  destination;
  platNo;
  currentTime;
  severityLists;
  patientRequestid;
  ambulanceId;
  patientLocation;
  selectAmbulancereq = {
    _id: null,
    first_name: "",
    last_name: "",
    gender: "",
    severity_level: null,
    fast_track_id: "",
    source_hospital_id: "",
    // destination_hospital_id:'',
    doctor_ids: "",
    driver_ids: "",
    nurse_ids: "",
    er_ids: "",
    ems_ids: "",
    moniter_id: "",
    age: "",
    request_type: "",
    sickness_type: "",
    is_trauma: false,
    glass_id: "",
    diagnosis_note: "",
    unique_hn_code: "",
    destination_hospital_id: "",
    zone_id: "",
    ambulance_id: "",
    source: "",
    destination: "",
    user_id: "",
    // remaining
    // zone_id:'',
    // ambulance_id:'',
    // user_request_id:'',
    // request_status:'',
    // request_note: '',
    // creater_id:'',
  };

  public beforeChange($event: NgbPanelChangeEvent) {
    if ($event.panelId === "preventchange-2") {
      $event.preventDefault();
    }

    if ($event.panelId === "preventchange-3" && $event.nextState === false) {
      $event.preventDefault();
    }
  }

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.loadMap();
    // }, 10000);
  }

  ngOnInit() {
    this.location = this.locationService.location.subscribe((data) => {});
    this.geoCoder = new google.maps.Geocoder();
    this.selectAmbulancereq.user_id = this.cookieService.get("user_id");
    // this.patientRequestid = JSON.parse(localStorage.getItem('patientRequestid'));
    this.getHospitalZone();
    if (this.tranS.patId == undefined) {
      this.tranS.patId = this.tranS.thirdData.subscribe((details) => {
        this.patientRequestid = details.thirdDetails;
      });
    }
    if (this.tranS.subsV == undefined) {
      this.tranS.subsV = this.tranS.secondData.subscribe((detail2) => {
        this.patientSelectHospitalId =
          detail2?.secondDetails[0]?.hospitalsDetail?._id;
        this.selectHospital =
          detail2?.secondDetails[0]?.hospitalsDetail?.full_name;
      });
    }

    if (this.modalS.subsVar == undefined) {
      this.modalS.subsVar = this.modalS.invokeFirstComponentFunction.subscribe(
        (detail) => {
          this.dragValue =
            detail.acceptReqDetail.patient_requests_data[0].source.address;
          // this.patientLocation =  detail.acceptReqDetail.patient_requests_data[0].source.location
          this.latitude =
            detail.acceptReqDetail.patient_requests_data[0].source.location.lat;
          this.longitude =
            detail.acceptReqDetail.patient_requests_data[0].source.location.lon;
          this.getAddress(this.latitude, this.longitude);

          this.patintBoolen = false;
          this.openaddAmbulanceModal(
            detail.acceptReqDetail["patient_requests_data"],
            detail.acceptReqDetail["ambulances_data"]
          );

          this.patientRequestsData =
            detail?.acceptReqDetail?.patient_requests_data[0];
          this.patientRequestsAmbulancesData =
            detail?.acceptReqDetail?.ambulances_data;

          this.selectAmbulancereq = {
            // first_name =  detail?.patientsDetail?.first_name;
            // last_name =  detail?.patientsDetail?.last_name;
            _id: this.patientRequestsData?.patientsDetail?._id
              ? this.patientRequestsData?.patientsDetail?._id
              : null,
            first_name: this.patientRequestsData?.patientsDetail?.first_name
              ? this.patientRequestsData?.patientsDetail?.first_name
              : "",
            last_name: this.patientRequestsData?.patientsDetail?.last_name
              ? this.patientRequestsData?.patientsDetail?.last_name
              : "",
            gender: this.patientRequestsData?.patientsDetail?.gender,
            severity_level: this.patientRequestsData?.severity_level
              ? this.patientRequestsData?.severity_level
              : 1,
            fast_track_id: this.patientRequestsData?.patientsDetail
              ?.fast_track_id
              ? this.patientRequestsData?.patientsDetail?.fast_track_id
              : "",
            source_hospital_id: this.patientSelectHospitalId,
            doctor_ids: this.patientRequestsData?.patientsDetail?.doctor_ids
              ? this.patientRequestsData?.patientsDetail?.doctor_ids
              : "",
            driver_ids: this.patientRequestsData?.patientsDetail?.driver_ids
              ? this.patientRequestsData?.patientsDetail?.driver_ids
              : "",
            nurse_ids: this.patientRequestsData?.patientsDetail?.nurse_ids
              ? this.patientRequestsData?.patientsDetail?.nurse_ids
              : "",
            er_ids: this.patientRequestsData?.patientsDetail?.er_ids
              ? this.patientRequestsData?.patientsDetail?.er_ids
              : "",
            ems_ids: this.patientRequestsData?.patientsDetail?.ems_ids
              ? this.patientRequestsData?.patientsDetail?.ems_ids
              : "",
            moniter_id: this.patientRequestsData?.patientsDetail?.moniter_id
              ? this.patientRequestsData?.patientsDetail?.moniter_id
              : "",
            age: this.patientRequestsData?.patientsDetail?.age
              ? this.patientRequestsData?.patientsDetail?.age
              : "",
            request_type: "EMS",
            sickness_type: this.patientRequestsData?.sickness_type
              ? this.patientRequestsData?.sickness_type
              : "",
            is_trauma:
              Number(this.patientRequestsData?.is_trauma) == 1 ? true : false,
            glass_id: this.patientRequestsData?.patientsDetail?.glass_id
              ? this.patientRequestsData?.patientsDetail?.glass_id
              : "",
            diagnosis_note: this.patientRequestsData?.diagnosis_note
              ? this.patientRequestsData?.diagnosis_note
              : "",
            unique_hn_code: this.patientRequestsData?.patientsDetail
              ?.unique_hn_code
              ? this.patientRequestsData?.patientsDetail?.unique_hn_code
              : "",
            destination_hospital_id: this.patientRequestsData?.patientsDetail
              ?.destination_hospital_id
              ? this.patientRequestsData?.patientsDetail
                  ?.destination_hospital_id
              : "",
            zone_id: this.patientRequestsData?.zone_id
              ? this.patientRequestsData?.zone_id
              : "",
            ambulance_id: this.patientRequestsAmbulancesData?._id
              ? this.patientRequestsAmbulancesData?._id
              : "",
            source: this.patientRequestsData?.patientsDetail?.source
              ? this.patientRequestsData?.patientsDetail?.source
              : "",
            destination: this.destination,
            user_id: this.selectAmbulancereq.user_id,
          };
          // this.selectAmbulancereq.first_name =  this.patientRequestsData?.patientsDetail?.first_name;
          // this.selectAmbulancereq.last_name =  this.patientRequestsData?.patientsDetail?.last_name;
        },
        (err) => {
          if (err.error && err.error.message) {
            this.toastService.show(
              err.error.message + `<br>` + "status code:" + err.status,
              { classname: "bg-danger text-white", delay: this.errTime }
            );
          } else {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }
        }
      );
    }

    this.ambulanceReqForm = new FormGroup({
      _id: new FormControl(),
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null),
      gender: new FormControl(null),
      s_hosp: new FormControl(null),
      severity_level: new FormControl(null, [Validators.required]),
      fast_track_id: new FormControl(),
      source_hospital_id: new FormControl(),
      doctor_ids: new FormControl(null),
      driver_ids: new FormControl(null),
      nurse_ids: new FormControl(null),
      paramedic_ids: new FormControl(null),
      er_ids: new FormControl(null),
      ems_ids: new FormControl(null),
      moniter_id: new FormControl(null),
      age: new FormControl(""),
      sickness_type: new FormControl(null),
      is_trauma: new FormControl(),
      glass_id: new FormControl(null),
      diagnosis_note: new FormControl(),
      request_type: new FormControl(null, [Validators.required]),
      unique_hn_code: new FormControl(null),
      ambulance_id: new FormControl(null),
      destination_hospital_id: new FormControl(null),
      zone_id: new FormControl(),
      patient_request_id: new FormControl(),
      patient_id: new FormControl(),
      // source: '',
      destination: new FormControl(null),
      user_id: new FormControl(),
      // request_status:new FormControl(null),
      // request_note: new FormControl(null),
      // creater_id:new FormControl(null),
      // zone_id:new FormControl(null),
      // user_request_id:new FormControl(null),
    });

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter =
      this.activatedRoute.snapshot.firstChild.data.extraParameter;
  }

  getHospitalZone() {
    let userId = this.cookieService.get("api_key");
    if (!userId) {
      this.router.navigate(["/"]);
    }

    this.apiService.getHospitalsByUserList(userId).subscribe(
      (data) => {
        this.userHospitalIds =
          [] || data["content"]["data"][0]["hospitalDetails"];
        this.zoneList = [] || data["content"]["data"][0]["zoneDetails"];
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }
  openAmbulanceListModal(ambulanceListModal) {
    this.jqeryfun();
    this.spinner.show();
    // this.ambulanceListStatus = !this.ambulanceListStatus;
    this.modalService.open(ambulanceListModal, { windowClass: "sidebar_list" });
    this.getAmbulanceList();
  }
  closeambulanceListModal() {
    this.ambulanceListStatus = false;
    this.patintBoolen = true;
    this.active = 1;
    $(".form_stepper .nav-tabs .nav-item .nav-link").removeClass("active");
    this.driverListShow = [];
    this.driverList = [];
    this.doctorListShow = [];
    this.doctorList = [];
    this.nurseListShow = [];
    this.nurseList = [];
    this.paramedicListShow = [];
    this.paramedicList = [];
    this.erstaffListShow = [];
    this.erstaffList = [];
    this.emsstaffListShow = [];
    this.emsstaffList = [];
    this.selectDriverIds = [];
    this.selectDoctorIds = [];
    this.selectNurseIds = [];
    this.selectErIds = [];
    this.selectEmsIds = [];
    this.ambulanceReqForm.reset();
    this.selectAmbulancereq.request_type = "";
    this.hospitalList = [];
    this.glassList = [];
    this.monitorsList = [];
    this.zoneList = [];
  }
  openaddAmbulanceModal(hospitalDetail, ambulanceDetail) {
    $("#ambulancelistID").click();
    this.spinner.show();
    this.ambulanceListStatus = !this.ambulanceListStatus;
    this.selectHospital = hospitalDetail.name1;
    this.selectSourceHospital = ambulanceDetail.hospital_id;
    this.selectAmbulance = ambulanceDetail.name1;

    if (ambulanceDetail && ambulanceDetail.hospital_id) {
      this.selectHospitalId = ambulanceDetail.hospital_id;
      this.selectSourceHospital = ambulanceDetail.hospital_id;
    } else {
      this.selectHospitalId = hospitalDetail._id;
      this.selectSourceHospital = hospitalDetail._id;
    }
    // else if( ambulanceDetail.hospital_id){
    //   this.selectHospitalId = ambulanceDetail.hospital_id;
    // }
    this.selectAmbulanceId = ambulanceDetail._id;
    this.platNo = ambulanceDetail.number;
    this.selectAmbulancereq.zone_id = hospitalDetail.zone_id;
    this.selectHospitalLocation = hospitalDetail.location;
    this.spinner.hide();
  }

  onLanguageChange(lang: any) {
    this.cookieService.set("lang", String(lang.label.code));
    window.location.reload();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }

  jqeryfun() {
    $(document).ready(function () {
      $(".filteroptselect").on("click", "li", function () {
        $(".filteroptselect li").removeClass("activeFilter");
        $(this).addClass("activeFilter");
      });
      $("#toggleClicks").click();
    });
  }

  getAmbulanceList(
    page_size = 5,
    current_page = 1,
    search_value = "",
    request_type_value = ""
  ) {
    this.spinner.show();
    this.pageSize = page_size;
    this.currentPage = current_page;
    this.searchValue = search_value;
    // this.request_type_value = request_type_value;
    let user_id = this.cookieService.get("api_key");
    
    this.apiService
      .getUserAmbulanceList(
        this.pageSize,
        this.currentPage,
        this.searchValue,
        this.request_type_value,
        this.userHospitalIds,
        user_id
      )
      .subscribe(
        (data) => {
          this.ambulancelist = data["content"]["data"];
          this.collectionsize = data["content"]["count"];
          this.spinner.hide();
          this.jqeryfun();
        },
        (err) => {
          if (err.error && err.error.message) {
            this.toastService.show(
              err.error.message + `<br>` + "status code:" + err.status,
              { classname: "bg-danger text-white", delay: this.errTime }
            );
          } else {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }
        }
      );
  }
  onPageChange(page) {
    this.currentPage = page;
    this.getAmbulanceList(this.pageSize, this.currentPage, this.searchValue);
  }

  filterItem(value) {
    this.searchValue = String(value);
    if (this.ambulanceListSearch) clearTimeout(this.ambulanceListSearch);
    this.ambulanceListSearch = setTimeout(() => {
      this.getAmbulanceList(this.pageSize, this.currentPage, this.searchValue);
    }, this.ambulanceListSearchTimeout);
  }

  next() {
    ++this.active;
    this.currentValue = this.active * 15;
    switch (this.active) {
      case 1:
        break;
      case 2:
        if (this.driverList.length == 0 && this.selectDriverIds.length == 0) {
          this.getAllDriverslist();
          break;
        }
      case 3:
        if (this.doctorList.length == 0 && this.selectDoctorIds.length == 0) {
          this.getAllDoctorslist();
          break;
        }
      case 4:
        if (this.nurseList.length == 0 && this.selectNurseIds.length == 0) {
          this.getAllNurseslist();
          break;
        }
      case 5:
        if (
          this.paramedicList.length == 0 &&
          this.selectParamedicIds.length == 0
        ) {
          this.getAllParamedicslist();
          break;
        }
      case 6:
        if (this.erstaffList.length == 0 && this.selectErIds.length == 0) {
          this.getAllErslist();
          break;
        }
      case 7:
        if (this.emsstaffList.length == 0 && this.selectEmsIds.length == 0) {
          this.getAllEmsslist();
          break;
        }

      case 8:
        this.getHospitalZone();
        this.postMonitersList();
        this.postGlassList();
        this.postFastTracksList();
        this.postSeverityColorCodesList();
        this.getHospitalsList(this.selectAmbulancereq.zone_id);

      default:
        this.currentValue = this.active * 15;
    }
  }

  previous() {
    --this.active;
    this.currentValue = this.currentValue - 15;

    switch (this.active) {
      case 1:
        break;
      case 2:
        if (this.driverList.length == 0 && this.selectDriverIds.length == 0) {
          this.getAllDriverslist();
          break;
        }
      case 3:
        if (this.doctorList.length == 0 && this.selectDoctorIds.length == 0) {
          this.getAllDoctorslist();
          break;
        }
      case 4:
        if (this.nurseList.length == 0 && this.selectNurseIds.length == 0) {
          this.getAllNurseslist();
          break;
        }
      case 5:
        if (
          this.paramedicList.length == 0 &&
          this.selectParamedicIds.length == 0
        ) {
          this.getAllParamedicslist();
          break;
        }
      case 6:
        if (this.erstaffList.length == 0 && this.selectErIds.length == 0) {
          this.getAllErslist();
          break;
        }
      case 7:
        if (this.emsstaffList.length == 0 && this.selectEmsIds.length == 0) {
          this.getAllEmsslist();
          break;
        }
      case 8:
        this.getHospitalZone();
        this.postMonitersList();
        this.postGlassList();
        this.postFastTracksList();
        this.postSeverityColorCodesList();
        this.getHospitalsList(this.selectAmbulancereq.zone_id);

      default:
        this.currentValue = this.active * 15;
    }
  }

  changeTab(event) {
    switch (event.nextId) {
      case 1:
        break;

      case 2:
        if (this.driverList.length == 0 && this.selectDriverIds.length == 0) {
          this.getAllDriverslist();
        }
        break;

      case 3:
        if (this.doctorList.length == 0 && this.selectDoctorIds.length == 0) {
          this.getAllDoctorslist();
        }
        break;

      case 4:
        if (this.nurseList.length == 0 && this.selectNurseIds.length == 0) {
          this.getAllNurseslist();
        }
        break;

      case 5:
        if (
          this.paramedicList.length == 0 &&
          this.selectParamedicIds.length == 0
        ) {
          this.getAllParamedicslist();
          break;
        }

      case 6:
        if (this.erstaffList.length == 0 && this.selectErIds.length == 0) {
          this.getAllErslist();
        }
        break;

      case 7:
        if (this.emsstaffList.length == 0 && this.selectEmsIds.length == 0) {
          this.getAllEmsslist();
        }
        break;

      case 8:
        this.getHospitalZone();
        this.postMonitersList();
        this.postGlassList();
        this.postFastTracksList();
        this.postSeverityColorCodesList();
        this.getHospitalsList(this.selectAmbulancereq.zone_id);

      default:
        this.currentValue = event.nextId * 15;
    }
    this.currentValue = event.nextId * 15;
  }

  getAllDriverslist() {
    this.spinner.show();
    this.apiService.postAllDriversList(this.selectHospitalId).subscribe(
      (data) => {
        this.driverList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  getAllDoctorslist() {
    this.spinner.show();
    this.apiService.postAllDoctorsList(this.selectHospitalId).subscribe(
      (data) => {
        this.doctorList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  getAllNurseslist() {
    this.spinner.show();
    this.apiService.postAllNursesList(this.selectHospitalId).subscribe(
      (data) => {
        this.nurseList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  getAllParamedicslist() {
    this.spinner.show();
    this.apiService.postAllParamedicsList(this.selectHospitalId).subscribe(
      (data) => {
        this.paramedicList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  getAllErslist() {
    this.spinner.show();
    this.apiService.postAllAocErStaffsList(this.selectHospitalId).subscribe(
      (data) => {
        this.erstaffList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  getAllEmsslist() {
    this.spinner.show();
    this.apiService.postAllAocEmsStaffsList(this.selectHospitalId).subscribe(
      (data) => {
        this.emsstaffList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  // getAllAocZonesList(){
  //   this.spinner.show();
  //   this.apiService.getAllAocZonesList().subscribe((data) => {
  //     this.zoneList = data['content']['data'];
  //     this.spinner.hide();
  //   })
  // }

  getHospitalsList(zoneId) {
    this.spinner.show();
    this.apiService.getHospitalsList(zoneId).subscribe(
      (hospitalDetail: any) => {
        this.hospitalList = [];
        this.hospitalList = hospitalDetail["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  zoneChange(e) {
    this.getHospitalsList(e.target.value);
  }

  selectDriver(driver, i) {
    if (this.driverListShow.findIndex((e) => e._id == driver._id) === -1) {
      this.driverListShow.push(driver);
      this.selectDriverIds.push(driver._id);
      this.driverList.splice(i, 1);
    }
  }

  deleteSelectDriver(driver, i) {
    this.driverList.push(driver);
    this.driverListShow.splice(i, 1);
  }

  selectDoctor(doctor, i) {
    if (this.doctorListShow.indexOf(doctor) === -1) {
      this.doctorListShow.push(doctor);
      this.selectDoctorIds.push(doctor._id);
      this.doctorList.splice(i, 1);
    }
  }
  deleteSelectDoctor(doctor, i) {
    this.doctorList.push(doctor);
    this.doctorListShow.splice(i, 1);
  }

  selectNurse(nurse, i) {
    if (this.nurseListShow.indexOf(nurse) === -1) {
      this.nurseListShow.push(nurse);
      this.selectNurseIds.push(nurse._id);
      this.nurseList.splice(i, 1);
    }
  }

  deleteselectNurse(nurse, i) {
    this.nurseList.push(nurse);
    this.nurseListShow.splice(i, 1);
  }

  selectParamedic(paramedic, i) {
    if (this.paramedicListShow.indexOf(paramedic) === -1) {
      this.paramedicListShow.push(paramedic);
      this.selectParamedicIds.push(paramedic._id);
      this.paramedicList.splice(i, 1);
    }
  }

  deleteselectParamedic(paramedic, i) {
    this.paramedicList.push(paramedic);
    this.paramedicListShow.splice(i, 1);
  }

  selectEr(er, i) {
    if (this.erstaffListShow.indexOf(er) === -1) {
      this.erstaffListShow.push(er);
      this.selectErIds.push(er._id);
      this.erstaffList.splice(i, 1);
    }
  }

  deleteSelectEr(er, i) {
    this.erstaffList.push(er);
    this.erstaffListShow.splice(i, 1);
  }

  selectEms(ems, i) {
    if (this.emsstaffListShow.indexOf(ems) === -1) {
      this.emsstaffListShow.push(ems);
      this.selectEmsIds.push(ems._id);
      this.emsstaffList.splice(i, 1);
    }
  }

  deleteSelectEms(ems, i) {
    this.emsstaffList.push(ems);
    this.emsstaffListShow.splice(i, 1);
  }

  zoneToggle(zone): void {
    let zoneCtrl = this.ambulanceReqForm.get("zone_id");
    zone._checked ? zoneCtrl.enable() : zoneCtrl.disable();
  }

  postMonitersList() {
    this.spinner.show();
    this.apiService.postMonitersList(this.selectAmbulanceId).subscribe(
      (data) => {
        this.monitorsList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  postGlassList() {
    this.spinner.show();
    this.apiService.postGlassList(this.selectAmbulanceId).subscribe(
      (data) => {
        this.glassList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  postFastTracksList() {
    this.spinner.show();
    this.apiService.postFastTracksList().subscribe(
      (data) => {
        this.fasttrackList = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  get f() {
    return this.ambulanceReqForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.ambulanceReqForm.value["destination"] = {};

    // this.ambulanceReqForm.value['user_id'] = this.cookieService.get('user_id');

    if (
      !this.destination &&
      (this.selectAmbulancereq.request_type === "EMS" ||
        this.selectAmbulancereq.request_type === "OTHER")
    ) {
      this.toastService.show("Please Add Destination Address", {
        classname: "bg-danger text-white",
        delay: 10000,
      });
      this.submitted = false;

      return;
    }

    if (
      !this.selectAmbulancereq.destination_hospital_id &&
      !(
        this.selectAmbulancereq.request_type == "EMS" ||
        this.selectAmbulancereq.request_type == "OTHER"
      )
    ) {
      this.toastService.show("Please Add Destination Hospital", {
        classname: "bg-danger text-white",
        delay: 10000,
      });
      this.submitted = false;

      return;
    }

    if (this.ambulanceReqForm.controls.request_type.status == "INVALID") {
      this.toastService.show("Please Add Request Type", {
        classname: "bg-danger text-white",
        delay: 10000,
      });
      this.submitted = false;

      return;
    }

    if (this.ambulanceReqForm.controls.severity_level.status == "INVALID") {
      this.toastService.show("Please Select Severity Level", {
        classname: "bg-danger text-white",
        delay: 10000,
      });
      this.submitted = false;

      return;
    }

    if (this.ambulanceReqForm.valid) {
      $("#submitLoader").css("display", "inline-block");
      this.ambulanceReqForm.patchValue({
        doctor_ids: this.selectDoctorIds.join(","),
        driver_ids: this.selectDriverIds.join(","),
        nurse_ids: this.selectNurseIds.join(","),
        paramedic_ids: this.selectParamedicIds.join(","),
        er_ids: this.selectErIds.join(","),
        ems_ids: this.selectEmsIds.join(","),
        source_hospital_id: this.selectHospitalId,
        ambulance_id: this.selectAmbulanceId,
        user_id: this.cookieService.get("user_id"),
      });
      if (this.patientRequestsData) {
        this.ambulanceReqForm.value["patient_id"] =
          this.patientRequestsData?.patient_id;
        this.ambulanceReqForm.value["patient_request_id"] =
          this.patientRequestid;
      }
      this.ambulanceReqForm.value["destination"] = JSON.stringify(
        this.destination
      );
      this.spinner.show();
      this.apiService
        .postAmbulanceRequest(this.ambulanceReqForm.value)
        .subscribe(
          (res: any) => {
            this.submitted = false;

            if (res.success) {
              $("#submitLoader").hide();
              this.spinner.hide();
              this.ambulanceReqForm.reset();
              $("#addAmbulanceClose").click();
              this.toastService.show(this.suceess, {
                classname: "bg-success text-white",
                delay: 10000,
              });
            }
            if (res.status === 201) {
              this.toastService.show(res.message, {
                classname: "bg-danger text-white",
                delay: 10000,
              });
              setTimeout(() => {
                $("#submitLoader").hide();
                this.closeambulanceListModal();
              }, 5000);
            }
            if (res.status === 5001) {
              this.monitorsList = [];
              this.selectAmbulancereq.moniter_id = "";
              this.toastService.show(res.message, {
                classname: "bg-danger text-white",
                delay: 10000,
              });
              this.postMonitersList();
            }
            if (res.status === 5002) {
              this.glassList = [];
              this.selectAmbulancereq.glass_id = "";
              this.toastService.show(res.message, {
                classname: "bg-danger text-white",
                delay: 10000,
              });
              this.postGlassList();
            }
            if (res.status === 5003) {
              this.fasttrackList = "";
              this.toastService.show(res.message, {
                classname: "bg-danger text-white",
                delay: 10000,
              });
              this.postFastTracksList();
            }
            // else{
            //   this.toastService.show(this.invalid, { classname: 'bg-danger text-white', delay: 10000 });
            //   setTimeout(() => {
            //     $('#submitLoader').hide();
            //   }, 5000);
            // }
          },
          (err) => {
            if (err.error && err.error.message) {
              this.toastService.show(
                err.error.message + `<br>` + "status code:" + err.status,
                { classname: "bg-danger text-white", delay: this.errTime }
              );
            } else {
              this.toastService.show(err.message, {
                classname: "bg-danger text-white",
                delay: this.errTime,
              });
            }
          }
        );
      this.spinner.hide();
    } else {
      this.toastService.show(this.invalid, {
        classname: "bg-danger text-white",
        delay: 10000,
      });
      setTimeout(() => {
        $("#submitLoader").hide();
      }, 5000);
      this.submitted = false;
    }
  }

  openPatientReqModal() {
    this.modalService.open(PatientReqListComponent, {
      windowClass: "sidebar_list snapModal",
    });
  }

  openShowMapModal(showMapmodal) {
    this.status = !this.status;
    this.mapOpen = true;
    this.showMapmodal = true;

    setTimeout(() => {
      this.loadMap();
      this.latitude = this.selectHospitalLocation.lat;
      this.longitude = this.selectHospitalLocation.lon;
    }, 1000);
    // this.modalService.open(showMapmodal,{
    //   backdropClass: 'transparent-backdrop',
    //   windowClass: 'mapShow_modal',
    //   size: 'sm',
    //   centered: true
    // });

    setTimeout(() => {
      fromEvent(this.searchElementRef.nativeElement, "keyup")
        .pipe(
          map((event: any) => {
            return event.target.value;
          })
        )
        .subscribe((res) => {});
    }, 3000);
  }

  // Get Current Location Coordinates
  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       this.zoom = 8;
  //       this.getAddress(this.latitude, this.longitude);
  //     });
  //   }
  // }

  loadMap() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      if (this.mapOpen) {
        let autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement
        );
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.getAddress(this.latitude, this.longitude);
            this.zoom = 12;
            this.toastService.show("This Address Location Selected. .", {
              classname: "bg-success text-white",
              timeOut: 20000,
            });
          });
        });
      }
    });
  }

  markerDragEnd($event: MouseEvent) {
    this.isDragged = true;
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            if (this.isDragged) {
              this.dragValue = results[0].formatted_address;
              this.toastService.show("This Address Location Selected .", {
                classname: "bg-success text-white",
                timeOut: 20000,
              });
            }
            this.destination = {
              _id: this.selectAmbulancereq.destination_hospital_id,
              address: results[0].formatted_address,
              location: {
                lat: this.latitude,
                lon: this.longitude,
              },
            };
          } else {
            window.alert("No results found");
          }
        } else {
          // window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  status: boolean = false;
  openMapviewModal() {
    this.status = !this.status;
    this.mapOpen = true;
    setTimeout(() => {
      this.loadMap();
    }, 500);
  }

  closeMap() {
    this.status = false;
  }

  toggle(acc: NgbAccordion) {
    if (this.ambulancelist) {
      acc.activeIds = this.ambulancelist.map((i) => `static-${i._id}`);
    }
  }

  changeReqTypeValue(e) {
    this.request_type_value = e.target.value;
    this.getAmbulanceList(
      this.pageSize,
      this.currentPage,
      this.searchValue,
      this.request_type_value
    );
  }

  logOut() {
    this.cookieService.deleteAll();
    this.router.navigate([""]);
    this.location.unsubscribe();
    this.locationService.roomJoined.map((e) => {
      this.locationService.roomLeave(String(e));
    });
  }

  openSnapshotModal(Snapshowdetail) {
    const modaref = this.modalService.open(SnapshotViewComponent, {
      windowClass: "sidebar_list snapModal",
    });
    modaref.componentInstance.snapShotData = Snapshowdetail;
  }
  openDirectionModal(request) {
    const modaref = this.modalService.open(DirectionViewComponent, {
      windowClass: "sidebar_list snapModal",
    });
    modaref.componentInstance.ambulanceData = request;
  }

  openMonitorModal(Monitordetail) {
    const modaref = this.modalService.open(MonitorViewComponent, {
      windowClass: "sidebar_list snapModal",
    });
    modaref.componentInstance.monitorData = Monitordetail;
  }
  postSeverityColorCodesList() {
    this.spinner.show();
    this.apiService.postSeverityColorCodesList().subscribe(
      (data) => {
        this.severityLists = data["content"]["data"];
        this.spinner.hide();
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }
}
