<div class="content-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="card main-card mb-3">
        <div class="card-header-tab card-header">
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal"
          >
            <i class="header-icon fa fa-users"> </i>
            {{ "titles.stafflist" | translate }}
          </div>
        </div>
        <div class="no-gutters row">
          <div class="card-body">
            <ul class="navTbasLink" id="tabs">
              <li (click)="changeTab($event)">
                <a id="Doctor" class="activeTab">{{
                  "titles.doctor" | translate
                }}</a>
              </li>
              <li (click)="changeTab($event)">
                <a id="Driver">{{ "titles.driver" | translate }}</a>
              </li>
              <li (click)="changeTab($event)">
                <a id="Nurse">{{ "titles.nurse" | translate }}</a>
              </li>
              <li (click)="changeTab($event)">
                <a id="AocErStaff">{{ "titles.erstaff" | translate }}</a>
              </li>
              <li (click)="changeTab($event)">
                <a id="AocEmsStaff">{{ "titles.emsstaff" | translate }}</a>
              </li>
              <li (click)="changeTab($event)">
                <a id="Paramedic">{{ "titles.paramedic" | translate }}</a>
              </li>
              <li (click)="changeTab($event)">
                <a id="Volunteer">{{ "titles.volunteer" | translate }}</a>
              </li>
              <li (click)="changeTab($event)">
                <a id="AocUser">{{ "titles.user" | translate }}</a>
              </li>
            </ul>
            <div class="container-fluid" id="DoctorC">
              <div class="page-body">
                <div class="table-responsive">
                  <table
                    class="table table-striped row-border w-100"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>{{ "labels.picture" | translate }}</th>
                        <th>{{ "labels.name" | translate }}</th>
                        <th>{{ "labels.email" | translate }}</th>
                        <th>{{ "labels.action" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detail of data; let i = index">
                        <td>
                          <img
                            src="{{ detail.profile_picture }}"
                            onerror="this.src='assets/images/user.png'"
                            alt="user"
                            height="50"
                            width="50"
                            class="img-fluid"
                          />
                          <!-- "assets/images/user.png" onerror="this.src='assets/images/user.png'" -->
                        </td>
                        <td>{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td>{{ detail.email }}</td>
                        <td class="action">
                          <a class="staffAction">
                            <label for="d{{ i + 1 }}">
                              <input
                                type="checkbox"
                                id="d{{ i + 1 }}"
                                [value]="detail.isactive == 1 ? false : true"
                                [(ngModel)]="detail.isactive"
                                (change)="
                                  checkInOutChange(detail, 'Doctor', $event)
                                "
                              />
                              <label for="d{{ i + 1 }}" class="check"></label>
                            </label>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="container-fluid" id="DriverC">
              <div class="page-body">
                <div class="table-responsive">
                  <table
                    class="table table-striped row-border w-100"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>{{ "labels.picture" | translate }}</th>
                        <th>{{ "labels.name" | translate }}</th>
                        <th>{{ "labels.email" | translate }}</th>
                        <th>{{ "labels.action" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detail of data; let i = index">
                        <td>
                          <img
                            src="{{ detail.profile_picture }}"
                            onerror="this.src='assets/images/user.png'"
                            alt="user"
                            height="50"
                            width="50"
                            class="img-fluid"
                          />
                        </td>
                        <td>{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td>{{ detail.email }}</td>
                        <td class="action">
                          <a class="staffAction">
                            <label for="driver{{ i + 1 }}">
                              <input
                                type="checkbox"
                                id="driver{{ i + 1 }}"
                                [value]="detail.isactive == 1 ? false : true"
                                [(ngModel)]="detail.isactive"
                                (change)="
                                  checkInOutChange(detail, 'Driver', $event)
                                "
                              />
                              <label
                                for="driver{{ i + 1 }}"
                                class="check"
                              ></label>
                            </label>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="container-fluid" id="NurseC">
              <div class="page-body">
                <div class="table-responsive">
                  <table
                    class="table table-striped row-border w-100"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>{{ "labels.picture" | translate }}</th>
                        <th>{{ "labels.name" | translate }}</th>
                        <th>{{ "labels.email" | translate }}</th>
                        <th>{{ "labels.action" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detail of data; let i = index">
                        <td>
                          <img
                            src="{{ detail.profile_picture }}"
                            onerror="this.src='assets/images/user.png'"
                            alt="user"
                            height="50"
                            width="50"
                            class="img-fluid"
                          />
                        </td>
                        <td>{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td>{{ detail.email }}</td>
                        <td class="action">
                          <a class="staffAction">
                            <label for="nurse{{ i + 1 }}">
                              <input
                                type="checkbox"
                                id="nurse{{ i + 1 }}"
                                [value]="detail.isactive == 1 ? false : true"
                                [(ngModel)]="detail.isactive"
                                (change)="
                                  checkInOutChange(detail, 'Nurse', $event)
                                "
                              />
                              <label
                                for="nurse{{ i + 1 }}"
                                class="check"
                              ></label>
                            </label>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="container-fluid" id="AocErStaffC">
              <div class="page-body">
                <div class="table-responsive">
                  <table
                    class="table table-striped row-border w-100"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>{{ "labels.picture" | translate }}</th>
                        <th>{{ "labels.name" | translate }}</th>
                        <th>{{ "labels.email" | translate }}</th>
                        <th>{{ "labels.action" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detail of data; let i = index">
                        <td>
                          <img
                            src="{{ detail.profile_picture }}"
                            onerror="this.src='assets/images/user.png'"
                            alt="user"
                            height="50"
                            width="50"
                            class="img-fluid"
                          />
                        </td>
                        <td>{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td>{{ detail.email }}</td>
                        <td class="action">
                          <a class="staffAction">
                            <label for="er{{ i + 1 }}">
                              <input
                                type="checkbox"
                                id="er{{ i + 1 }}"
                                [value]="detail.isactive == 1 ? false : true"
                                [(ngModel)]="detail.isactive"
                                (change)="
                                  checkInOutChange(detail, 'AocErStaff', $event)
                                "
                              />
                              <label for="er{{ i + 1 }}" class="check"></label>
                            </label>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="container-fluid" id="AocEmsStaffC">
              <div class="page-body">
                <div class="table-responsive">
                  <table
                    class="table table-striped row-border w-100"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>{{ "labels.picture" | translate }}</th>
                        <th>{{ "labels.name" | translate }}</th>
                        <th>{{ "labels.email" | translate }}</th>
                        <th>{{ "labels.action" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detail of data; let i = index">
                        <td>
                          <img
                            src="{{ detail.profile_picture }}"
                            onerror="this.src='assets/images/user.png'"
                            alt="user"
                            height="50"
                            width="50"
                            class="img-fluid"
                          />
                        </td>
                        <td>{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td>{{ detail.email }}</td>
                        <td class="action">
                          <a class="staffAction">
                            <label for="ems{{ i + 1 }}">
                              <input
                                type="checkbox"
                                id="ems{{ i + 1 }}"
                                [value]="detail.isactive == 1 ? false : true"
                                [(ngModel)]="detail.isactive"
                                (change)="
                                  checkInOutChange(
                                    detail,
                                    'AocEmsStaff',
                                    $event
                                  )
                                "
                              />
                              <label for="ems{{ i + 1 }}" class="check"></label>
                            </label>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="container-fluid" id="ParamedicC">
              <div class="page-body">
                <div class="table-responsive">
                  <table
                    class="table table-striped row-border w-100"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>{{ "labels.picture" | translate }}</th>
                        <th>{{ "labels.name" | translate }}</th>
                        <th>{{ "labels.email" | translate }}</th>
                        <th>{{ "labels.action" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detail of data; let i = index">
                        <td>
                          <img
                            src="{{ detail.profile_picture }}"
                            onerror="this.src='assets/images/user.png'"
                            alt="user"
                            height="50"
                            width="50"
                            class="img-fluid"
                          />
                        </td>
                        <td>{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td>{{ detail.email }}</td>
                        <td class="action">
                          <a class="staffAction">
                            <label for="ems{{ i + 1 }}">
                              <input
                                type="checkbox"
                                id="ems{{ i + 1 }}"
                                [value]="detail.isactive == 1 ? false : true"
                                [(ngModel)]="detail.isactive"
                                (change)="
                                  checkInOutChange(
                                    detail,
                                    'AocEmsStaff',
                                    $event
                                  )
                                "
                              />
                              <label for="ems{{ i + 1 }}" class="check"></label>
                            </label>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="container-fluid" id="VolunteerC">
              <div class="page-body">
                <div class="table-responsive">
                  <table
                    class="table table-striped row-border w-100"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>{{ "labels.picture" | translate }}</th>
                        <th>{{ "labels.name" | translate }}</th>
                        <th>{{ "labels.email" | translate }}</th>
                        <th>{{ "labels.action" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detail of data; let i = index">
                        <td>
                          <img
                            src="{{ detail.profile_picture }}"
                            onerror="this.src='assets/images/user.png'"
                            alt="user"
                            height="50"
                            width="50"
                            class="img-fluid"
                          />
                        </td>
                        <td>{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td>{{ detail.email }}</td>
                        <td class="action">
                          <a class="staffAction">
                            <label for="ems{{ i + 1 }}">
                              <input
                                type="checkbox"
                                id="ems{{ i + 1 }}"
                                [value]="detail.isactive == 1 ? false : true"
                                [(ngModel)]="detail.isactive"
                                (change)="
                                  checkInOutChange(
                                    detail,
                                    'AocEmsStaff',
                                    $event
                                  )
                                "
                              />
                              <label for="ems{{ i + 1 }}" class="check"></label>
                            </label>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="container-fluid" id="AocUserC">
              <div class="page-body">
                <div class="table-responsive">
                  <table
                    class="table table-striped row-border w-100"
                    datatable
                    [dtOptions]="dtOptions"
                  >
                    <thead>
                      <tr>
                        <th>{{ "labels.picture" | translate }}</th>
                        <th>{{ "labels.name" | translate }}</th>
                        <th>{{ "labels.email" | translate }}</th>
                        <th>{{ "labels.action" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let detail of data; let i = index">
                        <td>
                          <img
                            src="{{ detail.profile_picture }}"
                            onerror="this.src='assets/images/user.png'"
                            alt="user"
                            height="50"
                            width="50"
                            class="img-fluid"
                          />
                        </td>
                        <td>{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td>{{ detail.email }}</td>
                        <td class="action">
                          <a class="staffAction">
                            <label for="user{{ i + 1 }}">
                              <input
                                type="checkbox"
                                id="user{{ i + 1 }}"
                                [value]="detail.isactive == 1 ? false : true"
                                [(ngModel)]="detail.isactive"
                                (change)="
                                  checkInOutChange(detail, 'AocUser', $event)
                                "
                              />
                              <label
                                for="user{{ i + 1 }}"
                                class="check"
                              ></label>
                            </label>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ul ngbNav #nav="ngbNav" id="myTabs"  [(activeId)]="active" (navChange)="changeTab($event)"  class="nav-pills" #staticTabs>
              <li [ngbNavItem]="1">
                <a ngbNavLink  name="one">
                  <span class="stepmenu">{{'titles.doctor' | translate}}</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="page-body">
                    <div class="table-responsive">
                      <table class="table table-striped row-border w-100" datatable [dtOptions]="dtOptions">
                        <thead>
                          <tr>
                            <th>{{'labels.picture' | translate}}</th>
                            <th>{{'labels.name' | translate}}</th>
                            <th>{{'labels.email' | translate}}</th>
                            <th>{{'labels.action' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>name</td>
                            <td>name@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <label for="test">
                                  <input type="checkbox" >
                                  test
                                </label>
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>name</td>
                            <td>name@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> 
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink  name="two">
                  <span class="stepmenu">{{'titles.driver' | translate}}</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="page-body">
                    <div class="table-responsive">
                      <table class="table table-striped row-border w-100" datatable [dtOptions]="dtOptions">
                        <thead>
                          <tr>
                            <th>{{'labels.picture' | translate}}</th>
                            <th>{{'labels.name' | translate}}</th>
                            <th>{{'labels.email' | translate}}</th>
                            <th>{{'labels.action' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>driver name</td>
                            <td>drivername@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <label for="test">
                                  <input type="checkbox" >
                                  test 2
                                </label>
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>driver2name</td>
                            <td>driver2name@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> 
                  </div> 
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink  name="three">
                  <span class="stepmenu">{{'titles.nurse' | translate}}</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="page-body">
                    <div class="table-responsive">
                      <table class="table table-striped row-border w-100" datatable [dtOptions]="dtOptions">
                        <thead>
                          <tr>
                            <th>{{'labels.picture' | translate}}</th>
                            <th>{{'labels.name' | translate}}</th>
                            <th>{{'labels.email' | translate}}</th>
                            <th>{{'labels.action' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>nurse name</td>
                            <td>nursename@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>this
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>nurse2name</td>
                            <td>nurse2name@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                      </tbody>this
                </ng-template>
              </li>
              <li [ngbNavItem]="4">
                <a ngbNavLink  name="four">
                  <span class="stepmenu">{{'titles.erstaff' | translate}}</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="page-body">
                    <div class="table-responsive">
                      <table class="table table-striped row-border w-100" datatable [dtOptions]="dtOptions">
                        <thead>
                          <tr>
                            <th>{{'labels.picture' | translate}}</th>
                            <th>{{'labels.name' | translate}}</th>
                            <th>{{'labels.email' | translate}}</th>
                            <th>{{'labels.action' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>ername</td>
                            <td>ername@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>er2name</td>
                            <td>er2name@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> 
                  </div> 
                </ng-template>
              </li>
              <li [ngbNavItem]="5">
                <a ngbNavLink  name="five">
                  <span class="stepmenu">{{'titles.emsstaff' | translate}}</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="page-body">
                    <div class="table-responsive">
                      <table class="table table-striped row-border w-100" datatable [dtOptions]="dtOptions">
                        <thead>
                          <tr>
                            <th>{{'labels.picture' | translate}}</th>
                            <th>{{'labels.name' | translate}}</th>
                            <th>{{'labels.email' | translate}}</th>
                            <th>{{'labels.action' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>ems name</td>
                            <td>emsname@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>ems name 2</td>
                            <td>ems2name@gmail.com</td>
                            <td class="action">
                              <a class="staffAction">
                                <img src="assets/images/icon/Check-in.png" id="checkIn" alt="check-in" class="img-fluid">
                                <img src="assets/images/icon/Check-out.png" id="checkOut"  alt="check-out" class="img-fluid">
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> 
                  </div> 
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div> -->
