import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { DataTransService } from '../../Services/data-trans.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../../Services/toast.service'
import { environment } from 'src/environments/environment';
import { AuthService } from '../../Services/auth.service';
import { Location } from '@angular/common';
import { Router } from "@angular/router";

declare var $: any;


@Component({
  selector: 'app-ecg-report',
  templateUrl: './ecg-report.component.html',
  styleUrls: ['./ecg-report.component.sass']
})
export class EcgReportComponent implements OnInit {
  public data = [];
  dtOptions: any = {};
  
  pageSize = 10;
  currentPage= 1;
  searchValue= '';
  sortValue= {};
  column= ['serial_number', 'name'];
  user_id: any;
  errTime =  environment.timeout.errorNotificationTimeout;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @Output() onFilter  = new EventEmitter();

  constructor(private dataTrans:DataTransService, private cookieService : CookieService, private apiService: AuthService, private toastService:ToastService,  private _location: Location, private router: Router) {
   
  }
   

  ngOnInit(): void {
    this.user_id = this.cookieService.get('api_key');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.pageSize,
      lengthMenu : [10, 25, 50],
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.sortValue= {};
        this.currentPage = (dataTablesParameters.start/dataTablesParameters.length)+1;
        this.pageSize = dataTablesParameters.length;
        this.searchValue = String(dataTablesParameters.search.value);
        this.sortValue[this.column[Number(dataTablesParameters.order[0].column)]] = ((String(dataTablesParameters.order[0].dir).toUpperCase() == 'DESC')?-1:1);
        this.apiService.postUserMonitorList(this.pageSize, this.currentPage, this.searchValue, this.sortValue, this.user_id).subscribe((res) => { 
          this.data = res['content']['data'];
          console.log("postUserMonitorList : ", res)
          callback({
            recordsTotal: Number(res['content']['count']),
            recordsFiltered: Number(res['content']['count']),
            data: []
          });
          $('.dataTables_empty').remove();
        },err=>{
          if(err.error && err.error.message){
            this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
            } else{
            this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
          }
        })
      },
    };
  }

  postUserMonitorList(){
    // this.apiService.postUserMonitorList(this.pageSize, this.currentPage, this.searchValue, this.sortValue,this.user_id).subscribe((data) => {
    //   this.data = data['data'];
    // })
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: this.pageSize,
    //   lengthMenu : [10, 25, 50],
    //   serverSide: true,
    //   processing: true,
    //   ajax: (dataTablesParameters: any, callback) => {
    //     this.sortValue= {};
    //     this.currentPage = (dataTablesParameters.start/dataTablesParameters.length)+1;
    //     this.pageSize = dataTablesParameters.length;
    //     this.searchValue = String(dataTablesParameters.search.value);
    //     this.sortValue[this.column[Number(dataTablesParameters.order[0].column)]] = ((String(dataTablesParameters.order[0].dir).toUpperCase() == 'DESC')?1:-1);
    //     this.apiService.postUserMonitorList(this.pageSize, this.currentPage, this.searchValue, this.sortValue,this.user_id).subscribe((res) => { 
    //       console.log("postUserMonitorList ==res", res)
    //       this.data = res['data'];
    //       console.log("postUserMonitorList", this.data)
    //       callback({
    //         recordsTotal: Number(res['count']),
    //         recordsFiltered: Number(res['count']),
    //         data: []
    //       });
    //       $('.dataTables_empty').remove();
    //     },err=>{
    //       if(err.error && err.error.message){
    //         this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
    //         } else{
    //         this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });  
    //       }
    //     })
    //   },
    // };
  }
  ecgDetail(detail){
    localStorage.setItem('ecgDetails', JSON.stringify(detail));
    window.open(this._location.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree(['/user/ecg-report-detail']))), '_blank');
  }
}
