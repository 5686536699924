import { Component, Input, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../Services/auth.service';
import { ToastService } from '../../Services/toast.service'

@Component({
  selector: 'app-crash-modal',
  templateUrl: './crash-modal.component.html'
})
export class CrashModalComponent implements OnInit {
  closeResult = '';
  ambulaneCrashId =  '6096bab22c6b6be7b8fa7ca9';
  ambulanceCrashData;
  imgPath;
  errTime =  environment.timeout.errorNotificationTimeout;
  mapImg;
  @Input() crashDetail;

  constructor(private modalService: NgbModal, private apiService: AuthService,  private toastService:ToastService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.ambulaneCrashId = this.crashDetail;
    if(this.ambulaneCrashId){
      this.postAmbulanceCrashDetail();
    }
  }
  postAmbulanceCrashDetail(){
    this.apiService.postAmbulanceCrashDetail(this.ambulaneCrashId).subscribe((data) => {
      
      this.ambulanceCrashData = data['content'];
      this.mapImg = this.ambulanceCrashData?.location_detail?.image;
      this.imgPath = data['content']?.tracking_server_url;
      $('#crashModal').click();
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  confirmCrash(){
    this.apiService.postConfirmAmbulanceCrash(this.ambulaneCrashId).subscribe((data) => {
      if(data['success'] == true){
        this.activeModal.close();
        $('.close').click();
      }
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }
  // open(content) {
  //   this.modalService.open(content, {backdropClass: 'light-pink',  centered: true, windowClass:'crashModal'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  close(){
    this.activeModal.close()
    $('.modalopen').hide();
  }
   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  
}
