import { Component, OnInit, ViewChild, Input, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from '../../../Services/toast.service'
import { AuthService } from '../../../Services/auth.service'
import { LocationService } from 'src/app/Services/location.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-requestinfo',
  templateUrl: './requestinfo.component.html',
  styleUrls: ['./requestinfo.component.sass']
})
export class RequestinfoComponent implements OnInit, AfterViewInit {
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  streamingUrl: any;
  streamingFrame: Boolean = false;
  errTime = environment.timeout.errorNotificationTimeout;
  show: boolean = false;
  smallView: any = false;
  zoom: 12;
  latitude: 22.297680;
  longitude: 70.787460;
  coordinates = new google.maps.LatLng(environment.googleMapCenter.lat, environment.googleMapCenter.lon);
  map: google.maps.Map;
  isOnlineTimeout: any = {};
  markerMoveTimeout: any = {};
  infoWindow: any = {};
  isMapLoad: any = true;
  isMonitorLoad: any = true;
  imgPath = this.apiService.imgUrl;
  timeout: any = true;
  timeoutTime = environment.timeout.snapTrackEcgTimeout
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    // disableDefaultUI: true,
    mapTypeControl: false,
    zoom: 6,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    zoomControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM },

  };
  markerIcon: any = {
    url: '/assets/images/map_pin/ambulance/online.svg',
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(-30, -30)
  }
  markers: any={};
  location: any;
  SocketData: any;
  markerFocus: any = false;
  ambId;
  snapTrackEcgDetail: any;
  medicalEquipmentUrl: any;
  eta_time: string;
  severityColorCode;
  previousStatusUser: any = [];
  moniterDetail: any={web_view: 0, is_online: 0};
  ambulanceIds;
  safe_web_view_url: any='';
  equipmentUrl: string = environment.apiUrl;
  constructor(private apiService: AuthService, private locationService: LocationService, private spinner: NgxSpinnerService, private toastService: ToastService, private activatedRoute: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, private _location: Location, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.ambId = this.activatedRoute.snapshot.paramMap.get('id');

    this.ambulanceSnapTrackEcgDetail(this.ambId);

    navigator.geolocation.getCurrentPosition(pos => {
      this.mapOptions.center = new google.maps.LatLng(+pos.coords.latitude, +pos.coords.longitude);
    });

    this.location = this.locationService.location.subscribe(data => {
      if (data[0] == '{') {
        data = JSON.parse(data);
        this.SocketData = data
        this.getLiveLocation(data['_id'], { lat: data['tracking_latitude'], lon: data['tracking_longitude'], heading: data['tracking_heading'], speed: data['tracking_speed'], is_assign: data['is_assign'], request_type: data['request_type'], estimated_time: data['estimated_time'] });
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    });

    
  }

  getUrl(url: string) {
    this.streamingUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.streamingFrame = true;
  }

  getLiveLocation(id, location) {
   
    if (this.markers && this.markers[String(id)]) {
      this.infowindow()
      var marker = this.markers[String(id)]
      var old = { lat: Number(marker.getPosition().lat()), lon: Number(marker.getPosition().lng()) };
      var current = { lat: Number(location.lat), lon: Number(location.lon) };

      var num = 100;
      var delta = {};
      delta['lat'] = (current.lat - old.lat) / num;
      delta['lon'] = (current.lon - old.lon) / num;

      clearTimeout(this.isOnlineTimeout[id]);
      this.isOnlineTimeout[id] = setTimeout(() => {
        this.markers[String(id)].setIcon({
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          url: this.locationService.setMapMarker(0, Number(location['is_assign']), String(location['request_type']), Number(location.speed)) + '?id=' + String(id)
        });
      }, 30000);

      this.markers[String(id)].setIcon({
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        url: this.locationService.setMapMarker(1, Number(location['is_assign']), String(location['request_type']), Number(location.speed)) + '?id=' + String(id)
      });
      this.map.setCenter(this.markers[String(id)].getPosition());
      // google.maps.event.trigger(this.markers[String(id)], 'click');

      if (Number(location.heading)) {
        $('img[src="' + String(this.locationService.setMapMarker(1, Number(location['is_assign']), String(location['request_type']), Number(location.speed)) + '?id=' + String(id)) + '"]').css({
          'transform': 'rotate(' + Number(location.heading) + 'deg)'
        });
      }
      this.locationService.markerMove(this.locationService.markerMove, id, 0, num, delta, marker, old, current, location, this.markerMoveTimeout);
    }
  }

  ambulanceSnapTrackEcgDetail(ambulance_id) {
    this.apiService.AmbulanceSnapTrackEcgDetail(ambulance_id).subscribe((data: any) => {
      if (data['success'] == true){
        this.ambulanceIds = data['content']['ambulance_data'][0]['ambulance_id']
        this.snapTrackEcgDetail = data['content']['ambulance_data'][0];
        this.medicalEquipmentUrl = data['content']['medicalEquipmentsurl']
        this.previousStatusUser = data['content']['ambulance_data'][0]['requestStatusChange'];
        if(!this.streamingFrame) this.getUrl(String(this.snapTrackEcgDetail?.aocRequestsDetail?.patientGlassMacsDetail.rtmp_url));
        this.severityColorCode = {
          'background-color': this.snapTrackEcgDetail.patientRequestsDetail?.severity_color_code,
        };

        if(this.isMonitorLoad){
          this.setMoniterView()
        }
        this.setHospitalLocation()
      }

      if (data.status === 201){
        this.router.navigate(['error']);
      }

     
      // console.log("data.statu", data.status)
     
      if (this.timeout && data['success'] == true) {
        this.timeout = setTimeout(() => {
          this.ambulanceSnapTrackEcgDetail(ambulance_id);
        }, this.timeoutTime)
      } 
      // if (data['status'] === 201) {
      //   this.router.navigate(['error']);
      // }
      
      if (this.isMapLoad) this.setMap(this.snapTrackEcgDetail);
    
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } 
    else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }
  
  setMoniterView() {
    this.isMonitorLoad = false
    if(this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail){
      if(this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.web_view){
        const web_view_url = 'http://'+this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.web_view_url;
        this.safe_web_view_url = this.sanitizer.bypassSecurityTrustResourceUrl(web_view_url);
        
        if(this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.type == "JENNY"){
          const web_view_url = this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.web_view_url;
          this.safe_web_view_url = this.sanitizer.bypassSecurityTrustResourceUrl(web_view_url);
        }
      }
      this.moniterDetail = {
        serial_number: this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.serial_number,
        type: this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.type,
        web_view : this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.web_view,
        // web_view_url : this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.web_view_url,
        is_online : this.snapTrackEcgDetail.aocRequestsDetail.patientMonitorsDetail.is_online,
      }    
    }
  }

  setMap(snapTrackEcgDetail) {
    this.isMapLoad = false;
    // this.markers = {}

    var content = 'Box : ' + String(snapTrackEcgDetail?.ambulance_box_code) + '</br>' +
      'Name :' + String(snapTrackEcgDetail?.name1) + '</br>' + 'Req Type : ' + String((snapTrackEcgDetail?.aocRequestsDetail.request_type == undefined) ? "N/A" : snapTrackEcgDetail?.aocRequestsDetail.request_type) + '</br>';

    if (snapTrackEcgDetail && snapTrackEcgDetail.ambulanceTrackingDetail) {
      content += 'Speed :' + Math.ceil(Number(snapTrackEcgDetail?.ambulanceTrackingDetail.speed)) + '</br>' + 'ETA :' + ((String(snapTrackEcgDetail.ambulanceTrackingDetail.estimated_time) == null) ? 'N/A' : String(snapTrackEcgDetail?.ambulanceTrackingDetail.estimated_time));

      this.markers[String(snapTrackEcgDetail.ambulance_id)] = new google.maps.Marker({
        position: new google.maps.LatLng(snapTrackEcgDetail?.ambulanceTrackingDetail.location.lat, snapTrackEcgDetail?.ambulanceTrackingDetail.location.lon),
        map: this.map,
        title: content,
        icon: this.markerIcon
      });

      // this.markers[String(snapTrackEcgDetail.ambulance_id)].addListener("click", () => {
      //   if (this.infoWindow[String(snapTrackEcgDetail.ambulance_id)]) this.infoWindow[String(snapTrackEcgDetail.ambulance_id)].close();
      //   this.infoWindow[String(snapTrackEcgDetail.ambulance_id)] = new google.maps.InfoWindow({
      //     content: this.markers[String(snapTrackEcgDetail.ambulance_id)].getTitle()
      //   });
      //   this.infoWindow[String(snapTrackEcgDetail.ambulance_id)].open(this.markers[String(snapTrackEcgDetail.ambulance_id)].getMap(), this.markers[String(snapTrackEcgDetail.ambulance_id)]);
      // });
      this.infowindow()
      this.markers[String(snapTrackEcgDetail.ambulance_id)].setMap(this.map);

      this.isOnlineTimeout[String(snapTrackEcgDetail.ambulance_id)] = setTimeout(() => {
        if (this.snapTrackEcgDetail['aocRequestsDetail']) {
          this.markers[String(snapTrackEcgDetail.ambulance_id)].setIcon({
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            url: this.locationService.setMapMarker(0, 1, String(this.snapTrackEcgDetail['aocRequestsDetail']['request_type']), 0) + '?id=' + String(snapTrackEcgDetail.ambulance_id)
          });
        } else {
          this.markers[String(snapTrackEcgDetail.ambulance_id)].setIcon({
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            url: this.locationService.setMapMarker(0, 0, '', 0) + '?id=' + String(snapTrackEcgDetail.ambulance_id) + '?id=' + String(snapTrackEcgDetail.ambulance_id)
          });
        }
      }, 30000);
    }
  
    this.locationService.roomJoin(String( this.ambulanceIds));
    google.maps.event.trigger(this.markers[String( this.ambulanceIds)], 'click');
    this.markerFocus = String( this.ambulanceIds);
    this.map.setZoom(16);

    this.markers[String(snapTrackEcgDetail.ambulance_id)].addListener("click", () => {
      if (this.infoWindow[String(snapTrackEcgDetail.ambulance_id)]) this.infoWindow[String(snapTrackEcgDetail.ambulance_id)].close();
      this.infoWindow[String(snapTrackEcgDetail.ambulance_id)] = new google.maps.InfoWindow({
        content: this.markers[String(snapTrackEcgDetail.ambulance_id)].getTitle()
      });
      // this.infoWindow[String(snapTrackEcgDetail.ambulance_id)].open(this.markers[String(snapTrackEcgDetail.ambulance_id)].getMap(), this.markers[String(snapTrackEcgDetail.ambulance_id)]);
    });

    this.markers[String(snapTrackEcgDetail.ambulance_id)].setMap(this.map);
  }
  infowindow(){
    
    var content = 'Box : ' + String( this.snapTrackEcgDetail?.ambulance_box_code) + '</br>' +
    'Name :' + String( this.snapTrackEcgDetail?.name1) + '</br>' + 'Req Type : ' + String(( this.snapTrackEcgDetail?.aocRequestsDetail.request_type == undefined) ? "N/A" :  this.snapTrackEcgDetail?.aocRequestsDetail.request_type) + '</br>';
     content += 'Speed :' + Math.ceil(Number( this.snapTrackEcgDetail?.ambulanceTrackingDetail.speed)) + '</br>' + 'ETA :' + ((String( this.snapTrackEcgDetail.ambulanceTrackingDetail.estimated_time) == null) ? 'N/A' : String( this.snapTrackEcgDetail?.ambulanceTrackingDetail.estimated_time));

    setTimeout(() => {
      if (this.infoWindow[String( this.snapTrackEcgDetail.ambulance_id)]) this.infoWindow[String( this.snapTrackEcgDetail.ambulance_id)].close();
      this.infoWindow[String( this.snapTrackEcgDetail.ambulance_id)] = new google.maps.InfoWindow({
        content: this.markers[String( this.snapTrackEcgDetail.ambulance_id)].getTitle()
      });
      console.log("window open");
      this.infoWindow[String( this.snapTrackEcgDetail.ambulance_id)].open(this.markers[String( this.snapTrackEcgDetail.ambulance_id)].getMap(), this.markers[String( this.snapTrackEcgDetail.ambulance_id)]);
    }, 500);
  }

  setHospitalLocation() {
   
    if (this.snapTrackEcgDetail?.aocRequestsDetail?.source?.hospitalsDetail?.location) {
      new google.maps.Marker({
        position: new google.maps.LatLng(this.snapTrackEcgDetail?.aocRequestsDetail?.source?.hospitalsDetail?.location.lat, this.snapTrackEcgDetail?.aocRequestsDetail?.source?.hospitalsDetail?.location.lon),
        map: this.map,
        icon: {
          url: '/assets/images/map_pin/green_pin.png',
        }
      });
      new google.maps.Marker({
        position: new google.maps.LatLng(this.snapTrackEcgDetail?.aocRequestsDetail?.destination?.hospitalsDetail?.location.lat, this.snapTrackEcgDetail?.aocRequestsDetail?.destination?.hospitalsDetail?.location.lon),
        map: this.map,
        icon: {
          url: '/assets/images/map_pin/red_pin.png',
        }
      });
    }
  } 
  reloadIframe(){
    $('.reloadbutton i').addClass('reload')
    let iframe = document.getElementById('streamIframe') as HTMLIFrameElement
    iframe.src += '';
    setTimeout(() => {
      $('.reloadbutton i').removeClass('reload')
    }, 5000);
  }
  ngAfterViewInit() {
    this.mapInitializer();
  }
  ecgMoniterData(){
    localStorage.setItem('moniterDetail', JSON.stringify(this.moniterDetail));
    window.open(this._location.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree(['/user/ecgwaveform']))), '_blank');
  }

  reloadIframeMonitor(){
    $('.reloadwebbutton i').addClass('reload')
    let iframe = document.getElementById('streamIframeMonitor') as HTMLIFrameElement
    iframe.src += '';
    setTimeout(() => {
      $('.reloadwebbutton i').removeClass('reload')
    }, 5000);
  }
  openWebview(webview){
    this.modalService.open(webview, { centered: true, windowClass: 'webviewmodal', size: 'xl'  })
  }
  mapInitializer(): void {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.map.addListener("zoom_changed", () => {
      Object.keys(this.infoWindow).map(e => {
        this.infoWindow[e].close();
      });
      this.markerFocus = false;
    })
  }

}