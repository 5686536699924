import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { from, Observable } from "rxjs";
import { map, switchMap, retry } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  conversionEncryptOutput: any;
  conversionDecryptOutput: any;
  plainText: any;
  encryptText: any;
  encdecPassword: string = environment.encdecPassword;

  constructor(private cookieService: CookieService, private router: Router) {
    const allCookies: {} = cookieService.getAll();
  }

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const api_key = this.cookieService.get("api_key");
    const isencryption = String(environment.isencryption);
    const app_type = "web_user_prod";

    let request_params: any = {};

    if (
      httpRequest.body &&
      httpRequest.body.updates &&
      httpRequest.body.updates.length > 0
    ) {
      httpRequest.body.updates.map((e) => {
        request_params[e.param] = e["value"];
      });
    }

    return from(
      this.convertText("encrypt", JSON.stringify(request_params))
    ).pipe(
      switchMap((encryptStr: String) => {
        let httpReqData = {
          setHeaders: { api_key, isencryption, app_type },
          withCredentials: true,
        };

        if (String(environment.isencryption) == "true")
          httpReqData["body"] = { dataStr: encryptStr };

        return next.handle(httpRequest.clone(httpReqData));
      }),
      map(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (String(environment.isencryption) == "true") {
              var decryptStr = CryptoJS.AES.decrypt(
                event.body.content,
                this.encdecPassword.trim().toString()
              );
              event.body.content = JSON.parse(
                CryptoJS.enc.Utf8.stringify(decryptStr)
              );
            }
            if (
              event.body.content &&
              event.body.content.token &&
              event.body.content.token !== ""
            ) {
              //&& new Date(Date.now()).getTime() < 1654021800000  && event.body.content.token &&  event.body.content.token !== ""
              this.cookieService.set(
                "api_key",
                String(event.body.content.token)
              );
              this.cookieService.set(
                "user_id",
                String(event.body.content.token) // 66e85936b664fafe4236da06
              );
              this.cookieService.set("lang", "th");
              this.router.navigate(["user/dashboard"]);
            } else {
              if (
                event.body.status == 599
                //|| new Date(Date.now()).getTime() > 1654021800000
              ) {
                this.cookieService.deleteAll();
                this.router.navigate(["auth"]);
              } else {
                return event;
              }
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.router.navigate(["auth"]);
          }
        }
      )
    );
  }

  convertText(conversion: string, dataStr: string) {
    return new Promise((resolve) => {
      if (conversion == "encrypt") {
        this.conversionEncryptOutput = CryptoJS.AES.encrypt(
          dataStr.trim(),
          this.encdecPassword.trim()
        ).toString();
        resolve(this.conversionEncryptOutput);
      } else {
        this.conversionDecryptOutput = CryptoJS.AES.decrypt(
          this.encryptText.trim(),
          this.encdecPassword.trim()
        ).toString(CryptoJS.enc.Utf8);
        resolve(this.conversionDecryptOutput);
      }
    });
  }
}

export class RetryInterceptor implements HttpInterceptor {
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(retry(2));
  }
}
