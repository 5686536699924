<div class="snapViewModal">
  <div class="modal-header actionHeader">
    <button type="button" id="ambulancelistID" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="row">
      <div class="col-md-8">
        <h4 class="modal-title" id="modal-basic-title">
          <span class="titleImg">
            <img src="assets/images/ambulance.png" alt="btn_config" class="img-fluid mr-1">
          </span>
          <span>{{'titles.patient_request_list' | translate}}</span>
        </h4>
      </div>
      <div class="col-md-4">
        <div class="modal_action_button">
          <button type="button" class="btn d-none">
            <img src="assets/images/gear.png" alt="btn_config" class="img-fluid">
          </button>
          <button type="button" class="btn" (click)="clickSnapfullView()" >
            <img src="assets/images/full-screen-button.png" alt="ico_full" id="fullBtn" class="img-fluid">
            <img src="assets/images/small-screen.png" alt="ico_small" id="smallBtn" class="img-fluid">
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
    <table class="table table-striped row-border w-100" datatable [dtOptions]="dtOptions">
      <thead>
        <tr>
          <th>{{'labels.id' | translate}}</th>
          <th>{{'labels.name' | translate}}</th>
          <th>{{'labels.address' | translate}}</th>
          <th>{{'labels.created_at' | translate}}</th>
          <th>{{'labels.action' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr  class="no-data" *ngIf="data?.length == 0">
          <td colspan="5">{{'error.nodata' | translate}}</td>
        </tr>
        <tr  *ngFor="let detail of data; let i = index">
          <td>{{((currentPage-1)*pageSize + 1) + i}}</td>
          <td>{{detail.patientRequestsDetail.patientsDetail?.first_name}} {{detail.patientRequestsDetail.patientsDetail?.last_name}}</td>
          <td>{{detail.patientRequestsDetail?.source?.address}}</td>
          <td>{{ detail.patientRequestsDetail?.createdAt | date:'medium' }}</td>
          <td>
            <div class="actionButton">
              <button class="btn btn-primary" (click)="openMod(mymodal, detail)">{{'buttons.accept' | translate}}</button>
              <button class="btn btn-secondary" (click)="postPatientReqCancelRequest(detail)">{{'buttons.reject' | translate}}</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</div>


<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'titles.selectambulancelist' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="ambulanceReqForm" #form="ngForm">
      <div class="col-md-12">
        <div class="form-group position-relative">
          <label for="ambulance_id">{{'labels.hospital' | translate}}</label>
          <input   type="text"  class="form-control" formControlName="hospital_id" [(ngModel)]="selectHospital" disabled>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group position-relative">
          <label for="ambulance_id">{{'labels.ambulance' | translate}}</label>
          <select class="form-control" [(ngModel)]="selecteAmbulance" formControlName="ambulance_id" (change)="ambulanceChange($event)">
            <option value="{{a._id}}"  *ngFor="let a of ambulanceList">{{a.ambulance_box_code}} {{a.name1}} {{a.name2}}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="acceptRequest(); modal.close('Save click')">{{'buttons.request' | translate}}</button>
  </div>
</ng-template>