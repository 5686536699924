// export const environment = {
//   production: false,
//   languages: [
//     { code: "en", label: "English", shorthand: "en" },
//     { code: "th", label: "Thai", shorthand: "th" },
//   ],
//   apiUrl: "http://localhost:3000",
//   //apiUrl: "https://opera.aocv2.com",
//   googleMapApiKey: "AIzaSyAWILNiGlWIeNXIdpdDyYYyT1GmdlSgT1k",
//   googleMapCenter: { lat: 13.751737360100705, lon: 100.49271488546331 },
//   ecg_url: "https://ecgsocket.aocv2.com/",
//   ws_url: "http://localhost:5055",
//   //ws_url: "https://aoc112socket.aocv2.com",
//   img_url: "https://aoc112.aocv2.com/",
//   imgUrl: "https://opera.aocv2.com",
//   alive_server_url: "http://localhost:5055",
//   // alive_server_url: "https://alivesocket.aocv2.com",
//   isencryption: false,
//   encdecPassword: "aocv2@tely360",
//   searchTimeout: 850,
//   timeout: {
//     snapTrackEcgTimeout: 1000,
//     errorNotificationTimeout: 2000,
//   },
//   firebase: {
//     apiKey: "AIzaSyDJ_TT0v3OZTSsg9KUCmUJSh4rRskF6YXc",
//     authDomain: "aoc-v2-1.firebaseapp.com",
//     projectId: "aoc-v2-1",
//     storageBucket: "aoc-v2-1.appspot.com",
//     messagingSenderId: "941228483485",
//     appId: "1:941228483485:web:ddc11f0e1402c3d6f4c574",
//     measurementId: "G-R6P0T29RTL",
//   },
// };

// export const environment = {
//   production: true,
//   languages: [
//     { code: "en", label: "English", shorthand: "en" },
//     { code: "th", label: "Thai", shorthand: "th" },
//   ],
//   apiUrl: "http://117.18.126.117:3000",
//   //apiUrl: "http://localhost:3000",
//   googleMapApiKey: "AIzaSyAWILNiGlWIeNXIdpdDyYYyT1GmdlSgT1k",
//   googleMapCenter: { lat: 13.751737360100705, lon: 100.49271488546331 },
//   //ecg_url: "https://ecgsocket.aocv2.com/",
//   ecg_url: "http://117.18.126.117:5000/",
//   //ws_url: "http://localhost:5055",
//   ws_url: "http://117.18.126.118:5050/",
//   img_url: "http://117.18.126.118:5000/",
//   imgUrl: "http://117.18.126.117:3000/",
//   alive_server_url: "http://117.18.126.118:5050",
//   // alive_server_url: "https://alivesocket.aocv2.com",
//   isencryption: false,
//   encdecPassword: "aocv2@tely360",
//   searchTimeout: 850,
//   timeout: {
//     snapTrackEcgTimeout: 1000,
//     errorNotificationTimeout: 2000,
//   },
//   firebase: {
//     apiKey: "AIzaSyDJ_TT0v3OZTSsg9KUCmUJSh4rRskF6YXc",
//     authDomain: "aoc-v2-1.firebaseapp.com",
//     projectId: "aoc-v2-1",
//     storageBucket: "aoc-v2-1.appspot.com",
//     messagingSenderId: "941228483485",
//     appId: "1:941228483485:web:ddc11f0e1402c3d6f4c574",
//     measurementId: "G-R6P0T29RTL",
//   },
// };

// export const environment = {
//   production: true,
//   languages: [
//     { code: "en", label: "English", shorthand: "en" },
//     { code: "th", label: "Thai", shorthand: "th" },
//   ],
//   apiUrl: "http://opera.aocv2.com:3000",
//   googleMapApiKey: "AIzaSyAWILNiGlWIeNXIdpdDyYYyT1GmdlSgT1k",
//   googleMapCenter: { lat: 13.751737360100705, lon: 100.49271488546331 },
//   ecg_url: "http://ecgsocket.aocv2.com:5000",
//   ws_url: "http://sam-gps.aocv2.com:5050/",
//   img_url: "http://sam.aocv2.com:5000/",
//   imgUrl: "http://opera.aocv2.com:3000/",
//   alive_server_url: "http://sam-gps.aocv2.com:5050",
//   isencryption: false,
//   encdecPassword: "aocv2@tely360",
//   searchTimeout: 850,
//   timeout: {
//     snapTrackEcgTimeout: 1000,
//     errorNotificationTimeout: 2000,
//   },
//   firebase: {
//     apiKey: "AIzaSyDJ_TT0v3OZTSsg9KUCmUJSh4rRskF6YXc",
//     authDomain: "aoc-v2-1.firebaseapp.com",
//     projectId: "aoc-v2-1",
//     storageBucket: "aoc-v2-1.appspot.com",
//     messagingSenderId: "941228483485",
//     appId: "1:941228483485:web:ddc11f0e1402c3d6f4c574",
//     measurementId: "G-R6P0T29RTL",
//   },
// };

export const environment = {
  production: true,
  languages: [
    { code: "en", label: "English", shorthand: "en" },
    { code: "th", label: "Thai", shorthand: "th" },
  ],
  apiUrl: "https://opera.aocv2.com",
  googleMapApiKey: "AIzaSyAWILNiGlWIeNXIdpdDyYYyT1GmdlSgT1k",
  googleMapCenter: { lat: 13.751737360100705, lon: 100.49271488546331 },
  ecg_url: "https://ecgsocket.aocv2.com",
  ws_url: "https://sam-gps.aocv2.com",
  img_url: "https://sam.aocv2.com/",
  imgUrl: "https://opera.aocv2.com/",
  alive_server_url: "https://sam-gps.aocv2.com",
  isencryption: false,
  encdecPassword: "aocv2@tely360",
  searchTimeout: 850,
  timeout: {
    snapTrackEcgTimeout: 1000,
    errorNotificationTimeout: 2000,
  },
  firebase: {
    apiKey: "AIzaSyDJ_TT0v3OZTSsg9KUCmUJSh4rRskF6YXc",
    authDomain: "aoc-v2-1.firebaseapp.com",
    projectId: "aoc-v2-1",
    storageBucket: "aoc-v2-1.appspot.com",
    messagingSenderId: "941228483485",
    appId: "1:941228483485:web:ddc11f0e1402c3d6f4c574",
    measurementId: "G-R6P0T29RTL",
  },
};
