import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

export interface ISignInCredentials {
  email: string;
  password: string;
  user_type: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  apiUrl: string = environment.apiUrl;
  imgUrl: string = environment.img_url;

  constructor(private http: HttpClient) {}

  signIn(email, password, user_type) {
    let apiParam = new HttpParams();
    const url = this.apiUrl + "/api/user_auth/signIn";
    apiParam = apiParam.append("email", email);
    apiParam = apiParam.append("password", password);
    apiParam = apiParam.append("user_type", user_type);

    return this.http.post(url, apiParam, { withCredentials: true });
  }

  getHospitalOngoingAmbulanceList(user_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    const url =
      this.apiUrl + "/api/userAmbulance/getHospitalOngoingAmbulanceList";
    return this.http.post(url, apiParam);
  }

  getUserAmbulanceList(
    page_size,
    current_page,
    search_value,
    request_type_value,
    hospital_ids,
    user_id
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);
    apiParam = apiParam.append("search_value", search_value);
    apiParam = apiParam.append("request_type_value", request_type_value);
    apiParam = apiParam.append("hospital_ids", hospital_ids);
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/userAmbulance/getUserAmbulanceList";
    return this.http.post(url, apiParam);
  }

  getHospitalsByUserList(user_id: any) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/user/getHospitalsByUserList";
    return this.http.post(url, apiParam);
  }
  getAmbulanceFromHospital(hospital_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("hospital_id", hospital_id);
    const url = this.apiUrl + "/api/communication/getAmbulanceFromHospital";
    return this.http.post(url, apiParam);
  }

  getAllAocZonesList() {
    const url = this.apiUrl + "/api/userAmbulance/getAllAocZonesList";
    return this.http.post(url, {});
  }

  getHospitalsList(zoneId) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("zone_id", zoneId);
    const url = this.apiUrl + "/api/userAmbulance/getHospitalsList";
    return this.http.post(url, apiParam);
  }

  postAmbulanceRequest(formValue) {
    let apiParam = new HttpParams();
    for (const property in formValue) {
      apiParam = apiParam.append(property, formValue[property]);
    }
    const url = this.apiUrl + "/api/requests/postAmbulanceRequest";
    return this.http.post(url, apiParam);
  }

  postAllDriversList(selectHospitalId) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("hospital_id", selectHospitalId);
    const url = this.apiUrl + "/api/requests/postDriversList";
    return this.http.post(url, apiParam);
  }
  postAllDoctorsList(selectHospitalId) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("hospital_id", selectHospitalId);
    const url = this.apiUrl + "/api/requests/postDoctorsList";
    return this.http.post(url, apiParam);
  }
  postAllNursesList(selectHospitalId) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("hospital_id", selectHospitalId);
    const url = this.apiUrl + "/api/requests/postNursesList";
    return this.http.post(url, apiParam);
  }
  postAllParamedicsList(selectHospitalId) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("hospital_id", selectHospitalId);
    const url = this.apiUrl + "/api/requests/postParamedicsList";
    return this.http.post(url, apiParam);
  }
  postAllAocErStaffsList(selectHospitalId) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("hospital_id", selectHospitalId);
    const url = this.apiUrl + "/api/requests/postAocErStaffsList";
    return this.http.post(url, apiParam);
  }
  postAllAocEmsStaffsList(selectHospitalId) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("hospital_id", selectHospitalId);
    const url = this.apiUrl + "/api/requests/postAocEmsStaffsList";
    return this.http.post(url, apiParam);
  }

  postMonitersList(ambulance_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    const url = this.apiUrl + "/api/requests/postMonitersList";
    return this.http.post(url, apiParam);
  }

  postGlassList(ambulance_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    const url = this.apiUrl + "/api/requests/postGlassList";
    return this.http.post(url, apiParam);
  }

  postFastTracksList() {
    const url = this.apiUrl + "/api/requests/postFastTracksList";
    return this.http.post(url, {});
  }

  patientRequestList(
    page_size,
    current_page,
    search_value,
    sort_value,
    user_id
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);
    apiParam = apiParam.append("search_value", search_value);
    apiParam = apiParam.append("sort_value", JSON.stringify(sort_value));
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/requests/patientRequestList";
    return this.http.post(url, apiParam);
  }

  ListUsers(
    user_id,
    page_size,
    current_page,
    search_value,
    sort_value,
    user_type
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);
    apiParam = apiParam.append("search_value", search_value);
    apiParam = apiParam.append("sort_value", JSON.stringify(sort_value));
    apiParam = apiParam.append("user_type", user_type);
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/user/ListUsers";
    return this.http.post(url, apiParam);
  }

  checkInOutChange(isactive, user_type, user_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("isactive", isactive);
    apiParam = apiParam.append("user_type", user_type);
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/user/checkInOutChange";
    return this.http.post(url, apiParam);
  }

  getAmbPatientReq(patient_requests) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("patient_request_id", patient_requests._id);
    apiParam = apiParam.append("zone_id", patient_requests.zone_id);
    const url = this.apiUrl + "/api/requests/getAmbPatientReq";
    return this.http.post(url, apiParam);
  }

  aocRequestHistory(
    user_id,
    sort_value,
    page_size,
    current_page,
    request_status,
    hospital_id,
    zone_id,
    request_type
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    apiParam = apiParam.append("sort_value", sort_value);
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);
    apiParam = apiParam.append("request_status", request_status);
    apiParam = apiParam.append("hospital_id", hospital_id);
    apiParam = apiParam.append("zone_id", zone_id);
    apiParam = apiParam.append("request_type", request_type);
    const url = this.apiUrl + "/api/requests/aocRequestHistory";
    return this.http.post(url, apiParam);
  }

  aocRequestStatusChangeHistory(
    user_id,
    page_size,
    current_page,
    hospital_id,
    ambulance_id
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);
    apiParam = apiParam.append("hospital_id", hospital_id);
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    const url = this.apiUrl + "/api/requests/aocRequestStatusChangeHistory";
    return this.http.post(url, apiParam);
  }

  aocOngoingRequestHistory(
    user_id,
    sort_value,
    page_size,
    current_page,
    request_status,
    hospital_id,
    zone_id,
    request_type
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    apiParam = apiParam.append("sort_value", sort_value);
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);
    apiParam = apiParam.append("request_status", request_status);
    apiParam = apiParam.append("hospital_id", hospital_id);
    apiParam = apiParam.append("zone_id", zone_id);
    apiParam = apiParam.append("request_type", request_type);
    const url = this.apiUrl + "/api/requests/aocOngoingRequestHistory";
    return this.http.post(url, apiParam);
  }

  AocRequestsList(user_id, sort_value, search_value, request_status) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    apiParam = apiParam.append("sort_value", sort_value);
    apiParam = apiParam.append("search_value", search_value);
    apiParam = apiParam.append("request_status", request_status);
    const url = this.apiUrl + "/api/requests/AocRequestsList";
    return this.http.post(url, apiParam);
  }

  getTotalAocRequest(user_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/requests/getTotalAocRequest";
    return this.http.post(url, apiParam, { withCredentials: true });
  }

  getAmbulanceSnapShotsDetail(ambulance_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    const url = this.apiUrl + "/api/userAmbulance/getAmbulanceSnapShotsDetail";
    return this.http.post(url, apiParam);
  }

  getAmbulanceTrackingDetail(ambulance_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    const url = this.apiUrl + "/api/userAmbulance/getAmbulanceTrackingDetail";
    return this.http.post(url, apiParam);
  }
  getAmbulanceEcgDetail(ambulance_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    const url = this.apiUrl + "/api/userAmbulance/getAmbulanceEcgDetail";
    return this.http.post(url, apiParam);
  }
  getAmbulanceSnapTrackEcgDetail(ambulance_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    const url =
      this.apiUrl + "/api/userAmbulance/getAmbulanceSnapTrackEcgDetail";
    return this.http.post(url, apiParam);
  }

  getAllAocZonesLists() {
    const url = this.apiUrl + "/api/report/getAllAocZonesList";
    return this.http.post(url, {});
  }

  getZoneHospitalsList(zone_id, user_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("zone_id", zone_id);
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/report/getZoneHospitalsList";
    return this.http.post(url, apiParam);
  }

  getHospitalAmbulancesList(hospital_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("hospital_id", hospital_id);
    const url = this.apiUrl + "/api/report/getHospitalAmbulancesList";
    return this.http.post(url, apiParam);
  }

  postAmbulanceSpeedReport(
    start_date,
    end_date,
    ambulance_id,
    hospital_id,
    speed_limit,
    page_size,
    current_page
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("start_date", start_date);
    apiParam = apiParam.append("end_date", end_date);
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    apiParam = apiParam.append("hospital_id", hospital_id);
    apiParam = apiParam.append("speed_limit", speed_limit);
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);

    const url = this.apiUrl + "/api/report/postAmbulanceSpeedReport";
    return this.http.post(url, apiParam);
  }

  postAocRequestStatusChange(
    aoc_request_id,
    request_status,
    hospital_id,
    user_id,
    request_type
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("aoc_request_id", aoc_request_id);
    apiParam = apiParam.append("request_status", request_status);
    apiParam = apiParam.append("hospital_id", hospital_id);
    apiParam = apiParam.append("user_id", user_id);
    apiParam = apiParam.append("request_type", request_type);
    const url = this.apiUrl + "/api/userAocRequest/postAocRequestStatusChange";
    return this.http.post(url, apiParam);
  }

  getZoneHospitalsLists(aoc_request_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("aoc_request_id", aoc_request_id);
    const url = this.apiUrl + "/api/userAocRequest/getZoneHospitalsList";
    return this.http.post(url, apiParam);
  }

  getUserHospital(user_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/user/getUserHospital";
    return this.http.post(url, apiParam);
  }

  postAmbulanceCrashReport(
    start_date,
    end_date,
    zone_id,
    hospital_id,
    user_id,
    page_size,
    current_page
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("start_date", start_date);
    apiParam = apiParam.append("end_date", end_date);
    apiParam = apiParam.append("zone_id", zone_id);
    apiParam = apiParam.append("hospital_id", hospital_id);
    apiParam = apiParam.append("user_id", user_id);
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);

    const url = this.apiUrl + "/api/report/postAmbulanceCrashReport";
    return this.http.post(url, apiParam);
  }

  postAmbulanceCrashDetail(ambulance_crash_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("ambulance_crash_id", ambulance_crash_id);
    const url = this.apiUrl + "/api/report/postAmbulanceCrashDetail";
    return this.http.post(url, apiParam);
  }

  postConfirmAmbulanceCrash(ambulance_crash_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("ambulance_crash_id", ambulance_crash_id);
    const url = this.apiUrl + "/api/report/postConfirmAmbulanceCrash";
    return this.http.post(url, apiParam);
  }

  postSeverityColorCodesList() {
    const url = this.apiUrl + "/api/requests/postSeverityColorCodesList";
    return this.http.post(url, {});
  }

  postAmbulanceSpeedReportDetail(
    speed_above_type,
    speed_above_id,
    page_size,
    current_page
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("speed_above_type", speed_above_type);
    apiParam = apiParam.append("speed_above_id", speed_above_id);
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);
    const url = this.apiUrl + "/api/report/postAmbulanceSpeedReportDetail";
    return this.http.post(url, apiParam);
  }

  postPatientReqAmbulance(patient_request_id, ambulance_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("patient_request_id", patient_request_id);
    apiParam = apiParam.append("ambulance_id", ambulance_id);
    const url = this.apiUrl + "/api/requests/postPatientReqAmbulance";
    return this.http.post(url, apiParam);
  }
  postPatientReqCancelRequest(aoc_requests_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("aoc_requests_id", aoc_requests_id);
    const url = this.apiUrl + "/api/requests/postPatientReqCancelRequest";
    return this.http.post(url, apiParam);
  }

  AmbulanceSnapTrackEcgDetail(aoc_request_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("aoc_request_id", aoc_request_id);
    const url = this.apiUrl + "/api/social/getAmbulanceSnapTrackEcgDetail";
    return this.http.post(url, apiParam);
  }

  postAliveRequestDetail(aoc_request_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("aoc_request_id", aoc_request_id);
    const url = this.apiUrl + "/api/userAocRequest/postAliveRequestDetail";
    return this.http.post(url, apiParam);
  }

  postAocRequestStatusRevert(
    aoc_request_id,
    request_status,
    hospital_id,
    user_id
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("aoc_request_id", aoc_request_id);
    apiParam = apiParam.append("request_status", request_status);
    apiParam = apiParam.append("hospital_id", hospital_id);
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/userAocRequest/postAocRequestStatusRevert";
    return this.http.post(url, apiParam);
  }

  updateUserNotification(
    notification_type,
    notification_data,
    user_id,
    user_type
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("notification_type", notification_type);
    apiParam = apiParam.append("notification_data", notification_data);
    apiParam = apiParam.append("user_id", user_id);
    apiParam = apiParam.append("user_type", user_type);
    const url = this.apiUrl + "/api/userNotification/updateUserNotification";
    return this.http.post(url, apiParam);
  }

  getUserNotification(user_id) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/userNotification/getUserNotification";
    return this.http.post(url, apiParam);
  }

  postAocRequestDetailUpdate(aoc_requests_id, request_note) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("aoc_requests_id", aoc_requests_id);
    apiParam = apiParam.append("request_note", request_note);
    const url = this.apiUrl + "/api/userAocRequest/postAocRequestDetailUpdate";
    return this.http.post(url, apiParam);
  }

  postUserMonitorList(
    page_size,
    current_page,
    search_value,
    sort_value,
    user_id
  ) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("page_size", page_size);
    apiParam = apiParam.append("current_page", current_page);
    apiParam = apiParam.append("search_value", search_value);
    apiParam = apiParam.append("sort_value", JSON.stringify(sort_value));
    apiParam = apiParam.append("user_id", user_id);
    const url = this.apiUrl + "/api/report/postUserMonitorList";
    return this.http.post(url, apiParam);
  }

  postUserMonitorReportDetail(start_date, end_date, serial_number) {
    let apiParam = new HttpParams();
    apiParam = apiParam.append("start_date", "2021-10-21T06:29:25.851Z");
    apiParam = apiParam.append("end_date", "2021-10-22T06:30:25.851Z");
    apiParam = apiParam.append("serial_number", "128996000749");
    const url = this.apiUrl + "/api/report/postUserMonitorReportDetail";
    return this.http.post(url, apiParam);
  }
}
