<div class="content-wrapper">
  <div class="row">
      <div class="col-md-12">
          <div class="card main-card mb-3">
              <div class="card-header-tab card-header">
                  <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                      <i class="header-icon fa fa-television"> </i>
                      {{'titles.ecg_report_detail' | translate}}
                  </div>
              </div>
              <div class="no-gutters row">
                  <div class="card-body">
                      <div class="header-filter">
                          <div class="row align-items-center">
                              <div class="col-md-4">
                                  <div class="form-group input-group position-relative">
                                      <input class="form-control" type="text" name="daterange" id="daterange"
                                          value=" " />
                                  </div>
                              </div>
                              <div class="col-md-4">
                                  <button type="submit" class="btn btn-primary mb-3 mr-1"
                                      (click)="onSubmit()">{{'buttons.submit' | translate}}</button>
                                  <button type="submit" (click)="pdf()" class="btn btn-info mb-3">{{'buttons.pdf' | translate}}</button>
                              </div>
                          </div>
                      </div>
                      <div class="page-body historyTable" style="overflow-x: scroll;">
                          <div class="container-fluid" [style.width.%]="width">
                              <div class="row" style="width: 100%;">
                                  <div class="col-md-12 p-0"
                                      style="display: inline-block; vertical-align: top; background:rgb(0, 0, 0); width: 100%;">
                                      <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%; width: 100%;"
                                          id="moniter_serial_number">Loading...</p>
                                  </div>
                              </div>
                              <div class="row" style="background:rgb(0, 0, 0);">
                                <!-- <div class="col-md-3"> -->
                                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%; width: 100px;">ECGII</p>

                                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%; width: 100px;" id="ecgii_data_length">0</p>
                                <!-- </div>
                                <div class="col-md-3"> -->
                                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%; width: 100px;">SPO2</p>

                                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%; width: 100px;" id="spo2_data_length">0</p>
                                <!-- </div> -->
                            </div>
                              <div class="row" style="width: 100%;">
                                  <div id="chartContainer2" class="col-12 p-0" style="height: 120px; width: 100%;">
                                  </div>
                                  <div id="chartContainer4" class="col-12 p-0" style="height: 120px; width: 100%;">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="divider m-0 d-md-none d-sm-block"></div>
              </div>
          </div>
      </div>
  </div>
</div>