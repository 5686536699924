import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.sass']
})
export class UserprofileComponent implements OnInit {
  editsForm: FormGroup;
  constructor() { }
  editableBoolean : boolean = false;
  imagefile: any;
  url0: any;
  imgURL: any = ['/assets/images/AOC_LOGO.png'];
  selectEdituser = {
    _id:'',
    userProfile:'',
    name: 'Dhaval Purohit', 
    age: '30',
    gender: 'male',
    email:'roath@gmail.com',
    phone:'9582256585',
    password:'********',
  }
  ngOnInit(): void {

    this.editsForm = new FormGroup({
      _id:new FormControl(null),
      userProfile:new FormControl(null),
      name:new FormControl(null),
      age: new FormControl(null),
      gender:new FormControl(null),
      email:new FormControl(null),
      phone:new FormControl(null),
      password:new FormControl(null),
    })
  }
  edit(){
    this.editableBoolean = true;
  }

  onSubmit(){
    this.editableBoolean = false;
  }

  onFileChange(event) {
    //new added
    this.imagefile = (event.target as HTMLInputElement).files[0];
    this.imgURL = this.imagefile;
    var filesUrl = (event.target as HTMLInputElement).files;

    if (filesUrl) {
      var reader = new FileReader();
      reader.onload = (e: any) => {
        this.url0 = e.target.result;
      }
      reader.readAsDataURL(filesUrl[0]);
    }
    //end
  }

}
