import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../Services/auth.service';
import { ToastService } from '../Services/toast.service'


@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    
    constructor(private angularFireMessaging: AngularFireMessaging, private apiService: AuthService,private cookieService: CookieService, private toastService: ToastService) {
    this.angularFireMessaging.messages.subscribe(
        (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        })
        
    }
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
            this.cookieService.set('token', String(token));

            let user_id = this.cookieService.get('user_id');
            // if(user_id) {
            //     this.apiService.postFCMTokenUpdate(token, user_id).subscribe((data) => {
            //       console.log('postFCMTokenUpdate',data);
            //     })
            // }
        },(err) => {
            console.error('Unable to get permission to notify.', err);
        });
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
        (payload) => {
            // console.log("new message received. ", payload);
            let data = payload['notification'];
            let content = JSON.parse(payload['data']['content'])
            // console.log("content. message", content);
            // this.currentMessage.next(payload);
            this.toastService.show(data.title + ',                ' + data.body, { classname: 'bg-sky-blue text-white', delay:3000, allowHtml: true});
        })
    }
}