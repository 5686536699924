import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../../Services/auth.service';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../../Services/toast.service';
import { environment } from 'src/environments/environment';
declare var $: any;
import * as CanvasJS from './canvasjs.min';
import { Subscription } from 'rxjs';
import { EcgService } from 'src/app/Services/ecg.service';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var PDFDocument: any;
declare var blobStream: any;

pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Regular.ttf',
    regular: 'Roboto-Regular.ttf',
    medium: 'Roboto-Regular.ttf',
    italics: 'Roboto-Regular.ttf',
    bolditalics: 'Roboto-Regular.ttf'
  }
};

@Component({
  selector: 'app-ecg-report-detail',
  templateUrl: './ecg-report-detail.component.html',
  styleUrls: ['./ecg-report-detail.component.sass']
})
export class EcgReportDetailComponent implements OnInit {
  ecgReportForm: FormGroup;
  startDate;
  endDate;
  ecgId;
  errTime = environment.timeout.errorNotificationTimeout;
  private ecgData: Subscription;
  moniterDetail: any;
  chartbackgroundColor = '#000000';
  stripLineWidth = 10;
  axisYgridThickness = 0.2;
  chart2Interval: any;
  chart4Interval: any;
  dps2 = [];
  dps4 = [];
  lineThickness = 1.5;
  xAxisInterval = 4;
  yAxisInterval = 14;

  chart2: any;
  chart2StripLinesValue = 0;
  chart2Max = 255;
  chart2Min = -140;
  chart2xVal = 0;
  chart2updateInterval = 1;
  chart2dataLength = 800;

  chart4: any;
  chart4StripLinesValue = 0;
  chart4Max = 255;
  chart4Min = -80;
  chart4xVal = 0;
  chart4updateInterval = 30;
  chart4dataLength = 400;

  text_ECG_II = 'EcgII';
  text_ECG_SPO2 = 'Spo2';

  ECG_II = [];
  ECG_II_data = [];
  ECG_SPO2 = [];
  ECG_SPO2_data = [];
  dps = [];

  yAxisStripLinesArray = [];
  xAxisStripLinesArray = [];

  dataPointsArray = [];

  width = 100;
  colunm: number;
  multiplier: number = 1;

  constructor(private spinner: NgxSpinnerService, private apiService: AuthService, private cookieService: CookieService, private toastService: ToastService, private ecgService: EcgService) { }

  ngOnInit(): void {
    this.datePickerFun();

    this.moniterDetail = JSON.parse(localStorage.getItem('ecgDetails'));
    // document.getElementById("moniter_serial_number").innerHTML = String(this.moniterDetail.serial_number);

    this.chart2 = new CanvasJS.Chart("chartContainer2", {
      backgroundColor: this.chartbackgroundColor,
      title: {
        text: "ECG II",
        fontColor: "#FFFFFF"
      },
      axisX: {
        stripLines: [{
          value: this.chart2StripLinesValue,
          thickness: this.stripLineWidth,
          color: "#000000",
          showOnTop: true
        }],
        valueFormatString: "####"
      },
      axisY: {
        gridColor: "#000000",
        // maximum: this.chart2Max, 
        // minimum: this.chart2Min,
      },
      data: [{
        type: "line",
        color: "#00FF00",
        markerSize: 0,
        dataPoints: this.dps2,
        lineThickness: this.lineThickness
      }]
    });

    this.chart4 = new CanvasJS.Chart("chartContainer4", {
      backgroundColor: this.chartbackgroundColor,
      title: {
        text: "SPO2",
        fontColor: "#FFFFFF"
      },
      axisX: {
        stripLines: [{
          value: this.chart4StripLinesValue,
          thickness: this.stripLineWidth,
          color: "#000000",
          showOnTop: true
        }],
        valueFormatString: "####"
      },
      axisY: {
        gridColor: "#000000",
        maximum: this.chart4Max,
        minimum: this.chart4Min,
      },
      data: [{
        type: "line",
        color: "#0000FF",
        markerSize: 0,
        dataPoints: this.dps4,
        lineThickness: this.lineThickness
      }]
    });

    this.chart2.render();
    this.chart4.render();

    for (let i = 0; i <= this.chart2dataLength; i++) {
      this.chart2updateStripLine();
    }

    for (let i = 0; i <= this.chart4dataLength; i++) {
      this.chart4updateStripLine();
    }
  }

  datePickerFun() {
    var self = this;
    $('#daterange').daterangepicker({
      timePicker: true,
      locale: {
        format: 'MM/DD/YYYY hh:mm A'
      },
      opens: 'left',
    }, function (start, end) {
      self.startDate = start;
      self.endDate = end;
    });
  }

  onSubmit() {
    this.spinner.show();
    // this.startDate = new Date(this.startDate).toISOString();
    // this.endDate = new Date(this.endDate).toISOString();

    this.apiService.postUserMonitorReportDetail(this.startDate, this.endDate, this.ecgId).subscribe((data: any) => {
      this.spinner.hide();
console.log(data);

      if (data.success) {
        this.ECG_II = [];
        this.chart2StripLinesValue = 0;
        this.chart2xVal = 0;

        this.ECG_SPO2 = [];
        this.chart4StripLinesValue = 0;
        this.chart4xVal = 0;

        data = JSON.parse(data.content)

        data.forEach((e, i) => {
          this.dataPlotting(JSON.stringify(e.Data));
          if (data.length == (i + 1)) {
            this.ECG_II_data = this.ECG_II.slice();
            this.ECG_SPO2_data = this.ECG_SPO2.slice();
            this.setChartMaxMin(String(this.moniterDetail.type));
          }
        })
      }
    }, err => {
      if (err.error && err.error.message) {
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
      } else {
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  chart2dataEle = 0;
  chart2updateStripLine() {
    if (this.chart2xVal > this.chart2dataLength) {
      this.chart2StripLinesValue = 0;
      this.chart2xVal = 0;
      if (this.ECG_II.length == 0) clearInterval(this.chart2Interval);
    } else {
      this.chart2.options.data[0].dataPoints[this.chart2xVal] = {
        x: this.chart2xVal,
        y: (this.ECG_II[0]) ? (this.ECG_II[0]) : 0
      };
      if (this.ECG_II.length) {
        // this.ECG_II_data.push((this.ECG_II[0]) ? (this.ECG_II[0]) : 0)
        this.ECG_II.shift();
      }
      this
      this.chart2xVal++
    }

    this.chart2.axisX[0].stripLines[0].set("value", (this.chart2StripLinesValue++));
  }

  chart4dataEle = 0;
  chart4updateStripLine() {
    if (this.chart4xVal > this.chart4dataLength) {
      this.chart4StripLinesValue = 0;
      this.chart4xVal = 0;
      if (this.ECG_SPO2.length == 0) clearInterval(this.chart4Interval);
    } else {
      this.chart4.options.data[0].dataPoints[this.chart4xVal] = {
        x: this.chart4xVal,
        y: (this.ECG_SPO2[0]) ? (this.ECG_SPO2[0]) : 0
      };

      if (this.ECG_SPO2.length) this.ECG_SPO2.shift();
      if (this.ECG_SPO2.length) {
        // this.ECG_SPO2_data.push((this.ECG_SPO2[0]) ? (this.ECG_SPO2[0]) : 0)
        this.ECG_SPO2.shift();
      }
      this.chart4xVal++
    }

    this.chart4.axisX[0].stripLines[0].set("value", (this.chart4StripLinesValue++));
  }

  dataPlotting(rowdata) {

    let dataStr = rowdata.toString();

    dataStr = dataStr.trim('\n');
    dataStr = dataStr.replaceAll('}', '}abc');
    dataStr = dataStr.replaceAll('}abc}abc', '}}abc');
    dataStr = dataStr.replaceAll('abc]}abc', ']}');
    dataStr = dataStr.replaceAll('}abc]', '}]');
    dataStr = dataStr.replaceAll('abc,', ',');
    var dataArray = dataStr.split('abc');

    dataArray = dataArray.filter(function (d) { return d != '' });
    if (dataArray.length) {
      dataArray.forEach(d => {
        if (d !== "") {
          d = d.trim('\n');
          try {
            let data = JSON.parse(d);
            switch (String(data.Key)) {
              case this.text_ECG_II:
                try {
                  let Value = (data.Value);
                  let ValueArray = Value.split(',');
                  if (ValueArray.length) {
                    ValueArray.forEach(V => {
                      if ((String(this.moniterDetail.type) == 'BLT') && (Number(V) > 30)) this.ECG_II.push(Number(V));
                      else if ((String(this.moniterDetail.type) == 'DGT7')) this.ECG_II.push(Number(V));
                    })
                  }
                } catch (err) {
                  // console.log(this.text_ECG_II + ' Data Parse ERROR: ', err)
                }
                break;

              case this.text_ECG_SPO2:
                try {
                  var Value = (data.Value);
                  var ValueArray = Value.split(',');
                  if (ValueArray.length) {
                    ValueArray.forEach(V => {
                      this.ECG_SPO2.push(Number(V));
                    })
                  }
                } catch (err) {
                  // console.log(this.text_ECG_SPO2 + ' Data Parse ERROR: ', err);
                }
                break;

              default:
                break;
            }
          } catch (err) {
            // console.log('Data Parse ERROR: ', err)
          }
        }
      });
    }
  };

  setChartMaxMin(type: string) {
    if (type == 'BLT') {
      this.chart2dataLength = 1200;
      this.chart4dataLength = 900;

      this.multiplier = ((this.ECG_II).length > this.chart2dataLength) ? (Math.ceil((this.ECG_II).length / this.chart2dataLength)) : (this.width);
      this.width = (100 * this.multiplier);
      this.chart2dataLength = (this.chart2dataLength * this.multiplier);
      this.chart4dataLength = (this.chart4dataLength * this.multiplier);
      // console.log(this.chart2dataLength, this.chart4dataLength, this.width, this.multiplier);

      // for (let i = 0; i < this.chart2dataLength; i++) {
      //   this.chart2updateStripLine();
      // }
      // setTimeout(() => {
        this.chart2Interval = setInterval(() => {
          this.chart2updateStripLine();
          document.getElementById("ecgii_data_length").innerHTML = String(this.ECG_II.length);
        }, this.chart2updateInterval);
      // }, this.chart2dataLength)

      // for (let i = 0; i < this.chart4dataLength; i++) {
      //   this.chart4updateStripLine();
      // }
      // setTimeout(() => {
        this.chart4Interval = setInterval(() => {
          this.chart4updateStripLine();
          document.getElementById("spo2_data_length").innerHTML = String(this.ECG_SPO2.length);
        }, this.chart4updateInterval);
      // }, this.chart4dataLength);
    } else {
      this.chart2dataLength = 600;
      this.chart4dataLength = 400;

      // for (let i = 0; i < this.chart2dataLength; i++) {
      //   this.chart2updateStripLine();
      // }
      // setTimeout(() => {
        this.chart2Interval = setInterval(() => {
          this.chart2updateStripLine();
          document.getElementById("ecgii_data_length").innerHTML = String(this.ECG_II.length);
        }, this.chart2updateInterval);
      // }, this.chart2dataLength)

      // for (let i = 0; i < this.chart4dataLength; i++) {
      //   this.chart4updateStripLine();
      // }
      // setTimeout(() => {
        this.chart4Interval = setInterval(() => {
          this.chart4updateStripLine();
          document.getElementById("spo2_data_length").innerHTML = String(this.ECG_SPO2.length);
        }, this.chart4updateInterval);
      // }, this.chart4dataLength);
    }
  }

  pdf() {
    this.toastService.show("PDF will generate soon, please wait !", { classname: 'bg-info text-white', delay: this.errTime });
    var doc = new PDFDocument({size: 'RA2'});
    var stream = doc.pipe(blobStream());

    addPage(0, this.chart2dataLength, this.chart4dataLength, this.multiplier, this.ECG_II_data, this.ECG_SPO2_data);

    function addPage(page: number, chart2dataLength: number, chart4dataLength: number, multiplier: number, ECG_II_data: any[], ECG_SPO2_data: any[]) {
        for (let i = 1; i <= 241; i++) {
          if (((i - 1) % 10 == 0)) {
            doc.moveTo(i * 5, 5).lineTo(i * 5, 400).dash(0, { space: 0 }).lineWidth(1.1).strokeColor('black').stroke();
          } else {
            if ((i - 1) % 5 == 0) {
              doc.moveTo(i * 5, 5).lineTo(i * 5, 400).dash(5, { space: 3 }).lineWidth(0.01).strokeColor('#808080').stroke();
            } else {
              doc.moveTo(i * 5, 5).lineTo(i * 5, 400).dash(0, { space: 0 }).lineWidth(0.01).strokeColor('#EB0102').stroke();
            }
          }
        }

        for (let i = 1; i <= 80; i++) {
          if (i % 10 == 0 || i == 1) {
            doc.moveTo(5, i * 5).lineTo(1205, i * 5).dash(0, { space: 0 }).lineWidth(1.1).strokeColor('black').stroke();
          } else {
            if (i % 5 == 0) {
              doc.moveTo(5, i * 5).lineTo(1205, i * 5).dash(5, { space: 3 }).lineWidth(0.01).strokeColor('#808080').stroke()
            } else {
              doc.moveTo(5, i * 5).lineTo(1205, i * 5).dash(0, { space: 0 }).lineWidth(0.01).strokeColor('#EB0102').stroke();
            }
          }
        }

        new Promise(async (resolve, reject) => {
          doc.moveTo(5, 100);
          for (let i = 0; i < ((chart2dataLength/multiplier)-6);) {
            let x = i + 6;
            let ind = page*(chart2dataLength/multiplier) + i;
            doc.quadraticCurveTo(x, (ECG_II_data[ind])?(100 - (ECG_II_data[ind]/2)):100, x + 1, (ECG_II_data[ind+1])?(100 - (ECG_II_data[ind+1]/2)):100);
            i += (1200/(chart2dataLength/multiplier));
          }
          doc.lineTo(1205, 100).strokeColor('green').stroke();
          resolve(0);
        }).then((e) => {
          let v = 0
          doc.moveTo(5, 300);
          for (let i = 0; i <= ((chart2dataLength/multiplier)-6);) {
            let x = i + 6;
            let ind = page*(chart4dataLength/multiplier) + v;
            doc.quadraticCurveTo(x, (ECG_SPO2_data[ind])?(300 - (ECG_SPO2_data[ind]/2)):300, x + (1200/(chart4dataLength/multiplier)), (ECG_SPO2_data[ind+1])?(300 - (ECG_SPO2_data[ind+1]/2)):300);
            i += (1200/(chart4dataLength/multiplier));
            v += 1
          }
          doc.lineTo(1205, 300).strokeColor('blue').stroke();
          return;
        }).then(() => {
          if(multiplier == (page+1)) {
            doc.end();
      
            var saveData = (function () {
              var a = document.createElement("a");
              document.body.appendChild(a);
                a.style.display = "none";
                return function (blob, fileName) {
                  var url = window.URL.createObjectURL(blob);
                  a.href = url;
                  a.download = fileName;
                  a.click();
                  window.URL.revokeObjectURL(url);
                };
            }());
      
            stream.on('finish', function () {
              var blob = stream.toBlob('application/pdf');
              saveData(blob, 'aa.pdf');
            });
          }else {
            doc.addPage();
            addPage(page+1, chart2dataLength, chart4dataLength, multiplier, ECG_II_data, ECG_SPO2_data);
          }
        })
    }
  }

  ngOnDestroy() {
    this.ECG_II = [];
    this.ECG_SPO2 = [];
    this.ecgData.unsubscribe();
    clearInterval(this.chart2Interval);
    clearInterval(this.chart4Interval);
  }

}