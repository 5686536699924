<div #content class="modalopen">
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    
    <div class="crashView">
      <div class="row">
        <div class="col-md-12">
            <div class="crashImg">
              <img onerror="this.src='/assets/images/offline_stb.jpg'" [src]="imgPath + ambulanceCrashData?.ambulanceImageHistoriesDetail?.images?.image0" alt="No Image Found" class="img-fluid">
              <img onerror="this.src='/assets/images/offline_stb.jpg'" [src]="imgPath + ambulanceCrashData?.ambulanceImageHistoriesDetail?.images?.image1" alt="No Image Found" class="img-fluid">
            </div>
            <div class="staticMap">
              <img onerror="this.src='/assets/images/defaultmap.png'" [src]="mapImg" alt="map" class="img-fluid">
              <div class="crashDetails">
                  <div class="row mb-2">
                    <div class="col-md-12">
                      <span class="hospitalname">{{ambulanceCrashData?.hospitalsDetail?.full_name}}</span>
                      <span class="req_status">{{ambulanceCrashData?.aocRequestsDetail?.request_status}}</span>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-12">
                      <span class="req_code_title">{{'labels.request_code' | translate}}</span>
                      <span class="req_code">{{ambulanceCrashData?.aocRequestsDetail?.request_code}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="address_hospital">
                        {{ambulanceCrashData?.location_detail?.address}}
                      </span>
                      <span class="crash_date">
                        {{ambulanceCrashData?.createdAt | date:'medium'}}
                       </span>
                    </div>
                  </div>
              </div>
              <button class="btn text-uppercase crashbtn" (click) = "confirmCrash()">
                {{'buttons.confirm_crash' | translate}}
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>