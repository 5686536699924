import {Component, HostBinding, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import {ThemeOptions} from '../../../theme-options';
import { AuthService } from '../../../Services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../../../Services/toast.service'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit{
  errTime =  environment.timeout.errorNotificationTimeout;
  faEllipsisV = faEllipsisV;
  current_datetime:any;
  hospital_id;
  totalAocRequest;
  user_id;
  userNotificationList: any;
  isSwitchedOn:boolean;
  constructor(public globals: ThemeOptions, private apiService: AuthService, private cookieService : CookieService,  private toastService : ToastService) {
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;


  @select('config') public config$: Observable<any>;
  
  ngOnInit() {   
    this.user_id = this.cookieService.get('user_id');
    this.getTotalAocRequest();
    // this.getUserNotification();
    setInterval(() => {
      this.current_datetime = new Date(Date.now());
    }, 1000);
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }



  getTotalAocRequest(){
    this.apiService.getTotalAocRequest(this.user_id).subscribe((data) => {
      this.totalAocRequest = data['content']['data'];
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  updateUserNotification(event, type, notifType){
  
    let notification_type = notifType
    let notification_data = event
    let user_type =  type

    this.apiService.updateUserNotification(notification_type, notification_data,this.user_id, user_type).subscribe((data) => {
         },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }

  getUserNotification(){
    this.apiService.getUserNotification(this.user_id).subscribe((data) => {
      this.userNotificationList = data;
    },err=>{
      if(err.error && err.error.message){
        this.toastService.show(err.error.message + `<br>` + "status code:" + err.status, { classname: 'bg-danger text-white', delay: this.errTime });
        } else{
        this.toastService.show(err.message, { classname: 'bg-danger text-white', delay: this.errTime });
      }
    })
  }
}
