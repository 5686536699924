import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  // private _listners = new Subject<any>();

  //   listen(): Observable<any> {
  //      return this._listners.asObservable();
  //   }

  //   filter(filterBy: string) {
  //      this._listners.next(filterBy);
  //   }
  invokeFirstComponentFunction = new EventEmitter();    
  subsVar: Subscription;    
    
  constructor() { }    
    
  onFirstComponentButtonClick(acceptReqDetail) {
    let detail = {acceptReqDetail};
    this.invokeFirstComponentFunction.emit(detail);    
  }   

  secondData = new EventEmitter();
  secondButtonclickdData(secondDetails){
    let detail2 = {secondDetails};
    this.secondData.emit(detail2);    
  }
}
