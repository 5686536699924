<div class="content-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="card main-card mb-3">
        <div class="card-header-tab card-header">
          <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon fa fa-bell"> </i>
            {{'titles.crashreport' | translate}}
          </div>
        </div>
        <div class="no-gutters row">
          <div class="card-body">
            <form [formGroup]="crashReportForm" #form="ngForm" (ngSubmit)="onSubmit(form.value)">
              <input type="hidden" class="form-control" [(ngModel)]="selectCrashreport._id" formControlName="_id" name="_id">

              <div class="header-filter">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <div class="form-group input-group position-relative">
                      <input  class="form-control" placeholder="select Date" formControlName="range_date" type="text" name="range_date" id="daterange" value=" "/>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group position-relative">
                      <select name="zone_id" id="zoneId" [(ngModel)]="selectCrashreport.zone_id" formControlName="zone_id" class="form-control" (change)="zoneChange($event)">
                        <option value="" selected>{{'labels.selectzone' | translate}}</option>
                        <option value="{{zone._id}}" *ngFor="let zone of zoneList">{{zone.name1}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group position-relative">
                      <select name="hospital_id" id="destination" class="form-control" [(ngModel)]="selectCrashreport.hospital_id" formControlName="hospital_id">
                        <option value="" selected>{{'labels.selectHospital' | translate}}</option>
                        <option value="{{hospital._id}}"  *ngFor="let hospital of hospitalList">{{hospital.name1}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button type="submit" [disabled]="dateBoolean" class="btn btn-primary mb-3">{{'buttons.submit' | translate}}</button>
                  </div>
              </div>
              </div>
            </form>
            <div class="page-body notificaitionTable">
              <div class="paginations">
                <ngb-pagination [collectionSize]="collectionsize" (pageChange)="onPageChange($event)" [pageSize]="page_size" [(page)]="page" [rotate] = "true" [maxSize]="5" [boundaryLinks]="true" aria-label="Default pagination"></ngb-pagination>
              </div>
              <div class="table-responsive">
                <table class="table table-striped row-border">
                  <thead>
                    <tr>
                      <th style="width:5%;">{{'labels.id' | translate}}</th>
                      <th style="width:10%;">{{'labels.boxcode' | translate}}</th>
                      <th style="width:15%;">{{'labels.name' | translate}}</th>
                      <th style="width:10%;">{{'labels.time' | translate}}</th>
                      <th style="width:22%;">{{'labels.map' | translate}}</th>
                      <th>{{'labels.photo1' | translate}}</th>
                      <th>{{'labels.photo2' | translate}}</th>
                      <th style="width:15%; text-align: center;">{{'labels.visibility_mobile' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="no-data" *ngIf="crashtList?.length == 0">
                      <td colspan="8">{{'error.nodata' | translate}}</td>
                    </tr>
                   <tr *ngFor="let detail of crashtList; let i = index">
                     <td>{{((current_page-1)*page_size + 1) + i}}</td>
                     <td>
                      <span *ngIf="!detail?.ambulance_box_code">N/A</span>
                      <span *ngIf="detail?.ambulance_box_code">
                        {{detail?.ambulance_box_code}}
                      </span>
                     <td>
                      <span *ngIf="!detail?.name1">N/A</span>
                      <span *ngIf="detail?.name1">
                        {{detail?.name1}} {{detail?.name2}}
                      </span>
                      </td>
                     <td>
                      <span *ngIf="!detail?.ambulanceCrashesDetail?.createdAt">N/A</span>
                       <span *ngIf="detail?.ambulanceCrashesDetail?.createdAt">
                        {{detail?.ambulanceCrashesDetail?.createdAt | date:'shortTime'}}<br>
                        {{detail?.ambulanceCrashesDetail?.createdAt | date:'shortDate'}}
                       </span>
                      </td>
                     <td>
                      <span *ngIf="!detail?.ambulanceCrashesDetail?.location_detail?.address">N/A</span>
                      <span>{{detail?.ambulanceCrashesDetail?.location_detail?.address}}</span>
                     </td>
                     <td>
                      <img onerror="this.src='/assets/images/offline_stb.jpg'" [src]="imgPath + detail?.ambulanceCrashesDetail?.ambulanceImageHistoriesDetail?.images?.image0" alt="No Image Found" class="img-fluid">
                     </td>
                     <td>
                      <img onerror="this.src='/assets/images/offline_stb.jpg'" [src]="imgPath + detail?.ambulanceCrashesDetail?.ambulanceImageHistoriesDetail?.images?.image1" alt="No Image Found" class="img-fluid">
                     </td>
                     <td class="text-center">
                        <span class="visibilityRound redColor" *ngIf="detail?.ambulanceCrashesDetail?.app_notification == 0"></span>
                        <span class="visibilityRound greenColor" *ngIf="detail?.ambulanceCrashesDetail?.app_notification == 1"></span>
                    </td>
                   </tr>
                  </tbody>
                </table>
              </div> 
            </div>
          </div>
          <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
      </div>
    </div>
  </div>
</div>
