import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import en from '../../assets/lang/en.json';
import th from '../../assets/lang/thai.json';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment'
import { CookieService } from 'ngx-cookie-service';
const languageKey = 'lang';

@Injectable({
  providedIn: 'root',
})

export class LangService {
  isSingleLang = false;
  renderer: Renderer2;
  defaultLanguage ='en';
  supportedLanguages: Language[] = environment.languages

  constructor(private translate: TranslateService, private rendererFactory: RendererFactory2, private cookieService: CookieService) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  init() {
    this.defaultLanguage = this.cookieService.get(languageKey) || 'en';

      this.translate.setTranslation('en', en);
      this.translate.setTranslation('th', th);
    
    this.translate.setDefaultLang(this.defaultLanguage);
    if (this.isSingleLang) {
      this.translate.use(this.defaultLanguage);
    } else {
      this.language = '';
    }
  }


  set language(lang: string) {
    
    let language = lang || this.cookieService.get(languageKey);
    const isSupportedLanguage = this.supportedLanguages.map(item => item.code).includes(language);
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    if (lang !== '' && this.supportedLanguages.map(item => item.code).includes(lang)) {
      this.cookieService.set(languageKey, lang);
      window.location.reload();
    } else {
      this.translate.use(language);
    }
    this.cookieService.set(languageKey, language)
  }

  get language(): string {
    return this.translate.currentLang;
  }

  get languageShorthand(): string {
    return this.supportedLanguages.find(item => item.code === this.translate.currentLang).shorthand;
  }


  get languageLabel(): string {
    return this.supportedLanguages.find(item => item.code === this.translate.currentLang).label;
  }
}

export class Language {
  code: string;
  label: string;
  shorthand: string;
}
