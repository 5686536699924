<div #addAmbulanceModal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form_content">
          <div class="form_header">
            <div class="form_img">
              <img src="assets/images/icon/ambulance_icon.png" alt="ambulance_icon" class="img-fluid" >
            </div>
            <div class="form_default">
              <h4>{{selectHospital}}</h4>
              <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label for="ambulancename">{{'labels.ambulancename' | translate}}</label>
                        <input name="ambulancename"  id="ambulancename" type="text"  class="form-control" placeholder="{{selectAmbulance}}" disabled>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label for="platno">{{'labels.platno' | translate}}</label>
                        <input name="platno"  id="platno" type="text"  class="form-control" placeholder="ID85" disabled>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label for="platno">{{'labels.operating_time' | translate}}</label>
                        <input type='date'  name="datetimepicker" class="form-control" id='datetimepicker' disabled/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form_button">
                        <button class="btn btn-primary">{{'buttons.record' | translate}}</button>
                        <button class="btn btn-secondary">{{'buttons.cancel_operation' | translate}}</button>
                      </div>
                    </div>
                  </div>
              </form>
            </div>
          </div>
          <div class="divider"></div>
          <p><ngb-progressbar type="info" [value]="currentValue" [striped]="true" [animated]="true" [max]="100"></ngb-progressbar></p>
          <div class="form_body">
            <div class="form_stepper">
              <form [formGroup]="ambulanceReqForm" #form="ngForm">
                <div class="form-group error-l-50">
                  <input type="hidden" class="form-control" [(ngModel)]="selectAmbulancereq._id" formControlName="_id" name="_id">
                </div>

                <ul ngbNav #nav="ngbNav" id="myTabs" [(activeId)]="active" (navChange)="changeTab($event)"  class="nav-tabs" #staticTabs>
                  <li [ngbNavItem]="1">
                    <a ngbNavLink  name="one">
                      <span class="roundedselect">1</span>
                      <span class="stepmenu">{{'titles.type_operation' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="typesLists form-group">
                        <label class="typeRounded" for="ems">
                          <input id="ems" name="request_type" value="EMS" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                          <span>{{'labels.ems' | translate}}</span>
                        </label>
                        <label class="typeRounded" for="out">
                          <input id="out" name="request_type" value="out" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                          <span>{{'labels.out' | translate}}</span>
                        </label>
                        <label class="typeRounded" for="in">
                          <input id="in" name="request_type" value="in" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                          <span>{{'labels.in' | translate}}</span>
                        </label>
                        <label class="typeRounded" for="back">
                          <input id="back" name="request_type" value="back" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                          <span>{{'labels.back' | translate}}</span>
                        </label>
                        <label class="typeRounded" for="other">
                          <input id="other" name="request_type" value="other" type="radio" [(ngModel)]="selectAmbulancereq.request_type" formControlName="request_type" required="required" aria-required="true">
                          <span>{{'labels.other' | translate}}</span>
                        </label>
                      </div>

                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" disabled>
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="2">
                    <a ngbNavLink  name="tow" >
                      <span class="roundedselect">2</span>
                      <span class="stepmenu">{{'titles.driver' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectDriver(driver)" *ngFor="let driver of driverList">{{ driver.first_name }} {{ driver.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteSelectDriver(driver)" *ngFor="let driver of driverListShow; let i = index">
                                  <span>{{ driver.first_name }} {{ driver.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="3" >
                    <a ngbNavLink  name="three">
                      <span class="roundedselect">3</span>
                      <span class="stepmenu">{{'titles.doctor' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectDoctor(doctor)" *ngFor="let doctor of doctorList">{{ doctor.first_name }} {{ doctor.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li  (click)="deleteSelectDoctor(doctor)" *ngFor="let doctor of doctorListShow; let i = index">
                                  <span>{{ doctor.first_name }} {{ doctor.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="4">
                    <a ngbNavLink  name="four">
                      <span class="roundedselect">4</span>
                      <span class="stepmenu">{{'titles.nurse' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectNurse(nurse)" *ngFor="let nurse of nurseList">{{ nurse.first_name }} {{ nurse.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteselectNurse(nurse)" *ngFor="let nurse of nurseListShow; let i = index">
                                  <span>{{ nurse.first_name }} {{ nurse.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="5">
                    <a ngbNavLink  name="five">
                      <span class="roundedselect">5</span>
                      <span class="stepmenu">{{'titles.erstaff' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectEr(er)" *ngFor="let er of erstaffList">{{ er.first_name }} {{ er.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteSelectEr(er)" *ngFor="let er of erstaffListShow; let i = index">
                                  <span>{{ er.first_name }} {{ er.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="6">
                    <a ngbNavLink  name="six">
                      <span class="roundedselect">6</span>
                      <span class="stepmenu">{{'titles.emsstaff' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="stafflisting_content">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="stafflitsbox">
                              <ul>
                                <li (click)="selectEms(ems)" *ngFor="let ems of emsstaffList">{{ ems.first_name }} {{ ems.last_name }}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="stafflitsbox addstaffView">
                              <ul>
                                <li (click)="deleteSelectEms(ems)" *ngFor="let ems of emsstaffListShow; let i = index">
                                  <span>{{ ems.first_name }} {{ ems.last_name }}</span>
                                  <i class="fa fa-times-circle"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tabaction">
                        <button class="btn btn-warning previous-tab" (click)="previous()">
                          <i class="vsm-icon pe-7s-angle-left-circle"></i>
                          {{'buttons.previous' | translate}}
                        </button>
                        <button class="btn btn-warning next-tab" (click)="next()">
                          {{'buttons.next' | translate}}
                          <i class="vsm-icon pe-7s-angle-right-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li  [ngbNavItem]="7">
                    <a ngbNavLink  name="seven">
                      <span class="roundedselect">7</span>
                      <span class="stepmenu">{{'titles.details' | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                    <div class="detailsForm">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="hnnumber">{{'labels.hnnumber' | translate}}</label>
                            <input name="user_request_id"  id="hnnumber" type="text"  class="form-control"  [(ngModel)]="selectAmbulancereq.unique_hn_code" formControlName="unique_hn_code" required="required" aria-required="true">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form_button">
                            <button class="btn btn-primary">
                              <i class="vsm-icon pe-7s-up-arrow"></i>
                              {{'buttons.fetch' | translate}}
                            </button>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="patientname">{{'labels.patient_name' | translate}}</label>
                            <input name="first_name"  id="patientname" type="text"  class="form-control" [(ngModel)]="selectAmbulancereq.first_name" formControlName="first_name" required="required" aria-required="true">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="patientsur">{{'labels.patient_surname' | translate}}</label>
                            <input name="last_name"  id="patientsur" type="text"  class="form-control" [(ngModel)]="selectAmbulancereq.last_name" formControlName="last_name" required="required" aria-required="true">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="age">{{'labels.age' | translate}}</label>
                            <input name="age"  id="age" type="text"  class="form-control" [(ngModel)]="selectAmbulancereq.age" formControlName="age" required="required" aria-required="true">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="sickness">{{'labels.sickness_type' | translate}}</label>
                            <input name="sickness_type"  id="sickness" type="text"  class="form-control" [(ngModel)]="selectAmbulancereq.sickness_type" formControlName="sickness_type" required="required" aria-required="true">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="sickness">{{'labels.severity_level' | translate}}</label>
                            <div class="row">
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input type="radio" value="1" formControlName="severity_level" id="sickness1" name="severity_level" class="custom-control-input">
                                  <label class="custom-control-label" for="sickness1">1</label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input type="radio" value="2" formControlName="severity_level" id="sickness2" name="severity_level" class="custom-control-input">
                                  <label class="custom-control-label" for="sickness2">2</label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input type="radio" value="3" id="sickness3" formControlName="severity_level" name="severity_level" class="custom-control-input">
                                  <label class="custom-control-label" for="sickness3">3</label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input type="radio" value="4" id="sickness4" formControlName="severity_level" name="severity_level" class="custom-control-input">
                                  <label class="custom-control-label" for="sickness4">4</label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="custom-control custom-radio">
                                  <input type="radio" value="5" id="sickness5" formControlName="severity_level" name="severity_level" class="custom-control-input">
                                  <label class="custom-control-label" for="sickness5">5</label>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="hospital">{{'labels.zone_enable' | translate}}</label>
                            <div class="row">
                              <div class="col-12">
                                <ui-switch aria-required="true" (click)="zoneToggle()"></ui-switch>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="hospital">{{'labels.hospital' | translate}}</label>
                            <input name="source_hospital"  id="hospital" type="text"  class="form-control"  [(ngModel)]="selectHospital" formControlName="source_hospital" required="required" aria-required="true" value="source_hospital" disabled>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="zoneId">{{'labels.zone_id' | translate}}</label>
                            <select name="zone_id" id="zoneId" class="form-control" [(ngModel)]="selectAmbulancereq.zone_id" formControlName="zone_id" required="required" aria-required="true" (change)="zoneChange($event)">
                              <option value="{{zone._id}}"  *ngFor="let zone of zoneList">{{zone.name1}}</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="destination">{{'labels.destination' | translate}}</label>
                            <select name="hospital_id" id="destination" class="form-control" [(ngModel)]="selectAmbulancereq.hospital_id" formControlName="hospital_id" required="required" aria-required="true">
                              <option value="{{hospital._id}}"  *ngFor="let hospital of hospitalList">{{hospital.name1}}</option>
                            </select>
                          </div>
                        </div>

                        
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="glass">{{'labels.glass' | translate}}</label>
                            <select name="glass_id" id="glass" class="form-control" [(ngModel)]="selectAmbulancereq.glass_id" formControlName="glass_id" required="required" aria-required="true">
                              <option value="glass1">glass 1</option>
                              <option value="glass12">glass 2</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="monitor">{{'labels.monitor' | translate}}</label>
                            <select name="moniter_id" id="monitor" class="form-control" [(ngModel)]="selectAmbulancereq.moniter_id" formControlName="moniter_id" required="required" aria-required="true">
                              <option value="1">monitor 1</option>
                              <option value="2">monitor 2</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="gender">{{'labels.gender' | translate}}</label>
                            <select name="gender" id="gender" class="form-control" [(ngModel)]="selectAmbulancereq.gender" formControlName="gender" required="required" aria-required="true">
                              <option value="1">{{'labels.male' | translate}}</option>
                              <option value="2">{{'labels.female' | translate}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="hospital">{{'labels.trauma' | translate}}</label>
                            <div class="row">
                              <div class="col-12">
                                <ui-switch [(ngModel)]="selectAmbulancereq.is_trauma" formControlName="is_trauma" required="required" aria-required="true"></ui-switch>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="diagnos_note">{{'labels.diagnos_note' | translate}}</label>
                            <textarea name="diagnosis_note" id="diagnos_note" class="form-control" [(ngModel)]="selectAmbulancereq.diagnosis_note" formControlName="diagnosis_note" required="required" aria-required="true"></textarea>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group position-relative">
                            <label for="fast_track">{{'labels.fast_track' | translate}}</label>
                            <div class="fastTrackbox">
                              <div class="form-group">
                                <label class="fastList" for="ems">
                                  <input id="ems" name="fast_track" value="EMS" type="radio" [(ngModel)]="selectAmbulancereq.fast_track" formControlName="fast_track" required="required" aria-required="true">
                                  <span>{{'labels.ems' | translate}}</span>
                                </label>
                                <label class="fastList" for="out">
                                  <input id="out" name="fast_track" value="out" type="radio" [(ngModel)]="selectAmbulancereq.fast_track" formControlName="fast_track" required="required" aria-required="true">
                                  <span>{{'labels.out' | translate}}</span>
                                </label>
                                <label class="fastList" for="in">
                                  <input id="in" name="fast_track" value="in" type="radio" [(ngModel)]="selectAmbulancereq.fast_track" formControlName="fast_track" required="required" aria-required="true">
                                  <span>{{'labels.in' | translate}}</span>
                                </label>
                                <label class="fastList" for="back">
                                  <input id="back" name="fast_track" value="back" type="radio" [(ngModel)]="selectAmbulancereq.fast_track" formControlName="fast_track" required="required" aria-required="true">
                                  <span>{{'labels.back' | translate}}</span>
                                </label>
                                <label class="fastList" for="other">
                                  <input id="other" name="fast_track" value="other" type="radio" [(ngModel)]="selectAmbulancereq.fast_track" formControlName="fast_track" required="required" aria-required="true">
                                  <span>{{'labels.other' | translate}}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div class="tabaction">
                      <button class="btn btn-warning previous-tab" (click)="previous()">
                        <i class="vsm-icon pe-7s-angle-left-circle"></i>
                        {{'buttons.previous' | translate}}
                      </button>
                      <button class="btn btn-warning" type="submit" (click)="onSubmit(form.value)">
                        {{'buttons.submit' | translate}}
                        <i class="vsm-icon pe-7s-angle-right-circle"></i>
                      </button>
                    </div>
                    </ng-template>
                  </li>
                </ul>
              </form>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</div>