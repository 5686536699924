declare var google: any;
import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { AgmMap } from "@agm/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "../../../Services/toast.service";
import { AuthService } from "../../../Services/auth.service";
import { environment } from "../../../../environments/environment";

import { LocationService } from "../../../Services/location.service";
import { AliveServerService } from "../../../Services/alive-server.service";

import { Subscription } from "rxjs";

import { NgxSpinnerService } from "ngx-spinner";
import { AmbulanceListComponent } from "../../../common/ambulance-list/ambulance-list.component";
import { SnapshotViewComponent } from "../../../common/snapshot-view/snapshot-view.component";
import { DirectionViewComponent } from "../../../common/direction-view/direction-view.component";
import { MonitorViewComponent } from "../../../common/monitor-view/monitor-view.component";
import { CrashModalComponent } from "../../../common/crash-modal/crash-modal.component";
import { CookieService } from "ngx-cookie-service";
import MarkerClusterer from "@google/markerclustererplus";
import { AliveModalComponent } from "../../../common/alive-modal/alive-modal.component";
declare var $: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.sass"],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  private location: Subscription;
  errTime = environment.timeout.errorNotificationTimeout;
  markers: any = {};
  markerAnimation = "BOUNCE";
  numDeltas = 1000;
  aocRequestsList: any = [];
  user_id: any = "";
  markerFocus: any = false;
  aoc_request_id;
  newReqNotify;
  statusModal = "All";
  markerIcon: any = {
    url: this.locationService.setMapMarker(1, 0, "", 0),
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0, 0),
  };
  isOngoingAmbulance: Boolean = true;
  // @ViewChild(AgmMap) agmMap;
  @ViewChild("mapContainer", { static: false }) gmap: ElementRef;
  map: google.maps.Map;
  lat = environment.googleMapCenter.lat;
  lng = environment.googleMapCenter.lon;
  marker;

  isOnlineTimeout: any = {};
  infoWindow: any = {};
  markerMoveTimeout: any = {};
  sort_value: any = {
    ambulance_box_code: -1,
  };
  search_value: "";
  request_status: "";
  filterObject: any = {};
  coordinates = new google.maps.LatLng(
    environment.googleMapCenter.lat,
    environment.googleMapCenter.lon
  );
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    // disableDefaultUI: true,
    mapTypeControl: true,
    zoom: 6,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    zoomControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM },
    mapTypeControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
  };
  socketAmbulance: any;
  selectedAmbulance: any;

  constructor(
    private modalService: NgbModal,
    private aliveService: AliveServerService,
    private cookieService: CookieService,
    private apiService: AuthService,
    private spinner: NgxSpinnerService,
    private locationService: LocationService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.user_id = this.cookieService.get("user_id");

    this.getHospitalOngoingAmbulanceList();
    this.getUserZoneHospital();
    this.location = this.locationService.location.subscribe(
      (data) => {
        console.log("data", data);
        if (data[0] == "{") {
          data = JSON.parse(data);

          if (data.type == "CRASH_NOTIFY") {
            this.getCrashNotify(data.ambulance_crashes_id);
          } else if (data.type == "TRACKING") {
            this.socketAmbulance = data;
            this.getLiveLocation(data["_id"], {
              lat: data["tracking_latitude"],
              lon: data["tracking_longitude"],
              heading: data["tracking_heading"],
              speed: data["tracking_speed"],
              is_assign: data["is_assign"],
              request_type: data["request_type"],
              estimated_time: data["estimated_time"],
            });
            this.AmbulanceFocus(this.selectedAmbulance);
          }
        } else {
        }
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );

    this.location = this.aliveService.aliveServer.subscribe(
      (data) => {
        if (data[0] == "{") {
          data = JSON.parse(data);
          this.aoc_request_id = data.aoc_requests_id;
          if (data.type == "NEW_REQUEST_NOTIFY") {
            this.apiService
              .postAliveRequestDetail(this.aoc_request_id)
              .subscribe((res) => {
                this.newReqNotify = res["content"];
                this.getAliveNotify(this.newReqNotify);
              });
          }
        } else {
        }
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );

    navigator.geolocation.getCurrentPosition((pos) => {
      this.mapOptions.center = new google.maps.LatLng(
        +pos.coords.latitude,
        +pos.coords.longitude
      );
    });
  }

  ngAfterViewInit() {
    this.mapInitializer();
  }

  getHospitalOngoingAmbulanceList() {
    this.user_id = this.cookieService.get("user_id");
    this.apiService.getHospitalOngoingAmbulanceList(this.user_id).subscribe(
      (data) => {
        this.spinner.hide();
        if (data["success"]) {
          // Assign Data for Marker
          if (data["content"]["data"].length) {
            this.isOngoingAmbulance = true;
            data["content"]["data"].forEach((e, i) => {
              if (
                data["content"]["data"].length == i + 1 &&
                String(typeof this.markers[String(e._id)]) === "undefined"
              ) {
                this.setMapMarkers(e, true);
              } else if (
                String(typeof this.markers[String(e._id)]) === "undefined"
              ) {
                this.setMapMarkers(e, false);
              }
            });
          }
        }
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  setMapMarkers(e, i) {
    var content =
      "Box : " +
      String(e.ambulance_box_code) +
      "</br>" +
      "Name :" +
      String(e.name1) +
      "</br>" +
      "Req Type : " +
      String(e.aocRequestsDetail.request_type) +
      "</br>";

    this.locationService.roomJoin(String(e._id));
    this.locationService.roomJoin("CRASH_NOTIFY_" + String(e._id));
    this.locationService.roomJoined.push(String(e._id));
    //Ambulance Tracking Detail Condition

    if (this.markers[String(e._id)] == undefined) {
      if (Object.keys(e.ambulanceTrackingsDetail).length) {
        content +=
          "Speed :" +
          Math.ceil(Number(e.ambulanceTrackingsDetail.speed)) +
          "</br>" +
          "ETA : N/A";
        //Createing Pin For Marker
        this.markers[String(e._id)] = new google.maps.Marker({
          position: new google.maps.LatLng(
            e.ambulanceTrackingsDetail.location.lat,
            e.ambulanceTrackingsDetail.location.lon
          ),
          map: this.map,
          title: content,
          icon: {
            url:
              this.locationService.setMapMarker(1, 0, "", 0) +
              "?id=" +
              String(e._id),
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15),
          },
        });
      } else {
        content += "Speed : 0.00" + "</br>" + "ETA : N/A";

        this.markers[String(e._id)] = new google.maps.Marker({
          position: new google.maps.LatLng(0, 0),
          map: this.map,
          title: content,
          icon: {
            url:
              this.locationService.setMapMarker(1, 0, "", 0) +
              "?id=" +
              String(e._id),
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15),
          },
        });
      }

      this.markers[String(e._id)].addListener("click", () => {
        Object.keys(this.infoWindow).map((e) => {
          this.infoWindow[e].close();
        });

        this.infoWindow[String(e._id)] = new google.maps.InfoWindow({
          content: this.markers[String(e._id)].getTitle(),
        });
        this.map.setZoom(16);
        this.infoWindow[String(e._id)].open(
          this.markers[String(e._id)].getMap(),
          this.markers[String(e._id)]
        );
        this.map.setCenter(this.markers[String(e._id)].getPosition());
        this.markerFocus = String(e._id);
      });

      this.markers[String(e._id)].setMap(this.map);

      this.isOnlineTimeout[String(e._id)] = setTimeout(() => {
        if (e["aocRequestsDetail"]) {
          this.markers[String(e._id)].setIcon({
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15),
            url:
              this.locationService.setMapMarker(
                0,
                1,
                String(e["aocRequestsDetail"]["request_type"]),
                0
              ) +
              "?id=" +
              String(e._id),
          });
        } else {
          this.markers[String(e._id)].setIcon({
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15),
            url:
              this.locationService.setMapMarker(0, 0, "", 0) +
              "?id=" +
              String(e._id),
          });
        }
      }, 30000);

      if (i) {
        this.AocRequestsList(1);
        new MarkerClusterer(this.map, this.markers, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });
      }
    }
  }

  getUserZoneHospital() {
    this.apiService.getHospitalsByUserList(this.user_id).subscribe(
      (data) => {
        let zoneList = data["content"]["data"][0]["zoneDetails"];
        zoneList.forEach((e) => {
          this.aliveService.roomJoin("NEW_REQUEST_NOTIFY_" + String(e._id));
        });
      },
      (err) => {
        if (err.error && err.error.message) {
          this.toastService.show(
            err.error.message + `<br>` + "status code:" + err.status,
            { classname: "bg-danger text-white", delay: this.errTime }
          );
        } else {
          this.toastService.show(err.message, {
            classname: "bg-danger text-white",
            delay: this.errTime,
          });
        }
      }
    );
  }

  getCrashNotify(ambulanceCrashesId) {
    const modaref = this.modalService.open(CrashModalComponent, {
      backdropClass: "light-pink",
      centered: true,
      windowClass: "crashModal",
    });
    modaref.componentInstance.crashDetail = ambulanceCrashesId;
  }

  getLiveLocation(id, location) {
    if (this.markers[id]) {
      var marker = this.markers[id];
      var old = {
        lat: Number(marker.getPosition().lat()),
        lon: Number(marker.getPosition().lng()),
      };
      var current = { lat: Number(location.lat), lon: Number(location.lon) };

      var num = 100;
      var delta = {};
      delta["lat"] = (current.lat - old.lat) / num;
      delta["lon"] = (current.lon - old.lon) / num;

      clearInterval(this.isOnlineTimeout[id]);
      this.isOnlineTimeout[id] = setTimeout(() => {
        this.markers[String(id)].setIcon({
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(15, 15),
          url:
            this.locationService.setMapMarker(
              0,
              Number(location["is_assign"]),
              String(location["request_type"]),
              Number(location.speed)
            ) +
            "?id=" +
            String(id),
        });
      }, 30000);

      this.markers[String(id)].setIcon({
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(15, 15),
        url:
          this.locationService.setMapMarker(
            1,
            Number(location["is_assign"]),
            String(location["request_type"]),
            Number(location.speed)
          ) +
          "?id=" +
          String(id),
      });

      if (this.markerFocus == id) {
        this.map.setCenter(this.markers[id].getPosition());
        google.maps.event.trigger(marker, "click");
      }

      if (Number(location.heading))
        $(
          'img[src="' +
            String(
              this.locationService.setMapMarker(
                1,
                Number(location["is_assign"]),
                String(location["request_type"]),
                Number(location.speed)
              ) +
                "?id=" +
                String(id)
            ) +
            '"]'
        ).css({
          transform: "rotate(" + Number(location.heading) + "deg)",
        });

      this.locationService.markerMove(
        this.locationService.markerMove,
        id,
        0,
        num,
        delta,
        marker,
        old,
        current,
        location,
        this.markerMoveTimeout
      );
    }
  }

  mapInitializer(): void {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

    this.map.addListener("zoom_changed", () => {
      Object.keys(this.infoWindow).map((e) => {
        this.infoWindow[e].close();
      });
      this.markerFocus = false;
    });
  }

  openModal() {
    this.modalService.open(AmbulanceListComponent, {
      windowClass: "sidebar_list",
    });
  }

  openSnapshotModal(Snapshowdetail) {
    const modaref = this.modalService.open(SnapshotViewComponent, {
      windowClass: "sidebar_list snapModal",
    });
    modaref.componentInstance.snapShotData = Snapshowdetail;
  }

  openDirectionModal(request) {
    const modaref = this.modalService.open(DirectionViewComponent, {
      windowClass: "sidebar_list snapModal",
    });
    modaref.componentInstance.ambulanceData = request;
  }

  openMonitorModal(Monitordetail) {
    const modaref = this.modalService.open(MonitorViewComponent, {
      windowClass: "sidebar_list snapModal",
    });
    modaref.componentInstance.monitorData = Monitordetail;
  }

  AmbulanceFocus(ambulance) {
    if (this.selectedAmbulance && ambulance._id !== this.selectedAmbulance) {
      this.selectedAmbulance = {};
    }
    this.selectedAmbulance = ambulance;
    if (
      this.selectedAmbulance &&
      String(this.selectedAmbulance.ambulance_box_code) ==
        String(this.socketAmbulance.ambulance_box_code) &&
      this.socketAmbulance.tracking_speed > 0
    ) {
      this.map.setZoom(13);
      var latLng = new google.maps.LatLng(
        this.socketAmbulance.tracking_latitude,
        this.socketAmbulance.tracking_longitude
      );
      this.map.setCenter(latLng);
    }
  }

  AocRequestsList(timeout) {
    let sort = JSON.stringify(this.sort_value);
    this.apiService
      .AocRequestsList(
        this.user_id,
        sort,
        this.search_value,
        this.request_status
      )
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.aocRequestsList = data["content"]["data"];
            if (this.aocRequestsList.length && false) {
              this.aocRequestsList.forEach((e) => {
                if (
                  String(typeof this.markers[String(e._id)]) === "undefined" &&
                  this.isOngoingAmbulance
                ) {
                  this.isOngoingAmbulance = false;
                  this.getHospitalOngoingAmbulanceList();
                }
              });
            }
            console.log(timeout);

            if (timeout) {
              setTimeout(() => {
                this.AocRequestsList(1);
              }, 7000);
            }
          } else {
            setTimeout(() => {
              this.AocRequestsList(1);
            }, 15000);
          }
        },
        (err) => {
          if (err.status == 404) {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }

          setTimeout(() => {
            this.AocRequestsList(1);
          }, 30000);
        }
      );
  }

  sortAocRequestsList(e) {
    if (e == "ambulance_box_code") {
      if (this.sort_value.ambulance_box_code == 1) {
        this.sort_value = {
          ambulance_box_code: -1,
        };
      } else {
        this.sort_value = {
          ambulance_box_code: 1,
        };
      }
    }

    if (e == "speed") {
      if (this.sort_value["ambulanceTrackingsDetail.speed"] == -1) {
        this.sort_value = {
          "ambulanceTrackingsDetail.speed": 1,
        };
      } else {
        this.sort_value = {
          "ambulanceTrackingsDetail.speed": -1,
        };
      }
    }

    if (e == "estimated_time") {
      if (this.sort_value["ambulanceTrackingsDetail.estimated_time"] == 1) {
        this.sort_value = {
          "ambulanceTrackingsDetail.estimated_time": -1,
        };
      } else {
        this.sort_value = {
          "ambulanceTrackingsDetail.estimated_time": 1,
        };
      }
    }

    // this.filterObject = {
    //   'sort_value' : this.sort_value,
    //   'request_status': this.request_status,
    //   'search_value': this.search_value
    // }
    this.AocRequestsList(1);
  }

  requeststatusChange(e) {
    // this.sort_value = {}
    this.request_status = e;

    // this.filterObject = {
    //   'sort_value' : this.sort_value,
    //   'request_status': this.request_status,
    //   'search_value': this.search_value
    // }
    this.AocRequestsList(1);
  }

  filterItem(value) {
    if (!value) {
      this.AocRequestsList(1);
    } else {
      this.search_value = value.toLowerCase();
      // this.filterObject = {
      //   'sort_value' : this.sort_value,
      //   'request_status': this.request_status,
      //   'search_value': this.search_value
      // }
    }
    this.search_value = value.toLowerCase();
    // this.filterObject = {
    //   'sort_value' : this.sort_value,
    //   'request_status': this.request_status,
    //   'search_value': this.search_value
    // }
    this.AocRequestsList(1);
  }

  ngOnDestroy() {
    this.location.unsubscribe();
    this.locationService.roomJoined.map((e) => {
      this.locationService.roomLeave(String(e));
      // this.locationService.roomLeave('CRASH_NOTIFY_'+String(e._id));
    });
  }

  ambulanceFocus(id) {
    this.markerFocus = id;
    google.maps.event.trigger(this.markers[String(id)], "click");
  }

  getAliveNotify(details) {
    $(".alivemodalopen").show();
    const modaref = this.modalService.open(AliveModalComponent, {
      backdropClass: "light-orange",
      centered: true,
      windowClass: "aliveModal",
    });
    modaref.componentInstance.aliveData = details;
  }
}
