import { Component, ElementRef, Input, OnInit,ViewChild, OnDestroy } from '@angular/core';
import {ThemeOptions} from '../../theme-options';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../Services/auth.service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
// import * as $ from 'jquery';
declare var $: any;
import { AddAmbulanceComponent } from '../add-ambulance/add-ambulance.component'

@Component({
  selector: 'app-ambulance-list',
  templateUrl: './ambulance-list.component.html',
  styleUrls: ['./ambulance-list.component.sass']
})
export class AmbulanceListComponent implements OnInit {
 
 
  public extraParameter: any;
  public languages:any = environment.languages.map(e=> { return { 'label': e}});
  
  constructor(public activeModal: NgbActiveModal, private el: ElementRef, private spinner: NgxSpinnerService, private apiService: AuthService, private modalService: NgbModal, public globals: ThemeOptions, private activatedRoute: ActivatedRoute, private translate: TranslateService, private cookieService: CookieService) {
  
    translate.setDefaultLang('en');
    translate.use(this.cookieService.get('lang'));

  }
  activeId;
  page = 3;
  ambulancelist:any;
  totalAmbulance: any = [];

  ngOnInit(): void {
    this.getUserAmbulanceList();
    
  }
  
  getUserAmbulanceList(){
    // this.spinner.show();
    // this.apiService.getUserAmbulanceList().subscribe((data) => {
    //   this.ambulancelist = data['content']['data'];   
    //   this.totalAmbulance = this.ambulancelist[0]['ambulances'].length
    //   this.spinner.hide();
    // })
  } 

  close(){
    this.activeModal.close()
  }
  openModal(addAmbulanceModal, hospitalDetail, ambulanceDetail) {
    this.modalService.open(AddAmbulanceComponent, { windowClass: 'sidebar_list' });
    
  }
}
