import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../../../Services/auth.service";
import { Router } from "@angular/router";
import { ToastService } from "../../../Services/toast.service";
import { environment } from "src/environments/environment";
declare var $: any;

@Component({
  selector: "app-login-boxed",
  templateUrl: "./login-boxed.component.html",
  styles: [],
})
export class LoginBoxedComponent implements OnInit {
  signInForm: FormGroup;
  errTime = environment.timeout.errorNotificationTimeout;
  success = "Login successfully";
  invalid = "Invalid Details";

  constructor(
    private toastService: ToastService,
    private apiService: AuthService,
    private router: Router,
    private cookieService: CookieService
  ) {
    // translate.setDefaultLang('en');
    // translate.use(this.cookieService.get('lang'));
  }

  users = [{ name: "Aoc User", value: "Aoc User" }];
  selectsignIn = {
    email: "",
    password: "",
    user_type: "Aoc User",
  };

  ngOnInit() {
    if (this.cookieService.get("api_key")) {
      this.router.navigate(["user/dashboard"]);
    }
    this.selectsignIn.user_type = "Aoc User";
    this.signInForm = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      user_type: new FormControl(null, [Validators.required]),
    });
  }

  onSubmit(signInValue) {
    if (this.signInForm.status == "VALID") {
      $("#submitLoader").css("display", "inline-block");
      console.log("54");
      this.apiService
        .signIn(signInValue.email, signInValue.password, signInValue.user_type)
        .subscribe(
          (res: any) => {
            console.log("res", res);
            if (res.success == true) {
              $("#submitLoader").hide();
              this.cookieService.delete("api_key");
              this.router.navigate(["user/dashboard"]);
              this.toastService.show(this.success, {
                classname: "bg-success text-white",
                delay: 10000,
              });
            } else {
              setTimeout(() => {
                $("#submitLoader").hide();
              }, 5000);
              this.toastService.show("Credetial is Invalid !", {
                classname: "bg-danger text-white",
                delay: 10000,
              });
            }
          },
          (err) => {
            if (err.error && err.error.message) {
              this.toastService.show(
                err.error.message + `<br>` + "status code:" + err.status,
                { classname: "bg-danger text-white", delay: this.errTime }
              );
            } else {
              this.toastService.show(err.message, {
                classname: "bg-danger text-white",
                delay: this.errTime,
              });
            }
          }
        );
    } else {
      setTimeout(() => {
        $("#submitLoader").hide();
      }, 5000);
      this.toastService.show(this.invalid, {
        classname: "bg-danger text-white",
        delay: 10000,
      });
    }
  }
}
