<div class="userprofile"> 
    <div class="row justify-content-center">
        <div class="col-12 col-md-8">
            <div class="card">
                <form class="h_100" [formGroup]="editsForm" #form="ngForm">
                    <div class="form-group error-l-50">
                        <input type="hidden" class="form-control" [(ngModel)]="selectEdituser._id" formControlName="_id" name="_id">
                      </div>
                    <div class="row h_100"> 
                        <div class="col-md-4">
                            <div class="card-header">
                                <div class="profileHead">
                                    <!-- <img src="assets/images/AOC_LOGO.png" alt="logo" class="img-fluid"> -->
                                    <div class="profile_img">
                                        <div class="image-upload">
                                          <label for="file-input">
                                            <img id="imagePreview" onerror="this.src='/assets/images/AOC_LOGO.png'" [src]="url0" *ngIf="imgURL" alt="" class="img-fluid">
                                            <i *ngIf="editableBoolean" class="fa fa-pencil" aria-hidden="true"></i>
                                          </label>
                  
                                            <input *ngIf="editableBoolean" id="file-input" #file type="file" accept='image/*' class="custom-file-input" [(ngModel)]="selectEdituser.userProfile" name="userProfile" formControlName="userProfile" (change)="onFileChange($event)">
                                        </div>
                                      </div>
                                    <div class="userTitle">Fname Lname</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <div class="editIcon">
                                    <button class="btn" (click)="edit()" [disabled]="editableBoolean">
                                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="infoBox">
                                            <span class="lablestext">{{'labels.name' | translate}}</span>
                                            <div *ngIf="!editableBoolean" class="">{{selectEdituser.name}}</div>
                                            <div *ngIf="editableBoolean" class="form-group position-relative">
                                                <input name="name" [(ngModel)]="selectEdituser.name" formControlName="name" id="name" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="infoBox">
                                            <span class="lablestext">{{'labels.age' | translate}}</span>
                                            <div *ngIf="!editableBoolean" class="">{{selectEdituser.age}}</div>
                                            <div *ngIf="editableBoolean" class="form-group position-relative">
                                                <input name="age" [(ngModel)]="selectEdituser.age" formControlName="age" id="age" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="infoBox">
                                            <span class="lablestext">{{'labels.gender' | translate}}</span>
                                            <div *ngIf="!editableBoolean" class="">{{selectEdituser.gender}}</div>
                                            <div *ngIf="editableBoolean" class="form-group position-relative">
                                                <select name="gender" id="speedlimit" class="form-control" [(ngModel)]="selectEdituser.gender" formControlName="gender">
                                                  <option value="male" selected="selected">Male</option>
                                                  <option value="female">Female</option>
                                                </select>
                                              </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="infoBox">
                                            <span class="lablestext">{{'labels.email' | translate}}</span>
                                            <div class="">roath@gmail.com</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="infoBox">
                                            <span class="lablestext">{{'labels.phone' | translate}}</span>
                                            <div class="">+91-8585757525</div>
                                        </div>
                                    </div>
                                    <div *ngIf="editableBoolean" class="col-md-12 subBtn">
                                        <button type="submit" class="btn btn-primary mx-auto d-block" (click)="onSubmit()">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>