<div class="app-header header-shadow">
  <!-- <div class="logo-src"></div> -->
  <div class="app-header__content">
    <div class="topvalueContent">
      <!-- <a class="userIcon mr-2" routerLink="/user/user-profile">
        <img src="assets/images/AOC_LOGO.png" alt="logo" class="img-fluid">
      </a> -->
      <span class="topValue">{{ current_datetime }}</span>
      <span class="topValue" *ngFor="let detail of totalAocRequest; let i = index">
        <div class="blockType">
          <span>{{'labels.ems' | translate}} :</span>
          <span>{{detail.ems}}</span>
        </div>
        <div class="blockType">
          <span>{{'labels.in' | translate}} :</span>
          <span>{{detail.in}}</span>
        </div>
        <div class="blockType">
          <span>{{'labels.out' | translate}} :</span>
          <span>{{detail.out}}</span>
        </div>
        <div class="blockType">
          <span>{{'labels.back' | translate}} :</span>
          <span>{{detail.back}}</span>
        </div>
        <div class="blockType">
          <span>{{'labels.other' | translate}} :</span>
          <span>{{detail.other}}</span>
        </div>
        <div class="blockType totalType">
          <span>{{'labels.total' | translate}} :</span>
          <span>{{detail.ems + detail.ref + detail.in + detail.out + detail.back + detail.other}}</span>
        </div>
      </span>
      <!-- <span class="notification">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-primary" id="notifications" ngbDropdownToggle>
            <i class="fa fa-bell" aria-hidden="true"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="notifications">
            <div>
              <table class="table w-100">
                <tbody>
                  <tr>
                    <th scope="col" style="min-width:265px">
                      <span>#</span>
                      <ul>
                        <li>{{'labels.ambulance_online' | translate}}</li>
                        <li>{{'labels.ambulance_operation_update' | translate}}</li>
                        <li>{{'labels.speed_zone_change' | translate}}</li>
                        <li>{{'labels.eta_arrial_destination' | translate}}</li>
                        <li>{{'labels.acns_ambulance_crash' | translate}}</li>
                        <li>{{'labels.monitor_online_status' | translate}}</li>
                        <li>{{'labels.ambulance_3rd_eye' | translate}}</li>
                      </ul>
                    </th>
                    <th scope="col">
                      <span> {{'labels.aoc_mobile' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc?.ambulance_status_change" (change) = "updateUserNotification($event,'aoc','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc?.operation_status_change" (change) = "updateUserNotification($event,'aoc','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc?.ambulance_speed" (change) = "updateUserNotification($event,'aoc','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc?.ambulance_estimated_time" (change) = "updateUserNotification($event,'aoc','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc?.acns" (change) = "updateUserNotification($event,'aoc','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc?.patient_monitor_status_change" (change) = "updateUserNotification($event,'aoc','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'aoc','ambulance_3rd_eye_online_status')"></ui-switch></li>
                      </ul>
                    </th>
                    <th scope="col"  style="min-width:80px">
                      <span> {{'labels.alive' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.alive?.ambulance_status_change" (change) = "updateUserNotification($event,'alive','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.alive?.operation_status_change" (change) = "updateUserNotification($event,'alive','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.alive?.ambulance_speed" (change) = "updateUserNotification($event,'alive','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.alive?.ambulance_estimated_time" (change) = "updateUserNotification($event,'alive','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.alive?.acns" (change) = "updateUserNotification($event,'alive','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.alive?.patient_monitor_status_change" (change) = "updateUserNotification($event,'alive','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.alive?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'alive','ambulance_3rd_eye_online_status')"></ui-switch></li>
                        
                      </ul>
                    </th>
                    <th scope="col" style="min-width:80px">
                      <span> {{'labels.aom' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.aom?.ambulance_status_change" (change) = "updateUserNotification($event,'aom','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aom?.operation_status_change" (change) = "updateUserNotification($event,'aom','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aom?.ambulance_speed" (change) = "updateUserNotification($event,'aom','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aom?.ambulance_estimated_time" (change) = "updateUserNotification($event,'aom','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aom?.acns" (change) = "updateUserNotification($event,'aom','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aom?.patient_monitor_status_change" (change) = "updateUserNotification($event,'aom','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aom?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'aom','ambulance_3rd_eye_online_status')"></ui-switch></li>
                        
                      </ul>
                    </th>
                    <th scope="col">
                      <span> {{'labels.scanfor_life' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.scanfor_life?.ambulance_status_change" (change) = "updateUserNotification($event,'scanfor_life','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.scanfor_life?.operation_status_change" (change) = "updateUserNotification($event,'scanfor_life','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.scanfor_life?.ambulance_speed" (change) = "updateUserNotification($event,'scanfor_life','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.scanfor_life?.ambulance_estimated_time" (change) = "updateUserNotification($event,'scanfor_life','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.scanfor_life?.acns" (change) = "updateUserNotification($event,'scanfor_life','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.scanfor_life?.patient_monitor_status_change" (change) = "updateUserNotification($event,'scanfor_life','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.scanfor_life?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'scanfor_life','ambulance_3rd_eye_online_status')"></ui-switch></li>
                        
                      </ul>
                    </th>
                    <th scope="col" style="min-width:80px">
                      <span> {{'labels.aoc_tv' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc_tv?.ambulance_status_change" (change) = "updateUserNotification($event,'aoc_tv','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc_tv?.operation_status_change" (change) = "updateUserNotification($event,'aoc_tv','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc_tv?.ambulance_speed" (change) = "updateUserNotification($event,'aoc_tv','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc_tv?.ambulance_estimated_time" (change) = "updateUserNotification($event,'aoc_tv','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc_tv?.acns" (change) = "updateUserNotification($event,'aoc_tv','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc_tv?.patient_monitor_status_change" (change) = "updateUserNotification($event,'aoc_tv','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.aoc_tv?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'aoc_tv','ambulance_3rd_eye_online_status')"></ui-switch></li>
                        
                      </ul>
                    </th>
                    <th scope="col" style="min-width:80px">
                      <span> {{'labels.aoc_ccc' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.ccc?.ambulance_status_change" (change) = "updateUserNotification($event,'ccc','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ccc?.operation_status_change" (change) = "updateUserNotification($event,'ccc','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ccc?.ambulance_speed" (change) = "updateUserNotification($event,'ccc','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ccc?.ambulance_estimated_time" (change) = "updateUserNotification($event,'ccc','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ccc?.acns" (change) = "updateUserNotification($event,'ccc','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ccc?.patient_monitor_status_change" (change) = "updateUserNotification($event,'ccc','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ccc?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'ccc','ambulance_3rd_eye_online_status')"></ui-switch></li>
                        
                      </ul>
                    </th>
                    <th scope="col" style="min-width:138px">
                      <span> {{'labels.aoc_remote_hos' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.rmt_hos?.ambulance_status_change" (change) = "updateUserNotification($event,'rmt_hos','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.rmt_hos?.operation_status_change" (change) = "updateUserNotification($event,'rmt_hos','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.rmt_hos?.ambulance_speed" (change) = "updateUserNotification($event,'rmt_hos','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.rmt_hos?.ambulance_estimated_time" (change) = "updateUserNotification($event,'rmt_hos','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.rmt_hos?.acns" (change) = "updateUserNotification($event,'rmt_hos','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.rmt_hos?.patient_monitor_status_change" (change) = "updateUserNotification($event,'rmt_hos','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.rmt_hos?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'rmt_hos','ambulance_3rd_eye_online_status')"></ui-switch></li>
                       
                      </ul>
                    </th>
                    <th scope="col" style="min-width:80px">
                      <span> {{'labels.aoc_ekg' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.ekg?.ambulance_status_change" (change) = "updateUserNotification($event,'ekg','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ekg?.operation_status_change" (change) = "updateUserNotification($event,'ekg','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ekg?.ambulance_speed" (change) = "updateUserNotification($event,'ekg','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ekg?.ambulance_estimated_time" (change) = "updateUserNotification($event,'ekg','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ekg?.acns" (change) = "updateUserNotification($event,'ekg','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ekg?.patient_monitor_status_change" (change) = "updateUserNotification($event,'ekg','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.ekg?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'ekg','ambulance_3rd_eye_online_status')"></ui-switch></li>
                        
                      </ul>
                    </th>
                    <th scope="col">
                      <span> {{'labels.aoc_er' | translate}}</span>
                      <ul>
                        <li><ui-switch  [ngModel]="userNotificationList?.er?.ambulance_status_change" (change) = "updateUserNotification($event,'er','ambulance_status_change')">
                        </ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.er?.operation_status_change" (change) = "updateUserNotification($event,'er','operation_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.er?.ambulance_speed" (change) = "updateUserNotification($event,'er','ambulance_speed')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.er?.ambulance_estimated_time" (change) = "updateUserNotification($event,'er','ambulance_estimated_time')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.er?.acns" (change) = "updateUserNotification($event,'er','acns')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.er?.patient_monitor_status_change" (change) = "updateUserNotification($event,'er','patient_monitor_status_change')"></ui-switch></li>
                        <li><ui-switch  [ngModel]="userNotificationList?.er?.ambulance_3rd_eye_online_status" (change) = "updateUserNotification($event,'er','ambulance_3rd_eye_online_status')"></ui-switch></li>
                      </ul>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </span> -->
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
  </div>
  <!-- <div class="app-header__menu">
    <span>
        <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
                (click)="toggleHeaderMobile()">
            <div class="btn-icon-wrapper">
                <fa-icon [icon]="faEllipsisV"></fa-icon>
            </div>
        </button>
    </span>
  </div> -->
</div>
