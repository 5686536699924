<div class="bg-map bg-animation loginpage" style="overflow-x: hidden;">
  <div class="heightcalc" style="overflow-x: hidden;overflow-y: hidden">
    <div class="d-flex h-100 justify-content-center align-items-center">
      <div class="mx-auto app-login-box col-md-12">
        <div class="applogoinverse mx-auto mb-3">
          <div class="mb-3">
            <img src="assets/images/aoclabel.png" style="max-height: 50px;" alt="logo" class="img-fluid" >
          </div>
          <div class="mb-3">
            <img src="assets/images/aoc_round_logo.png" alt="logo" style="max-height: 100px;" class="img-fluid" >
          </div>
        </div>
        <div class="w_25 mx-auto">
            <form [formGroup]="signInForm" #form="ngForm" (ngSubmit)="onSubmit(form.value)">
            <!-- <div class="modal-content">
              <div class="modal-body"> -->
                <!-- <div class="h5 modal-title text-center">
                  <h4 class="mt-2">
                    <div>{{'titles.ambulance_command_center' | translate}}</div>
                    <span>{{'titles.safty_ambulance' | translate}}</span>
                  </h4>
                </div> -->
                <div class="login_content">
                  <div class="form-group position-relative">
                    <!-- <label for="email">{{'labels.email' | translate}}</label> -->
                    <input [(ngModel)]="selectsignIn.email" name="email" style="color: #fff;background-color: gray;caret-color: #fff;" placeholder="{{'labels.email' | translate}}" formControlName="email" id="email" type="email" required="required" aria-required="true" class="form-control grey_bg" aria-describedby="emailId">
                  </div>
                  <div class="form-group position-relative">
                    <!-- <label for="email">{{'labels.password' | translate}}</label> -->
                    <input [(ngModel)]="selectsignIn.password" style="color: #fff;background-color: gray;caret-color: #fff;" placeholder="{{'labels.password' | translate}}" name="password" formControlName="password" id="password" type="password" required="required" aria-required="true" class="form-control grey_bg">
                  </div>

                  <div class="position-relative text-white form-group">
                    <label for="user_type">{{'labels.user_type' | translate}}</label>
                    <ng-select [items]="users" 
                            bi name="user_type" id="user_type" bindLabel="name" 
                            bindValue="value" 
                            name="user_type" formControlName="user_type" class="grey_bg"
                            [(ngModel)]="selectsignIn.user_type">
                     </ng-select>    
                  </div>

                  <div class="submit_button">
                    <button type="submit" style="background-color: #1B7319;" class="btn btn-primary btn-lg w-100">{{'buttons.login' | translate}}
                      <span class="spinner-grow spinner-grow-sm" id="submitLoader" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
                <div class="applogoinverse mx-auto mb-4 mt-2">
                  <h3>We've Assisted <br> "Chain Of Survival"</h3>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </form>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
