import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { AuthService } from "../../Services/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
import { ToastService } from "../../Services/toast.service";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-staff-list",
  templateUrl: "./staff-list.component.html",
  styleUrls: ["./staff-list.component.sass"],
})
export class StaffListComponent implements OnInit {
  user_id = this.cookieService.get("user_id");

  activeId;
  active = 1;
  public data = [];
  dtOptions: any = {};
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  page_size = 10;
  current_page = 1;
  search_value = "";
  sort_value = {};
  user_type = "doctors";
  column = ["picture", "name", "email", "action"];
  _user_id = "";
  baseImgUrl = `${environment.imgUrl}/`;
  imgPath = `${environment.imgUrl}/`;
  isactive: boolean;
  errTime = environment.timeout.errorNotificationTimeout;
  constructor(
    private cookieService: CookieService,
    private apiService: AuthService,
    private spinner: NgxSpinnerService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: this.page_size,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.sort_value = {};
        this.current_page =
          dataTablesParameters.start / dataTablesParameters.length + 1;
        this.page_size = dataTablesParameters.length;
        this.search_value = String(dataTablesParameters.search.value);
        this.sort_value[
          this.column[Number(dataTablesParameters.order[0].column)]
        ] =
          String(dataTablesParameters.order[0].dir).toUpperCase() == "DESC"
            ? -1
            : 1;
        this.apiService
          .ListUsers(
            this.user_id,
            this.page_size,
            this.current_page,
            this.search_value,
            this.sort_value,
            this.user_type
          )
          .subscribe(
            (res) => {
              let udpatedData = res["content"]["data"].map((item) => {
                if (item.profile_picture) {
                  item.profile_picture = `${this.imgPath}${item.profile_picture}`;
                }
                return item;
              });
              this.data = udpatedData;
              callback({
                recordsTotal: Number(res["content"]["count"]),
                recordsFiltered: Number(res["content"]["count"]),
                data: [],
              });
              $(".dataTables_empty").remove();
              this.spinner.hide();
            },
            (err) => {
              if (err.error && err.error.message) {
                this.toastService.show(
                  err.error.message + `<br>` + "status code:" + err.status,
                  { classname: "bg-danger text-white", delay: this.errTime }
                );
              } else {
                this.toastService.show(err.message, {
                  classname: "bg-danger text-white",
                  delay: this.errTime,
                });
              }
            }
          );
      },
    };
    this.jquery();
  }

  jquery() {
    $(document).ready(function () {
      $("#tabs li a:not(:first)").addClass("inactive");
      $(".container-fluid").hide();
      $(".container-fluid:first").show();
      $("#tabs li a").click(function () {
        var t = $(this).attr("id");

        if ($(this).hasClass("inactive")) {
          $("#tabs li a").addClass("inactive");
          $(this).removeClass("inactive");

          $(".container-fluid").hide();
          $("#" + t + "C").fadeIn("slow");
        }
        if ($(this).hasClass("inactive")) {
          $("#tabs li a").addClass("inactive");
          $(this).removeClass("inactive");
          $(".container-fluid").hide();
          $(t).fadeIn("slow");
        }
      });
    });

    $(document).ready(function () {
      $(".navTbasLink").on("click", "li a", function () {
        $(".navTbasLink li a").removeClass("activeTab");
        $(this).addClass("activeTab");
      });
    });
    this.changeTab({ target: { id: "Doctor" } });
  }

  getUsersdeatils() {
    this.spinner.show();
    this.apiService
      .ListUsers(
        this.user_id,
        this.page_size,
        this.current_page,
        this.search_value,
        this.sort_value,
        this.user_type
      )
      .subscribe(
        (res) => {
          //this.data = res["content"]["data"];
          let udpatedData = res["content"]["data"].map((item) => {
            if (item.profile_picture) {
              item.profile_picture = `${this.imgPath}${item.profile_picture}`;
            }
            return item;
          });
          this.data = udpatedData;
          this.spinner.hide();
        },
        (err) => {
          if (err.error && err.error.message) {
            this.toastService.show(
              err.error.message + `<br>` + "status code:" + err.status,
              { classname: "bg-danger text-white", delay: this.errTime }
            );
          } else {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }
        }
      );
  }

  changeTab(event) {
    switch (event.target.id) {
      case "Doctor":
        this.user_type = "doctors";
        this.imgPath = this.baseImgUrl + "uploads/doctors/profile_picture/";
        this.getUsersdeatils();
        break;

      case "Driver":
        this.user_type = "drivers";
        this.imgPath = this.baseImgUrl + "uploads/drivers/profile_picture/";
        this.getUsersdeatils();
        break;

      case "Nurse":
        this.user_type = "nurses";
        this.imgPath = this.baseImgUrl + "uploads/nurses/profile_picture/";
        this.getUsersdeatils();
        break;

      case "AocErStaff":
        this.user_type = "aoc_er_staffs";
        this.imgPath =
          this.baseImgUrl + "uploads/aoc_er_staffs/profile_picture/";

        this.getUsersdeatils();
        break;

      case "AocEmsStaff":
        this.user_type = "aoc_ems_staffs";
        this.imgPath =
          this.baseImgUrl + "uploads/aoc_ems_staffs/profile_picture/";

        this.getUsersdeatils();
        break;

      case "AocUser":
        this.user_type = "aoc_users";
        this.imgPath = this.baseImgUrl + "uploads/aoc_users/profile_picture/";

        this.getUsersdeatils();
        break;

      case "Paramedic":
        this.user_type = "paramedics";
        this.imgPath = this.baseImgUrl + "uploads/paramedics/profile_picture/";

        this.getUsersdeatils();
        break;

      case "Volunteer":
        this.user_type = "volunteer";
        this.imgPath = this.baseImgUrl + "uploads/volunteers/profile_picture/";

        this.getUsersdeatils();
        break;

      // default:
      //   this.user_type = "doctors";
      //   this.imgPath = this.imgPath + "uploads/doctors/profile_picture/";

      //   this.getUsersdeatils();
    }
  }

  status: boolean = false;
  openMapviewModal() {
    this.status = !this.status;
  }

  checkInOutChange(user, userType, event) {
    switch (userType) {
      case "Doctor":
        this.user_type = "Doctor";
        this._user_id = user["_id"];
        this.isactive = event.target.value;
        break;

      case "Driver":
        this.user_type = "Driver";
        this._user_id = user["_id"];
        this.isactive = event.target.value;
        break;

      case "Nurse":
        this.user_type = "Nurse";
        this._user_id = user["_id"];
        this.isactive = event.target.value;
        break;

      case "AocErStaff":
        this.user_type = "AocErStaff";
        this._user_id = user["_id"];
        this.isactive = event.target.value;
        break;

      case "AocEmsStaff":
        this.user_type = "AocEmsStaff";
        this._user_id = user["_id"];
        this.isactive = event.target.value;
        break;

      case "AocUser":
        this.user_type = "AocUser";
        this._user_id = user["_id"];
        this.isactive = event.target.value;
        break;

      default:
        this._user_id = user["_id"];
        this.isactive = event.target.value;
    }

    this.apiService
      .checkInOutChange(this.isactive, this.user_type, this._user_id)
      .subscribe(
        (res) => {},
        (err) => {
          if (err.error && err.error.message) {
            this.toastService.show(
              err.error.message + `<br>` + "status code:" + err.status,
              { classname: "bg-danger text-white", delay: this.errTime }
            );
          } else {
            this.toastService.show(err.message, {
              classname: "bg-danger text-white",
              delay: this.errTime,
            });
          }
        }
      );
  }
}
