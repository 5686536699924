import { Injectable } from '@angular/core';
import { ecgConfig } from '../app.module';

@Injectable({
  providedIn: 'root'
})

export class EcgService {
  ecg = this.ecgSocket.fromEvent<any>('data');
  ecgRoomJoined: any = [];

  text_ECG_I = 'EcgI';
  text_ECG_II = 'EcgII';
  text_ECG_III = 'EcgIII';
  text_ECG_SPO2 = 'Spo2';

  constructor(public ecgSocket: ecgConfig) { }

  roomJoin(roomName: string) {
    this.ecgSocket.emit('room_join', String(roomName));
  }

  roomLeave(roomName: string) {
    this.ecgSocket.emit('room_leave', String(roomName));
  }
}
