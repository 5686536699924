<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 p-0" style="display: inline-block; vertical-align: top; background:rgb(0, 0, 0);">
            <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;" id="moniter_serial_number">Loading...</p>
            <div class="row">
                <!-- <div class="col-md-3">
                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;">ECG I</p>
                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;" id="ecgi_data_length"></p>
                </div> -->
                <!-- <div class="col-md-3">
                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;">ECG II</p>
                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;" id="ecgii_data_length"></p>
                </div> -->
                <!-- <div class="col-md-3">
                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;">ECG III</p>
                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;" id="ecgiii_data_length"></p>
                </div> -->
                <!-- <div class="col-md-3">
                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;">SPO2</p>
                    <p style="margin-top: 0px; background:rgb(4, 5, 15); color: #6495ed; text-align: center; font-size: 75%;" id="spo2_data_length"></p>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <div id="chartContainer2" style="height: 120px; width: 100%;"></div>
        <!-- <div id="chartContainer1" style="height: 120px; width: 100%;"></div> -->
        <!-- <div id="chartContainer3" style="height: 120px; width: 100%;"></div> -->
        <div id="chartContainer4" style="height: 120px; width: 100%;"></div>
    </div>
    <div class="row">
        <div class="col-md-3 p-0" style="display: inline-block; vertical-align: top;">
            <div id="rectangle" style="background:rgb(61, 136, 45); color: white;height: 150px;">
                <span style="margin-top: 0px; background:rgb(35, 85, 26);display: block;width: 100%;padding: 0 5px;">
                    <span> HR </span> 
                    <span class="right">b/min</span>
                </span>
                <div class="ecg_box">
                    <span class="minmax max_value">120</span>
                    <h1 class="ecgValues" id="hr">--</h1>
                    <span class="minmax min_value">50</span>

                    <div class="heart_icon">
                        <span>
                            <i class="fa fa-heart" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 p-0" style="display: inline-block; vertical-align: top;">
            <div id="rectangle" style="background:rgb(128, 136, 139); color: white;height: 150px;">
                <span style="margin-top: 0px; background:rgb(87, 95, 100);display: block;width: 100%;padding: 0 5px;">
                    <span>NIBP</span>
                    <span class="right">mmHG</span>
                </span>
                <div  class="ecg_box">
                    <span class="minmax max_value">160</span>
                    <span class="systitle">SYS/DIA</span>
                    <span class="d-flex" style="justify-content: center;
                    align-items: center;height: 100%;">
                        <h1 class="ecgValues" id="nibpsys">--</h1>
                        <b style="margin-top: -4%;"> / </b>
                        <h1 class="ecgValues" id="nibpdia">--</h1>
                        <br>
                    </span>
                    <h1 class="ecgValues extraecg" id="nibpmap">--</h1>
                    <span class="minmax min_value">90</span>
                    <div class="progress_vertical">
                        <p class="m-0"><ngb-progressbar type="success" [value]="25"></ngb-progressbar></p> 
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 p-0" style="display: inline-block; vertical-align: top;">
            <div id="rectangle" style="background: rgb(59, 72, 190); color: white;height: 150px;">
                <span style="margin-top: 0px; background:rgb(31, 51, 139);display: block;width: 100%;padding: 0 5px;">
                    <span> SpO2 </span>
                    <span class="right"> % </span>
                </span>
                <div class="ecg_box">
                    <span class="minmax max_value">100</span>
                    <h1 class="ecgValues" id="spo2">--</h1>
                    <span class="minmax min_value">50</span>
                    <div class="progress_vertical">
                        <p class="m-0"><ngb-progressbar type="success" [value]="25"></ngb-progressbar></p> 
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 p-0" style="display: inline-block; vertical-align: top;">
            <div id="rectangle" style="background: rgb(242, 101, 72); color: white;height: 150px;">
                <span style="margin-top: 0px; background:rgb(239, 56, 36);display: block;width: 100%;padding: 0 5px;">
                    <span> EtCO2 </span>
                    <span class="right"> mmHG </span>
                </span>
                <div class="ecg_box">
                    <h1 class="ecgValues" id="etco2">--</h1>
                </div>
            </div>
        </div>
    </div>
</div>