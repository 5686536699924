<div #content class="alivemodalopen">
    <div class="modal-body">
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
      
      <div class="aliveView">
        <div class="row">
          <div class="col-md-12">
              <div class="aliveInfo">
                <div class="row">
                    <div class="col-md-3"> 
                        <div class="aliveImg">
                            <img onerror="this.src='/assets/images/AOC_LOGO.png'" [src]="profilePath + aliveData?.patientRequestsDetail?.patientsDetail?.profile_image" alt="aliveProfile" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-5"> 
                        <div class="aliveDetail">
                            <div class="alivebox">
                                <label>{{'labels.name' | translate}}</label>
                                <span>{{aliveData?.patientRequestsDetail?.patientsDetail?.first_name}} {{aliveData?.patientRequestsDetail?.patientsDetail?.last_name}}</span>
                            </div>
                            <div class="alivebox">
                                <label>{{'labels.mobilecontact' | translate}}</label>
                                <span>{{aliveData?.patientRequestsDetail?.patientsDetail?.phone}}</span>
                                <span *ngIf="aliveData?.patientRequestsDetail?.patientsDetail?.phone.length == 0 && !(aliveData?.patientRequestsDetail?.patientsDetail?.phone)">N/A</span>
                            </div>
                            <div class="alivebox">
                                <label>{{'labels.currentsituation' | translate}}</label>
                                <span class="redText">{{aliveData?.patientRequestsDetail?.sickness_type}}</span>
                                <span *ngIf="!(aliveData?.patientRequestsDetail?.sickness_type) &&  aliveData?.patientRequestsDetail?.sickness_type.length == 0">N/A</span>
                            </div>
                            <div class="alivebox">
                                <label>{{'labels.location' | translate}}</label>
                                <span>{{aliveData?.destination?.address}}</span>
                                <span *ngIf="!(aliveData?.destination?.address) &&  aliveData?.destination?.address.length == 0">N/A</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4"> 
                        <div class="alivedate">
                            <span class="bluecolor">{{aliveData?.updatedAt | date:'medium' }}</span>
                        </div>
                    </div>
                </div>
              </div>
              <div class="staticMap">
                <img onerror="this.src='/assets/images/defaultmap.png'" [src]="mapImgPath + aliveData?.patientRequestsDetail?.location_image" alt="map" class="img-fluid">

                <div class="aliveAction">
                    <button (click) = "postPatientReqCancelRequest()" class="btn text-uppercase crashbtn redColor">
                    {{'buttons.reject' | translate}}
                    </button>
                    <button  (click)="openMod(mymodal, aliveData); close()" class="btn text-uppercase crashbtn greenColor">
                    {{'buttons.accept' | translate}}
                    </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'titles.selectambulancelist' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="ambulanceReqForm" #form="ngForm">
        <div class="col-md-12">
          <div class="form-group position-relative">
            <label for="ambulance_id">{{'labels.hospital' | translate}}</label>
            <input   type="text"  class="form-control" formControlName="hospital_id" [(ngModel)]="selectHospital" disabled>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group position-relative">
            <label for="ambulance_id">{{'labels.ambulance' | translate}}</label>
            <select class="form-control" [(ngModel)]="selecteAmbulance" formControlName="ambulance_id" (change)="ambulanceChange($event)">
              <option value="{{a._id}}"  *ngFor="let a of ambulanceList">{{a.ambulance_box_code}} {{a.name1}} {{a.name2}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="acceptRequest(); modal.close('Save click')">{{'buttons.request' | translate}}</button>
    </div>
  </ng-template>